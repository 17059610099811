import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, Modal, Select, Space} from "antd";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {adminLoadAllUsers} from "../../actions/accounts";

class CompaniesTableFiler extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFilter: 'ID',
            filterValue: '',
            filterKey: 'id',
            filterVisible: null,
            users: [],
            loadingUsers: false,
            usersLoaded: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingUsers && !this.state.usersLoaded) {
            this.setState({ loadingUsers: true })

            this.props.adminLoadAllUsers((res) => {
                this.setState({ loadingUsers: false, usersLoaded: true, users: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingUsers: false, usersLoaded: true });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    onChange(){
        let visibleValue = null;

        if(this.state.selectedFilter === 'OWNER') {
            visibleValue = this.getUserName(this.state.filterValue);
        }

        this.props.onChange({
            name: normalizeEnum(this.state.selectedFilter),
            key: this.state.filterKey,
            value: this.state.filterValue.trim(),
            visible: visibleValue
        });
    }

    closeModal(apply = false, close = true) {
        this.setState({
            selectedFilter: 'ID',
            filterValue: '',
            filterKey: 'id',
            filterVisible: null,
            users: [],
            loadingUsers: false,
            usersLoaded: false
        });

        if(close) {
            this.props.closeModal();
        }

        if(apply) {
            this.onChange();
        }
    }

    getUserName(guid) {
        let user = null;

        for(let i = 0; i < this.state.users.length; i++) {
            if(this.state.users[i].guid === guid) {
                user = this.state.users[i];
                break;
            }
        }

        return user !== null ? user.first_name + ' ' + user.last_name : '';
    }

    setFilter(name) {
        const filters = {
            EMAIL: { key: 'email', value: '' },
            NAME: { key: 'name', value: '' },
            OWNER: { key: 'owner', value: '' },
            ID: { key: 'id', value: '' },
            CAN_PLACE_ORDERS: { key: 'can_place_orders', value: '' },
        };

        this.setState({ selectedFilter: name, filterKey: filters[name].key, filterValue: filters[name].value });
    }

    render() {
        const { Option } = Select;

        let content = '';

        if(this.state.selectedFilter === 'NAME') {
            content = <Input value={this.state.filterValue}
                             onChange={(e) => this.setState({filterValue: e.target.value})}/>
        } else if(this.state.selectedFilter === 'ID') {
            content = <Input value={this.state.filterValue}
                             onChange={(e) => this.setState({ filterValue: e.target.value })} />
        } else if(this.state.selectedFilter === 'EMAIL') {
            content = <Input value={this.state.filterValue}
                             onChange={(e) => this.setState({ filterValue: e.target.value })} />
        } else if(this.state.selectedFilter === 'OWNER') {
            content = <Select
                filterOption={(input, option) => {
                    if(typeof option !== 'undefined') {
                        if(option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0) {
                            return true;
                        } else {
                            return option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                        }
                    }
                    return false;
                }}
                loading={this.state.loadingUsers}
                disabled={this.state.loadingUsers}
                value={this.state.filterValue}
                showSearch allowClear
                onChange={(value) => this.setState({ filterValue: value })}>
                {this.state.users.map((user, i) => {
                    return <Option key={i} value={user.guid}>{user.first_name + ' ' + user.last_name} ({user.email})</Option>
                })}
            </Select>
        } else if(this.state.selectedFilter === 'CAN_PLACE_ORDERS') {
            content = <Select
                value={this.state.filterValue}
                onChange={(value) => this.setState({ filterValue: value })}>
                <Option value='true'>Yes</Option>
                <Option value='false'>No</Option>
            </Select>
        }

        return(
            <Modal
                title='Add Filter'
                destroyOnClose={true}
                onCancel={() => this.closeModal()}
                footer={<Space>
                    <Button onClick={() => this.closeModal()}>Close</Button>
                    <Button onClick={() => this.closeModal(true, false)}>Apply</Button>
                    <Button type='primary' onClick={() => this.closeModal(true, true)}>Apply & Close</Button>
                </Space>}
                open={this.props.showModal}>
                <Form layout='vertical'>
                    <Form.Item label='Select filter:'>
                        <Select value={this.state.selectedFilter} onChange={(value) => this.setFilter(value)}>
                            <Option value='ID'>ID</Option>
                            <Option value='EMAIL'>Email address</Option>
                            <Option value='NAME'>Name</Option>
                            <Option value='OWNER'>Owner</Option>
                            <Option value='CAN_PLACE_ORDERS'>Can place orders?</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        {content}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { adminLoadAllUsers })(CompaniesTableFiler);