import React from "react";
import {connect} from "react-redux";
import {Button, Form, Modal, Select, Space, message} from "antd";
import {displayErrors} from "../../libs/utils";
import {loadAllCompanies} from "../../actions/users";
import {adminCreateCreditNote} from "../../actions/billing";

class NewCreditNoteModal extends React.Component {
    state = {
        loadingCompanies: false,
        companiesLoaded: false,
        companies: [],
        selectedCompany: '',
        currency: 'USD',
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingCompanies && !this.state.companiesLoaded) {
            this.setState({ loadingCompanies: true })

            this.props.loadAllCompanies((res) => {
                this.setState({ loadingCompanies: false, companiesLoaded: true, companies: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingCompanies: false, companiesLoaded: true });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    closeModal() {
        this.setState({
            loadingCompanies: false,
            companiesLoaded: false,
            companies: [],
            selectedCompany: '',
            currency: 'USD',
        });

        this.props.close();
    }

    createCreditNote() {
        this.setState({ loading: true });

        let owner = this.state.selectedCompany.trim();
        let currency = this.state.currency.trim();

        if(owner.length === 0) {
            return message.error('Please select a company!');
        }

        if(currency.lenght === 0) {
            return message.error('Please select a currency!');
        }

        this.props.adminCreateCreditNote({ owner, currency }, (res) => {
            this.setState({ loading: false });
            this.props.history.push('/billing/credit-notes/' + res.data.id);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    selectCompany(value) {
        let data = {
            selectedCompany: value
        };

        for(let i = 0; i < this.state.companies.length; i++) {
            if(this.state.companies[i].guid === value) {
                data['currency'] = this.state.companies[i].currency;
                break;
            }
        }

        this.setState(data);
    }

    render() {

        const { Option } = Select;

        return(
            <Modal title='New Credit Note'
                   destroyOnClose={true}
                   footer={<Space>
                       <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Close</Button>
                       <Button loading={this.state.loading} disabled={this.state.loading} type='primary' onClick={() => this.createCreditNote()}>Create</Button>
                   </Space>}
                   visible={this.props.showModal}
                   onCancel={() => this.closeModal()}>
                <Form layout='vertical'>
                    <Form.Item label='Company'>
                        <Select
                            filterOption={(input, option) => {
                                if(typeof option !== 'undefined') {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                                return false;
                            }}
                            loading={this.state.loadingCompanies}
                            disabled={this.state.loadingCompanies}
                            value={this.state.filterValue}
                            showSearch
                            allowClear
                            onChange={(value) => this.selectCompany(value)}>
                            {this.state.companies.map((company, i) => {
                                return <Option key={i} value={company.guid}>{company.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Currency'>
                        <Select value={this.state.currency} onChange={(value) => this.setState({ currency: value })}>
                            <Option value='EUR'>EUR</Option>
                            <Option value='USD'>USD</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { loadAllCompanies, adminCreateCreditNote })(NewCreditNoteModal);