import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    adminCancelCancellationRequest, adminCreateCancellationRequestCreditNote,
    adminLoadCancellationRequest, adminRemoveCancellationItem,
    adminProcessCancellationRequest
} from "../../actions/billing";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {Button, Col, Descriptions, Popconfirm, Row, Space, Table, message} from "antd";
import {Loading} from "../../libs/loading";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {Link} from "react-router-dom";
import {StatusIndicator} from "../shared/Status";
import {DateFormat} from "../shared/DateFormat";
import {MoneyField} from "../shared/MoneyField";
import {CarryOutOutlined, DeleteOutlined, DollarCircleOutlined} from "@ant-design/icons";
import {DateTimeFrom} from "../shared/DateTimeFrom";

class CancellationRequestsView extends React.Component {
    state = {
        loadingCancellationRequest: false,
        data: null,
        cancelling: false,
        processing: false
    };

    componentDidMount() {
        this.props.updatePage('Cancellation Request');
        this.adminLoadCancellationRequest();
    }

    adminLoadCancellationRequest() {
        this.setState({ loadingCancellationRequest: true });

        this.props.adminLoadCancellationRequest(this.props.match.params.id, (res) => {
            this.setState({ loadingCancellationRequest: false, data: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCancellationRequest: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminCancelRequest() {
        this.setState({ cancelling: true });

        this.props.adminCancelCancellationRequest(this.props.match.params.id, () => {
            this.setState({ cancelling: false });
            message.success('Cancellation request successfully cancelled!');
            this.adminLoadCancellationRequest();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ cancelling: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminProcessRequest() {
        this.setState({ processing: true });

        this.props.adminProcessCancellationRequest(this.props.match.params.id, () => {
            this.setState({ processing: false });
            message.success('Cancellation request successfully processed!');
            this.adminLoadCancellationRequest();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ processing: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminCreateCreditNote() {
        this.setState({ processing: true });

        this.props.adminCreateCancellationRequestCreditNote(this.props.match.params.id, () => {
            this.setState({ processing: false });
            message.success('Credit note successfully created!');
            this.adminLoadCancellationRequest();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ processing: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminRemoveCancellationItem(guid) {
        this.props.adminRemoveCancellationItem(this.props.match.params.id, guid, () => {
            message.success('Item successfully removed!');
            this.adminLoadCancellationRequest();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ processing: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        if(this.state.loadingCancellationRequest || this.state.data === null) {
            return <div className='text-center'><Loading /></div>;
        }

        const { data } = this.state;
        let items = '';

        if(data.ip_addresses !== null && data.ip_addresses.length > 0) {
            let ipColumns = [
                { title: 'IP address', dataIndex: 'ip_address' },
                { title: 'Location', dataIndex: 'location' },
                { title: 'Type', dataIndex: 'ip_address_type', render: (item) => normalizeEnum(item)},
                { title: 'Domains Allowed', dataIndex: 'domains_allowed', align: 'center'},
                { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />},
                { title: 'Order', dataIndex: 'order', render: (item) => <span><Link to={'/billing/orders/' + item.id}>#{item.id}</Link> ({normalizeEnum(item.status)})</span>},
                { title: 'Invoice', dataIndex: 'invoice', render: (item) => <span><Link to={'/billing/invoices/' + item.id}>#{item.id}</Link> ({normalizeEnum(item.status)})</span>},
                { title: 'Renewal Date', dataIndex: 'date_renewal', render: (item) => <DateFormat value={item} />},
                { title: 'Monthly cost', dataIndex: 'monthly_cost', render: (item, record) => <MoneyField amount={item} currency={record.currency} />},
                { title: 'Remaining funds', dataIndex: 'remaining_funds', render: (item, record) => <MoneyField amount={item} currency={record.currency} />},
                { title: '', render: (item, record) => {
                    return <Popconfirm
                        title="Are you sure you want to remove this IP address?"
                        onConfirm={() => this.adminRemoveCancellationItem(record.guid)}
                        okText='Remove'
                        placement='topRight'
                        okButtonProps={{danger: true}}
                        cancelText=''>
                        <Button type='circle' size='small' icon={<DeleteOutlined />} />
                    </Popconfirm>;
                }}
            ];

            items = <Table
                columns={ipColumns}
                rowKey={(item) => item.ip_address}
                dataSource={data.ip_addresses}
                pagination={false} />;
        }
        else if(data.hosting_account !== null) {
            items = <Descriptions bordered column={1} title='Details' size='small'>
                <Descriptions.Item label='Server'>{data.hosting_account.hostname}</Descriptions.Item>
                <Descriptions.Item label='IP address'>{data.hosting_account.ip_address}</Descriptions.Item>
                <Descriptions.Item label='Username'>{data.hosting_account.username}</Descriptions.Item>
                <Descriptions.Item label='Account'><Link to={'/hosting/' + data.hosting_account.guid}>{data.hosting_account.name}</Link></Descriptions.Item>
                <Descriptions.Item label='Order'><Link to={'/billing/orders/' + data.hosting_account.order.id}>#{data.hosting_account.order.id}</Link></Descriptions.Item>
                <Descriptions.Item label='Order status'><StatusIndicator status={data.hosting_account.order.status} el='badge' /></Descriptions.Item>
                <Descriptions.Item label='Invoice'><Link to={'/billing/invoices/' + data.hosting_account.invoice.id}>#{data.hosting_account.invoice.id}</Link></Descriptions.Item>
                <Descriptions.Item label='Invoice status'><StatusIndicator status={data.hosting_account.invoice.status} el='badge' /></Descriptions.Item>
                <Descriptions.Item label='Monthly cost'><MoneyField amount={data.hosting_account.order.monthly_cost} currency={data.hosting_account.order.currency} /></Descriptions.Item>
                <Descriptions.Item label='Remaining funds'><MoneyField amount={data.hosting_account.order.remaining_funds} currency={data.hosting_account.order.currency} /></Descriptions.Item>
            </Descriptions>
        }

        return(
            <Fragment>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Descriptions bordered column={1} title='Details' size='small'>
                            <Descriptions.Item label='ID'>{data.id}</Descriptions.Item>
                            <Descriptions.Item label='Customer'><Link to={'/accounts/companies/' + data.owner.guid}>{data.owner.name}</Link></Descriptions.Item>
                            <Descriptions.Item label='Created'><DateTimeFormat value={data.date_created} /> <small>(<DateTimeFrom value={data.date_created} />)</small></Descriptions.Item>
                            <Descriptions.Item label='Status'><StatusIndicator status={data.status} el='badge' /></Descriptions.Item>
                            <Descriptions.Item label='Cancellation time'>{normalizeEnum(data.cancellation_time)}</Descriptions.Item>
                            {data.status !== 'PROCESSED' ? <Descriptions.Item label='Actions'>
                                <Space>
                                    <Popconfirm
                                        title="Are you sure you want to process this request?"
                                        onConfirm={() => this.adminProcessRequest()}
                                        okText='Continue'
                                        okButtonProps={{danger: true}}
                                        cancelText=''>
                                        <Button type='primary' disabled={this.state.cancelling || this.state.processing} loading={this.state.processing} size='small' icon={<CarryOutOutlined />}>Process</Button>
                                    </Popconfirm>
                                    <Popconfirm
                                        title="Are you sure you want to cancel this request?"
                                        onConfirm={() => this.adminCancelRequest()}
                                        okText='Continue'
                                        okButtonProps={{danger: true}}
                                        cancelText=''>
                                        <Button type='round' disabled={this.state.cancelling || this.state.processing} loading={this.state.cancelling} size='small' icon={<DeleteOutlined />}>Cancel</Button>
                                    </Popconfirm>
                                    <Popconfirm
                                        title={<span>Are you sure you wish to create a credit note<br />for this cancellation request?</span>}
                                        onConfirm={() => this.adminCreateCreditNote()}
                                        okText='Continue'
                                        okButtonProps={{danger: true}}>
                                        <Button type='round' disabled={this.state.cancelling || this.state.processing || data.credit_note_created} loading={this.state.cancelling} size='small' icon={<DollarCircleOutlined />}>Create credit note</Button>
                                    </Popconfirm>
                                </Space>
                            </Descriptions.Item> : ''}
                            {data.order !== null ? <Descriptions.Item label='Order'><Link to={'/billing/orders/' + data.order}>#{data.order}</Link></Descriptions.Item> : ''}
                        </Descriptions>
                    </Col>
                    <Col span={12}>
                        <Descriptions bordered column={1} title='Feedback' size='small'>
                            <Descriptions.Item label='Reason'>{normalizeEnum(data.cancellation_reason)}</Descriptions.Item>
                            <Descriptions.Item label='Custom reason'>{data.custom_cancellation_reason}</Descriptions.Item>
                            <Descriptions.Item label='Score'>{data.recommendation_score}</Descriptions.Item>
                            <Descriptions.Item label='Returns'>{data.how_likely_to_use_us_again}</Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 16]}>
                    <Col span={24}>{items}</Col>
                </Row>
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminLoadCancellationRequest,
    adminCancelCancellationRequest, adminProcessCancellationRequest, adminCreateCancellationRequestCreditNote,
    adminRemoveCancellationItem})(CancellationRequestsView);