import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Col, Form, Row, Select, Space, Table} from "antd";
import {adminMonthlyRefundsReport} from "../../actions/accounting";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {MoneyField} from "../shared/MoneyField";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import moment from 'moment';

class MonthlyRefundsReport extends React.Component {
    state = {
        lines: [],
        selectedYear: 0,
        selectedMonth: 0,
        loadingRefunds: false,
        invoices: [],
    };

    componentDidMount() {
        this.props.updatePage('Monthly Refunds');

        let date = new Date();
        this.setState({ selectedYear: date.getFullYear(), selectedMonth: date.getMonth() });
    }

    generateReport() {
        this.setState({ loadingRefunds: true });

        this.props.adminMonthlyRefundsReport(this.state.selectedMonth, this.state.selectedYear, (res) => {
            this.setState({ loadingRefunds: false, lines: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingRefunds: false });
                displayErrors(err.response.data);
            }
        });
    }

    copyToClipboard(columns) {
        let lines = []
        let columnLines = [];

        for(let i = 0; i < columns.length; i++) {
            columnLines.push(columns[i].title);
        }

        lines.push(columnLines.join('\t'));

        let eurAmount = 0;
        let usdAmount = 0;

        for(let i = 0; i < this.state.lines.length; i++) {
            let tmpItems = [];

            let keys = Object.keys(this.state.lines[i]);

            for(let j = 0; j < keys.length; j++) {
                if(keys[j] !== 'row_id') {
                    if(keys[j] === 'date_created') {
                        tmpItems.push(moment(this.state.lines[i][keys[j]]).format('YYYY/MM/DD HH:mm'))
                    } else {
                        tmpItems.push(this.state.lines[i][keys[j]]);
                    }

                    if(keys[j] === 'currency' && this.state.lines[i][keys[j]] === 'EUR') {
                        eurAmount += this.state.lines[i].amount;
                    } else if(keys[j] === 'currency' && this.state.lines[i][keys[j]] === 'USD') {
                        usdAmount += this.state.lines[i].amount;
                    }
                }
            }

            lines.push(tmpItems.join('\t'));
        }

        lines.push('');
        lines.push('\t\t\t\tUSD\t' + usdAmount);
        lines.push('\t\t\t\tEUR\t' + eurAmount);

        let tmpInput = document.createElement('textarea');
        tmpInput.id = 'tmpInput';
        tmpInput.innerHTML= lines.join('\n');

        document.body.append(tmpInput);

        tmpInput.select();
        document.execCommand("copy");
        tmpInput.remove();
    }

    render() {
        const { Option } = Select;

        let years = [];
        for(let i = 2016; i <= new Date().getFullYear(); i++) {
            years.push(i);
        }

        let months = [];
        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August",
            "September", "October", "November", "December"];
        for(let i = 1; i <= 12; i++) {
            months.push(i);
        }

        const reportColumns = [
            { title: 'Refund', dataIndex: 'refund_id'},
            { title: 'Status', dataIndex: 'status', render: (item) => normalizeEnum(item)},
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: 'Company', dataIndex: 'company_name' },
            { title: 'Currency', align: 'center', dataIndex: 'currency' },
            { title: 'Amount', align: 'center', dataIndex: 'amount' },
        ];

        return(
            <Fragment>
                <Form layout='vertical'>
                    <Row gutter={[16, 16]}>
                        <Col span={3}>
                            <Form.Item label='Select month:'>
                                <Select value={this.state.selectedMonth} onChange={(value) => this.setState({ selectedMonth: value })}>
                                    {months.map((month, i) => {
                                        return <Option key={i} value={month}>{monthNames[month-1]}</Option>;
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item label='Select year:'>
                                <Select value={this.state.selectedYear} onChange={(value) => this.setState({ selectedYear: value })}>
                                    {years.map((year, i) => {
                                        return <Option key={i} value={year}>{year}</Option>;
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Space>
                                <Form.Item label='&nbsp;'>
                                    <Button type='primary' disabled={this.state.loadingRefunds} loading={this.state.loadingRefunds}
                                            onClick={() => this.generateReport()}>Generate</Button>
                                </Form.Item>
                                <Form.Item label='&nbsp;'>
                                    <Button type='primary' onClick={() => this.copyToClipboard(reportColumns)}>Copy</Button>
                                </Form.Item>
                            </Space>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col span={24}>
                            <Table dataSource={this.state.lines}
                                   columns={reportColumns} pagination={false}
                                   rowKey={(item) => item.row_id}
                                   summary={(records) => {
                                       let eurAmount = 0;
                                       let usdAmount = 0;

                                       for(let i = 0; i < records.length; i++) {
                                           if(records[i].currency === 'EUR') {
                                               eurAmount += records[i].amount;
                                           } else if(records[i].currency === 'USD') {
                                               usdAmount += records[i].amount;
                                           }
                                       }

                                       return <Fragment>
                                           <Table.Summary.Row>
                                               <Table.Summary.Cell colSpan={4} />
                                               <Table.Summary.Cell align='center'>
                                                   <strong>USD</strong>
                                               </Table.Summary.Cell>
                                               <Table.Summary.Cell align='center'>
                                                   <strong><MoneyField amount={usdAmount} currency={'USD'} /></strong>
                                               </Table.Summary.Cell>
                                           </Table.Summary.Row>
                                           <Table.Summary.Row>
                                               <Table.Summary.Cell colSpan={4} />
                                               <Table.Summary.Cell align='center'>
                                                   <strong>EUR</strong>
                                               </Table.Summary.Cell>
                                               <Table.Summary.Cell align='center'>
                                                   <strong><MoneyField amount={eurAmount} currency='EUR' /></strong>
                                               </Table.Summary.Cell>
                                           </Table.Summary.Row>
                                       </Fragment>;
                                   }}
                                   size='small' />
                        </Col>
                    </Row>
                </Form>
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminMonthlyRefundsReport })(MonthlyRefundsReport);