import React from "react";
import {connect} from "react-redux";
import {Button, Form, Modal, Space, message, Select, Row, Col, Input} from "antd";
import {adminAddNewIPAddresses, adminLoadAllPriceGroups} from "../../actions/network";
import {displayErrors} from "../../libs/utils";
import TextArea from "antd/es/input/TextArea";

class NewIPAddressesModal extends React.Component {
    state = {
        priceGroups: [],
        loadingPriceGroup: false,
        priceGroupLoaded: false,
        priceGroup: '',
        ipAddressType: 'DEDICATED',
        ipAddresses: [],
        ownCost: ''
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingPriceGroup && !this.state.priceGroupLoaded) {
            this.adminLoadAvailablePriceGroups();
        }
    }

    closeModal(reload = false) {
        this.setState({
            priceGroups: [],
            loadingPriceGroup: false,
            priceGroupLoaded: false,
            priceGroup: '',
            ipAddressType: 'DEDICATED',
            ipAddresses: [],
            ownCost: ''
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    adminLoadAvailablePriceGroups() {
        this.setState({ loadingPriceGroup: true });

        this.props.adminLoadAllPriceGroups((res) => {
            this.setState({ priceGroups: res.data.data, priceGroupLoaded: true, loadingPriceGroup: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ priceGroupLoaded: true, loadingPriceGroup: false });
                displayErrors(err.response.data);
            }
        });
    }

    addIPAddresses() {
        let price_group = this.state.priceGroup.trim();
        let ip_address_type = this.state.ipAddressType.trim();
        let own_cost = this.state.ownCost.trim();
        let ip_addresses = [];

        if(price_group.length === 0) {
            return message.error('Please select price group!');
        }

        if(ip_address_type.length === 0) {
            return message.error('Please select IP address type!');
        }

        if(own_cost.length === 0) {
            return message.error('Please enter own cost!');
        }

        for(let i = 0; i < this.state.ipAddresses.length; i++) {
            ip_addresses.push(this.state.ipAddresses[i].trim());
        }

        let data = { price_group, ip_address_type, ip_addresses, own_cost };

        this.setState({ saving: true });

        this.props.adminAddNewIPAddresses(this.props.ipNode, data, (res) => {
            this.setState({ saving: false });
            message.success('IP addresses successfully added!');
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ saving: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        return(
            <Modal title='Add IP Addresses'
                   visible={this.props.showModal}
                   onCancel={() => this.closeModal()}
                   footer={<Space>
                       <Button disabled={this.state.saving} onClick={() => this.closeModal()}>Close</Button>
                       <Button disabled={this.state.saving} loading={this.state.saving}  type='primary' onClick={() => this.addIPAddresses()}>Add</Button>
                   </Space>}>
                <Form layout='vertical'>
                    <Row gutter={[16, 16]}>
                        <Col span={8}>
                            <Form.Item label='Price group'>
                                <Select loading={this.state.loadingPriceGroup} value={this.state.priceGroup} onChange={(value) => this.setState({ priceGroup: value })}>
                                    {this.state.priceGroups.map((group) => <Option key={group.guid} value={group.guid}>{group.name}</Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label='IP type'>
                                <Select value={this.state.ipAddressType} onChange={(value) => this.setState({ ipAddressType: value })}>
                                    <Option value='DEDICATED'>Dedicated</Option>
                                    <Option value='SHARED'>Shared</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label='Own cost'>
                                <Input value={this.state.ownCost} onChange={(e) => this.setState({ ownCost: e.target.value })} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label='IP addresses' extra={<small>One IP address per row!</small>}>
                        <TextArea value={this.state.ipAddresses.join('\n')} rows={10} onChange={(e) => this.setState({ ipAddresses: e.target.value.split('\n')})} />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { adminLoadAllPriceGroups, adminAddNewIPAddresses })(NewIPAddressesModal);