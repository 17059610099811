import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Loading} from "../../libs/loading";
import {
    Button, Card,
    Col,
    Descriptions, Dropdown, Form,
    Input, InputNumber,
    Menu,
    message,
    Modal, Pagination,
    Row,
    Select, Space,
    Switch,
    Table,
    Tag, Tooltip,
    Typography
} from "antd";
import {
    adminBulkUpdateIPAddresses, adminGetIPNodeCredentials, adminIPNodeRunCmd, adminLoadAllPriceGroups,
    adminLoadIPNode,
    adminLoadIPNodeIPAddresses,
    adminUpdateIPAddress, updateIPNode
} from "../../actions/network";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {DEFAULT_FLAG_SIZE, DEFAULT_PER_PAGE} from "../../config";
import Flag from "react-world-flags";
import {StatusIndicator} from "../shared/Status";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import DesktopOutlined from "@ant-design/icons/lib/icons/DesktopOutlined";
import {MoneyField} from "../shared/MoneyField";
import {
    BarsOutlined,
    BranchesOutlined,
    CheckOutlined, CheckSquareOutlined,
    CloseOutlined, CodeOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined, ExportOutlined, EyeOutlined,
    FilterOutlined, LinkOutlined, SaveOutlined,
    SettingOutlined, ShoppingCartOutlined
} from "@ant-design/icons";
import IPNodeIPAddressFilter from "./IPNodeIPAddressFilter";
import NewIPAddressesModal from "./NewIPAddressesModal";
import SetPriceGroupModal from "./SetPriceGroupModal";
import IPNodeExportIPAddressesModal from "./IPNodeExportIPAddressesModal";
import Paragraph from "antd/es/typography/Paragraph";
import {adminLoadDomains} from "../../actions/domains";
import {Link} from "react-router-dom";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import MoveIPAddressesToAnotherNodeModal from "./MoveIPAddressesToAnotherNodeModal";
import SetIPAddressOwnCostModal from "./SetIPAddressOwnCostModal";
import IPAddressHealthCheckModal from "./IPAddressHealthCheckModal";

class IPNodeView extends React.Component {
    state = {
        loading: false,
        data: null,
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        domainListPagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        ipAddresses: [],
        selectedIPAddresses: [],
        filters: [],
        showFiltersModal: false,
        priceGroups: [],
        showNewIPAddressesModal: false,
        showSetPriceGroupModal: false,
        showIPAddressExportModal: false,
        username: null,
        password: null,
        loadingCredentials: false,
        adminPassword: '',
        showCmdResponseModal: false,
        activeService: '',
        activeServiceAction: '',
        runningCommand: false,
        commandResponse: '',
        ipAddressDomains: [],
        loadingIPAddressDomains: false,
        activeIPAddress: null,
        showMoveIPAddressesModal: false,
        showSetOwnPriceModal: false,
        updatingOwnPrice: false,
        newOwnCost: null,
        selectedDedicatedIPDefaultPriceGroup: '',
        selectedSharedIPDefaultPriceGroup: '',
        updatingDefaultPriceGroup: false,
        serverCostCurrency: '',
        serverCostAmount: '',
        serverIPAddress: '',
        serverHostname: '',
        showIPAddressHealthCheckModal: false,
        isAvailable: true,
    };

    componentDidMount() {
        this.adminLoadIPNode();
        this.adminLoadIPAddresses();
        this.adminLoadAvailablePriceGroups();
    }

    adminLoadIPNode() {
        this.setState({ loading: true });

        this.props.adminLoadIPNode(this.props.match.params.guid, (res) => {
            this.setState({ loading: false,
                selectedDedicatedIPDefaultPriceGroup: res.data.dedicated_ip_default_price_group,
                serverCostAmount: res.data.cost.amount, serverCostCurrency: res.data.cost.currency,
                serverIPAddress: res.data.ip_address, serverHostname: res.data.hostname, isAvailable: res.data.is_available,
                selectedSharedIPDefaultPriceGroup: res.data.shared_ip_default_price_group, data: res.data });
            this.props.updatePage(res.data.hostname);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadIPAddresses(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingIPAddresses: true });

        this.props.adminLoadIPNodeIPAddresses(this.props.match.params.guid, page, per_page, this.state.filters, (res) => {
            window.scrollTo(0, 0);

            this.setState({ loadingIPAddresses: false, ipAddresses: res.data.data,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIPAddresses: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadAvailablePriceGroups() {
        this.props.adminLoadAllPriceGroups((res) => {
            this.setState({ priceGroups: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    clearFilters() {
        this.setState({ filters: [] }, () => this.adminLoadIPAddresses());
    }

    setFilter(filter) {
        let filters = this.state.filters.filter(f => f.key !== filter.key);
        filters.push(filter);

        this.setState({ filters }, () => this.adminLoadIPAddresses());
    }

    removeFilter(item) {
        let filters = this.state.filters.filter(filter => filter.key !== item.key);
        this.setState({ filters }, () => this.adminLoadIPAddresses());
    }

    showFilters() {
        return this.state.filters.map((filter, i) => {
            let value;

            if (typeof filter.value === 'boolean') {
                if (filter.value) {
                    value = 'Yes';
                } else {
                    value = 'No';
                }
            } else {
                if (filter.visible !== null) {
                    value = filter.visible;
                } else {
                    value = normalizeEnum(filter.value);
                }
            }

            return <Tag key={i} closable onClose={() => this.removeFilter(filter)}>{filter.name}: {value}</Tag>;
        });
    }

    updateIPAddressStatus(guid, status) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to {status ? 'enable' : 'disable'} this IP address?</span>,
            onOk: () => {
                let ipAddresses = this.state.ipAddresses.map((ip) => {
                    if(ip.guid === guid) {
                        return {...ip, status: status ? 'ACTIVE' : 'DISABLED'};
                    }

                    return ip;
                });

                this.setState({ ipAddresses: ipAddresses, loadingIPAddresses: true });

                let ipStatus = status ? 'ACTIVE' : 'DISABLED';

                let data = { status: ipStatus };

                this.props.adminUpdateIPAddress(this.props.match.params.guid, guid, data,  () => {
                    this.setState({ loadingIPAddresses: false });
                    message.success('IP address successfully updated!');
                    this.adminLoadIPAddresses();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ loadingIPAddresses: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    bulkUpdateIPAddresses(status) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set status to <strong>{normalizeEnum(status)}</strong> selected IP addresses?</span>,
            onOk: () => {
                this.setState({ loadingIPAddresses: true });

                let data = {
                    ip_addresses: this.state.selectedIPAddresses,
                    status: status
                }

                this.props.adminBulkUpdateIPAddresses(this.props.match.params.guid, data,  () => {
                    this.setState({ loadingIPAddresses: false, selectedIPAddresses: [] });
                    message.success('IP addresses successfully updated!');
                    this.adminLoadIPAddresses();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ loadingIPAddresses: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    changeIPAddress(guid, field, value) {
        let ipAddresses = this.state.ipAddresses.map((ip) => {
            if(ip.guid === guid) {
                ip[field] = value;
                return ip;
            }

            return ip;
        });

        this.setState({ ipAddresses });
    }

    changeIPAddressPriceGroup(guid, value, groupType) {
        let priceGroup = null;

        for(let i = 0; i < this.state.priceGroups.length; i++) {
            if(this.state.priceGroups[i].guid === value) {
                priceGroup = this.state.priceGroups[i];
                break;
            }
        }

        let ipAddresses = this.state.ipAddresses.map((ip) => {
            if(ip.guid === guid) {
                ip[groupType] = priceGroup;
                return ip;
            }

            return ip;
        });

        this.setState({ ipAddresses });
    }

    saveIPAddress(guid) {
        let ipAddress = null;

        for(let i = 0; i < this.state.ipAddresses.length; i++) {
            if(this.state.ipAddresses[i].guid === guid) {
                ipAddress = this.state.ipAddresses[i];
                break;
            }
        }

        if(ipAddress === null) {
            return message.error('Unable to save this IP address!');
        }

        let data = {
            price_group: ipAddress.price_group.guid,
            default_price_group: ipAddress.default_price_group.guid,
            ip_address: ipAddress.ip_address,
            ip_address_type: ipAddress.ip_address_type,
            own_cost: parseFloat(ipAddress.own_cost)
        };

        this.setState({ loadingIPAddresses: true });

        this.props.adminUpdateIPAddress(this.props.match.params.guid, guid, data,  () => {
            message.success('IP address successfully updated!');
            this.adminLoadIPAddresses();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIPAddresses: false });
                displayErrors(err.response.data);
            }
        });
    }

    getCredentials() {
        let password = this.state.adminPassword;

        if(password.length === 0) {
            return message.error('Please enter your password!');
        }

        this.setState({ loadingCredentials: true });

        this.props.adminGetIPNodeCredentials(this.props.match.params.guid, { password }, (res) => {
            this.setState({ loadingCredentials: false, username: res.data.user, password: res.data.password,
                showPasswordModal: false, adminPassword: '' });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCredentials: false });
                displayErrors(err.response.data);
            }
        });
    }

    runCmd() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to do that?</span>,
            onOk: () => {
                this.setState({ runningCommand: true, commandResponse: '' });

                this.props.adminIPNodeRunCmd(this.props.match.params.guid, { service: this.state.activeService, action: this.state.activeServiceAction},  (res) => {
                    this.setState({ commandResponse: res.data.response, runningCommand: false });
                }, (err) => {
                    this.setState({ runningCommand: false });
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    updateIPNodeDefaultPriceGroup() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to update the default price group?</span>,
            onOk: () => {
                this.setState({ updatingDefaultPriceGroup: true });

                let data = {
                    dedicated_ip_default_price_group: this.state.selectedDedicatedIPDefaultPriceGroup,
                    shared_ip_default_price_group: this.state.selectedSharedIPDefaultPriceGroup,
                }

                this.props.updateIPNode(this.props.match.params.guid, data,  () => {
                    this.setState({ updatingDefaultPriceGroup: false });
                    this.adminLoadIPAddresses();
                }, (err) => {
                    this.setState({ updatingDefaultPriceGroup: false });
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    loadIPAddressDomains(data, page = null, per_page = null) {
        this.setState({ activeIPAddress: data.guid, loadingIPAddressDomains: true });

        if(page == null) {
            page = this.state.domainListPagination.current;
            per_page = this.state.domainListPagination.pageSize;
        }

        this.setState({ loadingDomains: true });

        this.props.adminLoadDomains(page, per_page, [{ key: 'ip_address', value: data.ip_address }], (res) => {
            this.setState({ ipAddressDomains: res.data.data, loadingIPAddressDomains: false,
                domainListPagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIPAddressDomains: false });
                displayErrors(err.response.data);
            }
        });
    }

    selectAllUnusedIPAddresses() {
        let unusedIPAddresses = [];

        for(let i = 0; i < this.state.ipAddresses.length; i++) {
            if(this.state.ipAddresses[i].used_ip_address_count === 0) {
                unusedIPAddresses.push(this.state.ipAddresses[i].guid);
            }
        }

        this.setState({ selectedIPAddresses: unusedIPAddresses });
    }

    updateServerCost() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set the server cost to <MoneyField amount={this.state.serverCostAmount} currency={this.state.serverCostCurrency} /></span>,
            onOk: () => {
                this.setState({ loading: true });

                let data = {
                    server_cost: parseFloat(this.state.serverCostAmount.toFixed(2)),
                    server_cost_currency: this.state.serverCostCurrency,
                }

                this.props.updateIPNode(this.props.match.params.guid, data,  () => {
                    this.setState({ loading: false });
                    this.adminLoadIPNode();
                }, (err) => {
                    this.setState({ loading: false });
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    updateServerIPAddress() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set the server IP address to <strong>{this.state.serverIPAddress}</strong></span>,
            onOk: () => {
                this.setState({ loading: true });

                let data = {
                    ip_address: this.state.serverIPAddress
                }

                this.props.updateIPNode(this.props.match.params.guid, data,  () => {
                    this.setState({ loading: false });
                    this.adminLoadIPNode();
                }, (err) => {
                    this.setState({ loading: false });
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    updateServerHostname() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set the server hostname to <strong>{this.state.serverHostname}</strong></span>,
            onOk: () => {
                this.setState({ loading: true });

                let data = {
                    hostname: this.state.serverHostname
                }

                this.props.updateIPNode(this.props.match.params.guid, data,  () => {
                    this.setState({ loading: false });
                    this.adminLoadIPNode();
                }, (err) => {
                    this.setState({ loading: false });
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    updateIsAvailable(value) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set the availability to <strong>{value ? 'available' : 'not available'}</strong></span>,
            onOk: () => {
                this.setState({ loading: true });

                let data = {
                    is_available: value
                };

                this.props.updateIPNode(this.props.match.params.guid, data,  () => {
                    this.setState({ loading: false });
                    this.adminLoadIPNode();
                }, (err) => {
                    this.setState({ loading: false });
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    render() {
        if(this.state.loading || this.state.data === null) {
            return <div className='text-center'><Loading /></div>;
        }

        const { data } = this.state;
        const { Option } = Select;
        const { Text } = Typography;

        const columns = [
            { title: 'ID', dataIndex: 'id', width: '30px' },
            { width: '30px', render: (item, record) => <Switch size='small' checked={record.status === 'ACTIVE'} onChange={(value) => this.updateIPAddressStatus(record.guid, value)} /> },
            { title: 'IP address', dataIndex: 'ip_address', render: (item, record) => <Input key={record.guid} value={item} onChange={(e) => this.changeIPAddress(record.guid, 'ip_address', e.target.value)} /> },
            { title: 'Used', dataIndex: 'used_ip_address_count', align: 'center', render: (item) => item > 0 ? <Text type="danger">Yes</Text> : <Text type="success">No</Text>},
            { title: 'Type', dataIndex: 'ip_address_type', render: (item, record) => {
                return <Select disabled={record.used_ip_address_count > 0} style={{width: '100%'}} value={item} onChange={(value) => this.changeIPAddress(record.guid, 'ip_address_type', value)}>
                    <Option value='DEDICATED'>Dedicated</Option>
                    <Option value='SHARED'>Shared</Option>
                </Select>;
            }},
            { title: 'Status', width: '150px', dataIndex: 'status', render: (item, record) => {
                    if(record.status !== 'DISABLED' && !record.is_available) {
                        return <span style={{color:'red'}}><ExclamationCircleOutlined /> OFFLINE</span>;
                    }

                    return <StatusIndicator status={item} el='badge' />;
            }},
            { title: 'Price group', width: '250px', dataIndex: 'price_group', render: (item, record) => {
                return <Select disabled={record.used_ip_address_count > 0} style={{width: '100%'}} value={record.price_group.guid} onChange={(value) => this.changeIPAddressPriceGroup(record.guid,  value, 'price_group')}>
                    {this.state.priceGroups.map((group) => <Option  key={group.guid} value={group.guid}>{group.name}</Option>)}
                </Select>;
            }},
            { title: 'Default price group', width: '250px', dataIndex: 'price_group', render: (item, record) => {
                return <Select style={{width: '100%'}} value={record.default_price_group.guid} onChange={(value) => this.changeIPAddressPriceGroup(record.guid,  value, 'default_price_group')}>
                    {this.state.priceGroups.map((group) => <Option  key={group.guid} value={group.guid}>{group.name}</Option>)}
                </Select>;
            }},
            { title: 'Price $ ', dataIndex: 'price_group', render: (item) => <MoneyField amount={item.ip_address_price} currency='USD' /> },
            { title: 'Site Price $ ', dataIndex: 'price_group', render: (item) => <MoneyField amount={item.site_price} currency='USD' /> },
            { title: 'Cost ', dataIndex: 'own_cost', render: (item, record) => <Input key={record.guid} style={{width:'80px'}} value={item} onChange={(e) => this.changeIPAddress(record.guid, 'own_cost', e.target.value)} /> },
            { title: '', render: (item, record) => <Button size='small' type='circle' icon={<SaveOutlined />} onClick={() => this.saveIPAddress(record.guid)} />}
        ];

        const domainTableColumns = [
            { title: 'Domain', dataIndex: 'domain', render: (item, record) => <Link to={'/domains/' + record.guid}>{item}</Link> },
            { title: 'Domain Status', align: 'center', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />},
            { title: 'Group', dataIndex: 'group', render: (item, record) => <Button size='small' type='text' onClick={(e) => this.setGroupFilter(record, e)}>{item.name}</Button> },
            { title: 'Owner', dataIndex: 'group', render: (item) => <Link to={'/accounts/companies/' + item.owner.guid}>{item.owner.name}</Link> },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: 'Deleted', dataIndex: 'date_deleted', render: (item) => <DateTimeFormat value={item} />},
            { title: '', align: 'right', render: (item, record) => {
                    return <Space>
                        <a href={'http://' + record.domain} target='_blank' rel='noopener noreferrer'><Button size='small' icon={<LinkOutlined />}>Visit</Button></a>
                        <Link to={'/domains/' + record.guid}><Button size='small' type='round' icon={<EyeOutlined />}>View Domain</Button></Link>
                    </Space>;
                }}
        ];

        let commandResponseBox = '';

        if(this.state.runningCommand && this.state.commandResponse === '') {
            commandResponseBox = <div className='text-center'><Loading /></div>;
        } else if(!this.state.runningCommand && this.state.commandResponse !== '') {
            commandResponseBox = <Card size='small' title='Response' bordered={false}><Paragraph><pre>{this.state.commandResponse}</pre></Paragraph></Card>;
        }

        return(
            <Fragment>
                <Row gutter={16}>
                    <Col span={24}>
                        <Row justify='space-between' style={{marginBottom: '10px'}}>
                            <Col>
                                <Space>
                                    <Button size='small' icon={<DesktopOutlined />} onClick={() => window.open('http://admin:FeYskS2qjP7qvED@' + data.ip_address + ':9094/haproxy?stats')}>Open Haproxy</Button>
                                    <Button size='small' icon={<DesktopOutlined />} onClick={() => window.open('http://admin:dI0kD9yC7vB8jX7e@' + data.ip_address + ':2812')}>Open Monit</Button>
                                    <Button size='small' icon={<CodeOutlined />} onClick={() => this.setState({ showCmdResponseModal: true })}>Run Command</Button>
                                    <Button size='small' icon={<BarsOutlined />} onClick={() => this.setState({ showIPAddressHealthCheckModal: true })}>IP Address Health Check</Button>
                                </Space>
                            </Col>
                            <Col>
                                <Button size='small' type='circle' icon={<UndoOutlined />} onClick={() => this.adminLoadIPNode()} />
                            </Col>
                        </Row>

                        <Descriptions bordered size='small' layout='horizontal' column={4}>
                            <Descriptions.Item label='Status'><StatusIndicator status={data.status} el='badge' /></Descriptions.Item>
                            <Descriptions.Item label='IP address'>
                                <Space>
                                    <Input value={this.state.serverIPAddress} onChange={(e) => this.setState({ serverIPAddress: e.target.value.trim() })} />
                                    <Button icon={<SaveOutlined />} size='small' type='circle' onClick={() => this.updateServerIPAddress()} />
                                </Space>
                            </Descriptions.Item>
                            <Descriptions.Item label='Hostname'>
                                <Space>
                                    <Input value={this.state.serverHostname} onChange={(e) => this.setState({ serverHostname: e.target.value.trim() })} />
                                    <Button icon={<SaveOutlined />} size='small' type='circle' onClick={() => this.updateServerHostname()} />
                                </Space>
                            </Descriptions.Item>
                            <Descriptions.Item label='Provider'><a href={data.provider.website} target='_blank' rel='noopener noreferrer'>{data.provider.name}</a></Descriptions.Item>
                            <Descriptions.Item label='Location'>
                                <span><Flag code={data.location.country.toLowerCase()} height={DEFAULT_FLAG_SIZE} /> {data.location.city + ', ' + data.location.country}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label='IP addresses'>{data.ip_address_count}</Descriptions.Item>
                            <Descriptions.Item label='Used IP addresses'>{data.used_ip_address_count}</Descriptions.Item>
                            <Descriptions.Item label='Relative IP cost'><MoneyField amount={data.relative_ip_cost} currency='USD' /></Descriptions.Item>
                            <Descriptions.Item label='Server cost'>
                                <Space>
                                    <InputNumber value={this.state.serverCostAmount} style={{width: '150px'}}
                                                 addonAfter={<Select value={this.state.serverCostCurrency} onChange={(value) => this.setState({ serverCostCurrency: value })}>
                                                    <Option value='USD'>USD</Option>
                                                    <Option value='EUR'>EUR</Option>
                                                </Select>}
                                                onChange={(value) => this.setState({ serverCostAmount: value })} />
                                    <Button icon={<SaveOutlined />} size='small' type='circle' onClick={() => this.updateServerCost()} />
                                </Space>
                            </Descriptions.Item>
                            <Descriptions.Item label='Is available'>
                                <Switch checked={this.state.isAvailable} size='small' onChange={(value) => this.updateIsAvailable(value)} />
                            </Descriptions.Item>

                            {this.state.username === null ? <Descriptions.Item label='Credentials'>
                                <Button size='small' onClick={() => this.setState({ showPasswordModal: true })} icon={<EyeOutlined />}>Show</Button>
                            </Descriptions.Item> : <Fragment>
                                <Descriptions.Item label='SSH user'>{this.state.username}</Descriptions.Item>
                                <Descriptions.Item label='SSH password'><code>{this.state.password}</code></Descriptions.Item>
                            </Fragment> }
                        </Descriptions>
                        <br />
                        <Descriptions bordered size='small' layout='horizontal' column={5}>
                            <Descriptions.Item label='Dedicated IP default price group'>
                                <Space>
                                    <Select value={this.state.selectedDedicatedIPDefaultPriceGroup} showSearch
                                            filterOption={(input, option) => {
                                                if(typeof option !== 'undefined') {
                                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                }
                                                return false;
                                            }}
                                            onChange={(value) => this.setState({ selectedDedicatedIPDefaultPriceGroup: value })}>
                                        {this.state.priceGroups.map((group) => <Option key={group.guid} value={group.guid}>{group.name}</Option>)}
                                    </Select>
                                    <Button icon={<SaveOutlined />} size='small' type='circle' onClick={() => this.updateIPNodeDefaultPriceGroup()}
                                            disabled={this.state.updatingDefaultPriceGroup} loading={this.state.updatingDefaultPriceGroup} />
                                </Space>
                            </Descriptions.Item>
                            <Descriptions.Item label='Shared IP default price group'>
                                <Space>
                                    <Select value={this.state.selectedSharedIPDefaultPriceGroup} showSearch
                                            filterOption={(input, option) => {
                                                if(typeof option !== 'undefined') {
                                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                }
                                                return false;
                                            }}
                                            onChange={(value) => this.setState({ selectedSharedIPDefaultPriceGroup: value })}>
                                        {this.state.priceGroups.map((group) => <Option key={group.guid} value={group.guid}>{group.name}</Option>)}
                                    </Select>
                                    <Button icon={<SaveOutlined />} size='small' type='circle' onClick={() => this.updateIPNodeDefaultPriceGroup()}
                                            disabled={this.state.updatingDefaultPriceGroup} loading={this.state.updatingDefaultPriceGroup} />
                                </Space>
                            </Descriptions.Item>
                        </Descriptions>
                        <br />
                        {this.showFilters()}

                        <Row justify='space-between' style={{marginBottom: '10px'}}>
                            <Col>
                                <Space>
                                    <Button size='small' icon={<PlusOutlined />} onClick={() => this.setState({ showNewIPAddressesModal: true })}>Add IP addresses</Button>
                                    <Button size='small' icon={<FilterOutlined />} onClick={() => this.setState({ showFiltersModal: true })}>Filters</Button>
                                    <Button size='small' disabled={this.state.filters.length === 0} onClick={() => this.clearFilters()} icon={<DeleteOutlined />}>Clear Filters</Button>
                                    <Button size='small' onClick={() => this.selectAllUnusedIPAddresses()} icon={<CheckSquareOutlined />}>Select Unused</Button>
                                    <Button size='small' disabled={this.state.selectedIPAddresses.length === 0} onClick={() => this.setState({ showMoveIPAddressesModal: true })} icon={<BranchesOutlined />}>Move</Button>
                                    <Button size='small' onClick={() => this.setState({ showIPAddressExportModal: true })} icon={<ExportOutlined />}>Export IP Addresses</Button>
                                    <Button size='small' disabled={this.state.selectedIPAddresses.length === 0} onClick={() => this.setState({ showSetPriceGroupModal: true })} icon={<ShoppingCartOutlined />}>Set Price Group</Button>

                                    <Dropdown overlay={<Menu key='status'>
                                        <Menu.Item icon={<CheckOutlined />} onClick={() => this.bulkUpdateIPAddresses('ACTIVE')}>Active</Menu.Item>
                                        <Menu.Item icon={<DeleteOutlined />} onClick={() => this.bulkUpdateIPAddresses('DELETED')}>Delete</Menu.Item>
                                        <Menu.Item icon={<CloseOutlined />} onClick={() => this.bulkUpdateIPAddresses('DISABLED')}>Disabled</Menu.Item>
                                    </Menu>}>
                                        <Button size='small' disabled={this.state.selectedIPAddresses.length === 0} icon={<SettingOutlined />}>Set Status</Button>
                                    </Dropdown>

                                    <Button size='small' disabled={this.state.selectedIPAddresses.length === 0} onClick={() => this.setState({ showSetOwnPriceModal: true })} icon={<ShoppingCartOutlined />}>Set Own Price</Button>
                                </Space>
                            </Col>
                            <Col>
                                <Space>
                                    <Tooltip title='Reload' placement='left'><Button size='small' type='circle' icon={<UndoOutlined />} onClick={() => this.adminLoadIPAddresses()} /></Tooltip>
                                    <Pagination onChange={(page, pageSize) => this.adminLoadIPAddresses(page, pageSize)} size='small' {...this.state.pagination} />
                                </Space>
                            </Col>
                        </Row>

                        <Table columns={columns}
                               rowSelection={{
                                   type: 'checkbox',
                                   selectedRowKeys: this.state.selectedIPAddresses,
                                   onChange: (ipAddresses) => this.setState({ selectedIPAddresses: ipAddresses })
                               }}
                               size='small'
                               rowKey={(item) => item.guid}
                               dataSource={this.state.ipAddresses}
                               loading={this.state.loadingIPAddresses}
                               pagination={false}
                               footer={() => <Row justify='space-between'>
                                   <Col>{this.state.selectedIPAddresses.length + ' selected'}</Col>
                                   <Col><Pagination onChange={(page, pageSize) => this.adminLoadIPAddresses(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                               </Row>}
                               expandable={{
                                   expandedRowRender: (record) => {
                                       return <div style={{marginLeft: '-30px', paddingBottom: '15px'}}><Table
                                           columns={domainTableColumns}
                                           size='small'
                                           rowKey={(item) => item.guid}
                                           dataSource={this.state.ipAddressDomains}
                                           loading={this.state.loadingIPAddressDomains}
                                           pagination={this.state.ipAddressDomains.length > this.state.domainListPagination.pageSize ? this.state.domainListPagination: false}
                                           onChange={(pagination) => this.loadIPAddressDomains(record, pagination.current, pagination.pageSize)}
                                       /></div>;
                                   },
                                   onExpand: (open, record) => {
                                       if(open) {
                                           this.loadIPAddressDomains(record);
                                       } else {
                                           this.setState({ activeIPAddress: null, loadingIPAddressDomains: false });
                                       }
                                   },
                                   expandedRowKeys: [this.state.activeIPAddress],
                                   rowExpandable: () => true,
                               }}
                        />
                    </Col>
                </Row>

                <SetPriceGroupModal
                    showModal={this.state.showSetPriceGroupModal}
                    reload={() => this.adminLoadIPAddresses()}
                    ipNode={this.props.match.params.guid}
                    selectedIPAddresses={this.state.selectedIPAddresses}
                    close={() => this.setState({ showSetPriceGroupModal: false, selectedIPAddresses: [] })} />

                <NewIPAddressesModal
                    showModal={this.state.showNewIPAddressesModal}
                    ipNode={this.props.match.params.guid}
                    reload={() => this.adminLoadIPAddresses()}
                    close={() => this.setState({ showNewIPAddressesModal: false })} />

                <IPNodeIPAddressFilter
                    showModal={this.state.showFiltersModal}
                    closeModal={() => this.setState({ showFiltersModal: false })}
                    onChange={(filter) => this.setFilter(filter) }/>

                <IPNodeExportIPAddressesModal
                    showModal={this.state.showIPAddressExportModal}
                    ipNode={this.props.match.params.guid}
                    close={() => this.setState({ showIPAddressExportModal: false })} />

                <MoveIPAddressesToAnotherNodeModal
                    showModal={this.state.showMoveIPAddressesModal}
                    reload={() => this.adminLoadIPAddresses()}
                    selectedIPAddresses={this.state.selectedIPAddresses}
                    close={() => this.setState({ showMoveIPAddressesModal: false })} />

                <Modal
                    visible={this.state.showCmdResponseModal}
                    title='Run Command'
                    closable={this.state.runningCommand}
                    onCancel={() => this.setState({ showCmdResponseModal: false, activeService: '', activeServiceAction: '', commandResponse: '' })}
                    footer={<Space>
                        <Button type='primary' loading={this.state.runningCommand} disabled={this.state.runningCommand} onClick={() => this.runCmd()}>Run</Button>
                        <Button disabled={this.state.runningCommand} onClick={() => this.setState({ showCmdResponseModal: false })}>Close</Button>
                    </Space>}>
                    <Form layout='vertical'>
                        <Form.Item label='Service'>
                            <Select onChange={(value) => this.setState({ activeService: value})} value={this.state.activeService}>
                                <Option value='WEB'>Web service</Option>
                                <Option value='DNS'>DNS service</Option>
                                <Option value='SMTP'>Email service</Option>
                                <Option value='SSL'>SSL service</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label='Action'>
                            {this.state.activeService !== 'SSL' ? <Select onChange={(value) => this.setState({ activeServiceAction: value})} value={this.state.activeServiceAction}>
                                <Option value='START'>Start</Option>
                                <Option value='STOP'>Stop</Option>
                                <Option value='RESTART'>Restart</Option>
                                <Option value='HARD_RESTART'>Hard restart</Option>
                            </Select> : <Select onChange={(value) => this.setState({ activeServiceAction: value})} value={this.state.activeServiceAction}>
                                <Option value='INSTALL'>Install</Option>
                                <Option value='UNINSTALL'>Uninstall</Option>
                                <Option value='RENEW'>Renew</Option>
                            </Select>}
                        </Form.Item>
                    </Form>
                    {commandResponseBox}
                </Modal>

                <SetIPAddressOwnCostModal
                    show={this.state.showSetOwnPriceModal}
                    reload={() => this.setState({ selectedIPAddresses: []}, () => this.adminLoadIPAddresses())}
                    selectedIPAddresses={this.state.selectedIPAddresses}
                    ipNodeId={this.props.match.params.guid}
                    close={() => this.setState({ showSetOwnPriceModal: false })} />

                <Modal
                    visible={this.state.showPasswordModal}
                    title='Enter password'
                    footer={<Space>
                        <Button onClick={() => this.setState({ showPasswordModal: false, adminPassword: '' })}>Close</Button>
                        <Button type='primary' loading={this.state.loadingCredentials}
                                disabled={this.state.loadingCredentials} onClick={() => this.getCredentials()}>Continue</Button>
                    </Space>}>
                    <Input type='password' onKeyUp={(e) => e.key === 'Enter' ? this.getCredentials() : true} onChange={(e) => this.setState({ adminPassword: e.target.value })} />
                </Modal>

                <IPAddressHealthCheckModal
                    showModal={this.state.showIPAddressHealthCheckModal}
                    ipNode={this.props.match.params.guid}
                    closeModal={() => this.setState({ showIPAddressHealthCheckModal: false })} />
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminLoadIPNode, adminLoadIPNodeIPAddresses,
    adminUpdateIPAddress, adminBulkUpdateIPAddresses, adminLoadAllPriceGroups, adminGetIPNodeCredentials,
    adminIPNodeRunCmd, adminLoadDomains, updateIPNode })(IPNodeView);