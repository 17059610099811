import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    adminLoadSharedHostingServer,
    adminLoadSharedHostingServerAccounts,
    adminLoadSharedHostingServerDomains,
    adminLoadSharedHostingServerExistingAccounts,
    adminRunSharedHostingServerAction,
    adminUpdateSharedHostingServerField
} from "../../actions/network";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {Loading} from "../../libs/loading";
import {
    Alert,
    Button,
    Col,
    Descriptions,
    Input,
    message,
    Modal, Pagination,
    Row,
    Select,
    Space,
    Switch,
    Table,
    Tabs,
    Tooltip
} from "antd";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {EditOutlined, ExclamationCircleOutlined, EyeOutlined, SaveOutlined} from "@ant-design/icons";
import SharedHostingServerHashModal from "./SharedHostingServerHashModal";
import {DEFAULT_PER_PAGE} from "../../config";
import {Link} from "react-router-dom";
import {StatusIndicator} from "../shared/Status";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {MoneyField} from "../shared/MoneyField";

class SharedHostingServerView extends React.Component {
    state = {
        loadingSharedHostingServer: false,
        server: null,
        isAvailable: true,
        status: 'ACTIVE',
        executingAction: false,
        hostname: '',
        ipAddress: '',
        showSharedHostingServerHashModal: false,
        accountsPagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        domainsPagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        domains: [],
        accounts: [],
        loadingDomains: false,
        loadingAccounts: false,
        existingAccounts: []
    };

    componentDidMount() {
        this.adminLoadSharedHostingServer();
        this.adminLoadSharedHostingServerExistingAccounts();
        this.adminLoadSharedHostingServerDomains();
        this.adminLoadSharedHostingServerAccounts();
    }

    adminLoadSharedHostingServer() {
        this.setState({ loadingSharedHostingServer: true });
        this.props.adminLoadSharedHostingServer(this.props.match.params.guid, (res) => {
            this.props.updatePage(res.data.hostname);
            this.setState({ loadingSharedHostingServer: false, hostname: res.data.hostname, ipAddress: res.data.ip_address,
                server: res.data, isAvailable: res.data.is_available, status: res.data.status });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingSharedHostingServer: false });
                displayErrors(err.response.data);
            }
        });
    }

    updateServerAvailability(status) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set server's availability to <strong>{status ? 'Available' : 'Unavailable'}</strong>?</span>,
            onOk: () => {
                let data = { is_available: status };
                this.props.adminUpdateSharedHostingServerField(this.props.match.params.guid, data,  () => {
                    message.success('Server availability successfully updated!');
                    this.adminLoadSharedHostingServer();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    updateServerStatus(status) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set server's status to <strong>{normalizeEnum(status)}</strong>?</span>,
            onOk: () => {
                let data = { status };
                this.props.adminUpdateSharedHostingServerField(this.props.match.params.guid, data,  () => {
                    message.success('Server status successfully updated!');
                    this.adminLoadSharedHostingServer();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    runAction(action) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to run: <strong>{normalizeEnum(action)}</strong>?</span>,
            onOk: () => {
                this.setState({ executingAction: true });

                let data = { action };

                this.props.adminRunSharedHostingServerAction(this.props.match.params.guid, data,  () => {
                    message.success('Server action successfully executed!');
                    this.setState({ executingAction: false });
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                        this.setState({ executingAction: false });
                    }
                });
            }
        });
    }

    updateServerHostname() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set server's hostname to <strong>{this.state.hostname}</strong>?</span>,
            onOk: () => {
                let data = { hostname: this.state.hostname.trim() };
                this.props.adminUpdateSharedHostingServerField(this.props.match.params.guid, data,  () => {
                    message.success('Server hostname successfully updated!');
                    this.adminLoadSharedHostingServer();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    updateServerIPAddress() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set server's IP address to <strong>{this.state.ipAddress}</strong>?</span>,
            onOk: () => {
                let data = { ip_address: this.state.ipAddress.trim() };
                this.props.adminUpdateSharedHostingServerField(this.props.match.params.guid, data,  () => {
                    message.success('Server IP address successfully updated!');
                    this.adminLoadSharedHostingServer();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    adminLoadSharedHostingServerDomains(page = null, per_page = null) {
        if(page == null) {
            page = this.state.domainsPagination.current;
            per_page = this.state.domainsPagination.pageSize;
        }

        this.setState({ loadingDomains: true });

        this.props.adminLoadSharedHostingServerDomains(this.props.match.params.guid, page, per_page, (res) => {
            this.setState({ loadingDomains: false, domains: res.data.data,
                domainsPagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingDomains: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadSharedHostingServerAccounts(page = null, per_page = null) {
        if(page == null) {
            page = this.state.accountsPagination.current;
            per_page = this.state.accountsPagination.pageSize;
        }

        this.setState({ loadingAccounts: true });

        this.props.adminLoadSharedHostingServerAccounts(this.props.match.params.guid, page, per_page, (res) => {
            this.setState({ loadingAccounts: false, accounts: res.data.data,
                accountsPagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingAccounts: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadSharedHostingServerExistingAccounts() {
        this.props.adminLoadSharedHostingServerExistingAccounts(this.props.match.params.guid, (res) => {
            this.setState({ existingAccounts: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        if(this.state.loadingSharedHostingServer || this.state.server === null) {
            return <div className='text-center'><Loading /></div>;
        }

        const { server } = this.state;
        const { Option } = Select;

        const domainColumns = [
            { title: 'Domain', dataIndex: 'domain', render: (item, record) => <Link to={'/domains/' + record.guid}>{item}</Link> },
            { title: 'Domain Status', align: 'center', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />},
            { title: 'IP address', dataIndex: 'ip_address', render: (item) => item.ip_address },
            { title: 'IP Status', dataIndex: 'ip_address', render: (item) => <StatusIndicator status={item.status} el='badge' /> },
            { title: 'Group', dataIndex: 'group', render: (item) => <Link to={'/domains/?group=' + item.guid}>{item.name}</Link>},
            { title: 'Owner', dataIndex: 'group', render: (item) => <Link to={'/accounts/companies/' + item.owner.guid}>{item.owner.name}</Link> },
        ];

        const accountsColumns = [
            { title: 'Name', dataIndex: 'name', render: (item, record) => <Link to={'/hosting/' + record.guid}>{record.name}</Link>},
            { title: 'Status', dataIndex: 'status', align: 'center', render: (item) => <StatusIndicator status={item} el='badge' />},
            { title: 'Username', dataIndex: 'username'},
            { title: 'Domains', dataIndex: 'domain_count', align: 'center'},
            { title: 'Plan', dataIndex: 'plan', render: (item) => item.name },
            { title: 'Owner', dataIndex: 'group', render: (item, record) => <Link to={'/accounts/companies/' + record.owner.guid}>{record.owner.name}</Link> },
            { title: '', align: 'right', render: (item, record) => <Link to={'/hosting/' + record.guid}><Button icon={<EyeOutlined />} size='small'>View</Button></Link>},
        ];

        const { TabPane } = Tabs;

        return(
            <Fragment>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Descriptions column={4} bordered size='small'>
                            <Descriptions.Item label='Hostname'>
                                <Space>
                                    <Input value={this.state.hostname} onChange={(e) => this.setState({ hostname: e.target.value })} />
                                    <Button size='small' type='circle' onClick={() => this.updateServerHostname()} icon={<SaveOutlined />} />
                                </Space>
                            </Descriptions.Item>
                            <Descriptions.Item label='IP address'>
                                <Space>
                                    <Input value={this.state.ipAddress} onChange={(e) => this.setState({ ipAddress: e.target.value })} />
                                    <Button size='small' type='circle' onClick={() => this.updateServerIPAddress()} icon={<SaveOutlined />} />
                                </Space>
                            </Descriptions.Item>
                            <Descriptions.Item label='Hash'>
                                <Button size='small' onClick={() => this.setState({ showSharedHostingServerHashModal: true })} icon={<EditOutlined />}>Edit</Button>
                            </Descriptions.Item>
                            <Descriptions.Item label='Created'><DateTimeFormat value={server.date_added} /></Descriptions.Item>
                            <Descriptions.Item label='Status'>
                                <Select value={this.state.status} onChange={(value) => this.updateServerStatus(value)}>
                                    <Option value='ACTIVE'>Active</Option>
                                    <Option value='DELETED'>Deleted</Option>
                                </Select>
                            </Descriptions.Item>
                            <Descriptions.Item label='Is available'>
                                <Switch size='small' checked={this.state.isAvailable} onChange={(value) => this.updateServerAvailability(value)}  />
                            </Descriptions.Item>
                            <Descriptions.Item label='Restart'>
                                <Space>
                                    <Button size='small' loading={this.state.executingAction} onClick={() => this.runAction('RESTART_APACHE')}>Apache</Button>
                                    <Button size='small' loading={this.state.executingAction} onClick={() => this.runAction('RESTART_MYSQL')}>MySQL</Button>
                                    <Button size='small' loading={this.state.executingAction} onClick={() => this.runAction('RESTART_SERVER')}>Server</Button>
                                </Space>
                            </Descriptions.Item>
                            <Descriptions.Item label='Recurring revenue'>
                                <MoneyField amount={server.recurring_revenue} currency='USD' />
                            </Descriptions.Item>
                            <Descriptions.Item label='Domain count'>
                                {server.domain_count}
                            </Descriptions.Item>
                            <Descriptions.Item label='Account count'>
                                {server.account_count}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <br />
                <Row hidden={this.state.existingAccounts.length === 0} gutter={[16, 16]}>
                    <Col span={24}>
                        <Alert type='warning' showIcon message={<span>There are {this.state.existingAccounts.length} accounts that are deleted or not connected: <ul>{this.state.existingAccounts.map((account) => <li>{account}</li>)}</ul></span>} />
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Tabs>
                            <TabPane tab='Domains' key='domains'>
                                <Row justify='space-between' style={{marginBottom: '10px'}}>
                                    <Col />
                                    <Col>
                                        <Space>
                                            <Tooltip title='Reload' placement='left'><Button icon={<UndoOutlined />} size='small' type='circle' onClick={() => this.adminLoadSharedHostingServerDomains()} /></Tooltip>
                                            <Pagination onChange={(page, pageSize) => this.adminLoadSharedHostingServerDomains(page, pageSize)} size='small' {...this.state.domainsPagination} />
                                        </Space>
                                    </Col>
                                </Row>
                                <Table columns={domainColumns}
                                       size='small'
                                       rowKey={(item) => item.guid}
                                       dataSource={this.state.domains}
                                       loading={this.state.loadingDomains}
                                       footer={() => <Row justify='space-between'>
                                           <Col />
                                           <Col><Pagination onChange={(page, pageSize) => this.adminLoadSharedHostingServerDomains(page, pageSize)} size='small' {...this.state.domainsPagination} /></Col>
                                       </Row>}
                                       pagination={false} />
                            </TabPane>
                            <TabPane tab='Accounts' key='accounts'>
                                <Row justify='space-between' style={{marginBottom: '10px'}}>
                                    <Col />
                                    <Col>
                                        <Space>
                                            <Tooltip title='Reload' placement='left'><Button icon={<UndoOutlined />} size='small' type='circle' onClick={() => this.adminLoadSharedHostingServerAccounts()} /></Tooltip>
                                            <Pagination onChange={(page, pageSize) => this.adminLoadSharedHostingServerAccounts(page, pageSize)} size='small' {...this.state.accountsPagination} />
                                        </Space>
                                    </Col>
                                </Row>
                                <Table columns={accountsColumns}
                                       size='small'
                                       rowKey={(item) => item.guid}
                                       dataSource={this.state.accounts}
                                       loading={this.state.loadingAccounts}
                                       footer={() => <Row justify='space-between'>
                                           <Col />
                                           <Col><Pagination onChange={(page, pageSize) => this.adminLoadSharedHostingServerAccounts(page, pageSize)} size='small' {...this.state.accountsPagination} /></Col>
                                       </Row>}
                                       pagination={false} />
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>

                <SharedHostingServerHashModal
                    guid={this.props.match.params.guid}
                    showModal={this.state.showSharedHostingServerHashModal}
                    close={() => this.setState({ showSharedHostingServerHashModal: false })} />
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminLoadSharedHostingServer,
    adminUpdateSharedHostingServerField, adminRunSharedHostingServerAction, adminLoadSharedHostingServerDomains,
    adminLoadSharedHostingServerAccounts, adminLoadSharedHostingServerExistingAccounts})(SharedHostingServerView);