import React, {Fragment} from "react";
import {connect} from "react-redux";
import {DEFAULT_PER_PAGE} from "../../config";
import {displayErrors} from "../../libs/utils";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {Button, Col, Pagination, Row, Space, Table, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {loadAffiliateSignups} from "../../actions/affiliate";
import {Link} from "react-router-dom";
import {MoneyField} from "../shared/MoneyField";

class AffiliateSignupsTable extends React.Component {
    state = {
        loading: false,
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        signups: []
    };

    componentDidMount() {
        this.loadSignups();
    }

    loadSignups(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loading: true });

        this.props.loadAffiliateSignups(this.props.guid, page, per_page, (res) => {
            this.setState({ loading: false, signups: res.data.data });
        }, (err) => {
            this.setState({ loading: false });
            displayErrors(err.response.data);
        });
    }

    render() {
        const columns = [
            { title: 'Timestamp', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Company', dataIndex: 'company', render: (item) => item ? <Link to={'/accounts/companies/' + item.guid}>{item.name}</Link> : '' },
            { title: 'Revenue', dataIndex: 'total_revenue', render: (item) => <MoneyField value={item} currency='USD' /> },
        ];

        return <Fragment>
            <Row justify='space-between' style={{marginBottom: '10px'}}>
                <Col></Col>
                <Col>
                    <Space>
                        <Tooltip title='Reload table' placement='left'><Button onClick={() => this.loadSignups()} icon={<UndoOutlined />} size='small' type='circle'></Button></Tooltip>
                        <Pagination onChange={(page, pageSize) => this.loadSignups(page, pageSize)} size='small' {...this.state.pagination} />
                    </Space>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={(item) => item.guid}
                dataSource={this.state.signups}
                footer={() => <Row justify='space-between'>
                    <Col></Col>
                    <Col><Pagination onChange={(page, pageSize) => this.loadSignups(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                </Row>}
                loading={this.state.loading} />
        </Fragment>;
    }
}

export default connect(null, { loadAffiliateSignups })(AffiliateSignupsTable);