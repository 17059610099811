import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, InputNumber, Modal, Space, message} from "antd";
import {displayErrors, isNumeric} from "../../libs/utils";
import {createCachedSubnets} from "../../actions/firewall";

class NewCachedSubnetModal extends React.Component {
    state = {
        subnets: '',
        expires: 0,
        note: '',
        creating: false,
    };

    closeModal(reload = false) {
        this.setState({
            subnets: '',
            expires: 0,
            note: '',
            creating: false,
        });

        this.props.close();

        if(reload) {
            this.props.reloadSubnets();
        }
    }

    addCachedSubnets() {
        let subnets = this.state.subnets.trim().split('\n');
        let expires = this.state.expires;
        let note = this.state.note;

        if(subnets.length === 0) {
            return message.error('Enter at least 1 subnet!');
        }

        if(!isNumeric(expires)) {
            return message.error('Block length must be a number in seconds!');
        }

        let data = { subnets, expires, note };

        this.setState({ creating: true });

        this.props.createCachedSubnets(data, () => {
            message.success('Cached subnets successfully created!');
            this.setState({ creating: false });
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ creating: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { TextArea } = Input;

        return(
            <Modal
                title='Add Cached Subnets'
                open={this.props.showModal}
                destroyOnClose={true}
                footer={
                    <Space>
                        <Button onClick={() => this.closeModal()}>Close</Button>
                        <Button type='primary' onClick={() => this.addCachedSubnets()}>Add</Button>
                    </Space>
                }
                onCancel={() => this.closeModal()}>

                <Form layout='vertical'>
                    <Form.Item label='Subnets'>
                        <TextArea rows={5} onChange={(e) => this.setState({ subnets: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Note'>
                        <TextArea onChange={(e) => this.setState({ note: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Expiration in seconds'>
                        <InputNumber value={this.state.expires} onChange={(value) => this.setState({ expires: value })} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { createCachedSubnets  })(NewCachedSubnetModal);