import React from "react";
import {connect} from "react-redux";
import FirewallRules from "../../components/firewall/FirewallRules";
import {Tabs} from "antd";
import FirewallPolicies from "../../components/firewall/FirewallPolicies";
import FirewallRulesets from "../../components/firewall/FirewallRulesets";
import FirewallBlockedIPAddresses from "../../components/firewall/FirewallBlockedIPAddresses";
import FirewallCachedSubnets from "../../components/firewall/FirewallCachedSubnets";

class Firewall extends React.Component {
    state = {
        pages: ['rules', 'policies', 'rulesets', 'request-check', 'build-cache', 'blocked-ip-addresses', 'cached-subnets']
    };

    changeTab(page) {
        if(this.state.pages.indexOf(page) > -1) {
            if(page === 'rules') {
                this.props.history.push('/firewall');
            } else {
                this.props.history.push('/firewall/' + page);
            }
        }
    }

    render() {
        const { TabPane } = Tabs;

        let page = 'rules';

        if(typeof this.props.match.params.page !== 'undefined' && this.state.pages.indexOf(this.props.match.params.page)) {
            page = this.props.match.params.page;
        }

        return(
            <Tabs defaultActiveKey={page} onChange={(e) => this.changeTab(e)}>
                <TabPane tab='Rules' key='rules'>
                    <FirewallRules history={this.props.history} />
                </TabPane>
                <TabPane tab='Policies' key='policies'>
                    <FirewallPolicies history={this.props.history} />
                </TabPane>
                <TabPane tab='Rulesets' key='rulesets'>
                    <FirewallRulesets history={this.props.history} />
                </TabPane>
                <TabPane tab='Blocked IP Addresses' key='blocked-ip-addresses'>
                    <FirewallBlockedIPAddresses history={this.props.history} />
                </TabPane>
                <TabPane tab='Cached Subnets' key='cached-subnets'>
                    <FirewallCachedSubnets history={this.props.history} />
                </TabPane>
            </Tabs>
        );
    }
}

export default connect(null, {} )(Firewall);