import React from "react";
import {connect} from "react-redux";
import {Button, Modal, Table, message, Descriptions} from "antd";
import {
    adminCreateIPAddressCheck,
    adminGetIPAddressCheck,
    adminGetIPAddressLastCheck,
    adminStopIPAddressCheck
} from "../../actions/network";
import {StatusIndicator} from "../shared/Status";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {CaretRightOutlined, StopOutlined} from "@ant-design/icons";
import {displayErrors} from "../../libs/utils";

class IPAddressHealthCheckModal extends React.Component {
    state = {
        ipAddresses: [],
        loadingIPAddresses: false,
        ipAddressesLoaded: false,
        checkRunning: false,
        checkId: null,
        checkStatus: 'PENDING',
        checkStarted: null,
        checkCompleted: null,
    };

    timer = null;

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingIPAddresses && !this.state.ipAddressesLoaded) {
            this.setState({ loadingIPAddresses: true });

            this.props.adminGetIPAddressLastCheck(this.props.ipNode, (res) => {
                if(typeof res.data.error !== 'undefined') {
                    this.setState({
                        loadingIPAddresses: false,
                        ipAddressesLoaded: true
                    });
                } else {
                    this.setState({
                        checkStatus: res.data.status,
                        checkStarted: res.data.date_created,
                        checkCompleted: res.data.date_completed,
                        ipAddresses: res.data.items,
                        loadingIPAddresses: false,
                        ipAddressesLoaded: true
                    });
                }
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingIPAddresses: false, ipAddressesLoaded: true });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    closeModal() {
        this.setState({
            ipAddresses: [],
            loadingIPAddresses: false,
            ipAddressesLoaded: false
        });

        this.props.closeModal();
    }

    stopCheck() {
        this.setState({ checkRunning: false });
        clearInterval(this.timer);
    }

    checkIPAddresses() {
        if(this.state.checkRunning) {
            clearInterval(this.timer);

            this.props.adminStopIPAddressCheck(this.props.ipNode, this.state.checkId, () => {
                message.success('IP address check successfully stopped!');
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    displayErrors(err.response.data);
                }
            });
        } else {
            this.props.adminCreateIPAddressCheck(this.props.ipNode, (res) => {
                this.setState({ checkRunning: true, checkId: res.data.check_id });

                this.timer = setInterval(() => {
                    this.props.adminGetIPAddressCheck(this.props.ipNode, this.state.checkId, (res) => {
                        this.setState({
                            checkStatus: res.data.status,
                            checkStarted: res.data.date_created,
                            checkCompleted: res.data.date_completed,
                            ipAddresses: res.data.items
                        });

                        if(res.data.status === 'COMPLETED' || res.data.status === 'STOPPED') {
                            this.stopCheck();
                        }
                    }, (err) => {
                        if(typeof err.response !== 'undefined') {
                            this.stopCheck();
                            displayErrors(err.response.data);
                        }
                    });
                }, 1000);
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.stopCheck();
                    displayErrors(err.response.data);
                }
            });
        }
    }

    render() {
        const columns = [
            { title: 'IP Address', dataIndex: 'ip_address' },
            { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' /> },
            { title: 'Checked', dataIndex: 'date_checked', render: (item) => <DateTimeFormat value={item} />}
        ];

        return(
            <Modal visible={this.props.showModal}
                   title='IP Address Health Check'
                   onCancel={() => this.closeModal()}
                   footer={<Button onClick={() => this.closeModal()}>Close</Button>}>

                <p><Button onClick={() => this.checkIPAddresses()}
                           icon={this.state.checkRunning ? <StopOutlined /> : <CaretRightOutlined />}
                           size='small'
                           loading={this.state.checkRunning}>{this.state.checkRunning ? 'Stop' : 'Run'} Check</Button></p>

                <Descriptions bordered size='small' layout='horizontal' column={1}>
                    <Descriptions.Item label='Created'><DateTimeFormat value={this.state.checkStarted} /></Descriptions.Item>
                    <Descriptions.Item label='Completed'><DateTimeFormat value={this.state.checkCompleted} /></Descriptions.Item>
                </Descriptions>
                    <br />
                <Table columns={columns} dataSource={this.state.ipAddresses} pagination={false} size='small' />

            </Modal>
        )
    }
}

export default connect(null, { adminCreateIPAddressCheck, adminStopIPAddressCheck,
    adminGetIPAddressCheck, adminGetIPAddressLastCheck })(IPAddressHealthCheckModal);