import React from "react";
import {connect} from "react-redux";
import {Button, Col, Form, Modal, Row, Space, message, Select} from "antd";
import { Input } from 'antd';
import {
    adminCreateCreditNoteItem,
    adminLoadCreditNoteItem, adminUpdateCreditNoteItem,
} from "../../actions/billing";
import {displayErrors, isNumeric} from "../../libs/utils";

class CreditNoteItemModal extends React.Component {
    state = {
        description: '',
        quantity: 1,
        rate: 0.0,
        currency: 'USD',
        tax_rate: 0,
        loading: false,
        loadingCreditNoteItem: false,
        creditNoteItemLoaded: false
    };

    closeModal(reload = false) {
        this.setState({
            description: '',
            quantity: 1,
            rate: 0.0,
            tax_rate: 0,
            currency: 'USD',
            subtotal: 0.0,
            subtotalCurrency: 'USD',
            loading: false,
            loadingCreditNoteItem: false,
            creditNoteItemLoaded: false
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    saveItem() {
        let description = this.state.description.trim();
        let quantity = this.state.quantity;
        let tax_rate = this.state.tax_rate;
        let currency = this.state.currency;
        let rate = parseFloat(this.state.rate);

        if(description.length === 0) {
            return message.error('Please enter credit item description!');
        }

        if(currency.length === 0) {
            return message.error('Please select the currency!');
        }

        if(parseFloat(quantity) === 0) {
            return message.error('Please enter quantity!');
        }

        if(parseFloat(rate) === 0) {
            return message.error('Please enter correct rate!');
        }

        if(!isNumeric(tax_rate)) {
            return message.error('Please enter tax rate!');
        }

        let data = { description, quantity, rate, currency, tax_rate };

        this.setState({ loading: true });

        this.props.adminCreateCreditNoteItem(this.props.creditNote, data, () => {
            message.success('Credit note item item successfully created!');
            this.setState({ loading: false });
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { TextArea } = Input;
        const { Option } = Select;

        const currencySelector = <Select
            value={this.state.currency}
            onChange={(value) => this.setState({ currency: value })}>
            <Option value='USD'>USD</Option>
            <Option value='EUR'>EUR</Option>
        </Select>;

        return(
            <Modal
                title='Add Credit Note Item'
                visible={this.props.showModal}
                destroyOnClose={true}
                footer={<Space>
                    <Button disabled={this.state.loading || this.state.loadingCreditNoteItem} onClick={() => this.closeModal()}>Close</Button>
                    <Button disabled={this.state.loading || this.state.loadingCreditNoteItem}  loading={this.state.loading}  type='primary' onClick={() => this.saveItem()}>{this.props.creditNoteItem !== null ? 'Update' : 'Create'}</Button>
                </Space>}
                onCancel={() => this.closeModal()}>
                <Form layout='vertical'>
                    <Form.Item label='Description:'>
                        <TextArea rows={2} value={this.state.description}
                                  onChange={(e) => this.setState({ description: e.target.value })} />
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col span={8}>
                            <Form.Item label='Quantity:'>
                                <Input value={this.state.quantity}
                                             onChange={(e) => this.setState({ quantity: parseInt(e.target.value) })} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label='Rate:'>
                                <Input value={this.state.rate}
                                       addonAfter={currencySelector}
                                       onChange={(e) => this.setState({ rate: e.target.value})} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label='Tax rate:'>
                                <Input value={this.state.tax_rate}
                                       onChange={(e) => this.setState({ tax_rate: parseInt(e.target.value) })} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { adminLoadCreditNoteItem, adminCreateCreditNoteItem,
    adminUpdateCreditNoteItem })(CreditNoteItemModal);