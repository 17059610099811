import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, Modal, Select, Space} from "antd";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {loadAllCompanies} from "../../actions/users";
import countryList from 'react-select-country-list'
import {adminLoadAllProviders} from "../../actions/network";
import {adminLoadAllLocations} from "../../actions/ip_addresses";

class IPNodesTableFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFilter: 'IP_ADDRESS',
            filterValue: '',
            filterKey: 'ip_address',
            filterVisible: null,

            loadingCompanies: false,
            companiesLoaded: false,
            companies: [],
            selectedCompany: '',
            loadingProviders: false,
            providers: [],
            loadingLocations: false,
            locations: []
        };
    }

    countries = countryList().getData();

    getLocationName(guid) {
        let location = null;

        for(let i = 0; i < this.state.locations.length; i++) {
            if(this.state.locations[i].guid === guid) {
                location = this.state.locations[i];
                break;
            }
        }

        return location !== null ? location.full_name : '';
    }

    onChange(){
        let visibleValue = null;

        if(this.state.selectedFilter === 'COMPANY') {
            visibleValue = this.getCompanyName(this.state.filterValue);
        } else if(this.state.selectedFilter === 'PROVIDER') {
            visibleValue = this.getProviderName(this.state.filterValue);
        } else if(this.state.selectedFilter === 'LOCATION') {
            visibleValue = this.getLocationName(this.state.filterValue);
        }

        this.props.onChange({
            name: normalizeEnum(this.state.selectedFilter),
            key: this.state.filterKey,
            value: this.state.filterValue.trim(),
            visible: visibleValue
        });
    }

    closeModal(apply = false, close = true) {
        this.setState({
            selectedFilter: 'IP_ADDRESS',
            filterValue: '',
            filterKey: 'ip_address',
            filterVisible: null,

            loadingCompanies: false,
            companiesLoaded: false,
            companies: [],
            selectedCompany: '',
            loadingProviders: false,
            providers: []
        });

        if(close) {
            this.props.closeModal();
        }

        if(apply) {
            this.onChange();
        }
    }

    getCompanyName(guid) {
        let company = null;

        for(let i = 0; i < this.state.companies.length; i++) {
            if(this.state.companies[i].guid === guid) {
                company = this.state.companies[i];
                break;
            }
        }

        return company !== null ? company.name : '';
    }

    setFilter(name) {
        const filters = {
            STATUS: { key: 'status', value: '' },
            COMPANY: { key: 'company', value: '' },
            HOSTNAME: { key: 'hostname', value: '' },
            PROVIDER: { key: 'provider', value: '' },
            COUNTRY: { key: 'country', value: '' },
            LOCATION: { key: 'location', value: '' },
            IP_ADDRESS: { key: 'ip_address', value: 'ACTIVE' },
        };

        if(name === 'PROVIDER') {
            this.adminLoadAllProviders();
        } else if(name === 'COMPANY') {
            this.adminLoadAllCompanies();
        } else if(name === 'LOCATION') {
            this.setState({ loadingLocations: true });

            this.props.adminLoadAllLocations((res) => {
                this.setState({ loadingLocations: false, locations: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingLocations: false });
                    displayErrors(err.response.data);
                }
            });
        }

        this.setState({ selectedFilter: name, filterKey: filters[name].key, filterValue: filters[name].value });
    }

    getProviderName(guid) {
        let provider = null;

        for(let i = 0; i < this.state.providers.length; i++) {
            if(this.state.providers[i].guid === guid) {
                provider = this.state.providers[i];
                break;
            }
        }

        return provider !== null ? provider.name : '';
    }

    adminLoadAllCompanies() {
        this.setState({ loadingCompanies: true })

        this.props.loadAllCompanies((res) => {
            this.setState({ loadingCompanies: false, companiesLoaded: true, companies: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCompanies: false, companiesLoaded: true });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadAllProviders() {
        this.setState({ loadingProviders: true });

        this.props.adminLoadAllProviders((res) => {
            this.setState({ loadingProviders: false, providers: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingProviders: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        let content = '';

        if(this.state.selectedFilter === 'STATUS') {
            content = <Select onChange={(value) => this.setState({ filterValue: value })}>
                <Option value='PENDING'>Pending</Option>
                <Option value='ACTIVE'>Active</Option>
                <Option value='SUSPENDED'>Suspended</Option>
                <Option value='CANCELLED'>Cancelled</Option>
                <Option value='DELIVERED'>Delivered</Option>
            </Select>
        } else if(this.state.selectedFilter === 'COMPANY') {
            content = <Select
                filterOption={(input, option) => {
                    if(typeof option !== 'undefined') {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                }}
                loading={this.state.loadingCompanies}
                disabled={this.state.loadingCompanies}
                value={this.state.filterValue}
                showSearch allowClear
                onChange={(value) => this.setState({ filterValue: value })}>
                {this.state.companies.map((company, i) => {
                    return <Option key={i} value={company.guid}>{company.name}</Option>
                })}
            </Select>
        } else if(this.state.selectedFilter === 'PROVIDER') {
            content = <Select
                filterOption={(input, option) => {
                    if(typeof option !== 'undefined') {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                }}
                loading={this.state.loadingProviders}
                disabled={this.state.loadingProviders}
                value={this.state.filterValue}
                showSearch allowClear
                onChange={(value) => this.setState({ filterValue: value })}>
                {this.state.providers.map((provider, i) => {
                    return <Option key={i} value={provider.guid}>{provider.name}</Option>
                })}
            </Select>
        } else if(this.state.selectedFilter === 'HOSTNAME' || this.state.selectedFilter === 'IP_ADDRESS') {
            content = <Input value={this.state.filterValue}
                             onChange={(e) => this.setState({filterValue: e.target.value})}/>;
        } else if(this.state.selectedFilter === 'COUNTRY') {
            content = <Select
                filterOption={(input, option) => {
                    if(typeof option !== 'undefined') {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                }}
                value={this.state.filterValue}
                showSearch allowClear
                onChange={(value) => this.setState({ filterValue: value })}>
                {this.countries.map((key) => {
                    return <Option key={key.value} value={key.value}>{key.label}</Option>;
                })}
            </Select>
        } else if(this.state.selectedFilter === 'LOCATION') {
            content = <Select
                filterOption={(input, option) => {
                    if(typeof option !== 'undefined') {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                }}
                showSearch
                allowClear
                loading={this.state.loadingLocations}
                disabled={this.state.loadingLocations}
                value={this.state.filterValue}
                onChange={(value) => this.setState({ filterValue: value })}>
                {this.state.locations.map((location, i) => {
                    return <Option key={i} value={location.guid}>{location.full_name}</Option>
                })}
            </Select>
        }

        return(
            <Modal
                title='Add Filter'
                destroyOnClose={true}
                onCancel={() => this.closeModal()}
                footer={<Space>
                    <Button onClick={() => this.closeModal()}>Close</Button>
                    <Button onClick={() => this.closeModal(true, false)}>Apply</Button>
                    <Button type='primary' onClick={() => this.closeModal(true, true)}>Apply & Close</Button>
                </Space>}
                visible={this.props.showModal}>
                <Form layout='vertical'>
                    <Form.Item label='Select filter:'>
                        <Select value={this.state.selectedFilter} onChange={(value) => this.setFilter(value)}>
                            <Option value='HOSTNAME'>Hostname</Option>
                            <Option value='IP_ADDRESS'>IP address</Option>
                            <Option value='STATUS'>Status</Option>
                            <Option value='COMPANY'>Company</Option>
                            <Option value='PROVIDER'>Provider</Option>
                            <Option value='COUNTRY'>Country</Option>
                            <Option value='LOCATION'>Location</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        {content}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { loadAllCompanies, adminLoadAllProviders,
    adminLoadAllLocations })(IPNodesTableFilter);