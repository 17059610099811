import React from "react";
import {CheckCircleOutlined, ExclamationCircleOutlined, SyncOutlined} from "@ant-design/icons";
import {Badge, Tag} from "antd";
import CloseCircleOutlined from "@ant-design/icons/lib/icons/CloseCircleOutlined";
import {normalizeEnum} from "../../libs/utils";

export const StatusIndicator = function(props) {
    let icon = null;
    let color = 'default';
    let el = 'tag';

    if(typeof props.el !== 'undefined') {
        el = props.el;
    }

    const processing = ['PENDING', 'INSTALLING', 'UNINSTALLING', 'ADDING', 'SCHEDULED', 'UPDATING', 'VERIFICATION',
        'DELETING', 'INVESTIGATING', 'PENDING_CANCELLATION', 'FIXING', 'RETRYING'];
    const success = ['ACTIVE', 'PAID', 'PROCESSED', 'ENABLED', 'YES', 'AVAILABLE', 'RESOLVED', 'SUCCESS', 'COMPLETED'];
    const error = ['ERROR', 'SUSPENDED', 'INACTIVE', 'UNPAID', 'DELETED', 'FAILURE', 'DISABLED', 'NO',
        'OFFLINE', 'CANCELLED', 'REPLACED', 'FRAUD', 'REJECTED'];
    const warning = ['PARTIALLY_PAID', 'DO_NOT_SUSPEND'];

    if(processing.indexOf(props.status) > -1) {
        icon = <SyncOutlined spin />;
        color = 'processing';
    } else if(success.indexOf(props.status) > -1) {
        icon = <CheckCircleOutlined />;
        color = 'success';
    } else if(error.indexOf(props.status) > -1) {
        icon = <CloseCircleOutlined />;
        color = 'error';
    } else if(warning.indexOf(props.status) > -1) {
        icon = <ExclamationCircleOutlined />;
        color = 'warning';
    }

    if(el === 'tag') {
        return <Tag color={color} icon={icon}>{normalizeEnum(props.status)}</Tag>
    }

    if(el === 'badge') {
        return <Badge status={color} text={normalizeEnum(props.status)} />
    }
};