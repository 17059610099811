import Dashboard from "./components/dashboard";
import Billing from "./pages/billing/billing";
import OrderView from "./components/billing/OrderView";
import InvoiceView from "./components/billing/InvoiceView";
import CreditNoteView from "./components/billing/CreditNoteView";
import Domains from "./pages/domains/domains";
import DomainDetail from "./pages/domains/domain_detail";
import IPAddresses from "./pages/ip_addresses/ip_addresses";
import IPAddressDetail from "./pages/ip_addresses/ip_addresses_detail";
import CancellationRequestsView from "./components/billing/CancellationRequestsView";
import Accounts from "./pages/accounts/accounts";
import UserView from "./components/accounts/UserView";
import CompanyView from "./components/accounts/CompanyView";
import Accounting from "./pages/accounting/accounting";
import AccountingReportView from "./pages/accounting/accounting_report_view";
import Network from "./pages/network/network";
import IPNodeView from "./components/network/IPNodeView";
import HostingAccounts from "./pages/hosting/hosting";
import HostingDetails from "./pages/hosting/hosting_detail";
import Firewall from "./pages/firewall/firewall";
import FirewallRuleDetail from "./pages/firewall/firewall_rule_detail";
import System from "./pages/system/system";
import SharedHostingServerView from "./components/network/SharedHostingServerView";
import IssueView from "./components/system/IssueView";
import Tools from "./pages/tools/tools";
import Analytics from "./pages/analytics/analytics";
import AnalyticsReportView from "./pages/analytics/analytics_report_view";
import SystemCache from "./pages/cache/cache";
import Logs from "./pages/logs/logs";
import ConfigurationFileEditor from "./components/network/ConfigurationFileEditor";
import IPAddressReservationDetail from "./components/network/IPAddressReservationDetail";
import Emails from "./pages/emails/emails";
import Messages from "./pages/messages/messages";
import Affiliate from "./pages/affiliate/affiliate";
import AffiliateDetail from "./pages/affiliate/affiliate_detail";

export const sidebarMenu = {
    '/': {
        name: 'Dashboard',
        page: Dashboard,
        exact: true,
        subpage: false,
        show_staff: true
    },
    '/domains': {
        name: 'Domains',
        page: Domains,
        exact: true,
        subpage: false,
        show_staff: true
    },
    '/domains/:guid': {
        page: DomainDetail,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/ip-addresses': {
        name: 'IP Addresses',
        page: IPAddresses,
        exact: true,
        subpage: false,
        show_staff: true
    },
    '/ip-addresses/:guid': {
        page: IPAddressDetail,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/hosting': {
        name: 'Hosting',
        page: HostingAccounts,
        exact: true,
        subpage: false,
        show_staff: true
    },
    '/hosting/:guid': {
        page: HostingDetails,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/billing': {
        name: 'Billing',
        page: Billing,
        exact: true,
        subpage: false,
        show_staff: true
    },
    '/billing/orders/:id': {
        page: OrderView,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/billing/invoices/:id': {
        page: InvoiceView,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/affiliate': {
        name: 'Affiliate',
        page: Affiliate,
        exact: true,
        subpage: false
    },
    '/affiliate/view/:guid': {
        page: AffiliateDetail,
        exact: false,
        subpage: true
    },
    '/affiliate/:page': {
        page: Affiliate,
        exact: false,
        subpage: true
    },
    '/billing/credit-notes/:id': {
        page: CreditNoteView,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/billing/cancellation-requests/:id': {
        page: CancellationRequestsView,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/billing/:page': {
        page: Billing,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/firewall': {
        name: 'Firewall',
        page: Firewall,
        exact: true,
        subpage: false,
        subpages: null,
        show_staff: true
    },
    '/firewall/add': {
        name: 'Firewall' ,
        page: FirewallRuleDetail,
        exact: true,
        subpage: true,
        subpages: null,
        show_staff: true
    },
    '/firewall/edit/:guid': {
        name: 'Firewall' ,
        page: FirewallRuleDetail,
        exact: true,
        subpage: true,
        subpages: null,
        show_staff: true
    },
    '/firewall/:page': {
        page: Firewall,
        exact: true,
        subpage: true,
        subpages: null,
        show_staff: true
    },
    '/analytics': {
        name: 'Analytics',
        page: Analytics,
        exact: true,
        subpage: false,
        show_staff: false
    },
    '/analytics/report/:report': {
        page: AnalyticsReportView,
        exact: false,
        subpage: true,
        show_staff: false
    },
    '/accounts': {
    name: 'Accounts',
        page: Accounts,
        exact: true,
        subpage: false,
        show_staff: true
    },
    '/accounts/users/:id': {
        page: UserView,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/accounts/companies/:id': {
        page: CompanyView,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/accounts/:page': {
        page: Accounts,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/accounting': {
        name: 'Accounting',
        page: Accounting,
        exact: true,
        subpage: false,
        show_staff: false
    },
    '/accounting/report/:report': {
        page: AccountingReportView,
        exact: false,
        subpage: true,
        show_staff: false
    },
    '/network': {
        name: 'Network',
        page: Network,
        exact: true,
        subpage: false,
        show_staff: true
    },
    '/network/shared-hosting-servers/:guid': {
        page: SharedHostingServerView,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/network/ip-nodes/:guid': {
        page: IPNodeView,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/network/configuration-files/:guid': {
        page: ConfigurationFileEditor,
        exact: false,
        subpage: true,
        show_staff: false
    },
    '/network/reservations/:guid': {
        page: IPAddressReservationDetail,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/network/:page': {
        page: Network,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/system': {
        name: 'System',
        page: System,
        exact: true,
        subpage: false,
        show_staff: true
    },
    '/system/issues/:guid': {
        page: IssueView,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/system/:page': {
        page: System,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/tools': {
        name: 'Tools',
        page: Tools,
        exact: true,
        subpage: false,
        show_staff: true
    },
    '/tools/:page': {
        page: Tools,
        exact: false,
        subpage: true,
        show_staff: true
    },
    '/cache': {
        name: 'Cache',
        page: SystemCache,
        exact: true,
        subpage: false,
        show_staff: true
    },
    '/logs': {
        name: 'Logs',
        page: Logs,
        exact: true,
        subpage: false,
        show_staff: true
    },
    '/emails': {
        name: 'Emails',
        page: Emails,
        exact: true,
        subpage: false,
        show_staff: true
    },
    '/messages': {
        name: 'Messages',
        page: Messages,
        exact: true,
        subpage: false,
        show_staff: true,
    },
    '/logout': {
        name: 'Logout',
        page: '',
        exact: true,
        subpage: false,
        show_staff: true
    }
};