import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    adminAddSupportUser, adminDeleteCompanyUser,
    adminLoadCompany,
    adminLoadCompanyContacts,
    adminLoadCompanyEmails,
    adminLoadCompanyUsers, adminUpdateCompany, adminUpdateCompanyCurrency, adminUpdateCompanySupportPin
} from "../../actions/accounts";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {Loading} from "../../libs/loading";
import {Button, Card, Col, Descriptions, message, InputNumber, Row, Space, Switch, Table, Select, Modal} from "antd";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    MailOutlined,
    RetweetOutlined,
    SaveOutlined,
    UserAddOutlined
} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import TextArea from "antd/lib/input/TextArea";
import {DEFAULT_PER_PAGE} from "../../config";
import {adminLoadCompanyIPGroups} from "../../actions/ip_addresses";
import {adminLoadCompanyDomainGroups} from "../../actions/domains";
import {Link} from "react-router-dom";
import {StatusIndicator} from "../shared/Status";
import {DateFormat} from "../shared/DateFormat";
import CompanyEmailViewModal from "./CompanyEmailViewModal";
import CompanyNewEmailModal from "./CompanyNewEmailModal";
import {MoneyField} from "../shared/MoneyField";
import {adminLoadCompanyHostingAccounts} from "../../actions/hosting";

class CompanyView extends React.Component {
    state = {
        loadingCompany: true,
        company: null,
        emails: [],
        loadingEmails: false,
        emailsPagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        ipGroups: [],
        loadingIPGroups: false,
        ipGroupsPagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        domainGroups: [],
        loadingDomainGroups: false,
        domainGroupsPagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        contacts: [],
        loadingContacts: false,
        contactsPagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        users: [],
        loadingUsers: false,
        usersPagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        hostingAccounts: [],
        loadingHostingAccounts: false,
        hostingAccountsPagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        showEmailModal: false,
        activeEmail: null,
        maximumFirstOrderIPLimit: 0,
        requiredMinimumIPLimit: 0,
        totalIPsLimit: 0,
        paymentTerms: 0,
        adminNotes: '',
        showNewEmailModal: false,
        selectedCurrency: ''
    };

    componentDidMount() {
        this.adminLoadCompany();
        this.adminLoadCompanyEmails();
        this.adminLoadCompanyIPGroups();
        this.adminLoadCompanyDomainGroups();
        this.adminLoadCompanyContacts();
        this.adminLoadCompanyUsers();
        this.adminLoadCompanyHostingAccounts();
    }

    adminLoadCompany() {
        this.setState({ loadingCompany: true });

        this.props.adminLoadCompany(this.props.match.params.id, (res) => {
            this.setState({ loadingCompany: false, company: res.data,
                maximumFirstOrderIPLimit: res.data.maximum_first_order_ip_limit,
                requiredMinimumIPLimit: res.data.required_minimum_ip_limit,
                totalIPsLimit: res.data.total_ips_limit,
                paymentTerms: res.data.payment_terms,
                adminNotes: res.data.admin_notes
            });
            this.props.updatePage(res.data.name);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCompany: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadCompanyEmails(page = null, per_page = null) {
        if(page == null) {
            page = this.state.emailsPagination.current;
            per_page = this.state.emailsPagination.pageSize;
        }

        this.setState({ loadingEmails: true });

        this.props.adminLoadCompanyEmails(this.props.match.params.id, page, per_page, (res) => {
            this.setState({ emails: res.data.data, loadingEmails: false,
                emailsPagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingEmails: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadCompanyHostingAccounts(page = null, per_page = null) {
        if(page == null) {
            page = this.state.hostingAccountsPagination.current;
            per_page = this.state.hostingAccountsPagination.pageSize;
        }

        this.setState({ loadingHostingAccounts: true });

        this.props.adminLoadCompanyHostingAccounts(this.props.match.params.id, page, per_page, (res) => {
            this.setState({ hostingAccounts: res.data.data, loadingHostingAccounts: false,
                hostingAccountsPagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingHostingAccounts: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadCompanyIPGroups(page = null, per_page = null) {
        if(page == null) {
            page = this.state.ipGroupsPagination.current;
            per_page = this.state.ipGroupsPagination.pageSize;
        }

        this.setState({ loadingIPGroups: true });

        this.props.adminLoadCompanyIPGroups(this.props.match.params.id, page, per_page, (res) => {
            this.setState({ ipGroups: res.data.data, loadingIPGroups: false,
                ipGroupsPagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIPGroups: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadCompanyDomainGroups(page = null, per_page = null) {
        if(page == null) {
            page = this.state.domainGroupsPagination.current;
            per_page = this.state.domainGroupsPagination.pageSize;
        }

        this.setState({ loadingDomainGroups: true });

        this.props.adminLoadCompanyDomainGroups(this.props.match.params.id, page, per_page, (res) => {
            this.setState({ domainGroups: res.data.data, loadingDomainGroups: false,
                domainGroupsPagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingDomainGroups: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadCompanyContacts(page = null, per_page = null) {
        if(page == null) {
            page = this.state.contactsPagination.current;
            per_page = this.state.contactsPagination.pageSize;
        }

        this.setState({ loadingContacts: true });

        this.props.adminLoadCompanyContacts(this.props.match.params.id, page, per_page, (res) => {
            this.setState({ contacts: res.data.data, loadingContacts: false,
                contactsPagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingContacts: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadCompanyUsers(page = null, per_page = null) {
        if(page == null) {
            page = this.state.usersPagination.current;
            per_page = this.state.usersPagination.pageSize;
        }

        this.setState({ loadingUsers: true });

        this.props.adminLoadCompanyUsers(this.props.match.params.id, page, per_page, (res) => {
            this.setState({ users: res.data.data, loadingUsers: false,
                usersPagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingUsers: false });
                displayErrors(err.response.data);
            }
        });
    }

    updateCompany(field, value) {
        let company = this.state.company;
        company[field] = value;

        this.setState({ company });

        this.props.adminUpdateCompany(this.props.match.params.id, { [field]: value }, () => {
            message.success('Field successfully updated!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingUsers: false });
                displayErrors(err.response.data);
            }
        });
    }

    updateSupportPin() {
        this.setState({ updatingSupportPin: true });

        this.props.adminUpdateCompanySupportPin(this.props.match.params.id, {}, () => {
            this.setState({ updatingSupportPin: false });
            this.adminLoadCompany();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ updatingSupportPin: false });
                displayErrors(err.response.data);
            }
        });
    }

    updateCurrency() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you want to change the currency?</span>,
            onOk: () => {
                let data = {
                    currency: this.state.selectedCurrency
                };

                this.props.adminUpdateCompanyCurrency(this.props.match.params.id, data, () => {
                    message.success('Currency successfully updated!');
                    this.adminLoadCompany();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    addSupportUser() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you want to add the support user?</span>,
            onOk: () => {
                this.props.adminAddSupportUser(this.props.match.params.id, () => {
                    message.success('Support user successfully added!');
                    this.adminLoadCompanyUsers();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    deleteCompanyUser(guid) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you want to delete this user?</span>,
            onOk: () => {
                console.log(this.props.match.params.id, guid)
                this.props.adminDeleteCompanyUser(this.props.match.params.id, guid, () => {
                    message.success('User successfully deleted!');
                    this.adminLoadCompanyUsers();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    render() {
        if(this.state.loadingCompany || this.state.company === null) {
            return <div className='text-center'><Loading /></div>;
        }

        const { company } = this.state;

        const emailsColumns = [
            { title: 'Subject', dataIndex: 'subject', render: (item) => item },
            { title: 'Sent', dataIndex: 'date_sent', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Is Sent', dataIndex: 'is_sent', align: 'center', render: (item) => item ? 'Yes' : 'No'},
            { title: '', render: (item, record) => <Button onClick={() => this.setState({ activeEmail: record.guid, showEmailModal: true })} size='small' icon={<EyeOutlined />}>View</Button>}
        ];
        const ipGroupsColumns = [
            { title: 'Name', dataIndex: 'name', render: (item, record) => <Link to={'/ip-addresses/?group=' + record.guid}>{item}</Link>},
            { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />},
            { title: 'IP addresses', align: 'center', dataIndex: 'ip_address_count' },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> }
        ];
        const domainGroupsColumns = [
            { title: 'Name', dataIndex: 'name', render: (item, record) => <Link to={'/domains/?group=' + record.guid}>{item}</Link>},
            { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />},
            { title: 'Domains', align: 'center', dataIndex: 'domain_count' },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> }
        ];

        const hostingAccountsColumns = [
            { title: 'Name', dataIndex: 'name', render: (item, record) => <Link to={'/hosting/' + record.guid + '/'}>{item}</Link>},
            { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />},
            { title: 'Domains', align: 'center', dataIndex: 'domain_count' },
            { title: 'Plan', dataIndex: 'plan', render: (item) => item.name },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> }
        ];

        const contactsColumns = [
            { title: 'Name', render: (item, record) => record.first_name + ' ' + record.last_name},
            { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />},
            { title: 'Email', dataIndex: 'email_address', render: (item) => item },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateFormat value={item} />},
            { title: 'Can Disable', dataIndex: 'can_be_disabled', align: 'center', render: (item) => item ? 'Yes' : 'No'}
        ];
        const usersColumns = [
            { title: 'Name', dataIndex: 'user', render: (item) => <Link to={'/accounts/users/' + item.guid}>{item.first_name + ' ' + item.last_name}</Link>},
            { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />},
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateFormat value={item} />},
            { title: 'Type', dataIndex: 'account_type', render: (item) => normalizeEnum(item)},
            { title: '', align: 'right', render: (item, record) => {
                if(record.account_type !== 'OWNER') {
                    return <Button size='small' icon={<DeleteOutlined />} onClick={() => this.deleteCompanyUser(record.user.guid)}>Delete</Button>;
                }
            }}
        ];

        const { Option } = Select;

        return(
            <Row gutter={[16, 16]}>
                <Col md={12} xs={24}>
                    <Descriptions bordered column={1} size='small' title='Profile'>
                        <Descriptions.Item label='ID'>{company.id}</Descriptions.Item>
                        <Descriptions.Item label='Name'>{company.name}</Descriptions.Item>
                        <Descriptions.Item label='Address'>{company.address1}</Descriptions.Item>
                        {company.address2 !== null && company.address2 !== '' ? <Descriptions.Item label='Address 2'>{company.address2}</Descriptions.Item> : '' }
                        <Descriptions.Item label='City'>{company.city}</Descriptions.Item>
                        <Descriptions.Item label='Region'>{company.region}</Descriptions.Item>
                        <Descriptions.Item label='Phone'>{company.phone}</Descriptions.Item>
                        <Descriptions.Item label='Postal code'>{company.postal_code}</Descriptions.Item>
                        <Descriptions.Item label='Country'>{company.country}</Descriptions.Item>
                        <Descriptions.Item label='Currency'>
                            <Space>
                                <Select defaultValue={company.currency} onChange={(value) => this.setState({ selectedCurrency: value })}>
                                    <Option value='USD'>USD</Option>
                                    <Option value='EUR'>EUR</Option>
                                </Select>
                                <Button icon={<SaveOutlined />} onClick={() => this.updateCurrency()} type='circle' size='small' />
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label='Invoice day'>{company.invoice_day}</Descriptions.Item>
                        <Descriptions.Item label='Registered'><DateTimeFormat value={company.date_created} /></Descriptions.Item>
                        <Descriptions.Item label='Credit'><MoneyField amount={company.credit} currency={company.credit_currency}/></Descriptions.Item>
                    </Descriptions>
                    <br />
                    <Card title='Users' size='small' extra={<Button onClick={() => this.addSupportUser()} size='small' icon={<UserAddOutlined />}>Add Support User</Button>}>
                        <Table dataSource={this.state.users}
                               rowKey={row => row.user.guid }
                               size='small'
                               loading={this.state.loadingUsers}
                               onChange={(pagination) => this.adminLoadCompanyUsers(pagination.current, pagination.pageSize)}
                               columns={usersColumns}
                               pagination={this.state.usersPagination} />
                    </Card>
                    <br />
                    <Card title='Contacts' size='small'>
                        <Table dataSource={this.state.contacts}
                               rowKey={row => row.guid}
                               size='small'
                               loading={this.state.loadingContacts}
                               columns={contactsColumns}
                               onChange={(pagination) => this.adminLoadCompanyContacts(pagination.current, pagination.pageSize)}
                               pagination={this.state.contactsPagination} />
                    </Card>
                    <br />
                    <Card title='Emails' size='small' extra={<Button size='small' onClick={() => this.setState({ showNewEmailModal: true })} icon={<MailOutlined />}>Send Email</Button>}>
                        <Table dataSource={this.state.emails}
                               rowKey={row => row.guid}
                               size='small'
                               loading={this.state.loadingEmails}
                               columns={emailsColumns}
                               onChange={(pagination) => this.adminLoadCompanyEmails(pagination.current, pagination.pageSize)}
                               pagination={this.state.emailsPagination} />
                    </Card>
                </Col>
                <Col md={12} xs={24}>
                    <Descriptions bordered column={1} size='small' title='Limits'>
                        <Descriptions.Item label='Support pin'><Text code>{company.support_pin}</Text> <Button type='circle' size='small' icon={<RetweetOutlined />} onClick={() => this.updateSupportPin()} /></Descriptions.Item>
                        <Descriptions.Item label='Can place new orders'>
                            <Switch size='small' checked={company.can_place_orders}  onChange={(value) => this.updateCompany('can_place_orders', value)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Can add cards'>
                            <Switch size='small' checked={company.can_add_cc} onChange={(value) => this.updateCompany('can_add_cc', value)} />
                        </Descriptions.Item>
                        <Descriptions.Item label='Maximum first order limit'>
                            <Space>
                                <InputNumber size='small' value={this.state.maximumFirstOrderIPLimit} onChange={(value) => this.setState({ maximumFirstOrderIPLimit: value })} />
                                <Button size='small' type='circle' icon={<SaveOutlined />} onClick={() => this.updateCompany('maximum_first_order_ip_limit', this.state.maximumFirstOrderIPLimit)} />
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label='Required minimum IP limit'>
                            <Space>
                                <InputNumber size='small' value={this.state.requiredMinimumIPLimit} onChange={(value) => this.setState({ requiredMinimumIPLimit: value })} />
                                <Button size='small' type='circle' icon={<SaveOutlined />} onClick={() => this.updateCompany('required_minimum_ip_limit', this.state.requiredMinimumIPLimit)} />
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label='Total IPs limit'>
                            <Space>
                                <InputNumber size='small' value={this.state.totalIPsLimit} onChange={(value) => this.setState({ totalIPsLimit: value })} />
                                <Button size='small' type='circle' icon={<SaveOutlined />} onClick={() => this.updateCompany('total_ips_limit', this.state.totalIPsLimit)} />
                            </Space>
                        </Descriptions.Item>
                        <Descriptions.Item label='Payment NET terms'>
                            <Space>
                                <InputNumber size='small' value={this.state.paymentTerms} onChange={(value) => this.setState({ paymentTerms: value })} />
                                <Button size='small' type='circle' icon={<SaveOutlined />} onClick={() => this.updateCompany('payment_terms', this.state.paymentTerms)} />
                            </Space>
                        </Descriptions.Item>
                    </Descriptions>
                    <br />
                    <Card title='IP groups' size='small'>
                        <Table dataSource={this.state.ipGroups}
                               rowKey={row => row.guid}
                               size='small'
                               loading={this.state.loadingIPGroups}
                               columns={ipGroupsColumns}
                               onChange={(pagination) => this.adminLoadCompanyIPGroups(pagination.current, pagination.pageSize)}
                               pagination={this.state.ipGroupsPagination} />
                    </Card>
                    <br />
                    <Card title='Domain groups' size='small'>
                        <Table dataSource={this.state.domainGroups}
                               rowKey={row => row.guid}
                               size='small'
                               loading={this.state.loadingDomainGroups}
                               onChange={(pagination) => this.adminLoadCompanyDomainGroups(pagination.current, pagination.pageSize)}
                               columns={domainGroupsColumns}
                               pagination={this.state.domainGroupsPagination} />
                    </Card>
                    <br />
                    <Card title='Hosting accounts' size='small'>
                        <Table dataSource={this.state.hostingAccounts}
                               rowKey={row => row.guid}
                               size='small'
                               loading={this.state.loadingHostingAccounts}
                               onChange={(pagination) => this.adminLoadCompanyHostingAccounts(pagination.current, pagination.pageSize)}
                               columns={hostingAccountsColumns}
                               pagination={this.state.hostingAccountsPagination} />
                    </Card>
                    <br />
                    <Card size='small' title='Admin notes' extra={<Button onClick={() => this.updateCompany('admin_notes', this.state.adminNotes)} size='small' type='round' icon={<SaveOutlined />}>Save</Button>}>
                        <TextArea value={this.state.adminNotes}
                                  onChange={(e) => this.setState({ adminNotes: e.target.value})} rows={5} />
                    </Card>
                </Col>

                <CompanyNewEmailModal
                    showModal={this.state.showNewEmailModal}
                    company={this.props.match.params.id}
                    reload={() => this.adminLoadCompanyEmails()}
                    close={() => this.setState({ showNewEmailModal: false })} />

                <CompanyEmailViewModal guid={this.state.activeEmail}
                                       companyGuid={this.props.match.params.id}
                                       showModal={this.state.showEmailModal}
                                       close={() => this.setState({ showEmailModal: false, activeEmail: null })} />
            </Row>
        );
    }
}

export default connect(null, { updatePage, adminLoadCompany, adminLoadCompanyEmails,
    adminLoadCompanyContacts, adminLoadCompanyIPGroups, adminLoadCompanyDomainGroups, adminLoadCompanyUsers,
    adminUpdateCompany, adminLoadCompanyHostingAccounts, adminUpdateCompanySupportPin, adminUpdateCompanyCurrency,
    adminAddSupportUser, adminDeleteCompanyUser})(CompanyView);