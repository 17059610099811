import React from "react";
import {Button, Form, Modal, Select, Space, message, DatePicker} from "antd";
import {connect} from "react-redux";
import {displayErrors} from "../../libs/utils";
import {submitBulkInvoicesActions} from "../../actions/billing";

class InvoicesBulkActionsModal extends React.Component {
    state = {
        selectedAction: '',
        selectedValue: null,
        loading: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    closeModal(reload = false) {
        this.setState({
            selectedAction: '',
            selectedValue: null,
            loading: false
        });

        this.props.close();

        if(reload) {
            this.props.reloadInvoices();
        }
    }

    performAction() {
        let action = this.state.selectedAction.trim();

        if(action.length === 0) {
            return message.error('Please select an action!');
        }

        let data = {
            invoices: this.props.selectedInvoices,
            action: this.state.selectedAction,
            value: this.state.selectedValue
        };

        this.setState({ loading: true });

        this.props.submitBulkInvoicesActions(data, (res) => {
            this.closeModal(true);

            message.success('Action successfully performed!');

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        let additionalValue = '';

        if(this.state.selectedAction === 'SET_DUE_DATE') {
            additionalValue = <Form.Item label='Select date'>
                <DatePicker onChange={(e, value) => this.setState({ selectedValue: value })} />
            </Form.Item>;
        }

        return(
            <Modal
                destroyOnClose={true}
                title='Actions'
                visible={this.props.showModal}
                footer={<Space>
                    <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Close</Button>
                    <Button disabled={this.state.loading} loading={this.state.loading}
                            onClick={() => this.performAction()} type='primary'>Submit</Button>
                </Space>}
                onCancel={() => this.closeModal()}>
                <Form layout='vertical'>
                    <Form.Item label='Select action'>
                        <Select onChange={(value) => this.setState({ selectedAction: value, selectedValue: null })}>
                            <Option value='PROCESS'>Process</Option>
                            <Option value='SET_DUE_DATE'>Set new due date</Option>
                            <Option value='CANCEL'>Cancel</Option>
                        </Select>
                    </Form.Item>
                    {additionalValue}
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { submitBulkInvoicesActions })(InvoicesBulkActionsModal);