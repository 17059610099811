import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Col, Popconfirm, Row, message, Table, Tooltip, Pagination, Space} from "antd";
import {displayErrors} from "../../libs/utils";
import {DEFAULT_PER_PAGE} from "../../config";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {Link} from "react-router-dom";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {adminDeleteIPAddressReservationList, adminLoadIPAddressReservations} from "../../actions/ip_addresses";
import NewIPAddressReservationListModal from "./NewIPAddressReservationListModal";

class IPAddressReservationsTable extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        reservations: [],
        loadingReservations: true,
        showNewReservationListModal: false
    };

    componentDidMount() {
        this.props.updatePage('IP Address Reservations');
        this.adminLoadIPAddressReservations();
    }

    adminLoadIPAddressReservations(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingReservations: true });

        this.props.adminLoadIPAddressReservations(page, per_page, (res) => {
            window.scrollTo(0, 0);

            this.setState({ reservations: res.data.data, loadingReservations: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingReservations: false });
                displayErrors(err.response.data);
            }
        });
    }

    deleteReservationList(guid) {
        this.setState({ loadingReservations: true });

        this.props.adminDeleteIPAddressReservationList(guid, () => {
            message.success('Reservation list successfully deleted!');
            this.adminLoadIPAddressReservations();

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingReservations: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const columns = [
            { title: 'Name', dataIndex: 'name', render: (item, record) => <Link to={'/network/reservations/' + record.guid}>{item}</Link> },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: 'Owner', render: (item, record) => record.list_owner.name},
            { align: 'right', render: (item, record) => <Popconfirm
                    title="Are you sure you wish to delete this list?"
                    onConfirm={() => this.deleteReservationList(record.guid)}
                    okText='Delete'
                    placement='topRight'
                    okButtonProps={{danger: true}}
                    cancelText=''>
                    <Button size='small' icon={<DeleteOutlined />}>Delete</Button>
                </Popconfirm>}
        ];

        return(
            <Fragment>
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <Button type='text' size='small' icon={<PlusOutlined />} onClick={() => this.setState({ showNewReservationListModal: true })}>New List</Button>
                    </Col>
                    <Col>
                        <Space>
                            <Tooltip title='Reload' placement='left'><Button type='circle' size='small' icon={<UndoOutlined />} onClick={() => this.adminLoadIPAddressReservations()} /></Tooltip>
                            <Pagination onChange={(page, pageSize) => this.adminLoadIPAddressReservations(page, pageSize)} size='small' {...this.state.pagination} />
                        </Space>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    rowKey={(item) => item.guid}
                    dataSource={this.state.reservations}
                    loading={this.state.loadingReservations}
                    pagination={false}
                    footer={() => <Row justify='space-between'>
                        <Col />
                        <Col><Pagination onChange={(page, pageSize) => this.adminLoadIPAddressReservations(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                    </Row>}
                />

                <NewIPAddressReservationListModal
                    showModal={this.state.showNewReservationListModal}
                    reload={() => this.adminLoadIPAddressReservations()}
                    close={() => this.setState({ showNewReservationListModal: false })} />
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminLoadIPAddressReservations,
    adminDeleteIPAddressReservationList })(IPAddressReservationsTable);