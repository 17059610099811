import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    Button, Col,
    message,
    Modal, Pagination,
    Popconfirm,
    Row,
    Space,
    Table, Tag,
    Tooltip
} from "antd";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {DEFAULT_PER_PAGE} from "../../config";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {DeleteOutlined, ExclamationCircleOutlined, FilterOutlined} from "@ant-design/icons";
import {bulkDeleteBlockedIPAddresses, deleteBlockedIPAddress, loadBlockedIPAddresses} from "../../actions/firewall";
import NewIPAddressBlockModal from "./NewIPAddressBlockModal";
import {Link} from "react-router-dom";
import FirewallBlockedIPAddressesTableFilter from "./FirewallBlockedIPAddressesTableFilter";

class FirewallBlockedIPAddresses extends React.Component {
    state = {
        ipAddresses: [],
        filters: [],
        loading: false,
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        selectedIPAddresses: [],
        showNewIPAddressBlockModal: false,
        showFiltersModal: false,
    };

    componentDidMount() {
        this.props.updatePage('Firewall Blocked IP Addresses');
        this.loadBlockedIPAddresses();
    }

    loadBlockedIPAddresses(page = null, per_page = null) {
        if(page === null) {
            page = this.state.pagination.current;
        }

        if(per_page === null) {
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loading: true });

        this.props.loadBlockedIPAddresses(page, per_page, this.state.filters, (res) => {
            window.scrollTo(0, 0);

            this.setState({ ipAddresses: res.data.data, loading: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true } });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false })
            }
        });
    }

    deleteBlockedIPAddress(id) {
        this.setState({ loading: true });

        this.props.deleteBlockedIPAddress(id, () => {
            message.success('Blocked IP address successfully deleted!');
            this.loadBlockedIPAddresses();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    confirmBulkDelete() {
        const { confirm } = Modal;

        confirm({
            content: 'Are you sure you want to delete these IP addresses?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
                this.setState({ loading: true });

                let data = {
                    action: 'DELETE',
                    ip_addresses: this.state.selectedIPAddresses
                };

                this.props.bulkDeleteBlockedIPAddresses(data, () => {
                    message.success('IP addresses successfully deleted!');
                    this.loadBlockedIPAddresses();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ loading: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        })
    }

    clearFilters() {
        this.setState({ filters: [] }, () => this.loadBlockedIPAddresses());
    }

    setFilter(filter) {
        let filters = this.state.filters.filter(f => f.key !== filter.key);
        filters.push(filter);

        this.setState({ filters }, () => this.loadBlockedIPAddresses());
    }

    removeFilter(item) {
        let filters = this.state.filters.filter(filter => filter.key !== item.key);
        this.setState({ filters }, () => this.loadBlockedIPAddresses());
    }

    showFilters() {
        return this.state.filters.map((filter, i) => {
            let value;

            if (typeof filter.value === 'boolean') {
                if (filter.value) {
                    value = 'Yes';
                } else {
                    value = 'No';
                }
            } else {
                if (filter.visible !== null) {
                    value = filter.visible;
                } else {
                    value = normalizeEnum(filter.value);
                }
            }

            return <Tag key={i} closable onClose={() => this.removeFilter(filter)}>{filter.name}: {value}</Tag>;
        });
    }

    render() {
        const columns = [
            { title: 'ID', dataIndex: 'id', width: '50px'},
            { title: 'IP address', dataIndex: 'ip_address' },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Expires', dataIndex: 'date_expires', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Router', dataIndex: 'router', render: (item) => item !== null ? item.hostname : '-'},
            { title: 'Rule', dataIndex: 'firewall_rule', render: (item) => item !== null ? <Link to={'/firewall/edit/' + item.guid}>{item.name}</Link> : '-'},
            { title: '', align: 'right', render: (item, record) => {
                    return <Popconfirm
                        title="Are you sure you wish to delete this IP address?"
                        onConfirm={() => this.deleteBlockedIPAddress(record.id)}
                        okText='Delete'
                        placement='topRight'
                        okButtonProps={{danger: true}}
                        cancelText='Cancel'>
                        <Button size='small' danger icon={<DeleteOutlined />}>Delete</Button>
                    </Popconfirm>;
                }}
        ];

        return(
            <Fragment>
                {this.showFilters()}

                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <Space>
                            <Button size='small' type='text' icon={<PlusOutlined />} onClick={() =>this.setState({ showNewIPAddressBlockModal: true })}>Block IP Addresses</Button>
                            <Button size='small' type='text' icon={<FilterOutlined />} onClick={() => this.setState({ showFiltersModal: true })}>Filters</Button>
                            <Button size='small' type='text' disabled={this.state.filters.length === 0} onClick={() => this.clearFilters()} icon={<DeleteOutlined />}>Clear Filters</Button>
                            <Button size='small' type='text' disabled={this.state.selectedIPAddresses.length === 0} icon={<DeleteOutlined />} onClick={() => this.confirmBulkDelete()}>Delete</Button>
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Space>
                                <Tooltip title='Reload table' placement='left'><Button onClick={() => this.loadBlockedIPAddresses()} icon={<UndoOutlined />} size='small' type='circle'></Button></Tooltip>
                                <Pagination onChange={(page, pageSize) => this.loadBlockedIPAddresses(page, pageSize)} size='small' {...this.state.pagination} />
                            </Space>
                        </Space>
                    </Col>
                </Row>

                <Table
                    dataSource={this.state.ipAddresses}
                    rowSelection={{
                        type: 'checkbox',
                        onChange: (ipAddresses) => this.setState({ selectedIPAddresses: ipAddresses })
                    }}
                    columns={columns}
                    footer={() => <Row justify='space-between'>
                        <Col>{this.state.selectedIPAddresses.length + ' selected'}</Col>
                        <Col><Pagination onChange={(page, pageSize) => this.loadBlockedIPAddresses(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                    </Row>}
                    rowKey={(item) => item.ip_address}
                    pagination={false}
                    loading={this.state.loading} />

                <NewIPAddressBlockModal
                    showModal={this.state.showNewIPAddressBlockModal}
                    close={() => this.setState({ showNewIPAddressBlockModal: false })} reloadBlocks={() => this.loadBlockedIPAddresses()} />

                <FirewallBlockedIPAddressesTableFilter
                    showModal={this.state.showFiltersModal}
                    closeModal={() => this.setState({ showFiltersModal: false })}
                    onChange={(filter) => this.setFilter(filter) }  />
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, deleteBlockedIPAddress, loadBlockedIPAddresses, bulkDeleteBlockedIPAddresses })(FirewallBlockedIPAddresses);