import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    adminDownloadConfigurationFile,
    adminLoadConfigurationFile,
    adminUpdateConfigurationFile
} from "../../actions/system";
import {displayErrors} from "../../libs/utils";
import {Loading} from "../../libs/loading";
import AceEditor from "react-ace";

import "ace-builds/src-noconflict/mode-text";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-ini";
import "ace-builds/src-noconflict/mode-sh";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-lua";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";

import {Button, Col, Row, message, Typography, Input, Select, Space} from "antd";
import {DownloadOutlined, SaveOutlined} from "@ant-design/icons";

class ConfigurationFileEditor extends React.Component {
    state = {
        loading: false,
        fileLoaded: false,
        name: '',
        fileType: '',
        source: '',
        fileName: '',
        saving: false,
        downloading: false,
        editorModes: {
            '.txt': 'text',
            '.conf': 'text',
            '.cfg': 'text',
            '.ini': 'ini',
            '.json': 'json',
            '.sh': 'sh',
            '.html': 'html',
            '.lua': 'lua',
            '.py': 'python'
        }
    };

    componentDidMount() {
        this.props.updatePage('Edit File');
        this.loadFile();
    }

    loadFile() {
        this.setState({ loading: true });

        this.props.adminLoadConfigurationFile(this.props.match.params.guid, (res) => {
            this.props.updatePage(res.data.name);
            this.setState({ loading: false, fileLoaded: true, name: res.data.name, fileType: res.data.file_type, source: res.data.source, fileName: res.data.file_name });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    onChange(data) {
        this.setState({ source: data });
    }

    saveFile() {
        let name = this.state.name.trim();
        let file_type = this.state.fileType.trim();
        let source = this.state.source;

        if(name.length === 0) {
            return message.error('Please enter the file name!');
        }

        if(file_type.length === 0) {
            return message.error('Please choose the file type!');
        }

        let data = { name, file_type, source };

        this.setState({ saving: true });

        this.props.adminUpdateConfigurationFile(this.props.match.params.guid, data, (res) => {
            message.success('File successfully updated!');
            this.setState({ saving: false, name: res.data.name, fileType: res.data.file_type, source: res.data.source, fileName: res.data.file_name });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ saving: false });
                displayErrors(err.response.data);
            }
        });
    }

    downloadFile() {
        this.setState({ downloading: true });

        this.props.adminDownloadConfigurationFile(this.props.match.params.guid, this.state.fileName, () => {
            this.setState({ downloading: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ downloading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        if(!this.state.fileLoaded) {
            return <div className='text-center'><Loading /></div>;
        }

        const { Paragraph } = Typography;
        const { Option } = Select;

        let downloadUrl = 'https://api.priorityprospect.com/system/config-files/download/' + this.props.match.params.guid + '/' + this.state.fileName;

        return(
            <Fragment>
                <Row style={{marginBottom: '10px'}} gutter={[16, 16]}>
                    <Col style={{width: '400px'}}>
                        <Input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                    </Col>
                    <Col>
                        <Select value={this.state.fileType} onChange={(value) => this.setState({ fileType: value })}>
                            <Option value='.txt'>Text</Option>
                            <Option value='.json'>JSON</Option>
                            <Option value='.conf'>Conf</Option>
                            <Option value='.ini'>Ini</Option>
                            <Option value='.sh'>Sh</Option>
                            <Option value='.html'>HTML</Option>
                            <Option value='.cfg'>Cfg</Option>
                            <Option value='.lua'>Lua</Option>
                            <Option value='.py'>Python</Option>
                        </Select>
                    </Col>
                    <Col style={{width: '250px'}}>
                        <Space>
                            <Button type='primary' icon={<SaveOutlined />} onClick={() => this.saveFile()} loading={this.state.saving} disabled={this.state.saving}>Save</Button>
                            <Button icon={<DownloadOutlined />} onClick={() => this.downloadFile()} loading={this.state.downloading} disabled={this.state.downloading}>Download</Button>
                        </Space>
                    </Col>
                    <Col>
                        <Paragraph copyable code style={{marginTop: '5px', marginLeft: '10px'}}>{downloadUrl}</Paragraph>
                    </Col>
                </Row>
                <AceEditor
                    mode={this.state.editorModes[this.state.fileType]}
                    theme="github"
                    onChange={(data) => this.onChange(data)}
                    width='100%'
                    height='800px'
                    value={this.state.source}
                    showPrintMargin={false}
                    name="UNIQUE_ID_OF_DIV"
                    editorProps={{ $blockScrolling: true }} />

            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminLoadConfigurationFile,
    adminUpdateConfigurationFile, adminDownloadConfigurationFile })(ConfigurationFileEditor);