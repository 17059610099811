import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    Badge,
    Button,
    Popconfirm,
    Switch,
    Table,
    Tooltip,
    message,
    Modal,
    Space,
    ConfigProvider,
    Row, Col, Pagination
} from "antd";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {DeleteOutlined, ExclamationCircleOutlined, EyeOutlined, FolderOpenOutlined} from "@ant-design/icons";
import {DEFAULT_PER_PAGE} from "../../config";
import {bulkDeleteFirewallRules, deleteFirewallRule, loadFirewallRules, updateFirewallRuleStatus} from "../../actions/firewall";
import {Link} from "react-router-dom";

class FirewallRules extends React.Component {

    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        loading: true,
        rules: [],
        selectedRules: []
    };

    componentDidMount() {
        this.props.updatePage('Firewall Rules');
        this.loadRules();
    }

    loadRules(page = null, per_page = null) {
        if(page === null) {
            page = this.state.pagination.current;
        }

        if(per_page === null) {
            per_page = this.state.pagination.pageSize;
        }

        this.props.loadFirewallRules(page, per_page,(res) => {
            this.setState({ rules: res.data.data, pagination: { current: res.data.page, pageSize: res.data.per_page, showSizeChanger: true, total: res.data.total }, loading: false });
        }, (err) => {
            this.setState({ loading: false });

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    deleteFirewallRule(guid) {
        this.setState({ loading: true });

        this.props.deleteFirewallRule(guid, () => {
            message.success('Firewall rule successfully deleted!');
            this.loadRules();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    toggleFirewallRuleStatus(guid, status) {
        let data = {
            status: status ? 'ACTIVE' : 'DISABLED'
        };

        let rules = this.state.rules.map((item) => {
            if(item.guid === guid) {
                return {...item, status: data['status']};
            }

            return item;
        });

        this.setState({ rules: rules, loading: true });

        this.props.updateFirewallRuleStatus(guid, data, () => {
            message.success('Firewall rule successfully updated!');
            this.setState({ loading: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    changePage(data) {
        this.loadRules(data.current, data.pageSize);
    }

    confirmBulkDelete() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure you want to delete these rules?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: 'This can have significant impact on your domains and content!',
            onOk: () => {
                this.setState({ loading: true });

                let data = {
                    rules: this.state.selectedRules
                };

                this.props.bulkDeleteFirewallRules(data, () => {
                    message.success('Firewall rules successfully deleted!');
                    this.loadRules();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ loading: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        })
    }

    render() {
        const columns = [
            { title: '', width: '30px', render: (item, record) => <Switch size='small' checked={record.status === 'ACTIVE'} onChange={(e) => this.toggleFirewallRuleStatus(record.guid, e)} /> },
            { title: 'ID', dataIndex: 'id', width: '30px'  },
            { title: 'Name', dataIndex: 'name', render: (item, record) => <Link to={'firewall/edit/' + record.guid}>{record.name}</Link> },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Status', dataIndex: 'status', render: (item) => <Badge status={item === 'ACTIVE' ? 'success' : 'error'} text={normalizeEnum(item)} /> },
            { title: 'Owner', render: (item, record) => record.is_system_rule ? 'System' : <Link to={'/accounts/companies/' + record.owner.guid}>{record.owner.name}</Link>},
            { title: '', align: 'right', render: (item, record) => {
                    return <Space>
                        <Button size='small' icon={<EyeOutlined />} onClick={() => this.props.history.push('/firewall/edit/' + record.guid)}>View</Button>
                        <Popconfirm
                            title="Are you sure you want to delete this rule?"
                            onConfirm={() => this.deleteFirewallRule(record.guid)}
                            okText='Delete'
                            placement='topRight'
                            okButtonProps={{danger: true}}
                            cancelText='Cancel'>
                            <Button size='small' danger icon={<DeleteOutlined />}>Delete</Button>
                        </Popconfirm>
                    </Space>;
                }}
        ];

        return(
            <Fragment>
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <Space>
                            <Button size='small' type='text' icon={<PlusOutlined />} onClick={() => this.props.history.push('/firewall/add')}>Add Firewall Rule</Button>
                            <Button size='small' type='text' disabled={this.state.selectedRules.length === 0} icon={<DeleteOutlined />} onClick={() => this.confirmBulkDelete()}>Delete</Button>
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Tooltip title='Reload table' placement='left'><Button onClick={() => this.loadRules()} icon={<UndoOutlined />} size='small' type='circle'></Button></Tooltip>
                            <Pagination onChange={(page, pageSize) => this.loadRules(page, pageSize)} size='small' {...this.state.pagination} />
                        </Space>
                    </Col>
                </Row>

                <ConfigProvider renderEmpty={() => <div style={{ textAlign: 'center' }}>
                    <FolderOpenOutlined style={{ fontSize: 40 }} />
                    <p>Nothing to see here...</p>
                    <Button icon={<PlusOutlined />} onClick={() => this.props.history.push('/firewall/add')}>Add Firewall Rule</Button>
                </div>}>

                    <Table
                        dataSource={this.state.rules}
                        rowSelection={{
                            type: 'checkbox',
                            onChange: (rules) => this.setState({ selectedRules: rules })
                        }}
                        columns={columns}
                        rowKey={(item) => item.guid}
                        pagination={false}
                        footer={() => <Row justify='space-between'>
                            <Col>{this.state.selectedRules.length + ' selected'}</Col>
                            <Col><Pagination onChange={(page, pageSize) => this.loadRules(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                        </Row>}
                        loading={this.state.loading} />

                </ConfigProvider>


            </Fragment>
        );
    }
}

export default connect(null, { updatePage, loadFirewallRules, updateFirewallRuleStatus, deleteFirewallRule, bulkDeleteFirewallRules })(FirewallRules);