import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Col, Input, message, Modal, Row, Select, Space, Table} from "antd";
import {CaretRightOutlined, DeleteOutlined, OrderedListOutlined, PlusOutlined, StopOutlined} from "@ant-design/icons";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {StatusIndicator} from "../shared/Status";
import {
    adminCreateDomainStatusCheck,
    adminGetDomainStatusCheck,
    adminGetDomainStatusChecks,
    adminStartDomainStatusCheck,
    adminStopDomainStatusCheck
} from "../../actions/tools";
import {displayErrors} from "../../libs/utils";

class DomainStatusChecker extends React.Component {
    state = {
        results: [],
        checkRunning: false,
        showAddModal: false,
        domains: [],
        domainList: '',
        checkId: 'CURRENT',
        checksList: [],
        loadingChecksList: false,
        checkStatus: 'PENDING',
        checkStarted: null,
        checkCompleted: null,
        showExportModal: false,
        exportDomainList: []
    };

    timer = null;

    componentDidMount() {
        this.props.updatePage('Domain Status Checker');
        this.getDomainStatusChecks();
    }

    getDomainStatusChecks() {
        this.setState({ loadingChecksList: true });

        this.props.adminGetDomainStatusChecks((res) => {
            this.setState({ loadingChecksList: false, checksList: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingChecksList: false });
                displayErrors(err.response.data);
            }
        });
    }

    stopCheckTimer() {
        this.setState({ checkRunning: false });
        clearInterval(this.timer);
    }

    runCheck() {
        if(this.state.checkId !== 'CURRENT') {
            this.startCheck();
        } else {
            this.setState({ checkRunning: true });

            let domains = this.state.domains.map(item => item['domain']);

            this.props.adminCreateDomainStatusCheck({domains}, (res) => {
                this.setState({ checkId: res.data.check_id });

                this.getDomainStatusChecks();

                this.timer = setInterval(() => {
                    this.props.adminGetDomainStatusCheck(this.state.checkId, (res) => {
                        this.setState({
                            checkStatus: res.data.status,
                            checkStarted: res.data.date_created,
                            checkCompleted: res.data.date_completed,
                            domains: res.data.items
                        });

                        if(res.data.status === 'COMPLETED' || res.data.status === 'STOPPED') {
                            this.stopCheckTimer();
                        }
                    }, (err) => {
                        if(typeof err.response !== 'undefined') {
                            this.stopCheckTimer();
                            displayErrors(err.response.data);
                        }
                    });
                }, 1000);
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.stopCheckTimer();
                    displayErrors(err.response.data);
                }
            });
        }
    }

    startCheck() {
        this.setState({ checkRunning: true  });

        this.props.adminStartDomainStatusCheck(this.state.checkId, () => {
            this.timer = setInterval(() => {
                this.props.adminGetDomainStatusCheck(this.state.checkId, (res) => {
                    this.setState({
                        checkStatus: res.data.status,
                        checkStarted: res.data.date_created,
                        checkCompleted: res.data.date_completed,
                        domains: res.data.items
                    });

                    if(res.data.status === 'COMPLETED' || res.data.status === 'STOPPED') {
                        this.stopCheckTimer();
                    }
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.stopCheckTimer();
                        displayErrors(err.response.data);
                    }
                });
            }, 1000);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.stopCheckTimer();
                displayErrors(err.response.data);
            }
        });
    }

    stopCheck() {
        clearInterval(this.timer);

        this.props.adminStopDomainStatusCheck(this.state.checkId, () => {
            message.success('Domains check successfully stopped!');
            this.setState({ checkRunning: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    loadCheck(checkId) {
        if(checkId === 'CURRENT') {
            this.setState({ checkId });
        } else {
            this.setState({ checkId, loadingChecksList: true });

            this.props.adminGetDomainStatusCheck(checkId, (res) => {
                this.setState({ loadingChecksList: false, domains: res.data.items });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    displayErrors(err.response.data);
                    this.setState({ loadingChecksList: false });
                }
            });
        }
    }

    addDomains() {
        let uniqueDomains = [];
        let domainList = [];
        let raw = this.state.domainList.trim().split('\n');

        for(let i = 0; i < raw.length; i++) {
            let domain = raw[i].trim().toLowerCase();

            if(uniqueDomains.indexOf(domain) === -1) {
                uniqueDomains.push(domain);
                domainList.push({
                   domain: domain,
                   status: 'PENDING',
                   status_code: 'Not checked',
                   page_title: 'Not checked',
                   date_completed: null,
                });
            }
        }

        this.setState({ domains: domainList, showAddModal: false, checkId: 'CURRENT' });
    }

    exportSuccessful() {
        let domains = this.state.domains.filter(item => item.status === 'SUCCESS').map(item => item.domain)
        this.setState({ showExportModal: true, exportDomainList: domains });
    }

    exportFailed() {
        let domains = this.state.domains.filter(item => item.status === 'FAILURE').map(item => item.domain)
        this.setState({ showExportModal: true, exportDomainList: domains });
    }

    exportPending() {
        let domains = this.state.domains.filter(item => item.status === 'PENDING').map(item => item.domain)
        this.setState({ showExportModal: true, exportDomainList: domains });
    }

    render() {
        const { TextArea } = Input;
        const { Option } = Select;

        const columns = [
            { title: 'Domain', dataIndex: 'domain', render: (item) => <a href={'http://' + item} target='_blank' rel='noreferrer noopener'>{item}</a> },
            { title: 'Status', dataIndex: 'status', align: 'center', render: (item) => <StatusIndicator status={item} el='badge' /> },
            { title: 'Status Code', dataIndex: 'status_code', align: 'center' },
            { title: 'Page Title', dataIndex: 'page_title', width: '350px' },
            { title: 'Completed', dataIndex: 'date_checked', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Error', dataIndex: 'error', width: '350px' },
        ];

        return <Fragment>
            <Row justify='space-between'>
                <Col>
                    <Space>
                        <Button disabled={this.state.checkRunning} onClick={() => this.setState({ showAddModal: true })} icon={<PlusOutlined />} size='small'>Add Domains</Button>
                        <Button onClick={() => this.state.checkRunning ? this.stopCheck() : this.runCheck()}
                                icon={this.state.checkRunning ? <StopOutlined /> : <CaretRightOutlined />}
                                size='small'>{this.state.checkRunning ? 'Stop' : 'Start'} Check</Button>
                        <Button disabled={this.state.checkRunning} onClick={() => this.setState({ domains: [] })} icon={<DeleteOutlined />} size='small'>Clear</Button>
                        <Button disabled={this.state.checkRunning} onClick={() => this.exportSuccessful()} icon={<OrderedListOutlined />} size='small'>Export Successful</Button>
                        <Button disabled={this.state.checkRunning} onClick={() => this.exportFailed()} icon={<OrderedListOutlined />} size='small'>Export Failed</Button>
                        <Button disabled={this.state.checkRunning} onClick={() => this.exportPending()} icon={<OrderedListOutlined />} size='small'>Export Pending</Button>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Select value={this.state.checkId} onChange={(value) => this.loadCheck(value)}
                                style={{width: '300px'}}
                                loading={this.state.loadingChecksList} disabled={this.state.loadingChecksList || this.state.checkRunning}>
                            <Option value='CURRENT'>Current list</Option>
                            {this.state.checksList.map(item => {
                                return <Option key={item.guid} value={item.guid}>{item.items.length} items [<DateTimeFormat value={item.date_created} />]</Option>
                            })}
                        </Select>
                    </Space>
                </Col>
            </Row>

            <Table
                dataSource={this.state.domains} size='small' pagination={false}
                columns={columns} rowKey={item => item.domain} />

            <Modal
                visible={this.state.showAddModal}
                onOk={() => this.addDomains()}
                onCancel={() => this.setState({ showAddModal: false, domainList: '' })}
                title='Add Domains'>
                <TextArea value={this.state.domainList} rows={15} onChange={(e) => this.setState({ domainList: e.target.value })} />
            </Modal>

            <Modal visible={this.state.showExportModal} title='Export' onCancel={() => this.setState({ showExportModal: false, exportDomainList: []})}>
                <TextArea value={this.state.exportDomainList.join('\n').trim()} rows={15} />
            </Modal>
        </Fragment>
    }
}

export default connect(null, { updatePage, adminStartDomainStatusCheck,
    adminStopDomainStatusCheck, adminGetDomainStatusChecks, adminGetDomainStatusCheck, adminCreateDomainStatusCheck })(DomainStatusChecker);