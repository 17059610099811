import {httpDelete, httpGet, httpPatch, httpPost, httpPut} from "../libs/http";

export const adminLoadDomains = (page, per_page, filters = [], onSuccess = null, onFailure = null) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/admin/domains/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadDomain = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/domains/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminUpdateDomain = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPatch('/admin/domains/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadDomainLogs = (guid, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/domains/' + guid + '/logs/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadDomainTasks = (guid, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/domains/' + guid + '/tasks/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadAllCompanysDomainGroups = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/domains/groups/?page=1&per_page=9999&status=ACTIVE&company=' + guid, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadCompanyDomainGroups = (owner, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/domains/groups/?page=' + page + '&per_page=' + per_page + '&company=' + owner, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminResetDomainTask = (domain_id, task_id, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/domains/' + domain_id + '/tasks/' + task_id + '/', {}, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminRestartDomainTaskItem = (domain_id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/domains/' + domain_id + '/tasks/items/restart/', data, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminMarkDomainTaskFailed = (domain_id, task_id, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/admin/domains/' + domain_id + '/tasks/' + task_id + '/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminUninstallSSLCertificate = (domain_id, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/admin/domains/' + domain_id + '/ssl_certificate/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminInstallSSLCertificate = (domain_id, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/domains/' + domain_id + '/ssl_certificate/', {}, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminResetSSLCertificates = (domain_id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/domains/' + domain_id + '/ssl_certificate/reset/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminCheckHealth = (domain_id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/domains/' + domain_id + '/health-check/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminUpdateSSLCertificateStatus = (domain_id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPatch('/admin/domains/' + domain_id + '/ssl_certificate/', data, (res) => onSuccess(res), (err) => onFailure(err));
}

export const loginTocPanel = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/domains/' + guid + '/login/cpanel/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadDomainGroupIPAddresses = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/domains/groups/' + guid + '/ip_addresses/?page=1&per_page=9999', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminUpdateDomainsIPAddress = (domain_id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/domains/' + domain_id + '/ip_address/', data, (res) => onSuccess(res), (err) => onFailure(err));
}

export const resetDNSZone = (guid, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/domains/' + guid + '/dns_zone/reset/', {},(res) => onSuccess(res), (err) => onFailure(err));
};

export const createDNSZone = (guid, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/domains/' + guid + '/dns_zone/create/', {},(res) => onSuccess(res), (err) => onFailure(err));
};

export const loadDNSZone = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/domains/' + guid + '/dns_zone/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteDNSRecordDomain = (guid, type, value, onSuccess, onFailure) => dispatch => {
    httpDelete('/admin/domains/' + guid + '/dns_zone/' + type + '/' + value + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const createDNSRecord = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/domains/' + guid + '/dns_zone/', data,(res) => onSuccess(res), (err) => onFailure(err));
};

export const editDNSRecord = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPut('/admin/domains/' + guid + '/dns_zone/', data,(res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadUrlRedirects = (guid, page, perPage, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/domains/' + guid + '/redirects/?page=' + page + '&per_page=' + perPage, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadSubdomains = (guid, page, perPage, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/domains/' + guid + '/subdomains/?page=' + page + '&per_page=' + perPage, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadEmailAddresses = (guid, page, perPage, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/domains/' + guid + '/email_addresses/?page=' + page + '&per_page=' + perPage, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminBulkUpdateDomains = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/domains/bulk/', data,(res) => onSuccess(res), (err) => onFailure(err));
};

export const adminBulkResetDomainsDNSZone = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/domains/bulk/dns_zone/reset/', data,(res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoginToSoftware = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/domains/' + guid + '/login/software/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadSoftwareInstallations = (guid, page, perPage, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/domains/' + guid + '/software_installations/?page=' + page + '&per_page=' + perPage, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadSSLCertificates = (guid, page, perPage, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/domains/' + guid + '/ssl_certificates/?page=' + page + '&per_page=' + perPage, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminUpdateSSLCertificateField = (ssl_certificate_id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPatch('/admin/domains/ssl_certificate/' + ssl_certificate_id + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminDomainCheckWhois = (guid, onSuccess, onFailure) => dispatch => {
    httpPatch('/admin/domains/' + guid + '/whois/', {}, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminDomainCheckMetrics = (guid, onSuccess, onFailure) => dispatch => {
    httpPatch('/admin/domains/' + guid + '/metrics/', {}, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminSendTestEmail = (guid, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/email_addresses/' + guid + '/test/', {},(res) => onSuccess(res), (err) => onFailure(err));
};

export const uninstallSoftware = (guid, onSuccess, onFailure) => dispatch => {
    httpDelete('/admin/domains/' + guid + '/software/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateDomainsInCpanel = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/domains/create-in-cpanel/', data,(res) => onSuccess(res), (err) => onFailure(err));
};
