import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_PER_PAGE} from "../../config";
import {displayErrors} from "../../libs/utils";
import {adminLoadPriceGroups} from "../../actions/network";
import {Button, Col, Pagination, Row, Space, Table, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import {MoneyField} from "../shared/MoneyField";
import {EyeOutlined} from "@ant-design/icons";
import PriceGroupModal from "./PriceGroupModal";

class PriceGroupsTable extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        priceGroups: [],
        loadingPriceGroups: true,
        showNewPriceGroupModal: false,
        activePriceGroup: null
    };

    componentDidMount() {
        this.props.updatePage('Price Groups');
        this.adminLoadPriceGroups();
    }

    adminLoadPriceGroups(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingPriceGroups: true });

        this.props.adminLoadPriceGroups(page, per_page, (res) => {
            this.setState({ loadingPriceGroups: false, priceGroups: res.data.data,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingPriceGroups: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const columns = [
            { title: 'Name', dataIndex: 'name' },
            { title: 'IP addresses', dataIndex: 'ip_address_count' },
            { title: 'Price $ ', align: 'center', render: (item, record) => <MoneyField amount={record.ip_address_price} currency='USD' /> },
            { title: 'Price € ', align: 'center', render: (item, record) => <MoneyField amount={record.ip_address_price_eur} currency='EUR' /> },
            { title: 'Site Price $ ', align: 'center', render: (item, record) => <MoneyField amount={record.site_price} currency='USD' /> },
            { title: 'Site Price € ', align: 'center', render: (item, record) => <MoneyField amount={record.site_price_eur} currency='EUR' /> },
            { title: '', align: 'right', render: (item, record) => <Button size='small' icon={<EyeOutlined />} onClick={() => this.setState({ showNewPriceGroupModal: true, activePriceGroup: record.guid })}>View</Button>}
        ];

        return(
            <Fragment>
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <Space>
                            <Button type='text' size='small' icon={<PlusOutlined />} onClick={() => this.setState({ showNewPriceGroupModal: true })}>New Price Group</Button>
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Tooltip title='Reload' placement='left'><Button icon={<UndoOutlined />} size='small' type='circle' onClick={() => this.adminLoadPriceGroups()} /></Tooltip>
                            <Pagination onChange={(page, pageSize) => this.adminLoadPriceGroups(page, pageSize)} size='small' {...this.state.pagination} />
                        </Space>
                    </Col>
                </Row>

                <Table
                    columns={columns}
                    rowKey={(item) => item.guid}
                    dataSource={this.state.priceGroups}
                    loading={this.state.loadingPriceGroups}
                    pagination={false}
                    footer={() => <Row justify='space-between'>
                        <Col />
                        <Col><Pagination onChange={(page, pageSize) => this.adminLoadPriceGroups(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                    </Row>}
                />

                <PriceGroupModal
                    showModal={this.state.showNewPriceGroupModal}
                    activePriceGroup={this.state.activePriceGroup}
                    reload={() => this.adminLoadPriceGroups()}
                    close={() => this.setState({ showNewPriceGroupModal: false, activePriceGroup: null })} />
            </Fragment>
        )
    }
}

export default connect(null, { updatePage, adminLoadPriceGroups })(PriceGroupsTable);