import React from 'react';
import { connect } from 'react-redux';
import {Button, Col, Form, Input, message, Row} from "antd";
import {login} from "../../actions/auth";
import {displayErrors} from "../../libs/utils";
import {Redirect} from "react-router-dom";
import {updatePage} from "../../actions/page";

class Login extends React.Component  {
    state = {
        loading: false,
        step: 1,
        email: '',
        password: '',
        remember: false
    };

    componentDidMount() {
        this.props.updatePage('Login');
        this.emailInput.focus();
    }

    nextStep() {
        let email = this.state.email.trim();

        if(email.length === 0) {
            return message.error('Please enter your email address!');
        }

        if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email)) {
            return message.error('Please enter correct email address!');
        }

        this.setState({ step: 2 }, () => {
            this.passwordInput.focus();
        });
    }

    login() {
        let email = this.state.email.trim();
        let password = this.state.password.trim();

        if(email.length === 0) {
            return message.error('Please enter your email address!');
        }

        if(password.length === 0) {
            return message.error('Please enter your password!');
        }

        let data = { email, password };

        this.setState({ loading: true });

        this.props.login(data, (res) => {}, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    render() {
        if(this.props.isAuthenticated) {
            return <Redirect to='/' />;
        }

        return(
            <Row className='login-wrapper' justify='center'>
                <Col xs={24} sm={12}>
                    <div className='login-form'>
                        <img alt='Priority Prospect' src='/img/logo-top-dark.png' style={{width: '100%', marginBottom: '30px'}} />
                        <Form layout='vertical'>
                            <Form.Item>
                                <Input type='email'
                                       value={this.state.email}
                                       ref={(input) => this.emailInput = input}
                                       placeholder='Email address'
                                       onKeyUp={(e) => e.key === 'Enter' ? this.login() : ''}
                                       onChange={(e) => this.setState({ email: e.target.value })} />
                            </Form.Item>
                            <Form.Item>
                                <Input type='password'
                                       ref={(input) => this.passwordInput = input}
                                       value={this.state.password}
                                       placeholder='Password'
                                       onKeyUp={(e) => e.key === 'Enter' ? this.login() : ''}
                                       onChange={(e) => this.setState({ password: e.target.value })} />
                            </Form.Item>
                            <Row>
                                <Col span={24}>
                                    <Form.Item>
                                        <Button type='primary'
                                                style={{width: '300px'}}
                                                disabled={this.state.loading}
                                                loading={this.state.loading}
                                                onClick={() => this.login()}>Login</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.isLoading
});

export default connect(mapStateToProps, { updatePage, login })(Login);