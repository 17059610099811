import {httpDelete, httpGet, httpPatch, httpPost} from "../libs/http";

export const adminLoadAllHostingAccounts = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/hosting/?owner=' + guid + '&page=1&per_page=9999', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadHostingAccounts = (page, per_page, filters, onSuccess = null, onFailure = null) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/admin/hosting/accounts/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadHostingAccount = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/hosting/accounts/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminGetSharedHostingPassword = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/hosting/shared_hosting/' + guid + '/password/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoginTocPanel = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/hosting/shared_hosting/' + guid + '/login/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadHostingAccountDomains = (guid, page, per_page, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/hosting/accounts/' + guid + '/domains/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminChangeCpanelPassword = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/hosting/shared_hosting/' + guid + '/change_password/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminUpdateHostingAccountField = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPatch('/admin/hosting/accounts/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminBulkHostingAccountActions = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/hosting/shared_hosting/bulk/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminDeleteCpanelAccount = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/admin/hosting/shared_hosting/' + guid + '/cpanel/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadCompanyHostingAccounts = (owner, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/hosting/accounts/?page=' + page + '&per_page=' + per_page + '&owner=' + owner, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminSuspendHostingAccount = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/hosting/shared_hosting/' + guid + '/suspend/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminUnuspendHostingAccount = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/hosting/shared_hosting/' + guid + '/unsuspend/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminResendNewHostingAccountEmail = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/hosting/shared_hosting/' + guid + '/resend-welcome-email/', (res) => onSuccess(res), (err) => onFailure(err));
};