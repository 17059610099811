import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Card, Col, Descriptions, Menu, Row, Select, Table, message, Popconfirm} from "antd";
import {adminDeleteIssueUpdate, adminLoadIssue, adminLoadIssueUpdates, adminUpdateIssue} from "../../actions/system";
import {displayErrors} from "../../libs/utils";
import {Loading} from "../../libs/loading";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {DeleteOutlined, PlusOutlined, SaveOutlined} from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import {adminLoadAllIPNodes, adminLoadAllSharedHostingServers} from "../../actions/network";
import NewIssueUpdateModal from "./NewIssueUpdateModal";
import {adminLoadHostingAccounts} from "../../actions/hosting";
import {adminLoadAllCompanies} from "../../actions/accounts";

class IssueView extends React.Component {
    state = {
        loadingIssue: false,
        data: null,
        status: '',
        message: '',
        updates: [],
        showNewIssueUpdateModal: false,
        loadingIPNodes: false,
        ipNodes: [],
        loadingSharedHostingServers: false,
        sharedHostingServers: [],
        loadingSharedHostingAccounts: false,
        sharedHostingAccounts: [],
        loadingCompanies: false,
        companies: [],
        companies_list: []
    };

    componentDidMount() {
        this.props.updatePage('Issue Detail');

        this.adminLoadIssue();
        this.adminLoadIPNodes();
        this.adminLoadSharedHostingServers();
        this.adminLoadSharedHostingAccounts();
        this.adminLoadCompanies();
    }

    adminLoadIssue() {
        this.setState({ loadingIssue: true });

        this.props.adminLoadIssue(this.props.match.params.guid, (res) => {
            this.setState({ loadingIssue: false, ...res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIssue: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadIPNodes() {
        this.setState({ loadingIPNodes: true });

        this.props.adminLoadAllIPNodes((res) => {
            this.setState({ loadingIPNodes: false, ipNodes: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIPNodes: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadSharedHostingServers() {
        this.setState({ loadingSharedHostingServers: true });

        this.props.adminLoadAllSharedHostingServers((res) => {
            this.setState({ loadingSharedHostingServers: false, sharedHostingServers: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingSharedHostingServers: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadSharedHostingAccounts() {
        this.setState({ loadingSharedHostingAccounts: true });

        this.props.adminLoadHostingAccounts(1, 9999, [{key: 'status', value: 'ACTIVE'}, {key: 'type', value: 'SHARED_HOSTING'}], (res) => {
            this.setState({ loadingSharedHostingAccounts: false, sharedHostingAccounts: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingSharedHostingAccounts: false });
                displayErrors(err.response.data);
            }
        });
    }
    adminLoadCompanies() {
        this.setState({ loadingCompanies: true });

        this.props.adminLoadAllCompanies((res) => {
            this.setState({ loadingCompanies: false, companies_list: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCompanies: false });
                displayErrors(err.response.data);
            }
        });
    }

    deleteUpdate(guid) {
        this.setState({ updatingUpdates: true });

        this.props.adminDeleteIssueUpdate(this.props.match.params.guid, guid, () => {
            this.setState({ updatingUpdates: false });
            message.success('Update successfully deleted!');

            this.adminLoadIssueUpdates();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ updatingUpdates: false });
                displayErrors(err.response.data);
            }
        });
    }

    saveIssue() {
        let messageBody = this.state.message;
        let status = this.state.status;
        let ip_nodes = this.state.ip_nodes;
        let shared_hosting_servers = this.state.shared_hosting_servers;
        let hosting_accounts = this.state.hosting_accounts;
        let companies = this.state.companies;

        let data = {
            message: messageBody, status, ip_nodes, shared_hosting_servers, hosting_accounts, companies
        };

        this.setState({ loading: true });

        this.props.adminUpdateIssue(this.props.match.params.guid, data, () => {
            this.setState({ loading: false });
            message.success('Issue successfully updated!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadIssueUpdates() {
        this.setState({ updatingUpdates: true });

        this.props.adminLoadIssueUpdates(this.props.match.params.guid, (res) => {
            this.setState({ updatingUpdates: false, updates: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ updatingUpdates: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        if(this.state.loadingIssue) {
            return <div className='text-center'><Loading /></div>;
        }

        const { Option } = Select;

        const columns = [
            { title: 'Message', dataIndex: 'message', width: '80%'},
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: '', align: 'right', render: (item, record) => <Popconfirm
                    title="Are you sure you want to remove this update?"
                    onConfirm={() => this.deleteUpdate(record.guid)}
                    okText='Remove'
                    placement='topRight'
                    okButtonProps={{danger: true}}
                    cancelText=''>
                    <Button size='small' type='circle' icon={<DeleteOutlined />}  />
                </Popconfirm>}
        ];

        return(
            <Fragment>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Card size='small' title='Details' extra={<Button size='small' icon={<SaveOutlined />} onClick={() => this.saveIssue()}>Update</Button>}>
                            <Descriptions bordered size='small' column={1}>
                                <Descriptions.Item label='Status' style={{width: '50%'}}>
                                    <Select style={{width: '100%'}} value={this.state.status} onChange={(value) => this.setState({ status: value })}>
                                        <Option value='INVESTIGATING'>Investigating</Option>
                                        <Option value='FIXING'>Fixing</Option>
                                        <Option value='RESOLVED'>Resolved</Option>
                                        <Option value='DELETED'>Deleted</Option>
                                    </Select>
                                </Descriptions.Item>
                                <Descriptions.Item label='Created'><DateTimeFormat value={this.state.date_created} /></Descriptions.Item>
                                <Descriptions.Item label='Initial message'>
                                    <TextArea value={this.state.message} onChange={(e) => this.setState({ message: e.target.value })} />
                                </Descriptions.Item>
                                <Descriptions.Item label='Affected IP nodes'>
                                    <Select mode='multiple'
                                            showSearch loading={this.state.loadingIPNodes} disabled={this.state.loadingIPNodes}
                                            value={this.state.ip_nodes}
                                            style={{width: '100%'}}
                                            filterOption={(input, option) => {
                                                if(typeof option !== 'undefined') {
                                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                }
                                                return false;
                                            }}
                                            onChange={(values) => this.setState({ ip_nodes: values })}>
                                        {this.state.ipNodes.map((ipNode) => <Option value={ipNode.guid}>{ipNode.hostname.replace('.gateway.prioritynap.net', '')}</Option>)}
                                    </Select>
                                </Descriptions.Item>
                                <Descriptions.Item label='Affected shared hosting servers'>
                                    <Select mode='multiple'
                                            showSearch loading={this.state.loadingSharedHostingServers} disabled={this.state.loadingSharedHostingServers}
                                            value={this.state.shared_hosting_servers}
                                            style={{width: '100%'}}
                                            filterOption={(input, option) => {
                                                if(typeof option !== 'undefined') {
                                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                }
                                                return false;
                                            }}
                                            onChange={(values) => this.setState({ shared_hosting_servers: values })}>
                                        {this.state.sharedHostingServers.map((server) => <Option value={server.guid}>{server.hostname}</Option>)}
                                    </Select>
                                </Descriptions.Item>
                                <Descriptions.Item label='Affected shared hosting accounts'>
                                    <Select mode='multiple'
                                            showSearch loading={this.state.loadingSharedHostingAccounts} disabled={this.state.loadingSharedHostingAccounts}
                                            value={this.state.hosting_accounts}
                                            style={{width: '100%'}}
                                            filterOption={(input, option) => {
                                                if(typeof option !== 'undefined') {
                                                    return option.children.join(' ').toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                }
                                                return false;
                                            }}
                                            onChange={(values) => this.setState({ hosting_accounts: values })}>
                                        {this.state.sharedHostingAccounts.map((account) => <Option value={account.guid}>#{account.id} - {account.name} ({account.owner.id} - {account.owner.name})</Option>)}
                                    </Select>
                                </Descriptions.Item>
                                <Descriptions.Item label='Affected companies'>
                                    <Select mode='multiple'
                                            showSearch loading={this.state.loadingCompanies} disabled={this.state.loadingCompanies}
                                            value={this.state.companies}
                                            style={{width: '100%'}}
                                            filterOption={(input, option) => {
                                                if(typeof option !== 'undefined') {
                                                    return option.children.join(' ').toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                                }
                                                return false;
                                            }}
                                            onChange={(values) => this.setState({ companies: values })}>
                                        {this.state.companies_list.map((company) => <Option key={company.guid} value={company.guid}>#{company.id} - {company.name} (#{company.owner.id} - {company.owner.first_name + ' ' + company.owner.last_name} - {company.owner.email})</Option>)}
                                    </Select>
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                </Row>
                <br />
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Menu mode="horizontal" selectedKeys={[]}>
                            <Menu.Item key='new_update' icon={<PlusOutlined />} onClick={() => this.setState({ showNewIssueUpdateModal: true })}>
                                New Update
                            </Menu.Item>
                        </Menu>
                        <Table
                            columns={columns}
                            size='small'
                            rowKey={(item) => item.guid}
                            dataSource={this.state.updates}
                            loading={this.state.updatingUpdates}
                            pagination={false}
                        />
                    </Col>
                </Row>

                <NewIssueUpdateModal
                    showModal={this.state.showNewIssueUpdateModal}
                    reload={() => this.adminLoadIssueUpdates()}
                    issueGuid={this.props.match.params.guid}
                    close={() => this.setState({ showNewIssueUpdateModal: false })} />
            </Fragment>
        )
    }
}

export default connect(null, { updatePage, adminLoadIssue,
    adminLoadAllSharedHostingServers, adminLoadAllIPNodes, adminDeleteIssueUpdate, adminLoadIssueUpdates,
    adminUpdateIssue, adminLoadHostingAccounts, adminLoadAllCompanies })(IssueView);