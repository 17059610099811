import React, {Fragment} from "react";
import {connect} from "react-redux";
import {deleteDNSRecordDomain, loadDNSZone, resetDNSZone} from "../../actions/domains";
import {Alert, Button, Card, message, Popconfirm, Space, Table} from "antd";
import {Loading} from "../../libs/loading";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";
import EditOutlined from "@ant-design/icons/lib/icons/EditOutlined";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import DNSRecordModal from "./DNSRecordModal";
import {displayErrors} from "../../libs/utils";

class DNSZone extends React.Component {
    state = {
        loadingDNSZone: false,
        updatingDNSRecord: false,
        domain: '',
        records: null,
        showDNSRecordModal: false,
        recordData: null,
        recordType: '',
        soaData: null,
        isAvailable: false
    };

    componentDidMount() {
        this.loadDNSZone();
    }

    loadDNSZone() {
        if(this.state.records === null) {
            this.setState({ loadingDNSZone: true });
        }

        this.props.loadDNSZone(this.props.guid, (res) => {
            this.setState({ loadingDNSZone: false, domain: res.data.domain, records: res.data.records, isAvailable: true });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingDNSZone: false });
            }
        });
    }

    deleteRecord(type, data) {
        this.setState({ updatingDNSRecord: true });

        let name = data.name;

        if(type === 'NS') {
            name = data.records[0];
        }

        this.props.deleteDNSRecordDomain(this.props.guid, type, name, (res) => {
            message.success('DNS record successfully deleted!');
            this.loadDNSZone();
            this.setState({ updatingDNSRecord: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ updatingDNSRecord: false });
            }
        });
    }

    resetDNSZone() {
        this.setState({ loadingDNSZone: true });

        this.props.resetDNSZone(this.props.guid, (res) => {
            message.success('DNS zone successfully reset!');

            this.setState({ loadingDNSZone: false });
            this.loadDNSZone();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingDNSZone: false });
            }
        });
    }

    render() {
        if(!this.state.loadingDNSZone && !this.state.isAvailable) {
            return <Alert message='DNS zone is not available, yet!' showIcon />;
        }

        if(this.state.loadingDNSZone || this.state.records === null) {
            return <div className='text-center'><Loading/></div>;
        }

        const { records } = this.state;

        let ns_records_columns = [
            { title: 'Nameserver', dataIndex: 'content', key: 'content', render: (item, row) => row.records.map((line, i) => { return <span key={i}>{line}</span>})},
            { title: 'TTL', dataIndex: 'ttl', key: 'ttl', render: (item, row) => row.ttl},
            { title: '', key: 'actions', width: 50, render: (item, row) => {
                    return <Popconfirm
                        onConfirm={() => this.deleteRecord('NS', row)}
                        placement='topRight'
                        title="Are you sure?"
                        okButtonProps={{danger: true}}
                        okText="Delete"
                        cancelText="Cancel">
                        <Button size='small' type='round' danger icon={<DeleteOutlined />} />
                    </Popconfirm>;
                }}
        ];

        let a_records_columns = [
            { title: 'Name', dataIndex: 'content', key: 'name', render: (item, row) => row.name},
            { title: 'Records', key: 'records', render: (item, row) => row.records.map((line, i) => { return <span key={i}>{line}</span>})},
            { title: 'TTL', dataIndex: 'ttl', key: 'ttl', render: (item, row) => row.ttl},
            { title: '', key: 'actions', width: 50, render: (item, row) => {
                    return <Space>
                        <Button size='small' type='round' icon={<EditOutlined />} onClick={() => this.setState({ showDNSRecordModal: true, recordType: 'A', recordData: { name: row.name, data: row } })} />
                        <Popconfirm
                            onConfirm={() => this.deleteRecord('A', row)}
                            placement='topRight'
                            title="Are you sure?"
                            okButtonProps={{danger: true}}
                            okText="Delete"
                            cancelText="Cancel">
                            <Button size='small' type='round' danger icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Space>
                }}
        ];

        let cname_records_columns = [
            { title: 'Name', dataIndex: 'name', key: 'name', render: (item, row) => row.name},
            { title: 'Records', key: 'records', render: (item, row) => row.records.map((line, i) => { return <span key={i}>{line}</span>})},
            { title: 'TTL', dataIndex: 'ttl', key: 'ttl', render: (item, row) => row.ttl},
            { title: '', key: 'actions', width: 50, render: (item, row) => {
                    return <Space>
                        <Button size='small' type='round' icon={<EditOutlined />} onClick={() => this.setState({ showDNSRecordModal: true, recordType: 'CNAME', recordData: { name: row.name, data: row } })} />
                        <Popconfirm
                            onConfirm={() => this.deleteRecord('CNAME', row)}
                            placement='topRight'
                            title="Are you sure?"
                            okButtonProps={{danger: true}}
                            okText="Delete"
                            cancelText="Cancel">
                            <Button size='small' type='round' danger icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Space>
                }}
        ];

        let mx_records_columns = [
            { title: 'Name', dataIndex: 'name', key: 'name',  render: (item, row) => row.name},
            { title: 'Records', key: 'hostname', render: (item, row) => row.records.map((line, i) => { return <div key={i}>{line}</div>})},
            { title: 'TTL', dataIndex: 'ttl', key: 'ttl', render: (item, row) => row.ttl},
            { title: '', key: 'actions', width: 50, render: (item, row) => {
                    return <Space>
                        <Button size='small' type='round' icon={<EditOutlined />} onClick={() => this.setState({ showDNSRecordModal: true, recordType: 'MX', recordData: { name: row.name, data: row } })} />
                        <Popconfirm
                            onConfirm={() => this.deleteRecord('MX', row)}
                            placement='topRight'
                            title="Are you sure?"
                            okButtonProps={{danger: true}}
                            okText="Delete"
                            cancelText="Cancel">
                            <Button size='small' type='round' danger icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Space>
                }}
        ];

        let txt_records_columns = [
            { title: 'Name', dataIndex: 'name', key: 'name', render: (item, row) => row.name},
            { title: 'Records', key: 'records', render: (item, row) => row.records.map((line, i) => { return <p key={i}>{line}</p>})},
            { title: 'TTL', dataIndex: 'ttl', key: 'ttl', render: (item, row) => row.ttl},
            { title: '', key: 'actions', width: 50, render: (item, row) => {
                    return <Space>
                        <Button size='small' type='round' icon={<EditOutlined />} onClick={() => this.setState({ showDNSRecordModal: true, recordType: 'TXT', recordData: { name: row.name, data: row } })} />
                        <Popconfirm
                            onConfirm={() => this.deleteRecord('TXT', row)}
                            placement='topRight'
                            title="Are you sure?"
                            okButtonProps={{danger: true}}
                            okText="Delete"
                            cancelText="Cancel">
                            <Button size='small' type='round' danger icon={<DeleteOutlined />} />
                        </Popconfirm>
                    </Space>
                }}
        ];

        let soa_records_columns = [
            { title: 'Nameserver', dataIndex: 'nameserver', key: 'nameserver'},
            { title: 'Email', dataIndex: 'email', key: 'email'},
            { title: 'Serial', dataIndex: 'serial', key: 'serial'},
            { title: 'Refresh', dataIndex: 'refresh', key: 'refresh'},
            { title: 'Retry', dataIndex: 'retry', key: 'retry'},
            { title: 'Expiry', dataIndex: 'expiry', key: 'expiry'},
            { title: 'TTL', dataIndex: 'ttl', key: 'ttl'},
            { title: '', key: 'actions', render: (item, row) => <Button size='small' type='round' icon={<EditOutlined />} onClick={() => this.setState({ showDNSRecordModal: true, recordData:  { name: row.name, data: row } , recordType: 'SOA'})} />}
        ];

        return(
            <Fragment>
                <Card bordered={false} size='small' title='SOA'>
                    <Table loading={this.state.updatingDNSRecord} columns={soa_records_columns} dataSource={records['SOA']} pagination={false} size='small' bordered={false} rowKey={(item) => item.nameserver} />
                </Card>
                <Card bordered={false} size='small' title='NS' extra={<Button size='small' type='round' onClick={() => this.setState({ showDNSRecordModal: true, recordData: null, recordType: 'NS' })}><PlusOutlined /> Add NS Record</Button>}>
                    <Table loading={this.state.updatingDNSRecord} columns={ns_records_columns} dataSource={records['NS']} pagination={false} size='small' bordered={false} rowKey={(item) => item.records[0]} />
                </Card>
                <Card bordered={false} size='small' title='A' extra={<Button size='small' type='round' onClick={() => this.setState({ showDNSRecordModal: true, recordData: null, recordType: 'A' })}><PlusOutlined /> Add A Record</Button>}>
                    <Table loading={this.state.updatingDNSRecord} columns={a_records_columns} dataSource={records['A']} pagination={false} size='small' bordered={false} rowKey={(item) => item.name} />
                </Card>
                <Card bordered={false} size='small' title='CNAME' extra={<Button size='small' type='round' onClick={() => this.setState({ showDNSRecordModal: true, recordData: null, recordType: 'CNAME' })}><PlusOutlined /> Add CNAME Record</Button>}>
                    <Table loading={this.state.updatingDNSRecord} columns={cname_records_columns} dataSource={records['CNAME']} pagination={false} size='small' bordered={false} rowKey={(item) => item.name} />
                </Card>
                <Card bordered={false} size='small' title='MX' extra={<Button size='small' type='round' onClick={() => this.setState({ showDNSRecordModal: true, recordData: null, recordType: 'MX' })}><PlusOutlined /> Add MX Record</Button>}>
                    <Table loading={this.state.updatingDNSRecord} columns={mx_records_columns} dataSource={records['MX']} pagination={false} size='small' bordered={false} rowKey={(item) => item.name} />
                </Card>
                <Card bordered={false} size='small' title='TXT' extra={<Button size='small' type='round' onClick={() => this.setState({ showDNSRecordModal: true, recordData: null, recordType: 'TXT' })}><PlusOutlined /> Add TXT Record</Button>}>
                    <Table loading={this.state.updatingDNSRecord} columns={txt_records_columns} dataSource={records['TXT']} pagination={false} size='small' bordered={false} rowKey={(item) => item.name} />
                </Card>

                <DNSRecordModal
                    showModal={this.state.showDNSRecordModal}
                    data={this.state.recordData}
                    type={this.state.recordType}
                    domainName={this.state.domain}
                    domainGuid={this.props.guid}
                    reloadZone={() => this.loadDNSZone()}
                    closeModal={() => this.setState({ showDNSRecordModal: false, recordData: null, recordType: null })} />
            </Fragment>
        );
    }
}

export default connect(null, { loadDNSZone, deleteDNSRecordDomain, resetDNSZone })(DNSZone);