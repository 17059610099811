import React from "react";
import {Descriptions, Modal, Typography} from "antd";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {Link} from "react-router-dom";

class EmailDetailModal extends React.Component {
    render() {
        const {Paragraph} = Typography;
        const { email } = this.props;

        if(email === null) {
            return '';
        }

        let account = '';

        if(email.user !== null) {
            account = <Link to={'/accounts/users/' + email.user.guid}>{email.user.first_name + ' ' + email.user.last_name}</Link>;
        }

        if(email.company !== null) {
            account = <Link to={'/accounts/companies/' + email.company.guid}>{email.company.name}</Link>;
        }

        return <Modal
            visible={this.props.show}
            title={email.subject}
            width={700}
            footer={false}
            onCancel={() => this.props.close()}>

            <Descriptions bordered size='small' layout='horizontal' column={1}>
                <Descriptions.Item style={{width: '30%'}}
                                   label='To'>{email.name + '<' + email.email + '>'}</Descriptions.Item>
                <Descriptions.Item label='Account'>{account}</Descriptions.Item>
                <Descriptions.Item label='Date Created'><DateTimeFormat value={email.date_created}/></Descriptions.Item>
                <Descriptions.Item label='Date sent'><DateTimeFormat value={email.date_sent}/></Descriptions.Item>
                <Descriptions.Item label='Is sent'>{email.is_sent ? 'Yes' : 'Scheduled'}</Descriptions.Item>
                <Descriptions.Item label='Attachment'>{email.attachment ? <a href={email.attachment.url} target='_blank' rel='noreferrer noopener'>{email.attachment.file_name}</a> : '-'}</Descriptions.Item>
            </Descriptions>
            <Paragraph style={{marginTop: '15px'}}>
                <div dangerouslySetInnerHTML={{__html: email.content}}/>
            </Paragraph>
        </Modal>;
    }
}

export default EmailDetailModal;