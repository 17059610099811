import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, Modal, Select, Space} from "antd";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {loadAllCompanies} from "../../actions/users";
import {adminLoadAllCompanysDomainGroups} from "../../actions/domains";

class TransactionsTableFilters extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFilter: 'ID',
            filterValue: '',
            filterKey: 'id',
            filterVisible: null,

            loadingCompanies: false,
            companiesLoaded: false,
            companies: [],
            domainGroups: [],
            loadingDomainGroups: false,
            selectedCompany: ''
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingCompanies && !this.state.companiesLoaded) {
            this.setState({ loadingCompanies: true })

            this.props.loadAllCompanies((res) => {
                this.setState({ loadingCompanies: false, companiesLoaded: true, companies: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingCompanies: false, companiesLoaded: true });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    onChange(){
        let visibleValue = null;

        if(this.state.selectedFilter === 'COMPANY') {
            visibleValue = this.getCompanyName(this.state.filterValue);
        }

        this.props.onChange({
            name: normalizeEnum(this.state.selectedFilter),
            key: this.state.filterKey,
            value: this.state.filterValue.trim(),
            visible: visibleValue
        });
    }

    closeModal(apply = false, close = true) {
        this.setState({
            selectedFilter: 'ID',
            filterValue: '',
            filterKey: 'id',
            filterVisible: null,
        });

        if(close) {
            this.props.closeModal();
        }

        if(apply) {
            this.onChange();
        }
    }

    getCompanyName(guid) {
        let company = null;

        for(let i = 0; i < this.state.companies.length; i++) {
            if(this.state.companies[i].guid === guid) {
                company = this.state.companies[i];
                break;
            }
        }

        return company !== null ? company.name : '';
    }

    setFilter(name) {
        const filters = {
            COMPANY: { key: 'company', value: '' },
            METHOD: { key: 'method', value: '' },
            ID: { key: 'id', value: '' },
            OWNER_EMAIL: { key: 'owner_email', value: '' },
        };

        this.setState({ selectedFilter: name, filterKey: filters[name].key, filterValue: filters[name].value });
    }

    loadCompanyDomainGroups(owner_id) {
        this.setState({ loadingDomainGroups: true, selectedCompany: owner_id });

        this.props.adminLoadAllCompanysDomainGroups(owner_id, (res) => {
            this.setState({ domainGroups: res.data.data, loadingDomainGroups: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingDomainGroups: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        let content = '';

        if(this.state.selectedFilter === 'COMPANY') {
            content = <Select
                filterOption={(input, option) => {
                    if(typeof option !== 'undefined') {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                }}
                loading={this.state.loadingCompanies}
                disabled={this.state.loadingCompanies}
                value={this.state.filterValue}
                showSearch allowClear
                onChange={(value) => this.setState({ filterValue: value })}>
                {this.state.companies.map((company, i) => {
                    return <Option key={i} value={company.guid}>{company.name}</Option>
                })}
            </Select>
        } else if(this.state.selectedFilter === 'ID') {
            content = <Input value={this.state.filterValue}
                             onChange={(e) => this.setState({ filterValue: e.target.value })} />
        } else if(this.state.selectedFilter === 'OWNER_EMAIL') {
            content = <Input value={this.state.filterValue}
                             onChange={(e) => this.setState({ filterValue: e.target.value })} />
        } else if(this.state.selectedFilter === 'METHOD') {
            content = <Select onChange={(value) => this.setState({ filterValue: value })}>
                <Option value='CARD'>Card</Option>
                <Option value='PAYPAL'>Paypal</Option>
                <Option value='BANK_TRANSFER'>Bank transfer</Option>
                <Option value='CREDIT'>Credit</Option>
                <Option value='CREDIT_VOUCHER'>Credit voucher</Option>
                <Option value='COUPON'>Coupon</Option>
            </Select>
        }

        return(
            <Modal
                title='Add Filter'
                destroyOnClose={true}
                onCancel={() => this.closeModal()}
                footer={<Space>
                    <Button onClick={() => this.closeModal()}>Close</Button>
                    <Button onClick={() => this.closeModal(true, false)}>Apply</Button>
                    <Button type='primary' onClick={() => this.closeModal(true, true)}>Apply & Close</Button>
                </Space>}
                visible={this.props.showModal}>
                <Form layout='vertical'>
                    <Form.Item label='Select filter:'>
                        <Select value={this.state.selectedFilter} onChange={(value) => this.setFilter(value)}>
                            <Option value='ID'>ID</Option>
                            <Option value='COMPANY'>Company</Option>
                            <Option value='METHOD'>Method</Option>
                            <Option value='OWNER_EMAIL'>Owner email</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        {content}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { loadAllCompanies, adminLoadAllCompanysDomainGroups })(TransactionsTableFilters);