import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Col, Pagination, Row, Space, Table, Tag, Tooltip} from "antd";
import {Link} from "react-router-dom";
import {DateFormat} from "../shared/DateFormat";
import {StatusIndicator} from "../shared/Status";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {EyeOutlined, FilterOutlined} from "@ant-design/icons";
import {DEFAULT_PER_PAGE} from "../../config";
import {adminLoadUsers} from "../../actions/accounts";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import UsersTableFilter from "./UsersTableFilter";
import {DateTimeFormat} from "../shared/DateTimeFormat";

class UsersTable extends React.Component {
    state = {
        users: [],
        loadingUsers: false,
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        filters: [],
        showFiltersModal: false,
    };

    componentDidMount() {
        this.props.updatePage('Users');
        this.adminLoadUsers();
    }

    adminLoadUsers(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingUsers: true });

        this.props.adminLoadUsers(page, per_page, this.state.filters, (res) => {
            window.scrollTo(0, 0);

            this.setState({ users: res.data.data, loadingUsers: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingUsers: false });
                displayErrors(err.response.data);
            }
        });
    }

    onTableChange(pagination) {
        this.adminLoadUsers(pagination.current, pagination.pageSize);
    }


    clearFilters() {
        this.setState({ filters: [] }, () => this.adminLoadUsers());
    }

    setFilter(filter) {
        let filters = this.state.filters.filter(f => f.key !== filter.key);
        filters.push(filter);

        this.setState({ filters }, () => this.adminLoadUsers());
    }

    removeFilter(item) {
        let filters = this.state.filters.filter(filter => filter.key !== item.key);
        this.setState({ filters }, () => this.adminLoadUsers());
    }

    showFilters() {
        return this.state.filters.map((filter, i) => {
            let value;

            if (typeof filter.value === 'boolean') {
                if (filter.value) {
                    value = 'Yes';
                } else {
                    value = 'No';
                }
            } else {
                if (filter.visible !== null) {
                    value = filter.visible;
                } else {
                    value = normalizeEnum(filter.value);
                }
            }

            return <Tag key={i} closable onClose={() => this.removeFilter(filter)}>{filter.name}: {value}</Tag>;
        });
    }

    render() {
        const columns = [
            { title: 'ID', dataIndex: 'id', render: (item, record) => <Link to={'/accounts/users/' + record.guid}>{item}</Link> },
            { title: 'First name', dataIndex: 'first_name', render: (item, record) => <Link to={'/accounts/users/' + record.guid}>{item}</Link> },
            { title: 'Last name', dataIndex: 'last_name', render: (item, record) => <Link to={'/accounts/users/' + record.guid}>{item}</Link> },
            { title: 'Email', dataIndex: 'email', render: (item, record) => <Link to={'/accounts/users/' + record.guid}>{item}</Link> },
            { title: 'Type', dataIndex: 'account_type', render: (item) => normalizeEnum(item)},
            { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' /> },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateFormat value={item} /> },
            { title: 'Last login', dataIndex: 'last_login', render: (item) => <DateTimeFormat value={item} /> },
            { title: '', align: 'right', render: (item, record) => <Link to={'/accounts/users/' + record.guid}><Button size='small' icon={<EyeOutlined />}>View</Button></Link>}
        ];

        return(
            <Fragment>
                <p>{this.showFilters()}</p>

                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <Button size='small' icon={<FilterOutlined />} onClick={() => this.setState({ showFiltersModal: true })}>Filters</Button>
                    </Col>
                    <Col>
                        <Space>
                            <Tooltip title='Reload' placement='left'><Button type='circle' size='small' icon={<UndoOutlined />} onClick={() => this.adminLoadUsers()} /></Tooltip>
                            <Pagination onChange={(page, pageSize) => this.adminLoadUsers(page, pageSize)} size='small' {...this.state.pagination} />
                        </Space>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    rowKey={(item) => item.guid}
                    dataSource={this.state.users}
                    loading={this.state.loadingUsers}
                    pagination={false}
                    footer={() => <Row justify='space-between'>
                        <Col />
                        <Col><Pagination onChange={(page, pageSize) => this.adminLoadUsers(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                    </Row>}
                />

                <UsersTableFilter
                    showModal={this.state.showFiltersModal}
                    closeModal={() => this.setState({ showFiltersModal: false })}
                    onChange={(filter) => this.setFilter(filter) } />
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminLoadUsers })(UsersTable);