import {httpGet, httpPost} from "../libs/http";

export const adminSubnetToIPAddress = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/tools/ip-address/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminRunPingTool = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/tools/ping/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminRunDigTool = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/tools/dig/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminRunTracerouteTool = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/tools/traceroute/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminRunCurlTool = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/tools/curl/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminRunRouterResolver = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/tools/router/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCheckRequestId = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/tools/request-id-check/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateDomainStatusCheck = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/tools/domains-checks/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminGetDomainStatusCheck = (checkId, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/tools/domains-checks/' + checkId+ '/',(res) => onSuccess(res), (err) => onFailure(err));
};

export const adminGetDomainStatusChecks = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/tools/domains-checks/',(res) => onSuccess(res), (err) => onFailure(err));
};

export const adminStopDomainStatusCheck = (checkId, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/tools/domains-checks/' + checkId+ '/stop/', {},(res) => onSuccess(res), (err) => onFailure(err));
};

export const adminStartDomainStatusCheck = (checkId, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/tools/domains-checks/' + checkId+ '/start/', {},(res) => onSuccess(res), (err) => onFailure(err));
};

export const adminRunWhoisTool = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/tools/whois/', data,(res) => onSuccess(res), (err) => onFailure(err));
};
