import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_PER_PAGE} from "../../config";
import {Button, Col, message, Pagination, Popconfirm, Row, Space, Table, Tag, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {adminCancelCancellationRequest, adminLoadCancellationRequests} from "../../actions/billing";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {DeleteOutlined, EyeOutlined, FilterOutlined} from "@ant-design/icons";
import {StatusIndicator} from "../shared/Status";
import {Link} from "react-router-dom";
import CancellationRequestsTableFilter from "./CancellationRequestsTableFilter";

class CancellationRequestsTable extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        cancellationRequests: [],
        loadingCancellationRequests: true,
        showCancellationRequestDetailModal: false,
        selectedCancellationRequest: '',
        filters: [],
    }

    componentDidMount() {
        this.props.updatePage('Cancellation Requests');
        this.adminLoadCancellationRequests();
    }

    adminLoadCancellationRequests(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingCancellationRequests: true });

        this.props.adminLoadCancellationRequests(page, per_page, this.state.filters, (res) => {
            window.scrollTo(0, 0);

            this.setState({ cancellationRequests: res.data.data, loadingCancellationRequests: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCancellationRequests: false });
                displayErrors(err.response.data);
            }
        });
    }
    cancelRequest(guid) {
        this.setState({ loadingCancellationRequests: true });

        this.props.adminCancelCancellationRequest(guid, () => {
            this.adminLoadCancellationRequests();
            message.success('Cancellation request successfully cancelled!');

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCancellationRequests: false });
                displayErrors(err.response.data);
            }
        });
    }

    clearFilters() {
        this.setState({ filters: [] }, () => this.adminLoadCancellationRequests());
    }

    setFilter(filter) {
        let filters = this.state.filters.filter(f => f.key !== filter.key);
        filters.push(filter);

        this.setState({ filters }, () => this.adminLoadCancellationRequests());
    }

    removeFilter(item) {
        let filters = this.state.filters.filter(filter => filter.key !== item.key);
        this.setState({ filters }, () => this.adminLoadCancellationRequests());
    }

    showFilters() {
        return this.state.filters.map((filter, i) => {
            let value;

            if (typeof filter.value === 'boolean') {
                if (filter.value) {
                    value = 'Yes';
                } else {
                    value = 'No';
                }
            } else {
                if (filter.visible !== null) {
                    value = filter.visible;
                } else {
                    value = normalizeEnum(filter.value);
                }
            }

            return <Tag key={i} closable onClose={() => this.removeFilter(filter)}>{filter.name}: {value}</Tag>;
        });
    }

    render() {
        const columns = [
            { title: 'ID', dataIndex: 'id', render: (item, record) => <Link to={'/billing/cancellation-requests/' + record.guid}>#{item}</Link>},
            { title: 'Owner', dataIndex: 'owner', render: (item, record) => <Link to={'/accounts/companies/' + record.owner.guid}>{record.owner.name}</Link>},
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' /> },
            { title: 'Cancellation time', dataIndex: 'cancellation_time', render: (item) => normalizeEnum(item) },
            { title: 'Date updated', dataIndex: 'date_updated', render: (item) => item !== null ? <DateTimeFormat value={item} /> : '-'},
            { title: 'Date processed', dataIndex: 'date_processed', render: (item) => item !== null ? <DateTimeFormat value={item} /> : '-'},
            { title: '', align: 'right', render: (item, record) => {
                    return <Space>
                        {record.status === 'PENDING' && record.can_cancel ? <Popconfirm
                            title="Are you sure you want to cancel this request?"
                            onConfirm={() => this.cancelRequest(record.guid)}
                            okText='Cancel'
                            placement='topRight'
                            okButtonProps={{danger: true}}
                            disabled={record.status !== 'PENDING'}
                            cancelText='Cancel'>
                            <Button danger size='small' disabled={record.status !== 'PENDING'} icon={<DeleteOutlined />}>Cancel</Button>
                        </Popconfirm>: ''}
                        <Link to={'/billing/cancellation-requests/' + record.guid}><Button size='small' icon={<EyeOutlined />}>View</Button></Link>
                    </Space>;
                }}
        ];

        return(
            <Fragment>
                {this.showFilters()}
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <Space>
                            <Button type='text' size='small' icon={<FilterOutlined />} onClick={() => this.setState({ showFiltersModal: true })}>Filters</Button>
                            <Button type='text' size='small' disabled={this.state.filters.length === 0} onClick={() => this.clearFilters()} icon={<DeleteOutlined />}>Clear Filters</Button>
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Tooltip title='Reload' placement='left'>
                                <Button size='small' loading={this.state.loadingCancellationRequests} disabled={this.state.loadingCancellationRequests} onClick={() => this.adminLoadCancellationRequests()} type='circle' icon={<UndoOutlined />} />
                            </Tooltip>
                            <Pagination onChange={(page, pageSize) => this.adminLoadCancellationRequests(page, pageSize)} size='small' {...this.state.pagination} />
                        </Space>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    rowKey={(item) => item.guid}
                    dataSource={this.state.cancellationRequests}
                    loading={this.state.loadingCancellationRequests}
                    pagination={false}
                    footer={() => <Row justify='space-between'>
                        <Col />
                        <Col><Pagination onChange={(page, pageSize) => this.adminLoadCancellationRequests(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                    </Row>}
                />

                <CancellationRequestsTableFilter
                    showModal={this.state.showFiltersModal}
                    closeModal={() => this.setState({ showFiltersModal: false })}
                    onChange={(filter) => this.setFilter(filter) } />
            </Fragment>
        )
    }
}

export default connect(null, { updatePage, adminLoadCancellationRequests, adminCancelCancellationRequest })(CancellationRequestsTable);