import {message} from "antd";

export const getRootPath = (url) => {
    if(typeof url === 'undefined' || url === '/') {
        return url;
    }

    const position = url.indexOf('/', 2);

    if(position === -1) {
        return url;
    }

    return url.substr(0, position);
};

export const compareArrays = (a, b) => {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a.length === b.length &&
        a.every((val, index) => val === b[index]);
};

export const isNumeric = (value) => {
    return /^-?\d+$/.test(value);
};

export const displayErrors = (data) => {
    if(typeof data.errors !== 'undefined') {
        for(let i = 0; i < data.errors.length; i++) {
            message.error(data.errors[i]);
        }
    }

    if(typeof data.error !== 'undefined') {
        message.error(data.error);
    }
};

export const normalizeEnum = (text, capitalize_all = true) => {
    if(typeof text === 'undefined') {
        return '';
    }

    let items = [];
    let pieces = text.toLowerCase().split('_');

    for(let i = 0; i < pieces.length; i++) {
        let text = pieces[i][0].toUpperCase() + pieces[i].substring(1);
        items.push(text);
    }

    if(capitalize_all)
    {
        return items.join(' ');
    }
    else
    {
        text = items.join(' ').toLowerCase();
        return text[0].toUpperCase() + text.substring(1);
    }
}


export const convertBytes = function(bytes) {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"]

    if (bytes === 0) {
        return "n/a"
    }

    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))

    if (i === 0) {
        return bytes + " " + sizes[i]
    }

    return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i]
}

export const shuffleArray = function(array) {
    let currentIndex = array.length, temporaryValue, randomIndex;

    while(0 !== currentIndex){
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export const randomPassword = () => {
    var generatePassword = (
        length = 20,
        wishlist = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz"
    ) => Array(length)
        .fill('')
        .map(() => wishlist[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * wishlist.length)])
        .join('');

    return generatePassword(16);
};