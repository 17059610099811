import {Button, Input, message, Modal, Space} from "antd";
import React from "react";
import {connect} from "react-redux";
import { adminBulkUpdateIPAddresses } from "../../actions/network";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {MoneyField} from "../shared/MoneyField";
import {displayErrors} from "../../libs/utils";

class SetIPAddressOwnCostModal extends React.Component {
    state = {
        newOwnCost: '',
        loading: false,
    };

    closeModal(reload = false) {
        this.setState({
            newOwnCost: '',
            loading: false,
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    bulkUpdateOwnPrice() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set own price to <strong>{<MoneyField amount={this.state.newOwnCost} currency='USD' />}</strong> for selected IP addresses?</span>,
            onOk: () => {
                this.setState({ loading: true });

                let data = {
                    ip_addresses: this.props.selectedIPAddresses,
                    own_cost: parseFloat(this.state.newOwnCost)
                };

                this.props.adminBulkUpdateIPAddresses(this.props.ipNodeId, data,  () => {
                    message.success('IP addresses successfully updated!');
                    this.closeModal(true);
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ loading: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    render() {
        return <Modal
            visible={this.props.show}
            title='Enter New Own Price'
            onCancel={() => this.closeModal()}
            destroyOnClose={true}
            footer={<Space>
                <Button onClick={() => this.closeModal()} disabled={this.state.loading}>Close</Button>
                <Button type='primary' loading={this.state.loading}
                        disabled={this.state.loading} onClick={() => this.bulkUpdateOwnPrice()}>Update</Button>
            </Space>}>
            <Input onChange={(e) => this.setState({ newOwnCost: e.target.value })} />
        </Modal>;
    }
}

export default connect(null, { adminBulkUpdateIPAddresses })(SetIPAddressOwnCostModal);