import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Tabs} from "antd";
import IPNodesTable from "../../components/network/IPNodesTable";
import RoutersTable from "../../components/network/RoutersTable";
import SharedHostingServersTable from "../../components/network/SharedHostingServersTable";
import PriceGroupsTable from "../../components/network/PriceGroupsTable";
import ConfigurationFilesTable from "../../components/network/ConfigurationFilesTable";
import IPAddressReservationsTable from "../../components/network/IPAddressReservationsTable";
import IPAddressesTable from "../../components/network/IPAddressesTable";

class Network extends React.Component {
    state = {
        pages: ['ip-nodes', 'ip-addresses', 'routers', 'shared-hosting-servers', 'price-groups', 'configuration-files', 'reservations'],
        titles: {
            'ip-nodes': 'IP Nodes',
            'ip-addresses': 'IP Addresses',
            'routers': 'Routers',
            'shared-hosting-servers': 'Shared Hosting Servers',
            'price-groups': 'Price Groups',
            'configuration-files': 'Configuration Files',
            'reservations': 'IP Address Reservations'
        }
    };

    changeTab(page) {
        if(this.state.pages.indexOf(page) > -1) {
            this.props.updatePage(this.state.titles[page]);

            if(page === 'ip-nodes') {
                this.props.history.push('/network');
            } else {
                this.props.history.push('/network/' + page);
            }
        }
    }

    render() {
        const { TabPane } = Tabs;

        let page = 'ip-nodes';

        if(typeof this.props.match.params.page !== 'undefined' && this.state.pages.indexOf(this.props.match.params.page)) {
            page = this.props.match.params.page;
        }

        return(
            <Tabs defaultActiveKey={page} onChange={(e) => this.changeTab(e)} destroyInactiveTabPane={true}>
                <TabPane tab='IP Nodes' key='ip-nodes'>
                    <IPNodesTable history={this.props.history} />
                </TabPane>
                <TabPane tab='IP Addresses' key='ip-addresses'>
                    <IPAddressesTable history={this.props.history} />
                </TabPane>
                <TabPane tab='Routers' key='routers'>
                    <RoutersTable history={this.props.history} />
                </TabPane>
                <TabPane tab='Shared Hosting Servers' key='shared-hosting-servers'>
                    <SharedHostingServersTable history={this.props.history} />
                </TabPane>
                <TabPane tab='Price Groups' key='price-groups'>
                    <PriceGroupsTable history={this.props.history} />
                </TabPane>
                <TabPane tab='Reservations' key='reservations'>
                    <IPAddressReservationsTable history={this.props.history} />
                </TabPane>
                {this.props.user.user.user_type === 'ADMIN' ? <TabPane tab='Configuration' key='configuration-files'>
                    <ConfigurationFilesTable history={this.props.history} />
                </TabPane> : ''}
            </Tabs>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
});

export default connect(mapStateToProps, { updatePage })(Network);