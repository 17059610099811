import {httpGet} from "../libs/http";

export const adminLoadMessages = (page, per_page, filters, onSuccess = null, onFailure = null) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/admin/system/messages/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};