import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_FLAG_SIZE, DEFAULT_PER_PAGE} from "../../config";
import {Button, Col, Descriptions, Pagination, Popover, Row, Space, Table, Tag, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {StatusIndicator} from "../../components/shared/Status";
import {Link} from "react-router-dom";
import {DeleteOutlined, EyeOutlined, FilterOutlined, RetweetOutlined} from "@ant-design/icons";
import {adminLoadIPAddresses} from "../../actions/ip_addresses";
import qs from "qs";
import Flag from "react-world-flags";
import IPAddressesTableFilter from "../../components/ip_addresses/IPAddressesTableFilter";
import IPAddressReplacementModal from "../../components/ip_addresses/IPAddressReplacementModal";
import IPAddressStatusChangeModal from "../../components/ip_addresses/IPAddressStatusChangeModal";
import {DateTimeFormat} from "../../components/shared/DateTimeFormat";

class IPAddresses extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        ipAddresses: [],
        loadingIPAddresses: true,
        filters: [],
        showFiltersModal: false,
        activeIPAddress: null,
        showIPAddressReplacementModal: false,
        selectedIPAddresses: [],
        showChangeIPAddressStatusModal: false
    }

    componentDidMount() {
        this.props.updatePage('IP Addresses');
        this.updateFilterFromHistoryChange();
    }

    updateFilterFromHistoryChange() {
        let queryString = qs.parse(window.location.search.substring(1));

        let filters = [];

        if('group' in queryString) {
            filters.push({
                name: 'Group',
                key: 'group',
                value: queryString['group'],
                visible: null
            });
        }

        if(filters.length === 0) {
            this.adminLoadIPAddresses();
        } else {
            this.setState({ filters }, () => this.adminLoadIPAddresses());
        }
    }

    adminLoadIPAddresses(page = null, per_page = null, scroll_top = true) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingIPAddresses: true });

        this.props.adminLoadIPAddresses(page, per_page, this.state.filters, (res) => {
            if(scroll_top) {
                window.scrollTo(0, 0);
            }

            this.setState({ ipAddresses: res.data.data, loadingIPAddresses: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIPAddresses: false });
                displayErrors(err.response.data);
            }
        });
    }

    onTableChange(pagination) {
        this.adminLoadIPAddresses(pagination.current, pagination.pageSize);
    }

    clearFilters() {
        this.setState({ filters: [] }, () => this.adminLoadIPAddresses());
    }

    setFilter(filter) {
        let filters = this.state.filters.filter(f => f.key !== filter.key);
        filters.push(filter);

        this.setState({ filters }, () => this.adminLoadIPAddresses());
    }

    removeFilter(item) {
        let filters = this.state.filters.filter(filter => filter.key !== item.key);
        this.setState({ filters }, () => this.adminLoadIPAddresses());
    }

    showFilters() {
        return this.state.filters.map((filter, i) => {
            let value;

            if (typeof filter.value === 'boolean') {
                if (filter.value) {
                    value = 'Yes';
                } else {
                    value = 'No';
                }
            } else {
                if (filter.visible !== null) {
                    value = filter.visible;
                } else {
                    value = normalizeEnum(filter.value);
                }
            }

            return <Tag key={i} closable onClose={() => this.removeFilter(filter)}>{filter.name}: {value}</Tag>;
        });
    }

    setGroupFilter(record, e) {
        e.target.parentElement.blur();

        let filters = this.state.filters.filter(filter => filter.key !== 'group');

        filters.push({
            name: 'Group',
            key: 'group',
            value: record.group.guid,
            visible: record.group.name
        });

        this.setState({ filters }, () => this.adminLoadIPAddresses());
    }

    getOrderList(orders) {
        let orderList = [];

        for(let i = 0; i < orders.length; i++) {
            orderList.push(<Link key={i} to={'/billing/orders/' + orders[i]}>#{orders[i]}</Link>);
        }

        if(orders.length === 0) {
            return <span>Missing!</span>;
        }

        return orderList.reduce((prev, curr) => [ prev, ', ', curr ])
    }

    render() {
        const columns = [
            { title: 'ID', dataIndex: 'id', render: (item, record) => <Link to={'/ip-addresses/' + record.guid}>{item}</Link>},
            { title: 'IP address', dataIndex: 'ip_address', render: (item, record) => <Link to={'/ip-addresses/' + record.guid}>{item}</Link> },
            { title: 'Orders', dataIndex: 'orders', render: (item) => item !== null ? this.getOrderList(item) : <StatusIndicator value='MISSING' />},
            { title: 'Type', dataIndex: 'ip_address_type', render: (item) => normalizeEnum(item) },
            { title: 'IP Node',  render: (item, record) => <Link to={'/network/ip-nodes/' + record.ip_node.guid}>{record.ip_node.hostname.replace('.gateway.prioritynap.net', '')}</Link> },
            { title: 'Location', render: (item, record) => <Tooltip title={record.city + ', ' + record.country}><Flag code={record.country_code.toLowerCase()} height={DEFAULT_FLAG_SIZE} /> {record.city}</Tooltip> },
            { title: 'Provider', dataIndex: 'provider'},
            { title: 'Status', align: 'center', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />},
            { title: 'Domains', align: 'center', render: (item, record) => <span>{record.domain_count + '/' + record.domains_allowed}</span>},
            { title: 'Group', dataIndex: 'group', render: (item, record) => <Button size='small' type='text' onClick={(e) => this.setGroupFilter(record, e)}>{item.name}</Button> },
            { title: 'Owner', dataIndex: 'group', render: (item) => <Link to={'/accounts/companies/' + item.owner.guid}>{item.owner.name}</Link> },
            { title: 'Replacement', dataIndex: 'replacement_ip_address', render: (item, record) => item !== null ? <Popover placement="topLeft" title={'Replacement Info'} content={
                    <Descriptions bordered size='small' layout='horizontal' column={1}>
                        <Descriptions.Item label='ID'>{item.id}</Descriptions.Item>
                        <Descriptions.Item label='IP address'>{item.ip_address}</Descriptions.Item>
                        <Descriptions.Item label='Date replaced'>
                            <DateTimeFormat value={record.date_replaced} />
                        </Descriptions.Item>
                    </Descriptions>
                } trigger="click">
                    <Button size='small' type='text'>{item.ip_address}</Button>
                </Popover> : '-'},
            { title: '', align: 'right', render: (item, record) => record.replacement_ip_address === null ? <Space>
                    <Tooltip title='Replace IP address' mouseEnterDelay={1}>
                        <Button size='small' type='circle' onClick={() => this.setState({ showIPAddressReplacementModal: true, activeIPAddress: record })} icon={<RetweetOutlined />} />
                    </Tooltip>
                    <Link to={'/domains?ip_address=' + record.ip_address}><Button size='small' type='round' icon={<EyeOutlined />}>Domains</Button></Link>
                </Space> : ''}
        ];

        return(
            <Fragment>
                <p>{this.showFilters()}</p>
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                   <Col>
                       <Space>
                           <Button size='small' type='text' icon={<FilterOutlined />} onClick={() => this.setState({ showFiltersModal: true })}>Filters</Button>
                           <Button size='small' type='text' disabled={this.state.filters.length === 0} onClick={() => this.clearFilters()} icon={<DeleteOutlined />}>Clear Filters</Button>
                           <Button size='small' type='text' disabled={this.state.selectedIPAddresses.length === 0} onClick={() => this.setState({ showChangeIPAddressStatusModal: true })} icon={<RetweetOutlined />}>Change Status</Button>
                       </Space>
                   </Col>
                   <Col>
                       <Space>
                           <Tooltip title='Reload' placement='left'><Button icon={<UndoOutlined />} size='small' type='circle' onClick={() => this.adminLoadIPAddresses()} /></Tooltip>
                           <Pagination onChange={(page, pageSize) => this.adminLoadIPAddresses(page, pageSize)} size='small' {...this.state.pagination} />
                       </Space>
                   </Col>
                </Row>
                <Table
                    columns={columns}
                    rowKey={(item) => item.guid}
                    dataSource={this.state.ipAddresses}
                    loading={this.state.loadingIPAddresses}
                    footer={() => <Row justify='space-between'>
                        <Col>{this.state.selectedIPAddresses.length + ' selected'}</Col>
                        <Col><Pagination onChange={(page, pageSize) => this.adminLoadIPAddresses(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                    </Row>}
                    pagination={false}
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: this.state.selectedIPAddresses,
                        onChange: (ipAddresses) => this.setState({ selectedIPAddresses: ipAddresses })
                    }}
                />

                <IPAddressStatusChangeModal
                    showModal={this.state.showChangeIPAddressStatusModal}
                    selectedIPAddresses={this.state.selectedIPAddresses}
                    reload={() => this.adminLoadIPAddresses()}
                    close={() => this.setState({ showChangeIPAddressStatusModal: false, selectedIPAddresses: []})} />

                <IPAddressesTableFilter
                    showModal={this.state.showFiltersModal}
                    closeModal={() => this.setState({ showFiltersModal: false })}
                    onChange={(filter) => this.setFilter(filter) } />

                <IPAddressReplacementModal
                    activeIPAddress={this.state.activeIPAddress}
                    showModal={this.state.showIPAddressReplacementModal}
                    reload={() => this.adminLoadIPAddresses(this.state.pagination.current, this.state.pagination.pageSize, false)}
                    close={() => this.setState({ showIPAddressReplacementModal: false, activeIPAddress: null })} />
            </Fragment>
        )
    }
}

export default connect(null, { updatePage, adminLoadIPAddresses })(IPAddresses);