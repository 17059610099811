import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Button, Card, Col, message, Modal, Progress, Row, Statistic, Table, Tooltip, Typography} from "antd";
import {adminLoadDashboard, deleteFailedSSLCertificate} from "../../actions/dashboard";
import {Loading} from "../../libs/loading";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {
    CheckCircleOutlined, DeleteOutlined, ExclamationCircleOutlined,
    EyeOutlined,
    LinkOutlined,
    RetweetOutlined,
    WarningOutlined
} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {TimeFormat} from "../shared/TimeFormat";
import {AreaChart, Area, XAxis, YAxis, CartesianGrid, ResponsiveContainer} from 'recharts';
import { Tooltip as ChartTooltip } from 'recharts';
import {MoneyField} from "../shared/MoneyField";
import {DateFormat} from "../shared/DateFormat";
import {StatusIndicator} from "../shared/Status";

class AdminDashboard extends React.Component {
    state = {
        loading: false,
        data: null,
        offlineIPsPagination: { current: 1, pageSize: 5, total: 0, showSizeChanger: true },
        failedSSLCertificatesPagination: { current: 1, pageSize: 5, total: 0, showSizeChanger: true },
        failedSSLPagination: { current: 1, pageSize: 5, total: 0, showSizeChanger: true },
        googleSafeBrowsingPagination: { current: 1, pageSize: 5, total: 0, showSizeChanger: true },
        reloadingPage: false
    };

    componentDidMount() {
        this.setState({ loading: true });
        this.adminLoadDashboard();
    }

    adminLoadDashboard() {
        this.setState({ reloadingPage: true });

        this.props.adminLoadDashboard((res) => {
            this.setState({ loading: false, reloadingPage: false, data: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false, reloadingPage: false });
                displayErrors(err.response.data);
            }
        });
    }

    deleteFailedSSLCertificate(guid) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to delete this message?</span>,
            onOk: () => {
                this.props.deleteFailedSSLCertificate(guid,  () => {
                    message.success('Message successfully deleted!');

                    this.adminLoadDashboard();
                    this.closeModal(true);
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ loading: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    render() {
        const { Text } = Typography;

        if(this.state.loading || this.state.data === null) {
            return <div className='text-center'><Loading /></div>;
        }

        const { data } = this.state;

        const offlineIPNodeColumns = [
            { title: 'Hostname', dataIndex: 'hostname', render: (item, record) => <Link to={'/network/ip-nodes/' + record.guid}>{item.replace('.gateway.prioritynap.net', '')}</Link>},
            { title: 'IP address', dataIndex: 'ip_address' },
            { title: 'Provider', dataIndex: 'provider' },
            { title: 'Checked', dataIndex: 'date_last_check', render: (item) => <Tooltip title={<DateTimeFormat value={item} />}><span><TimeFormat value={item} /></span></Tooltip> },
            { title: 'HTTP', dataIndex: 'http', align: 'center', render: (item) => item ? <CheckCircleOutlined style={{color:'green'}} /> : <WarningOutlined style={{color:'orange'}} />},
            { title: 'DNS', dataIndex: 'dns', align: 'center', render: (item) => item ? <CheckCircleOutlined style={{color:'green'}} /> : <WarningOutlined style={{color:'orange'}} />},
            { title: 'SMTP', dataIndex: 'smtp', align: 'center', render: (item) => item ? <CheckCircleOutlined style={{color:'green'}} /> : <WarningOutlined style={{color:'orange'}} />},
        ];

        const delayedSSLCheckIPNodeColumns = [
            { title: 'Hostname', dataIndex: 'hostname', render: (item, record) => <Link to={'/network/ip-nodes/' + record.guid}>{item.replace('.gateway.prioritynap.net', '')}</Link>},
            { title: 'IP address', dataIndex: 'ip_address' },
            { title: 'Provider', dataIndex: 'provider' },
            { title: 'Checked', dataIndex: 'date_last_check', render: (item) => <Tooltip title={<DateTimeFormat value={item} />}><span><TimeFormat value={item} /></span></Tooltip> }
        ];

        const overdueInvoicesColumns = [
            { title: 'ID', dataIndex: 'id', render: (item) => <Link to={'/billing/invoices/' + item}>#{item}</Link>},
            { title: 'Company', render: (item, record) => <Link to={'/accounts/companies/' + record.company_guid}>{record.company_name}</Link> },
            { title: 'Amount', render: (item, record) => <MoneyField amount={record.amount} currency={record.currency} /> },
            { title: 'Date due', render: (item, record) => <DateFormat value={record.date_due} />}
        ];

        const lastLoginsColumns = [
            { title: 'User', render: (item, record) => <Link to={'/accounts/users/' + record.user_guid}>{record.user_name}</Link> },
            { title: 'Status', render: (item, record) => <StatusIndicator status={record.status} el='badge' /> },
            { title: 'Timestamp', render: (item, record) => <Tooltip title={<DateTimeFormat value={record.date_created} />}><span><TimeFormat value={record.date_created} /></span></Tooltip>},
            { title: 'Location', dataIndex: 'location' }
        ];

        const unusedLegacyHostingAccountColumns = [
            { title: 'Account', render: (item, record) => <Link to={'/hosting/' + record.guid}>{record.name}</Link>},
            { title: 'Owner', render: (item, record) => <Link to={'/accounts/companies/' + record.owner.guid}>{record.owner.name}</Link>}
        ];

        const domainsWithFailedSSLColumns = [
            { title: 'Domain', render: (item, record) => <Link to={'/domains/' + record.guid}>{record.domain}</Link>},
            { title: 'Owner', render: (item, record) => <Link to={'/accounts/companies/' + record.company_guid}>{record.company}</Link>},
            { title: 'IP Node', render: (item, record) => <Link to={'/network/ip-nodes/' + record.ip_node_guid}>{record.ip_node_hostname.replace('.gateway.prioritynap.net', '')}</Link>},
            { title: 'Checked', dataIndex: 'date_checked', render: (item) => <Tooltip title={<DateTimeFormat value={item} />}><span><TimeFormat value={item} /></span></Tooltip> },
            {  render: (item, record) => <a href={'https://' + record.domain} target='_blank' rel='noopener noreferrer'><Button size='small' icon={<LinkOutlined />}>Visit</Button></a> }
        ];

        const offlineIPAddresses = [
            { title: 'IP address', dataIndex: 'ip_address'},
            { title: 'IP Node', render: (item, record) => <Link to={'/network/ip-nodes/' + record.ip_node_guid}>{record.ip_node_hostname.replace('.gateway.prioritynap.net', '')}</Link>},
            { title: 'Provider', dataIndex: 'provider'},
            { title: 'Checked', dataIndex: 'last_check', render: (item) => <Tooltip title={<DateTimeFormat value={item} />}><span><TimeFormat value={item} /></span></Tooltip> },
            { align: 'right', render: (item, record) => <a href={'http://' + record.ip_address} target='_blank' rel='noopener noreferrer'><Button size='small' icon={<LinkOutlined />}>Visit</Button></a>},
        ];

        const googleSafeBrowsingDomains = [
            { title: 'Domain', render: (item, record) => <Link to={'/domains/' + record.guid}>{record.domain}</Link>},
            { title: 'Checked', render: (item, record) => <DateTimeFormat value={record.date_last_check} />},
            { title: 'Status', render: (item, record) => record.status.join(', ')},
            { title: 'Expires', render: (item, record) => <DateTimeFormat value={record.date_status_expires} />},
        ];

        const unavailableServerColumns = [
            { title: 'Hostname', render: (item, record) => <Link to={'/network/ip-nodes/' + record.guid}>{record.hostname.replace('.gateway.prioritynap.net', '')}</Link>},
            { title: 'IP address', render: (item, record) => record.ip_address},
        ];

        const failedSSLCertificates = [
            { title: 'Domain', width: '50%', render: (item, record) => <Link to={'/domains/' + record.domain.guid}>{record.domain.domain}</Link>},
            { title: 'SSL ID', render: (item, record) => record.ssl_certificate},
            { title: 'Timestamp', render: (item, record) => <DateTimeFormat value={record.date_created} />},
            { title: '', align: 'right', render: (item, record) => <Button onClick={() => this.deleteFailedSSLCertificate(record.guid)} icon={<DeleteOutlined />} size='small'>Delete</Button>},
        ];

        return(
            <Fragment>
                <Row>
                    <Col span={24} className='text-right'>
                        <Tooltip title='Reload'>
                            <Button disabled={this.state.reloadingPage} loading={this.state.reloadingPage} size='small'
                                    type='circle' onClick={() => this.adminLoadDashboard()} icon={<RetweetOutlined />} />
                        </Tooltip>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={11}>
                        <Card bordered={false} title='Monthly finances' size='small'>
                            <Row gutter={[16, 16]}>
                                <Col span={8}>
                                    <Statistic title="Last month" prefix={'$'} value={data.monthly_finances.last_month} precision={2} />
                                </Col>
                                <Col span={8}>
                                    <Statistic title="This month" prefix={'$'} value={data.monthly_finances.this_month} precision={2} />
                                </Col>
                                <Col span={8}>
                                    <Statistic title="Unpaid invoices" prefix={'$'} value={data.unpaid_invoices} precision={2} />
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={13}>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Card bordered={false} title='Dedicated IP addresses' size='small'>
                                    <Row>
                                        <Col span={12} className='text-left'><Text type="secondary">{data.dedicated_ip_addresses.used} used</Text></Col>
                                        <Col span={12} className='text-right'><Text type="secondary">{data.dedicated_ip_addresses.available - data.dedicated_ip_addresses.used} available</Text></Col>
                                    </Row>
                                    <Progress percent={(data.dedicated_ip_addresses.used * 100) / data.dedicated_ip_addresses.available} showInfo={false} />
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card bordered={false} title='Shared IP addresses' size='small'>
                                    <Row>
                                        <Col span={12} className='text-left'><Text type="secondary">{data.shared_ip_addresses.used} used</Text></Col>
                                        <Col span={12} className='text-right'><Text type="secondary">{data.shared_ip_addresses.available - data.shared_ip_addresses.used} available</Text></Col>
                                    </Row>
                                    <Progress percent={(data.shared_ip_addresses.used * 100) / data.shared_ip_addresses.available} showInfo={false} />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={11}>
                        <Card bordered={false} title='Overall finances' size='small'>
                            <Row gutter={[16, 16]}>
                                <Col span={6}>
                                    <Statistic title="Profit" prefix={'$'} value={data.overall_finances.income - data.overall_finances.expenses} precision={2} />
                                </Col>
                                <Col span={6}>
                                    <Statistic title="Income" prefix={'$'} value={data.overall_finances.income} precision={2} />
                                </Col>
                                <Col span={6}>
                                    <Statistic title="Expenses" prefix={'$'} value={data.overall_finances.expenses} precision={2} />
                                </Col>
                                <Col span={6}>
                                    <Statistic title="Revenue Potential" prefix={'$'} value={data.overall_finances.available_revenue_potential} precision={2} />
                                </Col>
                            </Row>
                        </Card>
                        <br />
                        <Card bordered={false} title='Invoices' size='small' extra={<Link to={'/billing/invoices'}><Button size='small' icon={<EyeOutlined />}>View</Button></Link>}>
                            <div style={{ width: '100%', height: 250 }}>
                                <ResponsiveContainer>
                                    <AreaChart data={data.invoices}>
                                        <Area dataKey="total" stroke="#f6c85f" fill="#f6c85f" />
                                        <Area dataKey="total_paid" stroke="#9dd866" fill="#9dd866" />
                                        <Area dataKey="total_unpaid" stroke="#ca472f" fill="#ca472f" />
                                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                        <XAxis dataKey="date" />
                                        <YAxis tickFormatter={(e) => '$' + e} />
                                        <ChartTooltip formatter={(value, name) => ['$' + value.toFixed(2), normalizeEnum(name)] } />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </Card>
                        <br />
                        <Card bordered={false} title='Cancellation requests' size='small' extra={<Link to={'/billing/cancellation-requests'}><Button size='small' icon={<EyeOutlined />}>View</Button></Link>}>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Statistic title="Immediate" value={data.cancellation_requests.immediate} />
                                </Col>
                                <Col span={12}>
                                    <Statistic title="End of billing cycle" value={data.cancellation_requests.end_of_billing_cycle} />
                                </Col>
                            </Row>
                        </Card>
                        <br />
                        <Card bordered={false} title='Domains' size='small'>
                            <Row gutter={[16, 16]}>
                                <Col span={8}>
                                    <Statistic title="Total"  value={data.total_domains_count} />
                                </Col>
                                <Col span={8}>
                                    <Statistic title="Shared hosting"  value={data.shared_hosting_domains_count} />
                                </Col>
                                <Col span={8}>
                                    <Statistic title="Custom hosting"  value={data.custom_hosting_domains_count} />
                                </Col>
                            </Row>
                        </Card>
                        <br />
                        <Card bordered={false} title='Subnets' size='small'>
                            <Row gutter={[16, 16]}>
                                <Col span={8}>
                                    <Statistic title="A classes"  value={data.subnets.a_classes} />
                                </Col>
                                <Col span={8}>
                                    <Statistic title="B classes"  value={data.subnets.b_classes} />
                                </Col>
                                <Col span={8}>
                                    <Statistic title="C classes"  value={data.subnets.c_classes} />
                                </Col>
                            </Row>
                        </Card>
                        <br />

                        {data.ip_nodes.offline > 0 ? <Card bordered={false} title='Unused legacy accounts' size='small'>
                            <Table columns={unusedLegacyHostingAccountColumns} rowKey={item => item.guid} dataSource={data.unused_legacy_accounts} size='small' pagination={false} />
                        </Card> : ''}
                        <Card bordered={false} title='Last logins' size='small'>
                            <Table columns={lastLoginsColumns} rowKey={item => item.id} dataSource={data.last_logins} size='small' pagination={false} />
                        </Card>
                    </Col>
                    <Col span={13}>
                        {data.offline_ip_addresses.length > 0 ? <Card bordered={false} title='Offline IP Addresses' size='small'>
                            <Table columns={offlineIPAddresses} rowKey={item => item.ip_address} dataSource={data.offline_ip_addresses} size='small'
                                   onChange={(pagination) => this.setState({ offlineIPsPagination: { ...this.state.offlineIPsPagination, current: pagination.current, pageSize: pagination.pageSize }})}
                                   pagination={data.offline_ip_addresses.length > this.state.offlineIPsPagination.pageSize ? this.state.offlineIPsPagination : false} />
                        </Card> : ''}
                        {data.domains_with_failed_ssl.length > 0 ? <Card bordered={false} title='Failed SSL Checks' size='small'>
                            <Table columns={domainsWithFailedSSLColumns} rowKey={item => item.guid} dataSource={data.domains_with_failed_ssl} size='small'
                                   onChange={(pagination) => this.setState({ failedSSLPagination: { ...this.state.failedSSLPagination, current: pagination.current, pageSize: pagination.pageSize }})}
                                   pagination={data.domains_with_failed_ssl.length > this.state.failedSSLPagination.pageSize ? this.state.failedSSLPagination : false} />
                        </Card> : ''}
                        {data.domains_with_google_safe_browsing_status.length > 0 ? <Card style={{backgroundColor: '#ffe0e0'}} bordered={false} title='Google Safe Browsing' size='small'>
                            <Table columns={googleSafeBrowsingDomains} rowKey={item => item.guid} dataSource={data.domains_with_google_safe_browsing_status} size='small'
                                   onChange={(pagination) => this.setState({ googleSafeBrowsingPagination: { ...this.state.googleSafeBrowsingPagination, current: pagination.current, pageSize: pagination.pageSize }})}
                                   pagination={data.domains_with_google_safe_browsing_status.length > this.state.googleSafeBrowsingPagination.pageSize ? this.state.googleSafeBrowsingPagination : false} />
                        </Card> : ''}
                        {data.ip_nodes.offline > 0 ? <Card bordered={false} title='Offline IP nodes' size='small'>
                            <Table columns={offlineIPNodeColumns} rowKey={item => item.guid} dataSource={data.ip_nodes.offline_ip_nodes} size='small' pagination={false} />
                        </Card> : ''}
                        {data.delayed_ssl_check_ip_nodes.length > 0 ? <Card bordered={false} title='Delayed SSL Check' size='small'>
                            <Table columns={delayedSSLCheckIPNodeColumns} rowKey={item => item.guid} dataSource={data.delayed_ssl_check_ip_nodes} size='small' />
                        </Card> : ''}
                        {data.overdue_invoices.length > 0 ? <Card bordered={false} title='Overdue invoices' size='small'>
                            <Table columns={overdueInvoicesColumns} rowKey={item => item.id} dataSource={data.overdue_invoices} size='small' pagination={false} />
                        </Card> : ''}
                        {data.ip_nodes.unavailable_servers.length > 0 ? <Card bordered={false} title='Unavailable servers' size='small'>
                            <Table columns={unavailableServerColumns} rowKey={item => item.id} dataSource={data.ip_nodes.unavailable_servers} size='small' pagination={false} />
                        </Card> : ''}
                        {data.failed_ssl_certificates.length > 0 ? <Card bordered={false} title='Failed SSL Certificates' size='small'>
                            <Table columns={failedSSLCertificates} rowKey={item => item.guid} dataSource={data.failed_ssl_certificates} size='small'
                                   onChange={(pagination) => this.setState({ failedSSLCertificatesPagination: { ...this.state.failedSSLCertificatesPagination, current: pagination.current, pageSize: pagination.pageSize }})}
                                   pagination={data.failed_ssl_certificates.length > this.state.failedSSLCertificatesPagination.pageSize ? this.state.failedSSLCertificatesPagination : false} />
                        </Card> : ''}
                    </Col>
                </Row>
            </Fragment>
        )
    }
}

export default connect(null, { adminLoadDashboard, deleteFailedSSLCertificate })(AdminDashboard);