import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Form, Input, Modal, Space} from "antd";

class AnnouncementModal extends React.Component {
    state = {

    };

    closeModal(reload = false) {
        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    saveAnnouncement() {

    }

    render() {
        return(
            <Modal
                title={this.props.activeAnnouncement !== null ? 'Edit Announcement' : 'Create Announcement'}
                visible={this.props.showModal}
                footer={<Space>
                    <Button onClick={() => this.closeModal()}>Close</Button>
                    <Button type='primary' onClick={() => this.saveAnnouncement()}>{this.props.activeAnnouncement !== null ? 'Update' : 'Create'}</Button>
                </Space>}
                onCancel={() => this.closeModal()}>
                <Form layout='vertical'>
                    <Form.Item label='Title'>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { updatePage })(AnnouncementModal);