import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {loadAffiliates} from "../../actions/affiliate";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {DEFAULT_PER_PAGE} from "../../config";
import {Button, Col, Pagination, Row, Space, Table, Tooltip} from "antd";
import {EyeOutlined} from "@ant-design/icons";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {Link} from "react-router-dom";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {StatusIndicator} from "../shared/Status";
import {MoneyField} from "../shared/MoneyField";

class AffiliateOverview extends React.Component {
    state = {
        loading: false,
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        affiliates: []
    };

    componentDidMount() {
        this.props.updatePage('Overview');
        this.loadOverview();
    }

    loadOverview(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loading: true });

        this.props.loadAffiliates(page, per_page, (res) => {
            this.setState({ loading: false, affiliates: res.data.data });
        }, (err) => {
            this.setState({ loading: false });
            displayErrors(err.response.data);
        });
    }

    render() {
        const columns = [
            { title: 'ID', dataIndex: 'id', width: '30px', render: (item, record) => <Link to={'/affiliate/view/' + record.guid}>#{record.id}</Link> },
            { title: 'Name', render: (item, record) => <Link to={'/affiliate/view/' + record.guid}>{record.first_name + ' ' + record.last_name}</Link> },
            { title: 'Company', render: (item, record) => record.company_name },
            { title: 'User', render: (item, record) => <Link to={'/accounts/users/' + record.user.guid}>{record.user.first_name + ' ' + record.user.last_name}</Link> },
            { title: 'Status', align: 'center',  dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' /> },
            { title: 'Created', align: 'center', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Type', align: 'center',  dataIndex: 'account_type', render: (item) => normalizeEnum(item) },
            { title: 'Revenue',  dataIndex: 'total_revenue', render: (item) => <MoneyField amount={item} currency='USD' /> },
            { title: 'Conversions', align: 'center',  dataIndex: 'total_conversions' },
            { title: '', align: 'right', render: (item, record) => <Link to={'/affiliate/view/' + record.guid}><Button size='small' type='round' icon={<EyeOutlined />}>View</Button></Link>}
        ]

        return <Fragment>
            <Row justify='space-between' style={{marginBottom: '10px'}}>
                <Col></Col>
                <Col>
                    <Space>
                        <Tooltip title='Reload table' placement='left'><Button onClick={() => this.loadOverview()} icon={<UndoOutlined />} size='small' type='circle'></Button></Tooltip>
                        <Pagination onChange={(page, pageSize) => this.loadOverview(page, pageSize)} size='small' {...this.state.pagination} />
                    </Space>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={(item) => item.guid}
                dataSource={this.state.affiliates}
                footer={() => <Row justify='space-between'>
                    <Col></Col>
                    <Col><Pagination onChange={(page, pageSize) => this.loadOverview(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                </Row>}
                loading={this.state.loading} />
        </Fragment>
        ;
    }
}

export default connect(null, { updatePage, loadAffiliates })(AffiliateOverview);