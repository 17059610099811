import React from "react";
import {connect} from "react-redux";
import {Button, Form, Modal, Space, message, Select} from "antd";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {adminAddOrderCoupon, adminLoadAllCoupons} from "../../actions/billing";

class OrderAddCouponModal extends React.Component {
    state = {
        selectedCoupon: null,
        loadingCoupons: false,
        couponsLoaded: false,
        coupons: [],
        saving: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    componentDidMount() {
        this.loadCoupons();
    }

    loadCoupons() {
        this.props.adminLoadAllCoupons((res) => {
            this.setState({ loadingCoupons: false, couponsLoaded: true, coupons: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCoupons: false, couponsLoaded: true });
                displayErrors(err.response.data);
            }
        });
    }

    closeModal(reload = false) {
        this.setState({
            selectedCoupon: ''
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    addCoupon() {
        if(this.state.selectedCoupon === null) {
            return message.error('Please select a coupon!');
        }

        let data = {
            coupon: this.state.selectedCoupon
        };

        this.setState({ saving: true });

        this.props.adminAddOrderCoupon(this.props.orderId, data,  (res) => {
            this.setState({ saving: false });
            message.success('Coupon successfully added to the order!');
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ saving: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        return(
            <Modal title='Add Coupon'
                   visible={this.props.showModal}
                   onCancel={() => this.closeModal()}
                   footer={<Space>
                       <Button disabled={this.state.saving} onClick={() => this.closeModal()}>Close</Button>
                       <Button disabled={this.state.saving} loading={this.state.saving}  type='primary' onClick={() => this.addCoupon()}>Add Coupon</Button>
                   </Space>}>
                <Form layout='vertical'>
                    <Form.Item label='Choose coupon'>
                        <Select value={this.state.selectedCoupon} loading={this.state.loadingCoupons}
                                disabled={this.state.loadingCoupons} showSearch
                                filterOption={(input, option) => {
                                    if(typeof option !== 'undefined') {
                                        return option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }
                                    return false;
                                }} onChange={(value) => this.setState({ selectedCoupon: value })}>

                            {this.state.coupons.map((coupon, i) => <Option value={coupon.id} key={i}>{coupon.name} - {normalizeEnum(coupon.status)} ({coupon.value})</Option>)}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { adminAddOrderCoupon, adminLoadAllCoupons })(OrderAddCouponModal);