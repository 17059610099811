import React, {Fragment} from "react";
import {connect} from "react-redux";
import {loadAffiliateClicks} from "../../actions/affiliate";
import {Button, Col, Pagination, Row, Space, Table, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors} from "../../libs/utils";
import {DEFAULT_PER_PAGE} from "../../config";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {Link} from "react-router-dom";

class AffiliateClicksTable extends React.Component {
    state = {
        loading: false,
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        clicks: []
    };

    componentDidMount() {
        this.loadClicks();
    }

    loadClicks(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loading: true });

        this.props.loadAffiliateClicks(this.props.guid, page, per_page, (res) => {
            this.setState({ loading: false, clicks: res.data.data });
        }, (err) => {
            this.setState({ loading: false });
            displayErrors(err.response.data);
        });
    }

    render() {
        const columns = [
            { title: 'Timestamp', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'IP address', dataIndex: 'ip_address' },
            { title: 'User agent', dataIndex: 'user_agent' },
            { title: 'Referer', dataIndex: 'referer', render: (item) => <a href={item} target='_blank' rel='noopener noreferrer'>{item}</a> },
            { title: 'Signup', dataIndex: 'signup', render: (item) => item ? <Link to={'/accounts/companies/' + item.company.guid}>{item.company.name}</Link> : '' },
        ];

        return <Fragment>
            <Row justify='space-between' style={{marginBottom: '10px'}}>
                <Col></Col>
                <Col>
                    <Space>
                        <Tooltip title='Reload table' placement='left'><Button onClick={() => this.loadClicks()} icon={<UndoOutlined />} size='small' type='circle'></Button></Tooltip>
                        <Pagination onChange={(page, pageSize) => this.loadClicks(page, pageSize)} size='small' {...this.state.pagination} />
                    </Space>
                </Col>
            </Row>
            <Table
                columns={columns}
                rowKey={(item) => item.guid}
                dataSource={this.state.clicks}
                footer={() => <Row justify='space-between'>
                    <Col></Col>
                    <Col><Pagination onChange={(page, pageSize) => this.loadClicks(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                </Row>}
                loading={this.state.loading} />
        </Fragment>;
    }
}

export default connect(null, { loadAffiliateClicks })(AffiliateClicksTable);