import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, Modal, Select, Space} from "antd";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {loadAllCompanies, loadAllUsers} from "../../actions/users";

class EmailsTableFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFilter: 'SUBJECT',
            filterValue: '',
            filterKey: 'subject',
            filterVisible: null,

            loadingCompanies: false,
            companiesLoaded: false,
            companies: [],
            selectedCompany: '',
            loadingUsers: false,
            users: [],
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingCompanies && !this.state.companiesLoaded) {
            this.setState({ loadingCompanies: true, loadingUsers: true })

            this.props.loadAllCompanies((res) => {
                this.setState({ loadingCompanies: false, companiesLoaded: true, companies: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingCompanies: false, companiesLoaded: true });
                    displayErrors(err.response.data);
                }
            });

            this.props.loadAllUsers((res) => {
                this.setState({ loadingUsers: false, users: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingUsers: false });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    onChange(){
        let visibleValue = null;

        if(this.state.selectedFilter === 'COMPANY') {
            visibleValue = this.getCompanyName(this.state.filterValue);
        }

        if(this.state.selectedFilter === 'USER') {
            visibleValue = this.getUserName(this.state.filterValue);
        }

        this.props.onChange({
            name: normalizeEnum(this.state.selectedFilter),
            key: this.state.filterKey,
            value: typeof this.state.filterValue !== 'boolean' ? this.state.filterValue.trim() : this.state.filterValue,
            visible: visibleValue
        });
    }

    closeModal(apply = false, close = true) {
        this.setState({
            selectedFilter: 'SUBJECT',
            filterValue: '',
            filterKey: 'subject',
            filterVisible: null,

            loadingCompanies: false,
            companiesLoaded: false,
            companies: [],
            selectedCompany: '',
            loadingUsers: false,
            users: [],
        });

        if(close) {
            this.props.closeModal();
        }

        if(apply) {
            this.onChange();
        }
    }

    getCompanyName(guid) {
        let company = null;

        for(let i = 0; i < this.state.companies.length; i++) {
            if(this.state.companies[i].guid === guid) {
                company = this.state.companies[i];
                break;
            }
        }

        return company !== null ? company.name : '';
    }

    getUserName(guid) {
        let user = null;

        for(let i = 0; i < this.state.users.length; i++) {
            if(this.state.users[i].guid === guid) {
                user = this.state.users[i];
                break;
            }
        }

        return user !== null ? user.first_name + ' ' + user.last_name : '';
    }

    setFilter(name) {
        const filters = {
            COMPANY: { key: 'company', value: '' },
            USER: { key: 'user', value: '' },
            SUBJECT: { key: 'subject', value: '' },
            CONTENT: { key: 'content', value: '' },
            ID: { key: 'id', value: '' },
        };

        this.setState({ selectedFilter: name, filterKey: filters[name].key, filterValue: filters[name].value });
    }

    render() {
        const { Option } = Select;

        let content = '';

        if(this.state.selectedFilter === 'SUBJECT' || this.state.selectedFilter === 'CONTENT' || this.state.selectedFilter === 'ID') {
            content = <Input value={this.state.filterValue}
                             onChange={(e) => this.setState({filterValue: e.target.value})}/>;

        } else if(this.state.selectedFilter === 'COMPANY') {
            content = <Select
                filterOption={(input, option) => {
                    if(typeof option !== 'undefined') {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                }}
                loading={this.state.loadingCompanies}
                disabled={this.state.loadingCompanies}
                value={this.state.filterValue}
                showSearch
                allowClear
                onChange={(value) => this.setState({ filterValue: value })}>
                {this.state.companies.map((company, i) => {
                    return <Option key={i} value={company.guid}>{company.name}</Option>
                })}
            </Select>
        } else if(this.state.selectedFilter === 'USER') {
            content = <Select
                filterOption={(input, option) => {
                    if(typeof option !== 'undefined') {
                        return option.children.join(' ').toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                }}
                loading={this.state.loadingUsers}
                disabled={this.state.loadingUsers}
                value={this.state.filterValue}
                showSearch
                allowClear
                onChange={(value) => this.setState({ filterValue: value })}>
                {this.state.users.map((user, i) => {
                    return <Option key={i} value={user.guid}>{user.id} - {user.first_name + ' ' + user.last_name} ({user.email})</Option>
                })}
            </Select>
        }

        return(
            <Modal
                title='Add Filter'
                destroyOnClose={true}
                onCancel={() => this.closeModal()}
                footer={<Space>
                    <Button onClick={() => this.closeModal()}>Close</Button>
                    <Button onClick={() => this.closeModal(true, false)}>Apply</Button>
                    <Button type='primary' onClick={() => this.closeModal(true, true)}>Apply & Close</Button>
                </Space>}
                open={this.props.showModal}>
                <Form layout='vertical'>
                    <Form.Item label='Select filter:'>
                        <Select value={this.state.selectedFilter} onChange={(value) => this.setFilter(value)}>
                            <Option value='SUBJECT'>Subject</Option>
                            <Option value='CONTENT'>Content</Option>
                            <Option value='COMPANY'>Company</Option>
                            <Option value='USER'>User</Option>
                            <Option value='ID'>ID</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        {content}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { loadAllCompanies, loadAllUsers })(EmailsTableFilter);