import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Col, Form, Input, Row, message, Select, Descriptions} from "antd";
import {CheckCircleOutlined, ExclamationCircleOutlined, PlayCircleOutlined} from "@ant-design/icons";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {adminRunRouterResolver} from "../../actions/tools";
import {Link} from "react-router-dom";

class RouterResolverTool extends React.Component {
    state = {
        result: null,
        loading: false,
        router: 'router-2.ams.nl',
        domain: '',
        ipAddress: '',
        scheme: 'http',
        method: 'GET',
        url: '/',
        userAgent: '',
    };

    componentDidMount() {
        this.props.updatePage('Router Resolver');
    }

    runResolver() {
        let domain = this.state.domain.trim().toLowerCase();
        let router = this.state.router;
        let ip_address = this.state.ipAddress.trim();
        let scheme = this.state.scheme;
        let method = this.state.method;
        let url = this.state.url.trim();
        let user_agent = this.state.userAgent.trim();

        if(domain.length === 0) {
            return message.error('Please enter a domain!');
        }

        let data = { domain, router, scheme, method };

        if(ip_address !== '') {
            data['ip_address'] = ip_address;
        }

        if(user_agent !== '') {
            data['user_agent'] = user_agent;
        }

        if(url !== '') {
            data['url'] = url;
        }

        this.setState({ loading: true, result: null });

        this.props.adminRunRouterResolver(data, (res) => {
            this.setState({ loading: false, result: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false, result: null });
                displayErrors(err.response.data);
            }
        });
    }

    parseBoolean(value) {
        if(value === 1) {
            return <CheckCircleOutlined style={{color:'green'}} />;
        }

        return <ExclamationCircleOutlined style={{color:'red'}} />;
    }

    render() {
        const { Option } = Select;

        return(
            <Form layout='vertical'>
                <Row gutter={[16, 16]}>
                    <Col span={7}>
                        <Form.Item label='Domain' extra={<small>Enter the domain that's already in the system.</small>}>
                            <Input disabled={this.state.loading} onChange={(e) => this.setState({ domain: e.target.value })} value={this.state.domain} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label='URL'>
                            <Input disabled={this.state.loading} onChange={(e) => this.setState({ url: e.target.value })} value={this.state.url} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label='Router'>
                            <Select disabled={this.state.loading} onChange={(value) => this.setState({ router: value })} defaultValue={this.state.router}>
                                <Option value='router-2.ams.nl'>router-2.ams.nl</Option>
                                <Option value='router-3.ams.nl'>router-3.ams.nl</Option>
                                <Option value='router-3.ash.us'>router-3.ash.us</Option>
                                <Option value='router-4.ash.us'>router-4.ash.us</Option>
                                <Option value='router-2.phx.us'>router-2.phx.us</Option>
                                <Option value='router-3.phx.us'>router-3.phx.us</Option>
                                <Option value='router-1.sin.sg'>router-1.sin.sg</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label='IP address' extra={<small>Leave empty to use your IP address.</small>}>
                            <Input disabled={this.state.loading} onChange={(e) => this.setState({ ipAddress: e.target.value })} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label='User agent' extra={<small>Leave empty to use your browser's user agent.</small>}>
                            <Input disabled={this.state.loading} onChange={(e) => this.setState({ userAgent: e.target.value })} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label='Scheme'>
                            <Select onChange={(value) => this.setState({ scheme: value })} defaultValue={this.state.scheme}>
                                <Option value='http'>HTTP</Option>
                                <Option value='https'>HTTPS</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label='Method'>
                            <Select onChange={(value) => this.setState({ method: value })} defaultValue={this.state.method}>
                                <Option value='GET'>GET</Option>
                                <Option value='POST'>POST</Option>
                                <Option value='PUT'>PUT</Option>
                                <Option value='PATCH'>PATCH</Option>
                                <Option value='OPTIONS'>OPTIONS</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label='&nbsp;'>
                            <Button type='primary' icon={<PlayCircleOutlined />} loading={this.state.loading} disabled={this.state.loading} onClick={() => this.runResolver()}>Run</Button>
                        </Form.Item>
                    </Col>
                </Row>
                <Row hidden={this.state.result === null}>
                    <Col span={24}>
                        <Descriptions bordered size='small' layout='horizontal' column={1}>
                            {this.state.result !== null ? Object.keys(this.state.result).map((item, i) => {
                                let value = this.state.result[item];
                                let yesNo = ['is_bot', 'block', 'delay', 'error']
                                let booleans = ['ip_domain_match', 'domain_available']

                                if(yesNo.indexOf(item) !== -1) {
                                    value = value === '1' ? 'Yes' : 'No';
                                }

                                if(booleans.indexOf(item) !== -1) {
                                    value = this.parseBoolean(value);
                                }

                                if(value === '') {
                                    value = '-';
                                }

                                if(item === 'waf_rule') {
                                    if (this.state.result[item] !== null) {
                                        return <Descriptions.Item label={normalizeEnum(item)} key={i}>
                                            <Link to={'/firewall/edit/' + this.state.result[item].guid}
                                                  target='_blank'>{this.state.result[item].name}</Link>
                                        </Descriptions.Item>;
                                    }
                                } else if(item === 'headers') {
                                    if(this.state.result[item] !== null) {
                                        return <Descriptions.Item label={normalizeEnum(item)} key={i}><Descriptions bordered size='small' layout='horizontal' column={1}>
                                            {Object.keys(this.state.result[item]).map((_item, j) => {
                                                return <Descriptions.Item style={{width:'50%'}} label={normalizeEnum(_item)} key={j}>
                                                    {this.state.result[item][_item]}
                                                </Descriptions.Item>})}
                                        </Descriptions></Descriptions.Item>;
                                    }
                                } else {
                                    return <Descriptions.Item label={normalizeEnum(item)} key={i}>
                                        {value}
                                    </Descriptions.Item>;
                                }

                                return '';


                            }) : ''}
                        </Descriptions>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default connect(null, { updatePage, adminRunRouterResolver })(RouterResolverTool);