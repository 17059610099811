import React from "react";
import {connect} from "react-redux";
import DNSZone from "./DNSZone";
import {Modal} from "antd";

class DNSZoneModal extends React.Component {
    closeModal() {
        this.props.close();
    }

    render(){
        return(
            <Modal visible={this.props.showModal}
                   centered
                   destroyOnClose={true}
                   title='DNS Zone'
                   width='1000px'
                   footer={false}
                   onCancel={() => this.closeModal()}>
                <DNSZone guid={this.props.activeDomain} />
            </Modal>
        );
    }
}

export default connect(null, { })(DNSZoneModal);