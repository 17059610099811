import React from "react";
import {connect} from "react-redux";
import {Button, Form, message, Modal, Select, Space} from "antd";
import TextArea from "antd/es/input/TextArea";
import {adminLoadAllIPNodes, adminLoadAllSharedHostingServers} from "../../actions/network";
import {displayErrors} from "../../libs/utils";
import {adminCreateIssue} from "../../actions/system";
import {adminLoadHostingAccounts} from "../../actions/hosting";
import {adminLoadAllCompanies} from "../../actions/accounts";

class NewIssueModal extends React.Component {
    state = {
        message: '',
        ipNodesLoaded: false,
        loadingIPNodes: false,
        selectedIPNodes: [],
        ipNodes: [],
        sharedHostingServersLoaded: false,
        loadingSharedHostingServers: false,
        selectedSharedHostingServers: [],
        sharedHostingServers: [],
        sharedHostingAccountsLoaded: false,
        loadingSharedHostingAccounts: false,
        selectedSharedHostingAccounts: [],
        sharedHostingAccounts: [],
        companies: [],
        loadingCompanies: false,
        companiesLoaded: false,
        selectedCompanies: [],
        loading: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingIPNodes && !this.state.ipNodesLoaded) {
            this.setState({ loadingIPNodes: true });

            this.props.adminLoadAllIPNodes((res) => {
                this.setState({ loadingIPNodes: false, ipNodesLoaded: true, ipNodes: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingIPNodes: false });
                    displayErrors(err.response.data);
                }
            });
        }

        if(this.props.showModal && !this.state.loadingSharedHostingServers && !this.state.sharedHostingServersLoaded) {
            this.setState({ loadingSharedHostingServers: true });

            this.props.adminLoadAllSharedHostingServers((res) => {
                this.setState({ loadingSharedHostingServers: false, sharedHostingServersLoaded: true,
                    sharedHostingServers: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingSharedHostingServers: false });
                    displayErrors(err.response.data);
                }
            });
        }

        if(this.props.showModal && !this.state.loadingSharedHostingAccounts && !this.state.sharedHostingAccountsLoaded) {
            this.setState({ loadingSharedHostingAccounts: true });

            this.props.adminLoadHostingAccounts(1, 9999, [{key: 'status', value: 'ACTIVE'}, {key: 'type', value: 'SHARED_HOSTING'}], (res) => {
                this.setState({ loadingSharedHostingAccounts: false, sharedHostingAccountsLoaded: true,
                    sharedHostingAccounts: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingSharedHostingAccounts: false });
                    displayErrors(err.response.data);
                }
            });
        }

        if(this.props.showModal && !this.state.loadingCompanies && !this.state.companiesLoaded) {
            this.setState({ loadingCompanies: true });

            this.props.adminLoadAllCompanies((res) => {
                this.setState({ loadingCompanies: false, companiesLoaded: true, companies: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingCompanies: false });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    closeModal(reload = false) {
        this.setState({
            message: '',
            ipNodesLoaded: false,
            loadingIPNodes: false,
            selectedIPNodes: [],
            ipNodes: [],
            sharedHostingServersLoaded: false,
            loadingSharedHostingServers: false,
            selectedSharedHostingServers: [],
            sharedHostingServers: [],
            sharedHostingAccountsLoaded: false,
            loadingSharedHostingAccounts: false,
            selectedSharedHostingAccounts: [],
            sharedHostingAccounts: [],
            companies: [],
            loadingCompanies: false,
            selectedCompanies: [],
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    createIssue() {
        let messageBody = this.state.message.trim();
        let ipNodes = this.state.selectedIPNodes;
        let sharedHostingServers = this.state.selectedSharedHostingServers;
        let sharedHostingAccounts = this.state.selectedSharedHostingAccounts;
        let companies = this.state.selectedCompanies;

        if(messageBody.length === 0) {
            return message.error('Please enter a message!');
        }

        if(ipNodes.length === 0 && sharedHostingServers.length === 0 && sharedHostingAccounts.length === 0 && companies.length === 0) {
            return message.error('Please select at least one IP node or shared hosting server or shared hosting account or company!');
        }

        let data = {
            message: messageBody, ip_nodes: ipNodes, shared_hosting_servers: sharedHostingServers,
            hosting_accounts: sharedHostingAccounts, companies: companies
        };

        this.setState({ loading: true });

        this.props.adminCreateIssue(data, () => {
            this.setState({ loading: false });
            message.success('Issue successfully created!');
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        return(
            <Modal
                title='Create Issue'
                visible={this.props.showModal}
                footer={<Space>
                    <Button onClick={() => this.closeModal()} disabled={this.state.loading}>Close</Button>
                    <Button type='primary' onClick={() => this.createIssue()} loading={this.state.loading} disabled={this.state.loading}>Create</Button>
                </Space>}
                onCancel={() => this.closeModal()}>
                <Form layout='vertical'>
                    <Form.Item label='Message'>
                        <TextArea rows={3} value={this.state.message} onChange={(e) => this.setState({ message: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Affected IP nodes'>
                        <Select mode='multiple'
                                showSearch loading={this.state.loadingIPNodes} disabled={this.state.loadingIPNodes}
                                value={this.state.selectedIPNodes}
                                filterOption={(input, option) => {
                                    if(typeof option !== 'undefined') {
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }
                                    return false;
                                }}
                                onChange={(values) => this.setState({ selectedIPNodes: values })}>
                            {this.state.ipNodes.map((ipNode) => <Option key={ipNode.guid} value={ipNode.guid}>{ipNode.hostname.replace('.gateway.prioritynap.net', '')}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Affected shared hosting servers'>
                        <Select mode='multiple'
                                showSearch loading={this.state.loadingSharedHostingServers} disabled={this.state.loadingSharedHostingServers}
                                value={this.state.selectedSharedHostingServers}
                                filterOption={(input, option) => {
                                    if(typeof option !== 'undefined') {
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }
                                    return false;
                                }}
                                onChange={(values) => this.setState({ selectedSharedHostingServers: values })}>
                            {this.state.sharedHostingServers.map((server) => <Option key={server.guid} value={server.guid}>{server.hostname}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Affected shared hosting accounts'>
                        <Select mode='multiple'
                                showSearch loading={this.state.loadingSharedHostingAccounts} disabled={this.state.loadingSharedHostingAccounts}
                                value={this.state.selectedSharedHostingAccounts}
                                filterOption={(input, option) => {
                                    if(typeof option !== 'undefined') {
                                        let value = option.children.join(' ');
                                        return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }
                                    return false;
                                }}
                                onChange={(values) => this.setState({ selectedSharedHostingAccounts: values })}>
                            {this.state.sharedHostingAccounts.map((account) => <Option key={account.guid} value={account.guid}>#{account.id} - {account.name} ({account.owner.id} - {account.owner.name})</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Affected companies'>
                        <Select mode='multiple'
                                showSearch loading={this.state.loadingCompanies} disabled={this.state.loadingCompanies}
                                value={this.state.selectedCompanies}
                                filterOption={(input, option) => {
                                    if(typeof option !== 'undefined') {
                                        let value = option.children.join(' ');
                                        return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }
                                    return false;
                                }}
                                onChange={(values) => this.setState({ selectedCompanies: values })}>
                            {this.state.companies.map((company) => <Option key={company.guid} value={company.guid}>#{company.id} - {company.name} (#{company.owner.id} - {company.owner.first_name + ' ' + company.owner.last_name} - {company.owner.email})</Option>)}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { adminLoadAllIPNodes, adminLoadAllSharedHostingServers,
    adminCreateIssue, adminLoadHostingAccounts, adminLoadAllCompanies })(NewIssueModal);