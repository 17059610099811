import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {loadAffiliateDetail, updateAffiliateNotes, updateAffiliateStatus} from "../../actions/affiliate";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {Loading} from "../../libs/loading";
import {Button, Card, Col, Descriptions, Input, Row, Statistic, Tabs, message, Space, Modal, Form} from "antd";
import {Link} from "react-router-dom";
import {CheckOutlined, CloseOutlined, ExclamationCircleOutlined, SaveOutlined} from "@ant-design/icons";
import AffiliateSignupsTable from "../../components/affiliate/AffiliateSignupsTable";
import AffiliateClicksTable from "../../components/affiliate/AffiliateClicksTable";
import {StatusIndicator} from "../../components/shared/Status";
import {DateTimeFormat} from "../../components/shared/DateTimeFormat";

class AffiliateDetail extends React.Component {
    state = {
        loading: false,
        data: null,
        savingNotes: false,
        notes: '',
        showStatusReasonModal: false,
        statusReason: '',
        setStatus: '',
    }
    componentDidMount() {
        this.loadDetails();
    }

    loadDetails() {
        this.setState({ loading: true });

        this.props.loadAffiliateDetail(this.props.match.params.guid, (res) => {
            this.setState({ loading: false, data: res.data, notes: res.data.notes });
            this.props.updatePage(res.data.first_name + ' ' + res.data.last_name);

        }, (err) => {
            if (typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    saveNotes() {
        let notes = this.state.notes.trim();

        let data = { notes };

        this.props.updateAffiliateNotes(this.props.match.params.guid, data, () => {
            message.success('Notes successfully updated!');
        }, (err) => {
            if (typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    approveAffiliate() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you wish to approve this application?',
            onOk: () => {
                let data = { status: 'ACTIVE' };

                this.props.updateAffiliateStatus(this.props.match.params.guid, data, () => {
                    message.success('Status successfully updated!');
                    this.loadDetails();
                }, (err) => {
                    if (typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                        this.setState({ loading: false });
                    }
                });
            }
        });
    }

    rejectAffiliate() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you wish to approve reject this application?',
            onOk: () => {
                this.setState({ showStatusReasonModal: true, setStatus: 'REJECTED' });
            }
        });
    }

    disableAffiliate(is_fraud = false) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you wish to approve disable this application?',
            onOk: () => {
                this.setState({ showStatusReasonModal: true, setStatus: is_fraud ? 'FRAUD' : 'DISABLED' });
            }
        });
    }

    updateStatus() {
        let data = {
            status: this.state.setStatus,
            reason: this.state.statusReason
        };

        this.props.updateAffiliateStatus(this.props.match.params.guid, data, () => {
            message.success('Status successfully updated!');

            this.setState({ showStatusReasonModal: false, setStatus: '' });
            this.loadDetails();
        }, (err) => {
            if (typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    render() {
        if(this.state.loading || this.state.data === null) {
            return <div className='text-center'><Loading/></div>;
        }

        const { TextArea } = Input;

        const { data } = this.state;

        const tabs = [
            { label: 'Sign Ups', key: 'signups', children: <AffiliateSignupsTable guid={data.guid} /> },
            { label: 'Clicks', key: 'clicks', children: <AffiliateClicksTable guid={data.guid} /> },
        ];

        return <Fragment>
            <Row gutter={[16, 16]}>
                <Col md={12} xs={24}>
                    <Card bordered={false} title='Statistics' size='small'>
                        <Row gutter={[16, 16]}>
                            <Col span={8}><Statistic title="Conversions" value={data.total_conversions} /></Col>
                            <Col span={8}><Statistic title="Clicks" value={data.total_clicks} /></Col>
                            <Col span={8}><Statistic title="Revenue" value={data.total_revenue} precision={2} prefix={'$'} /></Col>
                        </Row>
                    </Card>
                    <br />
                    <Card bordered={false} title='Information' size='small'>
                        <Descriptions size='small' column={1} bordered>
                            <Descriptions.Item label='ID' style={{width:'50%'}}>{data.id}</Descriptions.Item>
                            <Descriptions.Item label='Account type'>{normalizeEnum(data.account_type)}</Descriptions.Item>
                            <Descriptions.Item label='Created'><DateTimeFormat value={data.date_created} /></Descriptions.Item>
                            <Descriptions.Item label='Updated'><DateTimeFormat value={data.date_updated} /></Descriptions.Item>
                            <Descriptions.Item label='Status'>
                                <Row gutter={[16, 16]} justify='space-between'>
                                    <Col><StatusIndicator status={data.status} el='badge' /> {data.status === 'FRAUD' || data.status === 'DISABLED' ? <small>({data.disabled_reason})</small> : ''}</Col>
                                    <Col>
                                        {data.status === 'PENDING' ? <Fragment>
                                            <Space>
                                                <Button size='small' icon={<CheckOutlined />} onClick={() => this.approveAffiliate()}>Approve</Button>
                                                <Button size='small' icon={<CloseOutlined />} onClick={() => this.rejectAffiliate()}>Reject</Button>
                                            </Space>
                                        </Fragment> : ''}
                                        {data.status === 'ACTIVE' ? <Fragment>
                                            <Space>
                                                <Button size='small' icon={<CloseOutlined />} onClick={() => this.disableAffiliate()}>Disable</Button>
                                                <Button size='small' icon={<CloseOutlined />} onClick={() => this.disableAffiliate(true)}>Fraud</Button>
                                            </Space>
                                        </Fragment> : ''}
                                    </Col>
                                </Row>
                            </Descriptions.Item>
                            <Descriptions.Item label='User'><Link to={'/accounts/users/' + data.user.guid}>{data.user.first_name + ' ' + data.user.last_name}</Link></Descriptions.Item>
                            <Descriptions.Item label='Name'>{data.first_name + ' ' + data.last_name}</Descriptions.Item>
                            <Descriptions.Item label='Company'>{data.company_name}</Descriptions.Item>
                            <Descriptions.Item label='Address'>{data.address1}</Descriptions.Item>
                            {data.address2 !== null && data.address2 !== '' ? <Descriptions.Item label='Address 2'>{data.address2}</Descriptions.Item> : ''}
                            <Descriptions.Item label='City'>{data.city}</Descriptions.Item>
                            <Descriptions.Item label='Region'>{data.region}</Descriptions.Item>
                            <Descriptions.Item label='Postal code'>{data.postal_code}</Descriptions.Item>
                            <Descriptions.Item label='Country'>{data.country}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
                <Col md={12} xs={24}>
                    <Card bordered={false} title='Extra Details' size='small'>
                        <Descriptions size='small' column={1} bordered>
                            <Descriptions.Item label='Promotion methods' style={{width:'50%'}}>{data.promotion_methods}</Descriptions.Item>
                            <Descriptions.Item label='Promotion platforms'>{data.promotion_platforms}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                    <br />
                    <Card bordered={false} size='small' title='Notes'
                          extra={<Button size='small' type='primary' loading={this.state.savingNotes}
                                         onClick={() => this.saveNotes()}
                                         icon={<SaveOutlined />} disabled={this.state.savingNotes}>Save</Button>}>
                        <TextArea rows={10} value={this.state.notes} disabled={this.state.loading}
                                  onChange={(e) => this.setState({ notes: e.target.value })}
                                  placeholder='Enter your notes here!' />
                    </Card>
                </Col>
            </Row>
            <br />
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Tabs destroyInactiveTabPane={true} items={tabs} />
                </Col>
            </Row>

            <Modal open={this.state.showStatusReasonModal}
                   title='Confirm'
                   destroyOnClose={true}
                   footer={<Space>
                       <Button onClick={() => this.setState({ setStatus: '', showStatusReasonModal: false })}>Close</Button>
                       <Button type='primary' onClick={() => this.updateStatus()}>Continue</Button>
                   </Space>}>
                <Form layout='vertical'>
                    <Form.Item label='Please enter the reason:'>
                        <Input onChange={(e) => this.setState({ statusReason: e.target.value })} value={this.state.statusReason} />
                    </Form.Item>
                </Form>
            </Modal>
        </Fragment>;
    }
}

export default connect(null, { updatePage, loadAffiliateDetail,
    updateAffiliateNotes, updateAffiliateStatus })(AffiliateDetail);