import {httpDelete, httpGet, httpPatch, httpPost} from "../libs/http";

export const loadFirewallRules = (page, per_page, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/firewall/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadFirewallRule = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/firewall/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadPredefinedBots = (onSuccess, onFailure) => dispatch => {
    httpGet('/admin/firewall/predefined-bots/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const createFirewallRule = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/firewall/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const editFirewallRule = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/firewall/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateFirewallRuleStatus = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPatch('/admin/firewall/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteFirewallRule = (guid, onSuccess, onFailure) => dispatch => {
    httpDelete('/admin/firewall/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const createFirewallPolicy = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/firewall/policies/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadFirewallPolicies = (page, per_page, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/firewall/policies/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateFirewallPolicyStatus = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPatch('/admin/firewall/policies/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteFirewallPolicy = (guid, onSuccess, onFailure) => dispatch => {
    httpDelete('/admin/firewall/policies/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadFirewallPolicy = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/firewall/policies/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const editFirewallPolicy = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/firewall/policies/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const createFirewallRuleset = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/firewall/rulesets/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadFirewallRulesets = (page, per_page, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/firewall/rulesets/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateFirewallRulesetStatus = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPatch('/admin/firewall/rulesets/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteFirewallRuleset = (guid, onSuccess, onFailure) => dispatch => {
    httpDelete('/admin/firewall/rulesets/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadFirewallRuleset = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/firewall/rulesets/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const editFirewallRuleset = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/firewall/rulesets/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const bulkDeleteFirewallRules = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/firewall/delete/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const bulkDeleteFirewallRulesets = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/firewall/rulesets/delete/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const bulkDeleteFirewallPolicies = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/firewall/policies/delete/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadBlockedIPAddresses = (page, per_page, filters, onSuccess, onFailure) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/admin/firewall/blocked-ip-addresses/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteBlockedIPAddress = (id, onSuccess, onFailure) => dispatch => {
    httpDelete('/admin/firewall/blocked-ip-addresses/' + id + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const bulkDeleteBlockedIPAddresses = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/firewall/blocked-ip-addresses/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const createIPAddressBlocks = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/firewall/blocked-ip-addresses/', data, (res) => onSuccess(res), (err) => onFailure(err));
};


export const loadCachedSubnets = (page, per_page, filters, onSuccess, onFailure) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/admin/firewall/cached-subnets/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const deleteCachedSubnet = (id, onSuccess, onFailure) => dispatch => {
    httpDelete('/admin/firewall/cached-subnets/' + id + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const createCachedSubnets = (data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/firewall/cached-subnets/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const bulkDeleteCachedSubnets = (data, onSuccess, onFailure) => dispatch => {
    httpPatch('/admin/firewall/cached-subnets/', data, (res) => onSuccess(res), (err) => onFailure(err));
};
