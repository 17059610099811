import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Col, Form, Input, Row, message, Typography} from "antd";
import {PlayCircleOutlined} from "@ant-design/icons";
import {adminRunTracerouteTool} from "../../actions/tools";
import {displayErrors} from "../../libs/utils";

class TracerouteTool extends React.Component {
    state = {
        value: '',
        result: '',
        loading: false
    };

    componentDidMount() {
        this.props.updatePage('Traceroute');
    }

    runTraceroute() {
        let value = this.state.value;

        if(value.length === 0) {
            return message.error('Please enter a domain or an IP address!');
        }

        let data = { value };

        this.setState({ loading: true });

        this.props.adminRunTracerouteTool(data, (res) => {
            this.setState({ loading: false, result: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Paragraph } = Typography;

        return(
            <Form layout='vertical'>
                <Row gutter={[16, 16]}>
                    <Col span={18}>
                        <Form.Item label='Enter IP address or domain'>
                            <Input disabled={this.state.loading} onChange={(e) => this.setState({ value: e.target.value })} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label='&nbsp;'>
                            <Button type='primary' icon={<PlayCircleOutlined />} loading={this.state.loading} disabled={this.state.loading} onClick={() => this.runTraceroute()}>Run</Button>
                        </Form.Item>
                    </Col>
                </Row>
                <Row hidden={this.state.result === ''}>
                    <Col span={24}>
                        <Paragraph><pre>{this.state.result.trim()}</pre></Paragraph>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default connect(null, { updatePage, adminRunTracerouteTool })(TracerouteTool);