import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    adminCreateOrderFromIPAddressReservationList,
    adminDeleteIPAddressReservationList,
    adminLoadIPAddressReservation,
    adminUpdateIPAddressesFromReservation
} from "../../actions/ip_addresses";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {Button, Col, Descriptions, message, Modal, Popconfirm, Row, Space, Table} from "antd";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {Link} from "react-router-dom";
import {Loading} from "../../libs/loading";
import {StatusIndicator} from "../shared/Status";
import {DeleteOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import AddIPAddressToReservationModal from "./AddIPAddressToReservationModal";
import {MoneyField} from "../shared/MoneyField";

class IPAddressReservationDetail extends React.Component {
    state = {
        loading: false,
        data: null,
        selectedIPAddresses: [],
        showAddIPAddressModal: false
    };

    componentDidMount() {
        this.loadReservation();
    }

    loadReservation() {
        this.setState({ loading: true });

        this.props.adminLoadIPAddressReservation(this.props.match.params.guid, (res) => {
            this.setState({ loading: false, data: res.data });

            this.props.updatePage(res.data.name);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    removeIPAddresses() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to remove these IP addresses?</span>,
            onOk: () => {
                let data = {
                    action: 'REMOVE',
                    ip_addresses: this.state.selectedIPAddresses
                };

                this.props.adminUpdateIPAddressesFromReservation(this.props.match.params.guid, data, () => {
                    message.success('IP address(es) successfully removed!');
                    this.loadReservation();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    deleteReservationList() {
        this.setState({ loading: true });

        this.props.adminDeleteIPAddressReservationList(this.props.match.params.guid, () => {
            message.success('Reservation list successfully deleted!');
            window.location.href = '/network/reservations/';

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    createOrder() {
        this.setState({ loading: true });

        this.props.adminCreateOrderFromIPAddressReservationList(this.props.match.params.guid, () => {
            message.success('Order successfully created!');

            this.loadReservation();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        if(this.state.loading || this.state.data === null) {
            return <div className='text-center'><Loading /></div>;
        }

        const columns = [
            { title: 'IP address', dataIndex: 'ip_address' },
            { title: 'Status', dataIndex: 'status', render: (item, record) => <StatusIndicator el='badge' status={record.status} />},
            { title: 'Type', dataIndex: 'ip_address_type', render: (item, record) => normalizeEnum(record.ip_address_type) },
            { title: 'Location', render: (item, record) => <span>{record.ip_node.location.city}, {record.ip_node.location.country}</span>},
            { title: 'IP node', render: (item, record) => <Link to={'/network/ip-nodes/' + record.ip_node.guid}>{record.ip_node.hostname.replace('.gateway.prioritynap.net', '')}</Link>},
            { title: 'Price', dataIndex: 'price', render: (item) => <MoneyField amount={item} currency='USD' />}
        ];

        let totalCost = 0;

        for(let i = 0; i < this.state.data.ip_addresses.length; i++) {
            totalCost += parseFloat(this.state.data.ip_addresses[i].price);
        }

        return(
            <Fragment>
                <Descriptions bordered size='small' layout='horizontal' column={1}>
                    <Descriptions.Item label='Name'>{this.state.data.name}</Descriptions.Item>
                    <Descriptions.Item label='Created'><DateTimeFormat value={this.state.data.date_created} /></Descriptions.Item>
                    <Descriptions.Item label='Owner'><Link to={'/accounts/companies/' + this.state.data.list_owner.guid}>{this.state.data.list_owner.name}</Link></Descriptions.Item>
                    <Descriptions.Item label='IP address count'>{this.state.data.ip_addresses.length}</Descriptions.Item>
                    <Descriptions.Item label='Total cost'><MoneyField amount={totalCost} currency='USD' /></Descriptions.Item>
                    <Descriptions.Item label='Actions'>
                        <Space>
                            <Popconfirm
                                title="Are you sure you wish to delete this list?"
                                onConfirm={() => this.deleteReservationList()}
                                okText='Delete'
                                okButtonProps={{danger: true}}>
                                <Button size='small' danger icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                            <Popconfirm
                                title="Are you sure you wish to create an order for this list?"
                                onConfirm={() => this.createOrder()}
                                okText='Create'>
                                <Button size='small' icon={<PlusOutlined />}>Create Order</Button>
                            </Popconfirm>
                        </Space>
                    </Descriptions.Item>
                </Descriptions>

                <Row justify='space-between' style={{marginBottom: '10px', marginTop: '10px'}}>
                    <Col>
                        <Space>
                            <Button type='text' size='small' icon={<PlusOutlined />} onClick={() => this.setState({ showAddIPAddressModal: true })}>Add IP address</Button>
                            <Button type='text' size='small' icon={<DeleteOutlined />} onClick={() => this.removeIPAddresses()}>Remove</Button>
                        </Space>
                    </Col>
                    <Col>
                        <Button type='circle' size='small' icon={<UndoOutlined />} onClick={() => this.loadReservation()} />
                    </Col>
                </Row>
                <Table dataSource={this.state.data.ip_addresses} columns={columns}
                       pagination={false} size='small'
                       rowSelection={{
                           type: 'checkbox',
                           selectedRowKeys: this.state.selectedIPAddresses,
                           onChange: (ipAddresses) => this.setState({ selectedIPAddresses: ipAddresses })
                       }}
                       rowKey={item => item.guid} />

                <AddIPAddressToReservationModal
                    guid={this.props.match.params.guid}
                    showModal={this.state.showAddIPAddressModal}
                    close={() => this.setState({ showAddIPAddressModal: false })}
                    reload={() => this.loadReservation()} />
            </Fragment>
        )
    }
}

export default connect(null, { adminLoadIPAddressReservation, updatePage,
    adminUpdateIPAddressesFromReservation, adminDeleteIPAddressReservationList,
    adminCreateOrderFromIPAddressReservationList})(IPAddressReservationDetail);