import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";

class AffiliateReports extends React.Component {
    componentDidMount() {
        this.props.updatePage('Reports');
    }

    render() {
        return <p>Affiliate reports</p>;
    }
}

export default connect(null, { updatePage })(AffiliateReports);