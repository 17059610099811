import Moment from "react-moment";
import React, {Fragment} from "react";
import 'moment-timezone';

export const DateTimeFormat = function(props) {
    let format = 'YYYY/MM/DD HH:mm:ss';

    if(props.value === null) {
        return <Fragment>N/A</Fragment>;
    }

    if(typeof props.format !== 'undefined') {
        format = props.format;
    }

    return <Moment date={props.value} format={format} withTitle tz='UTC' />;
};