import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Tabs} from "antd";
import UsersTable from "../../components/accounts/UsersTable";
import CompaniesTable from "../../components/accounts/CompaniesTable";

class Accounts extends React.Component {
    state = {
        pages: ['users', 'companies'],
        titles: {
            'users': 'Users',
            'companies': 'Companies',
        }
    }

    changeTab(page) {
        if(this.state.pages.indexOf(page) > -1) {
            this.props.updatePage(this.state.titles[page]);

            if(page === 'users') {
                this.props.history.push('/accounts');
            } else {
                this.props.history.push('/accounts/' + page);
            }
        }
    }

    render() {
        const { TabPane } = Tabs;

        let page = 'users';

        if(typeof this.props.match.params.page !== 'undefined' && this.state.pages.indexOf(this.props.match.params.page)) {
            page = this.props.match.params.page;
        }

        return(
            <Tabs defaultActiveKey={page} onChange={(e) => this.changeTab(e)} destroyInactiveTabPane={true}>
                <TabPane tab='Users' key='users'>
                    <UsersTable history={this.props.history} />
                </TabPane>
                <TabPane tab='Companies' key='companies'>
                    <CompaniesTable history={this.props.history} />
                </TabPane>
            </Tabs>
        );
    }
}

export default connect(null, { updatePage })(Accounts);