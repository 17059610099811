import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_PER_PAGE} from "../../config";
import {Button, Col, Pagination, Row, Space, Table, Tag, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {adminLoadOrders} from "../../actions/billing";
import {DateFormat} from "../shared/DateFormat";
import {StatusIndicator} from "../shared/Status";
import {DeleteOutlined, EyeOutlined, FilterOutlined, OrderedListOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import OrdersTableFilter from "./OrdersTableFilter";
import OrdersBulkActionsModal from "./OrdersBulkActionsModal";
import {DateTimeFormat} from "../shared/DateTimeFormat";

class OrdersTable extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        orders: [],
        loading: true,
        filters: [],
        showFiltersModal: false,
        selectedOrders: [],
        showBulkActionsModal: false
    }

    componentDidMount() {
        this.props.updatePage('Orders');
        this.adminLoadOrders();
    }

    adminLoadOrders(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loading: true });

        this.props.adminLoadOrders(page, per_page, this.state.filters, (res) => {
            window.scrollTo(0, 0);

            this.setState({ orders: res.data.data, loading: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    clearFilters() {
        this.setState({ filters: [] }, () => this.adminLoadOrders());
    }

    setFilter(filter) {
        let filters = this.state.filters.filter(f => f.key !== filter.key);
        filters.push(filter);

        this.setState({ filters }, () => this.adminLoadOrders());
    }

    removeFilter(item) {
        let filters = this.state.filters.filter(filter => filter.key !== item.key);
        this.setState({ filters }, () => this.adminLoadOrders());
    }

    showFilters() {
        return this.state.filters.map((filter, i) => {
            let value;

            if (typeof filter.value === 'boolean') {
                if (filter.value) {
                    value = 'Yes';
                } else {
                    value = 'No';
                }
            } else {
                if (filter.visible !== null) {
                    value = filter.visible;
                } else {
                    value = normalizeEnum(filter.value);
                }
            }

            return <Tag key={i} closable onClose={() => this.removeFilter(filter)}>{filter.name}: {value}</Tag>;
        });
    }

    render() {
        const columns = [
            { title: 'Order ID', dataIndex: 'id', render: (item, record) => <Link to={'/billing/orders/' + record.id}>#{record.id}</Link> },
            { title: 'Status', align: 'center', dataIndex: 'status', render: (item, record) => record.do_not_suspend ? <StatusIndicator status={'DO_NOT_SUSPEND'} el='badge' /> : <StatusIndicator status={item} el='badge' />},
            { title: 'Owner', dataIndex: 'owner', render: (item) => {
                return <Link to={'/accounts/companies/' + item.guid}>{item.name}</Link>;
            }},
            { title: 'Created', dataIndex: 'date_created', render: (item, record) => <DateTimeFormat value={record.date_created} /> },
            { title: 'Processed', dataIndex: 'date_processed', render: (item, record) => <DateTimeFormat value={record.date_processed} /> },
            { title: 'Next Invoice', dataIndex: 'date_next_invoice', render: (item, record) => <DateFormat value={record.date_next_invoice} /> },
            { title: 'Billing Cycle', align: 'center', dataIndex: 'billing_cycle', render: item => normalizeEnum(item) },
            { title: 'Order Type', align: 'center', dataIndex: 'order_type', render: (item) => normalizeEnum(item) },
            { title: 'Currency', align: 'center', dataIndex: 'currency' },
            { title: 'Tags', dataIndex: 'tags', render: (item) => item.length > 0 ? item.map((tag, i) => <Tag key={i}>{tag}</Tag>) : '-'},
            { title: '', align: 'right', render: (item, record) => <Link to={'/billing/orders/' + record.id}><Button size='small' icon={<EyeOutlined />}>View</Button></Link>}
        ];

        return(
            <Fragment>
                {this.showFilters()}
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <Space>
                            <Button size='small' type='text' icon={<FilterOutlined />} onClick={() => this.setState({ showFiltersModal: true })}>Filters</Button>
                            <Button size='small' type='text'  disabled={this.state.filters.length === 0} onClick={() => this.clearFilters()} icon={<DeleteOutlined />}>Clear Filters</Button>
                            <Button size='small' type='text'  disabled={this.state.selectedOrders.length === 0} onClick={() => this.setState({ showBulkActionsModal: true })} icon={<OrderedListOutlined />}>Actions</Button>
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Tooltip title='Reload' placement='left'>
                                <Button size='small' type='circle' loading={this.state.loading} disabled={this.state.loading} icon={<UndoOutlined />} onClick={() => this.adminLoadOrders()} />
                            </Tooltip>
                            <Pagination onChange={(page, pageSize) => this.adminLoadOrders(page, pageSize)} size='small' {...this.state.pagination} />
                        </Space>
                    </Col>
                </Row>
                <Table
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: this.state.selectedOrders,
                        onChange: (orders) => this.setState({ selectedOrders: orders })
                    }}
                    columns={columns}
                    rowKey={(item) => item.id}
                    dataSource={this.state.orders}
                    loading={this.state.loading}
                    pagination={false}
                    footer={() => <Row justify='space-between'>
                        <Col />
                        <Col><Pagination onChange={(page, pageSize) => this.adminLoadOrders(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                    </Row>}
                />

                <OrdersTableFilter
                    showModal={this.state.showFiltersModal}
                    closeModal={() => this.setState({ showFiltersModal: false })}
                    onChange={(filter) => this.setFilter(filter) } />

                <OrdersBulkActionsModal
                    selectedOrders={this.state.selectedOrders}
                    reloadOrders={() => this.adminLoadOrders()}
                    showModal={this.state.showBulkActionsModal}
                    close={() => this.setState({ showBulkActionsModal: false })} />
            </Fragment>
        )
    }
}

export default connect(null, { updatePage, adminLoadOrders })(OrdersTable);