import {httpGet} from "../libs/http";

export const loadAllCompanies = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/users/companies/all/?page=1&per_page=9999', (res) => onSuccess(res), (err) => onFailure(err));
}

export const loadAllUsers = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/users/users/?page=1&per_page=9999', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadUserEmails = (guid, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/users/users/' + guid + '/emails/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
}
