import React from 'react';
import {Button, Descriptions, Modal, Typography} from "antd";
import {DateTimeFormat} from "../shared/DateTimeFormat";

export default class MessageDetailModal extends React.Component {
    render() {
        if(this.props.message === null) {
            return null;
        }

        const { Paragraph } = Typography;
        const { message } = this.props;

        return <Modal
            title='Message'
            width='900px'
            visible={this.props.showModal}
            footer={<Button onClick={() => this.props.closeModal()}>Close</Button>}
            onCancel={() => this.props.closeModal()}>
            <Descriptions bordered size='small' layout='horizontal' column={1}>
                <Descriptions.Item label='ID'>{message.id}</Descriptions.Item>
                <Descriptions.Item label='Date created'><DateTimeFormat value={message.date_created} /></Descriptions.Item>
                <Descriptions.Item label='Message'>
                    <Paragraph>
                        <pre>
                        {message.message}
                    </pre>
                    </Paragraph>

                </Descriptions.Item>
            </Descriptions>
        </Modal>
    }
}