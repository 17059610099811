import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_PER_PAGE} from "../../config";
import {Col, message, Modal, Pagination, Row, Space, Table, Tag, Tooltip} from "antd";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {DeleteOutlined, ExclamationCircleOutlined, EyeOutlined, FilterOutlined} from "@ant-design/icons";
import Button from "antd/es/button";
import {Link} from "react-router-dom";
import {StatusIndicator} from "../../components/shared/Status";
import {adminBulkHostingAccountActions, adminLoadHostingAccounts} from "../../actions/hosting";
import HostingAccountsTableFilter from "../../components/hosting/HostingAccountsTableFilter";

class HostingAccounts extends React.Component {
    state = {
        loadingAccounts: false,
        accounts: [],
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, showSizeChanger: true, total: 0 },
        showAddHostingModal: false,
        filters: [],
        showFiltersModal: false,
        selectedAccounts: []
    };

    componentDidMount() {
        this.props.updatePage('Hosting Accounts');
        this.loadHostingAccounts();
    }

    loadHostingAccounts(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingAccounts: true });

        this.props.adminLoadHostingAccounts(page, per_page, this.state.filters, (response) => {
            window.scrollTo(0, 0);

            this.setState({ accounts: response.data.data,
                pagination: { current: response.data.page, pageSize: response.data.per_page, showSizeChanger: true, total: response.data.total }, loadingAccounts: false });
        }, (err) =>
        {
            this.setState({ loadingAccounts: false });

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    clearFilters() {
        this.setState({ filters: [] }, () => this.loadHostingAccounts());
    }

    setFilter(filter) {
        let filters = this.state.filters.filter(f => f.key !== filter.key);
        filters.push(filter);

        this.setState({ filters }, () => this.loadHostingAccounts());
    }

    removeFilter(item) {
        let filters = this.state.filters.filter(filter => filter.key !== item.key);
        this.setState({ filters }, () => this.loadHostingAccounts());
    }

    showFilters() {
        return this.state.filters.map((filter, i) => {
            let value;

            if (typeof filter.value === 'boolean') {
                if (filter.value) {
                    value = 'Yes';
                } else {
                    value = 'No';
                }
            } else {
                if (filter.visible !== null) {
                    value = filter.visible;
                } else {
                    value = normalizeEnum(filter.value);
                }
            }

            return <Tag key={i} closable onClose={() => this.removeFilter(filter)}>{filter.name}: {value}</Tag>;
        });
    }

    deleteHostingAccounts() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to delete <strong>{this.state.selectedAccounts.length}</strong> hosting accounts?</span>,
            onOk: () => {
                this.setState({ deleting: true });

                let data = {
                    'action': 'DELETE',
                    'hosting_accounts': this.state.selectedAccounts
                };

                this.props.adminBulkHostingAccountActions(data, () => {
                    this.setState({ deleting: false, selectedAccounts: [] });
                    message.success('Hosting accounts successfully deleted!');
                    this.loadHostingAccounts();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ deleting: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    render() {
        const columns = [
            { title: 'ID', dataIndex: 'id', width: '30px', render: (item, record) => <Link to={'/hosting/' + record.guid}>{item}</Link>},
            { title: 'Name', dataIndex: 'name', render: (item, record) => <Link to={'/hosting/' + record.guid}>{item}</Link> },
            { title: 'Status', align: 'center', dataIndex: 'status',  render: (item) => <StatusIndicator status={item} el={'badge'} /> },
            { title: 'Owner', dataIndex: 'owner', render: (item) => <Link to={'/accounts/companies/' + item.guid}>{item.name}</Link>},
            { title: 'Type', dataIndex: 'account_type',  render: (item) => normalizeEnum(item) },
            { title: 'Plan', dataIndex: 'plan', render: (item) => item.name },
            { title: 'Domains', align: 'center', dataIndex: 'domain_count'},
            { title: '', align: 'right', render: (item, record) => <Button size='small' onClick={() => this.props.history.push('/hosting/' + record.guid)} icon={<EyeOutlined />}>Details</Button>}
        ];

        return(
            <Fragment>
                <p>{this.showFilters()}</p>
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <Space>
                            <Button size='small' type='text' icon={<FilterOutlined />} onClick={() => this.setState({ showFiltersModal: true })}>Filters</Button>
                            <Button size='small' type='text' disabled={this.state.filters.length === 0} onClick={() => this.clearFilters()} icon={<DeleteOutlined />}>Clear Filters</Button>
                            <Button size='small' type='text' disabled={this.state.deleting} icon={<DeleteOutlined />} onClick={() => this.deleteHostingAccounts()}>Delete</Button>
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Tooltip title='Reload' placement='left'><Button icon={<UndoOutlined />} size='small' type='circle' onClick={() => this.loadHostingAccounts()} /></Tooltip>
                            <Pagination onChange={(page, pageSize) => this.loadHostingAccounts(page, pageSize)} size='small' {...this.state.pagination} />
                        </Space>
                    </Col>
                </Row>

                <Table
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: this.state.selectedAccounts,
                        onChange: (accounts) => this.setState({ selectedAccounts: accounts })
                    }}
                    footer={() => <Row justify='space-between'>
                        <Col>{this.state.selectedAccounts.length + ' selected'}</Col>
                        <Col><Pagination onChange={(page, pageSize) => this.loadHostingAccounts(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                    </Row>}
                    dataSource={this.state.accounts}
                    columns={columns}
                    rowKey={(item) => item.guid}
                    pagination={false}
                    loading={this.state.loadingAccounts} />

                <HostingAccountsTableFilter
                    showModal={this.state.showFiltersModal}
                    closeModal={() => this.setState({ showFiltersModal: false })}
                    onChange={(filter) => this.setFilter(filter) } />
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminLoadHostingAccounts, adminBulkHostingAccountActions })(HostingAccounts);