import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import KeyValueReport from "../../components/analytics/KeyValueReport";

class AnalyticsReportView extends React.Component {
    render() {
        let report = this.props.match.params.report;

        let reports = {
            'ip-allocation-by-coupons': { title: 'IP Allocation By Coupons', normalizeKey: false, showMonthPicker: true, showYearPicker: true, showGraph: false },
            'daily-registrations': { title: 'Daily Registrations', normalizeKey: false, showMonthPicker: true, showYearPicker: true, showGraph: true },
            'revenue-by-coupons': { title: 'Revenue By Coupons', normalizeKey: false, showMonthPicker: true, showYearPicker: true, showGraph: false },
            'daily-revenue': { title: 'Daily Revenue', normalizeKey: false, showMonthPicker: true, showYearPicker: true, showGraph: true },
            'daily-orders': { title: 'Daily Orders', normalizeKey: false, showMonthPicker: true, showYearPicker: true, showGraph: true },
            'daily-ip-allocation': { title: 'Daily IP Allocation', normalizeKey: false, showMonthPicker: true, showYearPicker: true, showGraph: true },
            'daily-ip-cancellations': { title: 'Daily IP Cancellations', normalizeKey: false, showMonthPicker: true, showYearPicker: true, showGraph: true },
            'orders-by-coupons': { title: 'Orders By Coupons', normalizeKey: false, showMonthPicker: true, showYearPicker: true, showGraph: false },
            'orders-by-type': { title: 'Orders By Type', normalizeKey: true, showMonthPicker: true, showYearPicker: true, showGraph: false },
            'orders-by-status': { title: 'Orders By Status', normalizeKey: true, showMonthPicker: false, showYearPicker: true, showGraph: false },
            'invoices-by-status': { title: 'Invoices By Status', normalizeKey: true, showMonthPicker: true, showYearPicker: true, showGraph: false },
            'users-by-country': { title: 'Users By Country', normalizeKey: false, showMonthPicker: false, showYearPicker: false, showGraph: false },
            'users-by-orders': { title: 'Users By Orders', normalizeKey: false, showMonthPicker: false, showYearPicker: false, showGraph: false },
            'users-by-revenue': { title: 'Users By Revenue', normalizeKey: false, showMonthPicker: false, showYearPicker: false, showGraph: false },
            'ip-addresses-by-orders': { title: 'Active IP addresses by user', normalizeKey: false, showMonthPicker: false, showYearPicker: false, showGraph: false },
            'countries-by-revenue': { title: 'Countries by revenue', normalizeKey: false, showMonthPicker: false, showYearPicker: false, showGraph: false },
            'weekly-revenue': { title: 'Weekly Revenue', normalizeKey: false, showMonthPicker: false, showYearPicker: true, showGraph: true },
            'monthly-revenue': { title: 'Monthly Revenue', normalizeKey: false, showMonthPicker: false, showYearPicker: true, showGraph: true },
            'users-by-found': { title: 'Users by Found value', normalizeKey: true, showMonthPicker: true, showYearPicker: true, showGraph: false },
        };

        return <KeyValueReport report={report} {...reports[report]}/>;
    }
}

export default connect(null, { updatePage })(AnalyticsReportView);