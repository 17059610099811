import React from "react";
import {connect} from "react-redux";
import {Button, Form, Modal, Space, message, Select} from "antd";
import {displayErrors} from "../../libs/utils";
import {adminLoadAllIPNodes, adminMoveIPAddresses} from "../../actions/network";

class MoveIPAddressesToAnotherNodeModal extends React.Component {
    state = {
        loadingIPNodes: false,
        ipNodesLoaded: false,
        ipNodes: [],
        loading: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingIPNodes && !this.state.ipNodesLoaded) {
            this.adminLoadIPNodes();
        }
    }

    closeModal(reload = false) {
        this.setState({
            loadingIPNodes: false,
            ipNodesLoaded: false,
            ipNodes: [],
            loading: false,
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    adminLoadIPNodes() {
        this.setState({ loadingIPNodes: true });

        this.props.adminLoadAllIPNodes((res) => {
            this.setState({ loadingIPNodes: false, ipNodesLoaded: true, ipNodes: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIPNodes: false });
                displayErrors(err.response.data);
            }
        });
    }

    moveIPAddresses() {
        let data = {
            ip_addresses: this.props.selectedIPAddresses,
            destination_ip_node: this.state.selectedIPNode
        };

        this.setState({ loading: true });

        this.props.adminMoveIPAddresses(data, (res) => {
            this.setState({ loading: false });
            message.success('IP addresses successfully moved!');

            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        return(
            <Modal title='Move Selected IP Addresses'
                   visible={this.props.showModal}
                   onCancel={() => this.closeModal()}
                   footer={<Space>
                       <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Close</Button>
                       <Button disabled={this.state.loading} loading={this.state.loading}  type='primary' onClick={() => this.moveIPAddresses()}>Move</Button>
                   </Space>}>
                <Form layout='vertical'>
                    <Form.Item label='Destination IP node'>
                        <Select showSearch loading={this.state.loadingIPNodes} disabled={this.state.loadingIPNodes}
                                value={this.state.selectedIPNode}
                                filterOption={(input, option) => {
                                    if(typeof option !== 'undefined') {
                                        let value = option.children[0] + ' - ' + option.children[2];
                                        return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }
                                    return false;
                                }}
                                onChange={(value) => this.setState({ selectedIPNode: value })}>
                            {this.state.ipNodes.map((ipNode, i) => <Option key={i} value={ipNode.guid}>{ipNode.hostname.replace('.gateway.prioritynap.net', '')} / {ipNode.ip_address} / {ipNode.provider.name}</Option>)}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { adminLoadAllIPNodes, adminMoveIPAddresses })(MoveIPAddressesToAnotherNodeModal);