import {httpGet, httpPatch, httpPost} from "../libs/http";

export const adminLoadIPNodes = (page, per_page, filters = [], onSuccess = null, onFailure = null) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/admin/ip_addresses/ip_nodes/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadIPAddresses = (page, per_page, filters = [], onSuccess = null, onFailure = null) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/admin/ip_addresses/list/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadAllIPNodes = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/ip_nodes/?page=1&per_page=9999', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateIPNode = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/ip_addresses/ip_nodes/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminGetIPNodeCredentials = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/ip_addresses/ip_nodes/' + guid + '/credentials/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadSharedHostingServers = (page, per_page, filters = [], onSuccess = null, onFailure = null) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/admin/hosting/shared_hosting/servers/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadAllSharedHostingServers = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/hosting/shared_hosting/servers/?page=1&per_page=9999', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadAllProviders = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/ip_nodes/providers/?page=1&per_page=9999', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadIPNode = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/ip_nodes/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateIPNode = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/ip_addresses/ip_nodes/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadIPNodeIPAddresses = (guid, page, per_page, filters, onSuccess = null, onFailure = null) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/admin/ip_addresses/ip_nodes/' + guid + '/ip_addresses/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));

};

export const adminExportIPNodeIPAddresses = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/ip_nodes/' + guid + '/ip_addresses/export/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminUpdateIPAddress = (ip_node_id, ip_address_id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPatch('/admin/ip_addresses/ip_nodes/' + ip_node_id + '/ip_addresses/' + ip_address_id + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminAddNewIPAddresses = (ip_node_id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/ip_addresses/ip_nodes/' + ip_node_id + '/ip_addresses/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminBulkUpdateIPAddresses = (ip_node_id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/ip_addresses/ip_nodes/' + ip_node_id + '/ip_addresses/bulk/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateSharedHostingServer = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/hosting/shared_hosting/servers/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadSharedHostingServer = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/hosting/shared_hosting/servers/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminUpdateSharedHostingServerField = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPatch('/admin/hosting/shared_hosting/servers/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminRunSharedHostingServerAction = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/hosting/shared_hosting/servers/' + guid + '/action/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadSharedHostingServerDomains = (guid, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/hosting/shared_hosting/servers/' + guid + '/domains/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadSharedHostingServerAccounts = (guid, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/hosting/shared_hosting/servers/' + guid + '/accounts/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadSharedHostingServerExistingAccounts = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/hosting/shared_hosting/servers/' + guid + '/existing_accounts/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadPriceGroups = (page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/price_groups/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadAllIPNodeLocations = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/locations/?page=1&per_page=9999', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadAllPriceGroups = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/price_groups/?page=1&per_page=9999', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadPriceGroup = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/price_groups/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreatePriceGroup = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/ip_addresses/price_groups/', data,(res) => onSuccess(res), (err) => onFailure(err));
};

export const adminUpdatePriceGroup = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPatch('/admin/ip_addresses/price_groups/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminUP = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPatch('/admin/ip_addresses/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminIPNodeRunCmd = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/ip_addresses/ip_nodes/' + guid + '/cmd/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminMoveIPAddresses = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/ip_addresses/move/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateIPAddressCheck = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/ip_addresses/ip_nodes/' + guid + '/ip_addresses/check/', {}, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminGetIPAddressCheck = (guid, checkId, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/ip_nodes/' + guid + '/ip_addresses/check/' + checkId+ '/',(res) => onSuccess(res), (err) => onFailure(err));
};

export const adminGetIPAddressLastCheck = (guid,onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/ip_nodes/' + guid + '/ip_addresses/check/last/',(res) => onSuccess(res), (err) => onFailure(err));
};

export const adminStopIPAddressCheck = (guid, checkId, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/ip_addresses/ip_nodes/' + guid + '/ip_addresses/check/' + checkId+ '/stop/', {},(res) => onSuccess(res), (err) => onFailure(err));
};