import React, {Fragment} from "react";
import {connect} from "react-redux";
import {DEFAULT_PER_PAGE} from "../../config";
import {Button, Col, message, Modal, Row, Space, Table} from "antd";
import {displayErrors} from "../../libs/utils";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {adminLoadHostingAccountDomains} from "../../actions/hosting";
import {Link} from "react-router-dom";
import {StatusIndicator} from "../shared/Status";
import {
    CheckSquareOutlined,
    ExclamationCircleOutlined,
    LinkOutlined,
    PlusOutlined,
    SettingOutlined
} from "@ant-design/icons";
import ChangeDomainStatusModal from "./ChangeDomainStatusModal";
import {adminCreateDomainsInCpanel} from "../../actions/domains";

class HostingAccountDomainsTable extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        domains: [],
        loadingDomains: true,
        selectedDomains: []
    }

    componentDidMount() {
        this.loadDomains();
    }

    loadDomains(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingDomains: true });

        this.props.adminLoadHostingAccountDomains(this.props.guid, page, per_page, (res) => {
            window.scrollTo(0, 0);

            this.setState({ domains: res.data.data, loadingDomains: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingDomains: false });
                displayErrors(err.response.data);
            }
        });
    }

    selectDomains(status) {
        let domains = [];

        for(let i = 0; i < this.state.domains.length; i++) {
            if(this.state.domains[i].status === status) {
                domains.push(this.state.domains[i].guid);
            }
        }

        this.setState({ selectedDomains: domains });
    }

    createDomainsInCpanel() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to add the following domains to cPanel?</span>,
            onOk: () => {
                this.setState({ loading: true });

                let data = {
                    domains: this.state.selectedDomains
                };

                this.props.adminCreateDomainsInCpanel(data, () => {
                    message.success('Domain adding tasks successfully created!');
                    this.closeModal(true);
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ loading: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        })
    }

    render() {
        const columns = [
            { title: 'Domain', dataIndex: 'domain', render: (item, record) => <Link to={'/domains/' + record.guid}>{item}</Link>},
            { title: 'IP address', dataIndex: 'ip_address', render: (item) => item.ip_address },
            { title: 'Location', dataIndex: 'ip_address', render: (item) => item.city + ', ' + item.country },
            { title: 'Status', align: 'center', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el={'badge'} /> },
            { title: 'Group', dataIndex: 'group', render: (item) => <Link to={'/domains/' + item.guid}>{item.name}</Link>},
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Deleted', dataIndex: 'date_deleted', render: (item) => <DateTimeFormat value={item} /> },
            { align: 'right', render: (item, record) => <a href={'https://' + record.domain} target='_blank' rel='noopener noreferrer'><Button icon={<LinkOutlined />} size='small'>Visit</Button></a>}
        ];

        return(
            <Fragment>
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <Space>
                            <Button onClick={() => this.selectDomains('ACTIVE')} size='small' icon={<CheckSquareOutlined />}>Select Active</Button>
                            <Button onClick={() => this.selectDomains('DELETED')} size='small' icon={<CheckSquareOutlined />}>Select Deleted</Button>
                            <Button onClick={() => this.setState({ showChangeDomainStatusModel: true })} size='small' icon={<SettingOutlined />}>Change Status</Button>
                            <Button onClick={() => this.createDomainsInCpanel()} size='small' icon={<PlusOutlined />}>Create In cPanel</Button>
                        </Space>
                    </Col>
                    <Col></Col>
                </Row>
                <Table
                    columns={columns}
                    size='small'
                    rowKey={(item) => item.guid}
                    dataSource={this.state.domains}
                    loading={this.state.loadingDomains}
                    pagination={this.state.pagination}
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: this.state.selectedDomains,
                        onChange: (domains) => this.setState({ selectedDomains: domains })
                    }}
                    onChange={(pagination) => this.loadDomains(pagination.current, pagination.pageSize)}
                />
                
                <ChangeDomainStatusModal
                    showModal={this.state.showChangeDomainStatusModel}
                    close={() => this.setState({ showChangeDomainStatusModel: false })}
                    reload={() => this.setState({ selectedDomains: []}, () => this.loadDomains())}
                    selectedDomains={this.state.selectedDomains} />
            </Fragment>
        )
    }
}

export default connect(null, { adminLoadHostingAccountDomains,
    adminCreateDomainsInCpanel })(HostingAccountDomainsTable);