import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, Modal, Select, Space, Tooltip, message} from "antd";
import {displayErrors} from "../../libs/utils";
import {loadAllCompanies} from "../../actions/users";
import {adminCreateNewRefund, adminLoadOwnerTransactions} from "../../actions/billing";
import {RetweetOutlined} from "@ant-design/icons";

class NewRefundModal extends React.Component {
    state = {
        companies: [],
        paymentMethods: [],
        creditTransactions: [],
        creditVouchers: [],
        coupons: [],
        loadingCompanies: false,
        companiesLoaded: false,
        loadingPaymentMethods: false,
        loadingCoupons: false,

        owner: '',
        amount: 0,
        currency: 'USD',
        forceCurrency: false,
        paymentMethodType: 'PAYPAL',
        paymentMethod: '',
        transactionId: '',
        hiddenTransactionId: '',
        creditTransaction: '',
        creditVoucher: '',
        coupon: '',

        transactionType: 'NEW',
        existingTransactionId: '',
        existingTransactions: [],
        loadingExistingTransactions: false
    };

    componentDidMount() {
        if(this.props.currency !== null) {
            this.setState({ currency: this.props.currency, forceCurrency: true });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && this.state.currency !== this.props.currency) {
            this.setState({ currency: this.props.currency, forceCurrency: true });
        }

        if(this.props.showModal && !this.state.loadingCompanies && !this.state.companiesLoaded) {
            if(this.props.invoice === null) {
                this.setState({ loadingCompanies: true });

                this.props.loadAllCompanies((res) => {
                    this.setState({ loadingCompanies: false, companiesLoaded: true, companies: res.data.data });
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ loadingCompanies: false, companiesLoaded: true });
                        displayErrors(err.response.data);
                    }
                });
            }
        }
    }

    closeModal(reload = false) {
        this.setState({
            companies: [],
            paymentMethods: [],
            creditTransactions: [],
            creditVouchers: [],
            coupons: [],
            loadingCompanies: false,
            companiesLoaded: false,
            loadingPaymentMethods: false,
            loadingCoupons: false,

            owner: '',
            amount: 0,
            currency: 'USD',
            forceCurrency: false,
            paymentMethodType: 'PAYPAL',
            paymentMethod: '',
            transactionId: '',
            hiddenTransactionId: '',
            creditTransaction: '',
            creditVoucher: '',
            coupon: '',

            transactionType: 'NEW',
            existingTransactionId: '',
            existingTransactions: [],
            loadingExistingTransactions: false
        });

        this.props.close();

        if(reload) {
            this.props.reloadRefunds();
        }
    }

    adminCreateRefund() {
        let owner = this.state.owner.trim();
        let amount = parseFloat(this.state.amount);
        let currency = this.state.currency.trim();
        let payment_method_type = this.state.paymentMethodType.trim();
        let payment_method = this.state.paymentMethod.trim();
        let transaction_id = this.state.transactionId.trim();
        let hidden_transaction_id = this.state.hiddenTransactionId.trim();

        if(amount === 0) {
            return message.error('Please enter the transaction amount!');
        }

        if(currency.length === 0 && (currency !== 'EUR' || currency !== 'USD')) {
            return message.error('Please enter the transaction currency!');
        }

        if(payment_method_type.length === 0) {
            return message.error('Please enter the transaction payment method type!');
        }

        let data = { amount, currency, payment_method_type };

        if(this.state.transactionType === 'NEW') {
            if(transaction_id.length === 0) {
                return message.error('Please enter the transaction id!');
            } else {
                data['transaction_id'] = transaction_id;
            }
        } else {
            if(this.state.existingTransactionId.length === 0) {
                return message.error('Please select an existing transaction!');
            } else {
                data['existing_transaction'] = this.state.existingTransactionId;
            }
        }

        if(owner.length !== 0 && this.state.invoice === null) {
            data['owner'] = owner;
        }

        if(hidden_transaction_id.length > 0) {
            data['hidden_transaction_id'] = hidden_transaction_id;
        }

        if(payment_method.length > 0) {
            data['payment_method'] = payment_method;
        }

        if(this.props.invoice !== null) {
            data['invoice'] = this.props.invoice;
        }

        this.props.adminCreateNewRefund(data, () => {
            this.setState({ creating: false });
            this.closeModal(true);

            message.success('Transaction successfully created!');

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ creating: false });
                displayErrors(err.response.data);
            }
        });
    }

    setTransactionOwner(owner) {
        this.setState({ owner: owner, loadingPaymentMethods: true  });

        this.props.adminLoadAllPaymentMethods(owner, (res) => {
            this.setState({ loadingPaymentMethods: false, paymentMethods: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingPaymentMethods: false });
                displayErrors(err.response.data);
            }
        });
    }

    async generateTransactionId() {
        const msgBuffer = new TextEncoder().encode(Date.now());
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('').toUpperCase().substring(1, 18);

        this.setState({ transactionId: hashHex });
    }

    setPaymentMethodType(paymentMethodType) {
        this.setState({ paymentMethodType });
    }

    updateTransactionType(value) {
        this.setState({ transactionType: value, loadingExistingTransactions: true });

        if(value === 'EXISTING') {
            this.props.adminLoadOwnerTransactions(this.props.company, (res) => {
                this.setState({ loadingExistingTransactions: false, existingTransactions: res.data });
            }, (err) => {
                this.setState({ loadingExistingTransactions: false });
                displayErrors(err.response.data);
            });
        }
    }

    setExistingTransaction(value) {
        this.setState({ existingTransactionId: value });

        for(let i = 0; i < this.state.existingTransactions.length; i++) {
            if(this.state.existingTransactions[i].id === value) {
                let data = this.state.existingTransactions[i];
                this.setState({ amount: Math.abs(data.amount), paymentMethodType:  data.payment_method_type});
                break;
            }
        }
    }

    render() {
        const { Option } = Select;

        const currencySelector = <Select disabled={this.state.forceCurrency} value={this.state.currency}
                                         onChange={(value) => this.setState({ currency: value })}>
            <Option value='USD'>USD</Option>
            <Option value='EUR'>EUR</Option>
        </Select>;

        const generateTransactionId = <Tooltip title='Generate'><RetweetOutlined onClick={() => this.generateTransactionId()} /></Tooltip>;

        return(
            <Modal
                visible={this.props.showModal}
                destroyOnClose={true}
                footer={<Space>
                    <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Close</Button>
                    <Button type='primary' loading={this.state.loading} disabled={this.state.loading} onClick={() => this.adminCreateRefund()}>Create</Button>
                </Space>}
                onCancel={() => this.closeModal()}
                title='New Refund'>
                <Form layout='vertical'>
                    <Form.Item label='Amount:'>
                        <Input addonAfter={currencySelector} value={this.state.amount}
                               onChange={(e) => this.setState({ amount: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Owner:' hidden={this.state.invoice !== null}>
                        <Select
                            filterOption={(input, option) => {
                                if(typeof option !== 'undefined') {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                                return false;
                            }}
                            loading={this.state.loadingCompanies}
                            disabled={this.state.loadingCompanies}
                            value={this.state.owner}
                            showSearch allowClear
                            onChange={(value) => this.setTransactionOwner(value)}>
                            {this.state.companies.map((company, i) => {
                                return <Option key={i} value={company.guid}>{company.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Payment method type:'>
                        <Select value={this.state.paymentMethodType} onChange={(value) => this.setPaymentMethodType(value)}>
                            <Option value='PAYPAL'>Paypal</Option>
                            <Option value='CARD'>Card</Option>
                            <Option value='CREDIT'>Credit</Option>
                            <Option value='BANK_TRANSFER'>Bank transfer</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='Transaction type:'>
                        <Select value={this.state.transactionType} onChange={(value) => this.updateTransactionType(value)}>
                            <Option value='NEW'>New transaction</Option>
                            <Option value='EXISTING'>Existing transaction</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='Existing transaction:' hidden={this.state.transactionType === 'NEW'}>
                        <Select value={this.state.existingTransactionId}
                                loading={this.state.loadingExistingTransactions}
                                disabled={this.state.loadingExistingTransactions}
                                onChange={(value) => this.setExistingTransaction(value)}>
                            {this.state.existingTransactions.map((transaction, i) => {
                                return <Option key={i} value={transaction.id}>{transaction.id} - {transaction.amount} {transaction.currency} - {transaction.payment_method_type} ({transaction.transaction_id})</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Transaction ID:' hidden={this.state.transactionType === 'EXISTING'}>
                        <Input value={this.state.transactionId} addonAfter={generateTransactionId}
                               onChange={(e) => this.setState({ transactionId: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Hidden transaction ID:' hidden={this.state.transactionType === 'EXISTING'}>
                        <Input value={this.state.hiddenTransactionId}
                               onChange={(e) => this.setState({ hiddenTransactionId: e.target.value })} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { loadAllCompanies, adminCreateNewRefund,
    adminLoadOwnerTransactions })(NewRefundModal);