import React from "react";
import {connect} from "react-redux";
import {Button, Form, Modal, Space, message, Select, Input} from "antd";
import {
    adminAddNewIPAddresses, adminCreateIPNode,
    adminLoadAllIPNodeLocations,
    adminLoadAllPriceGroups,
    adminLoadAllProviders
} from "../../actions/network";
import {displayErrors, randomPassword} from "../../libs/utils";
import {RetweetOutlined} from "@ant-design/icons";

class NewIPNodeModal extends React.Component {
    state = {
        hostname: '',
        ipAddress: '',
        provider: '',
        loadingProviders: false,
        providersLoaded: false,
        providers: [],
        location: '',
        locations: [],
        user: 'root',
        password: randomPassword(),
        loadingLocations: false,
        locationsLoaded: false,
        cost: '',
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingProviders && !this.state.providersLoaded) {
            this.adminLoadAllProviders();
            this.adminLoadAllLocations();
        }
    }

    adminLoadAllProviders() {
        this.setState({ loadingProviders: true });

        this.props.adminLoadAllProviders((res) => {
            this.setState({ loadingProviders: false, providersLoaded: true, providers: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingProviders: false, providersLoaded: true });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadAllLocations() {
        this.setState({ loadingLocations: true });

        this.props.adminLoadAllIPNodeLocations((res) => {
            this.setState({ loadingLocations: false, locationsLoaded: true, locations: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingLocations: false, locationsLoaded: true });
                displayErrors(err.response.data);
            }
        });
    }

    closeModal(reload = false) {
        this.setState({
            hostname: '',
            ipAddress: '',
            provider: '',
            loadingProviders: false,
            providersLoaded: false,
            providers: [],
            location: '',
            locations: [],
            user: 'root',
            password: randomPassword(),
            loadingLocations: false,
            locationsLoaded: false,
            cost: '',
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    saveIPNode() {
        let hostname = this.state.hostname;
        let ip_address = this.state.ipAddress;
        let provider = this.state.provider;
        let location = this.state.location;
        let user = this.state.user;
        let password = this.state.password;
        let cost = this.state.cost;

        if(hostname.length === 0) {
            return message.error('Please enter hostname!');
        }

        if(ip_address.length === 0) {
            return message.error('Please enter IP address!');
        }

        if(provider.length === 0) {
            return message.error('Please select a provider!');
        }

        if(location.length === 0) {
            return message.error('Please select a location!');
        }

        if(user.length === 0) {
            return message.error('Please enter user!');
        }

        if(password.length === 0) {
            return message.error('Please enter password!');
        }

        if(cost.length === 0) {
            return message.error('Please enter cost!');
        }

        let data = { hostname, ip_address, provider, location, user, password, cost };

        this.setState({ saving: true });

        this.props.adminCreateIPNode(data, (res) => {
            this.setState({ saving: false });
            message.success('IP node successfully added!');
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ saving: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        return(
            <Modal title='Add IP Node'
                   visible={this.props.showModal}
                   onCancel={() => this.closeModal()}
                   footer={<Space>
                       <Button disabled={this.state.saving} onClick={() => this.closeModal()}>Close</Button>
                       <Button disabled={this.state.saving} loading={this.state.saving}  type='primary' onClick={() => this.saveIPNode()}>Add</Button>
                   </Space>}>
                <Form layout='vertical'>
                    <Form.Item label='Hostname'>
                        <Input value={this.state.hostname} onChange={(e) => this.setState({ hostname: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='IP address'>
                        <Input value={this.state.ipAddress} onChange={(e) => this.setState({ ipAddress: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Provider'>
                        <Select
                            filterOption={(input, option) => {
                                if(typeof option !== 'undefined') {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                                return false;
                            }}
                            loading={this.state.loadingProviders}
                            disabled={this.state.loadingProviders}
                            value={this.state.provider}
                            showSearch allowClear
                            onChange={(value) => this.setState({ provider: value })}>
                            {this.state.providers.map((provider, i) => {
                                return <Option key={i} value={provider.guid}>{provider.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Location'>
                        <Select
                            filterOption={(input, option) => {
                                if(typeof option !== 'undefined') {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                                return false;
                            }}
                            loading={this.state.loadingLocations}
                            disabled={this.state.loadingLocations}
                            value={this.state.location}
                            showSearch allowClear
                            onChange={(value) => this.setState({ location: value })}>
                            {this.state.locations.map((location, i) => {
                                return <Option key={i} value={location.guid}>{location.city + ', ' + location.country_name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label='SSH user'>
                        <Input value={this.state.user} onChange={(e) => this.setState({ user: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='SSH password'>
                        <Input value={this.state.password}
                               onChange={(e) => this.setState({ password: e.target.value })}
                               suffix={<RetweetOutlined onClick={() => this.setState({ password: randomPassword() })} />} />
                    </Form.Item>
                    <Form.Item label='Server cost'>
                        <Input value={this.state.cost} onChange={(e) => this.setState({ cost: e.target.value })} />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { adminLoadAllPriceGroups, adminAddNewIPAddresses,
    adminLoadAllProviders, adminLoadAllIPNodeLocations, adminCreateIPNode })(NewIPNodeModal);