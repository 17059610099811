import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Col, Divider, Form, Row, Select, Space, Table} from "antd";
import {adminBillingMonthlyGroupedInvoices, adminBillingReportsMonthlyGrouped} from "../../actions/accounting";
import {displayErrors} from "../../libs/utils";
import {Link} from "react-router-dom";
import {MoneyField} from "../shared/MoneyField";
import {StatusIndicator} from "../shared/Status";
import {DateFormat} from "../shared/DateFormat";

class MonthlyGroupedReport extends React.Component {
    state = {
        lines: [],
        selectedYear: 0,
        selectedMonth: 0,
        generating: false,
        loadingInvoices: false,
        invoices: [],
        activeAccount: null,
    };

    componentDidMount() {
        this.props.updatePage('Monthly Grouped');

        let date = new Date();
        this.setState({ selectedYear: date.getFullYear(), selectedMonth: date.getMonth() });
    }

    generateReport() {
        this.setState({ generating: true });

        this.props.adminBillingReportsMonthlyGrouped(this.state.selectedMonth, this.state.selectedYear, (res) => {
            this.setState({ generating: false, lines: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ generating: false });
                displayErrors(err.response.data);
            }
        });
    }

    loadDetails(record) {
        this.setState({ activeAccount: record.row_id, loadingInvoices: true, invoices: [], loadingRefunds: true, refunds: [] });

        this.props.adminBillingMonthlyGroupedInvoices(record.company_id, record.name, record.currency, record.vat_id, this.state.selectedMonth, this.state.selectedYear, (res) => {
            this.setState({ invoices: res.data.invoices, refunds: res.data.refunds, loadingInvoices: false, loadingRefunds: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingInvoices: false, invoices: [], loadingRefunds: true, refunds: [] });
            }
        });
    }

    copyToClipboard(columns) {
        let lines = []
        let columnLines = [];

        for(let i = 0; i < columns.length; i++) {
            columnLines.push(columns[i].title);
        }

        lines.push(columnLines.join('\t'));

        let eurAmount = 0;
        let usdAmount = 0;
        let eurTax = 0;
        let usdTax = 0;
        let eurPaid = 0;
        let usdPaid = 0;
        let eurRefunds = 0;
        let usdRefunds = 0;

        for(let i = 0; i < this.state.lines.length; i++) {
            let tmpItems = [];

            let keys = Object.keys(this.state.lines[i]);

            for(let j = 0; j < keys.length; j++) {
                if(keys[j] !== 'row_id') {
                    tmpItems.push(this.state.lines[i][keys[j]]);

                    if(keys[j] === 'currency' && this.state.lines[i][keys[j]] === 'EUR') {
                        eurAmount += this.state.lines[i].amount;
                        eurTax += this.state.lines[i].tax_amount;
                        eurPaid += this.state.lines[i].paid;
                        eurRefunds += this.state.lines[i].refunds;
                    } else if(keys[j] === 'currency' && this.state.lines[i][keys[j]] === 'USD') {
                        usdAmount += this.state.lines[i].amount;
                        usdTax += this.state.lines[i].tax_amount;
                        usdPaid += this.state.lines[i].paid;
                        usdRefunds += this.state.lines[i].refunds;
                    }
                }
            }

            lines.push(tmpItems.join('\t'));
        }

        lines.push('');
        lines.push('\t\t\t\t' + usdTax + '\tUSD\t\t' + usdAmount + '\t' + (usdAmount + usdTax) + '\t' + usdPaid + '\t' + usdRefunds);
        lines.push('\t\t\t\t' + eurTax + '\tEUR\t\t' + eurAmount + '\t' + (eurAmount + eurTax) + '\t' + eurPaid + '\t' + eurRefunds);

        let tmpInput = document.createElement('textarea');
        tmpInput.id = 'tmpInput';
        tmpInput.innerHTML= lines.join('\n');

        document.body.append(tmpInput);

        tmpInput.select();
        document.execCommand("copy");
        tmpInput.remove();
    }

    render() {
        const { Option } = Select;

        let years = [];
        for(let i = 2016; i <= new Date().getFullYear(); i++) {
            years.push(i);
        }

        let months = [];
        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August",
            "September", "October", "November", "December"];
        for(let i = 1; i <= 12; i++) {
            months.push(i);
        }

        const reportColumns = [
            { title: 'VAT ID', dataIndex: 'vat_id' },
            { title: 'ID', width: '30px', align: 'center', dataIndex: 'company_id' },
            { title: 'Name', dataIndex: 'name' },
            { title: 'Invoices', width: '30px', align: 'center', dataIndex: 'invoice_count' },
            { title: 'VAT Amount', align: 'center', dataIndex: 'tax_amount' },
            { title: 'Currency', align: 'center', dataIndex: 'currency' },
            { title: 'VAT rate', align: 'center', dataIndex: 'tax_rate' },
            { title: 'Amount', align: 'center', dataIndex: 'amount' },
            { title: 'Total Combined Amount', align: 'center', dataIndex: 'combined_total_amount' },
            { title: 'Total Paid Amount', align: 'center', dataIndex: 'paid' },
            { title: 'Total Refunded', align: 'center', dataIndex: 'refunds' },
            { title: 'Country', dataIndex: 'country' },
            { title: 'ISO', align: 'center', dataIndex: 'country_code' },
        ];

        const invoiceColumns = [
            { title: 'Invoice ID', dataIndex: 'invoice_id', render: (item) => <Link target='_blank' to={'/billing/invoices/' + item}>#{item}</Link> },
            { title: 'Amount', align: 'center', dataIndex: 'amount', render: (item, record) => <MoneyField amount={item} currency={record.currency} />},
            { title: 'Tax', align: 'center', dataIndex: 'tax', render: (item, record) => <MoneyField amount={item} currency={record.currency} />},
            { title: 'Status', align: 'center', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' /> },
            { title: 'Created', dataIndex: 'date_created', render: (item, record) => <DateFormat value={record.date_created} /> }
        ];

        const refundColumns = [
            { title: 'Refund ID', dataIndex: 'refund_id'},
            { title: 'Amount', align: 'center', dataIndex: 'amount', render: (item, record) => <MoneyField amount={item} currency={record.currency} />},
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateFormat value={item} /> }
        ];

        return(
            <Fragment>
                <Form layout='vertical'>
                    <Row gutter={[16, 16]}>
                        <Col span={3}>
                            <Form.Item label='Select month:'>
                                <Select value={this.state.selectedMonth} onChange={(value) => this.setState({ selectedMonth: value })}>
                                    {months.map((month, i) => {
                                        return <Option key={i} value={month}>{monthNames[month-1]}</Option>;
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item label='Select year:'>
                                <Select value={this.state.selectedYear} onChange={(value) => this.setState({ selectedYear: value })}>
                                    {years.map((year, i) => {
                                        return <Option key={i} value={year}>{year}</Option>;
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Space>
                                <Form.Item label='&nbsp;'>
                                    <Button type='primary' disabled={this.state.generating} loading={this.state.generating}
                                            onClick={() => this.generateReport()}>Generate</Button>
                                </Form.Item>
                                <Form.Item label='&nbsp;'>
                                    <Button type='primary' onClick={() => this.copyToClipboard(reportColumns)}>Copy</Button>
                                </Form.Item>
                            </Space>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col span={24}>
                            <Table dataSource={this.state.lines}
                                   columns={reportColumns} pagination={false}
                                   rowKey={(item) => item.row_id}
                                   summary={(records) => {
                                       let eurAmount = 0;
                                       let usdAmount = 0;
                                       let eurTax = 0;
                                       let usdTax = 0;
                                       let eurPaid = 0;
                                       let usdPaid = 0;
                                       let eurRefunds = 0;
                                       let usdRefunds = 0;

                                       for(let i = 0; i < records.length; i++) {
                                           if(records[i].currency === 'EUR') {
                                                eurAmount += records[i].amount;
                                                eurTax += records[i].tax_amount;
                                                eurPaid += records[i].paid;
                                                eurRefunds += records[i].refunds;
                                           } else if(records[i].currency === 'USD') {
                                               usdAmount += records[i].amount;
                                               usdTax += records[i].tax_amount;
                                               usdPaid += records[i].paid;
                                               usdRefunds += records[i].refunds;
                                           }
                                       }

                                       return <Fragment>
                                           <Table.Summary.Row>
                                               <Table.Summary.Cell colSpan={5} />
                                               <Table.Summary.Cell align='center'>
                                                   <strong><MoneyField amount={usdTax} currency='USD' /></strong>
                                               </Table.Summary.Cell>
                                               <Table.Summary.Cell align='center'>
                                                   <strong>USD</strong>
                                               </Table.Summary.Cell>
                                               <Table.Summary.Cell />
                                               <Table.Summary.Cell align='center'>
                                                   <strong><MoneyField amount={usdAmount} currency={'USD'} /></strong>
                                               </Table.Summary.Cell>
                                               <Table.Summary.Cell align='center'>
                                                   <strong><MoneyField amount={usdAmount + usdTax} currency={'USD'} /></strong>
                                               </Table.Summary.Cell>
                                               <Table.Summary.Cell align='center'>
                                                   <strong><MoneyField amount={usdPaid} currency={'USD'} /></strong>
                                               </Table.Summary.Cell>
                                               <Table.Summary.Cell align='center'>
                                                   <strong><MoneyField amount={usdRefunds} currency={'USD'} /></strong>
                                               </Table.Summary.Cell>
                                               <Table.Summary.Cell colSpan={3} />
                                           </Table.Summary.Row>
                                           <Table.Summary.Row>
                                               <Table.Summary.Cell colSpan={5} />
                                               <Table.Summary.Cell align='center'>
                                                   <strong><MoneyField amount={eurTax} currency='EUR' /></strong>
                                               </Table.Summary.Cell>
                                               <Table.Summary.Cell align='center'>
                                                   <strong>EUR</strong>
                                               </Table.Summary.Cell>
                                               <Table.Summary.Cell />
                                               <Table.Summary.Cell align='center'>
                                                   <strong><MoneyField amount={eurAmount} currency='EUR' /></strong>
                                               </Table.Summary.Cell>
                                               <Table.Summary.Cell align='center'>
                                                   <strong><MoneyField amount={eurAmount + eurTax} currency='EUR' /></strong>
                                               </Table.Summary.Cell>
                                               <Table.Summary.Cell align='center'>
                                                   <strong><MoneyField amount={eurPaid} currency='EUR' /></strong>
                                               </Table.Summary.Cell>
                                               <Table.Summary.Cell align='center'>
                                                   <strong><MoneyField amount={eurRefunds} currency='EUR' /></strong>
                                               </Table.Summary.Cell>
                                               <Table.Summary.Cell colSpan={3} />
                                           </Table.Summary.Row>
                                       </Fragment>;
                                   }}
                                   expandable={{
                                       expandedRowRender: () => {
                                           return <Fragment>
                                               <Table
                                                   columns={invoiceColumns} bordered={true}
                                                   size='small'
                                                   rowKey={(item) => item.invoice_id}
                                                   summary={(records) => {
                                                       let totalAmount = 0;
                                                       let totalTax = 0;
                                                       let currency = 'USD';

                                                       for(let i = 0; i < records.length; i++) {
                                                           if(records[i].status !== 'CANCELLED') {
                                                               totalAmount += records[i].amount;
                                                               totalTax += records[i].tax;
                                                           }
                                                       }

                                                       if(totalAmount > 0) {
                                                           currency = records[0].currency;
                                                       }

                                                       return <Fragment>
                                                           <Table.Summary.Row>
                                                               <Table.Summary.Cell />
                                                               <Table.Summary.Cell align='center'>
                                                                   <strong><MoneyField amount={totalAmount} currency={currency} /></strong>
                                                               </Table.Summary.Cell>
                                                               <Table.Summary.Cell align='center'>
                                                                   <strong><MoneyField amount={totalTax} currency={currency} /></strong>
                                                               </Table.Summary.Cell>
                                                               <Table.Summary.Cell colSpan={2} />
                                                           </Table.Summary.Row>
                                                           <Table.Summary.Row>
                                                               <Table.Summary.Cell />
                                                               <Table.Summary.Cell colSpan={2} align='center'>
                                                                   <strong><MoneyField amount={totalAmount + totalTax} currency={currency} /></strong>
                                                               </Table.Summary.Cell>
                                                               <Table.Summary.Cell colSpan={2} />
                                                           </Table.Summary.Row>
                                                       </Fragment>;
                                                   }}
                                                   dataSource={this.state.invoices}
                                                   loading={this.state.loadingInvoices}
                                                   pagination={false}
                                               />
                                               <Divider />
                                               {this.state.refunds.length > 0 ? <Table
                                                   columns={refundColumns} bordered={true}
                                                   size='small'
                                                   rowKey={(item) => item.refund_id}
                                                   summary={(records) => {
                                                       let totalAmount = 0;
                                                       let currency = 'USD';

                                                       for(let i = 0; i < records.length; i++) {
                                                           totalAmount += records[i].amount;
                                                       }

                                                       if(totalAmount > 0) {
                                                           currency = records[0].currency;
                                                       }

                                                       return <Table.Summary.Row>
                                                           <Table.Summary.Cell />
                                                           <Table.Summary.Cell align='center'>
                                                               <strong><MoneyField amount={totalAmount} currency={currency} /></strong>
                                                           </Table.Summary.Cell>
                                                           <Table.Summary.Cell />
                                                       </Table.Summary.Row>;
                                                   }}
                                                   dataSource={this.state.refunds}
                                                   loading={this.state.loadingRefunds}
                                                   pagination={false}
                                               /> : ''}

                                           </Fragment>;
                                       },
                                       onExpand: (open, record) => {
                                           if(open) {
                                               this.loadDetails(record);
                                           } else {
                                               this.setState({ activeAccount: null, loadingInvoices: false });
                                           }
                                       },
                                       expandedRowKeys: [this.state.activeAccount],
                                       rowExpandable: () => true,
                                   }}
                                   size='small' />
                        </Col>
                    </Row>
                </Form>
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminBillingReportsMonthlyGrouped,
    adminBillingMonthlyGroupedInvoices })(MonthlyGroupedReport);