import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, Modal, Select, Space} from "antd";
import {normalizeEnum} from "../../libs/utils";

class MessagesFilterModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFilter: 'ID',
            filterValue: '',
            filterKey: 'id',
            filterVisible: null,

            loadingCompanies: false,
            companiesLoaded: false,
            companies: [],
            domainGroups: [],
            loadingDomainGroups: false,
            selectedCompany: ''
        };
    }

    onChange(){
        let visibleValue = null;

        this.props.onChange({
            name: normalizeEnum(this.state.selectedFilter),
            key: this.state.filterKey,
            value: this.state.filterValue.trim(),
            visible: visibleValue
        });
    }

    closeModal(apply = false, close = true) {
        this.setState({
            selectedFilter: 'ID',
            filterValue: '',
            filterKey: 'id',
            filterVisible: null,
        });

        if(close) {
            this.props.closeModal();
        }

        if(apply) {
            this.onChange();
        }
    }

    setFilter(name) {
        const filters = {
            ID: { key: 'id', value: '' },
            MESSAGE_TYPE: { key: 'message_type', value: '' },
            CONTENT: { key: 'content', value: '' },
        };

        this.setState({ selectedFilter: name, filterKey: filters[name].key, filterValue: filters[name].value });
    }

    render() {
        const { Option } = Select;

        let content = '';

        if(this.state.selectedFilter === 'MESSAGE_TYPE') {
            content = <Select onChange={(value) => this.setState({ filterValue: value })}>
                <Option value='ERROR'>Error</Option>
            </Select>
        } else if(this.state.selectedFilter === 'ID' || this.state.selectedFilter === 'CONTENT') {
            content = <Input value={this.state.filterValue}
                             onChange={(e) => this.setState({filterValue: e.target.value})}/>
        }

        return(
            <Modal
                title='Add Filter'
                destroyOnClose={true}
                onCancel={() => this.closeModal()}
                footer={<Space>
                    <Button onClick={() => this.closeModal()}>Close</Button>
                    <Button onClick={() => this.closeModal(true, false)}>Apply</Button>
                    <Button type='primary' onClick={() => this.closeModal(true, true)}>Apply & Close</Button>
                </Space>}
                visible={this.props.showModal}>
                <Form layout='vertical'>
                    <Form.Item label='Select filter:'>
                        <Select value={this.state.selectedFilter} onChange={(value) => this.setFilter(value)}>
                            <Option value='ID'>ID</Option>
                            <Option value='MESSAGE_TYPE'>Message type</Option>
                            <Option value='CONTENT'>Content</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        {content}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { })(MessagesFilterModal);