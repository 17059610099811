import React from "react";
import {Button, Form, Modal, Select, Space, message} from "antd";
import {connect} from "react-redux";
import {submitBulkOrderActions} from "../../actions/billing";
import {displayErrors} from "../../libs/utils";

class OrdersBulkActionsModal extends React.Component {
    state = {
        selectedAction: '',
        selectedValue: null,
        loading: false
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    closeModal(reload = false) {
        this.setState({
            selectedAction: '',
            selectedValue: null,
            loading: false
        });

        this.props.close();

        if(reload) {
            this.props.reloadOrders();
        }
    }

    performAction() {
        let action = this.state.selectedAction.trim();

        if(action.length === 0) {
            return message.error('Please select an action!');
        }

        let data = {
            orders: this.props.selectedOrders,
            action: this.state.selectedAction,
            value: this.state.selectedValue
        };

        this.setState({ loading: true });

        this.props.submitBulkOrderActions(data, (res) => {
            this.closeModal(true);

            message.success('Action successfully performed!');

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        let additionalValue = '';

        if(this.state.selectedAction === 'CHANGE_BILLING_CYCLE') {
            additionalValue = <Form.Item label='Select billing cycle'>
                <Select key={this.state.selectedAction} onChange={(value) => this.setState({ selectedValue: value })}>
                    <Option value='MONTHLY'>Monthly</Option>
                    <Option value='QUARTERLY'>Quarterly (3 months)</Option>
                    <Option value='SEMIANNUALLY'>Semi-annually (6 months)</Option>
                    <Option value='ANNUALLY'>Annually (12 months)</Option>
                </Select>
            </Form.Item>;
        } else if(this.state.selectedAction === 'TOGGLE_DO_NOT_SUSPEND') {
            additionalValue = <Form.Item label='Select state'>
                <Select key={this.state.selectedAction} onChange={(value) => this.setState({ selectedValue: value })}>
                    <Option value='ENABLED'>Enabled</Option>
                    <Option value='DISABLED'>Disabled</Option>
                </Select>
            </Form.Item>;
        } else if(this.state.selectedAction === 'TOGGLE_SUSPENSION') {
            additionalValue = <Form.Item label='Select state'>
                <Select key={this.state.selectedAction} onChange={(value) => this.setState({ selectedValue: value })}>
                    <Option value='SUSPEND'>Suspend</Option>
                    <Option value='UNSUSPEND'>Unsuspend</Option>
                </Select>
            </Form.Item>;
        } else if(this.state.selectedAction === 'CANCEL') {
            additionalValue = <Form.Item label='Select cancellation time'>
                <Select key={this.state.selectedAction} onChange={(value) => this.setState({ selectedValue: value })}>
                    <Option value='IMMEDIATE'>Immediate</Option>
                    <Option value='END_OF_BILLING_CYCLE'>End of billing cycle</Option>
                </Select>
            </Form.Item>;
        }

        return(
            <Modal
                destroyOnClose={true}
                title='Actions'
                visible={this.props.showModal}
                footer={<Space>
                    <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Close</Button>
                    <Button disabled={this.state.loading} loading={this.state.loading}
                            onClick={() => this.performAction()} type='primary'>Submit</Button>
                </Space>}
                onCancel={() => this.closeModal()}>
                <Form layout='vertical'>
                    <Form.Item label='Select action'>
                        <Select onChange={(value) => this.setState({ selectedAction: value, selectedValue: null })}>
                            <Option value='PROCESS'>Process</Option>
                            <Option value='TOGGLE_SUSPENSION'>Toggle suspension</Option>
                            <Option value='CHANGE_BILLING_CYCLE'>Change billing cycle</Option>
                            <Option value='TOGGLE_DO_NOT_SUSPEND'>Toggle "Do not suspend"</Option>
                            <Option value='CANCEL'>Cancel</Option>
                        </Select>
                    </Form.Item>
                    {additionalValue}
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { submitBulkOrderActions })(OrdersBulkActionsModal);