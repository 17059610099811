import {USER_LOADED, USER_LOADING, AUTH_ERROR, LOGIN_IN_PROGRESS, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_FAILURE, LOGOUT_SUCCESS, LOGOUT_IN_PROGRESS} from '../actions/types';

const initialState = {
    token: localStorage.getItem('token'),
    isAuthenticated: false,
    isLoading: true,
    user: null
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch(action.type) {
        case LOGIN_SUCCESS:

            localStorage.setItem('token', action.payload.token);

            return {
                ...state,
                user: action.payload,
                isAuthenticated: true,
                isLoading: false
            };
        case USER_LOADING:

            let isAuthenticated = false;

            if(state.token)
            {
                isAuthenticated = true;
            }

            return {
                ...state,
                isAuthenticated: isAuthenticated,
                isLoading: true
            };
        case USER_LOADED:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: true,
                user: action.payload
            };
        case LOGIN_IN_PROGRESS:
            return {
                ...state,
                isLoading: true
            };
        case LOGOUT_IN_PROGRESS:
            return {
                ...state,
                isLoading: true
            };
        case LOGIN_FAILURE:
        case AUTH_ERROR:
        case LOGOUT_FAILURE:
        case LOGOUT_SUCCESS:
            localStorage.removeItem('token');

            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                user: null,
                token: null
            };
        default:
            return state;
    }
}