import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, Modal, Select, Space, message} from "antd";
import {adminNewConfigurationFile} from "../../actions/system";
import {displayErrors} from "../../libs/utils";

class NewConfigurationFileModal extends React.Component {
    state = {
        loading: false,
        name: '',
        fileType: ''
    };

    closeModal(reload = false) {
        this.setState({
            loading: false,
            name: '',
            fileType: ''
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    createFile() {
        this.setState({ loading: true });

        let name = this.state.name.trim();
        let file_type = this.state.fileType.trim();

        if(name.length === 0) {
            return message.error('Please enter the name!');
        }

        if(file_type.length === 0) {
            return message.error('Please enter the file type!');
        }

        let data = { name, file_type };

        this.props.adminNewConfigurationFile(data, () => {
            message.success('File successfully created!');
            this.setState({ loading: false });
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        return(
            <Modal visible={this.props.show}
                   title='New File'
                   onCancel={() => this.closeModal()}
                   footer={<Space>
                       <Button onClick={() => this.closeModal()} disabled={this.state.loading}>Close</Button>
                       <Button type='primary' onClick={() => this.createFile()} loading={this.state.loading} disabled={this.state.loading}>Create</Button>
                   </Space>}>
                <Form layout='vertical'>
                   <Form.Item label='Name'>
                       <Input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                   </Form.Item>
                   <Form.Item label='Type'>
                       <Select value={this.state.fileType} onChange={(value) => this.setState({ fileType: value })}>
                           <Option value='.txt'>Text</Option>
                           <Option value='.json'>JSON</Option>
                           <Option value='.conf'>Conf</Option>
                           <Option value='.ini'>Ini</Option>
                           <Option value='.sh'>Sh</Option>
                           <Option value='.html'>HTML</Option>
                           <Option value='.cfg'>Cfg</Option>
                           <Option value='.lua'>Lua</Option>
                           <Option value='.py'>Python</Option>
                       </Select>
                   </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { adminNewConfigurationFile })(NewConfigurationFileModal);