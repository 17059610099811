import React from "react";
import {connect} from "react-redux";
import {Button, Form, Modal, Space, message, Select} from "antd";
import {adminBulkUpdateIPAddresses, adminLoadAllPriceGroups} from "../../actions/network";
import {displayErrors} from "../../libs/utils";

class SetPriceGroupModal extends React.Component {
    state = {
        priceGroups: [],
        loadingPriceGroups: false,
        priceGroupsLoaded: false,
        priceGroup: '',
        saving: false,
        groupType: 'PRICE_GROUP',
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingPriceGroups && !this.state.priceGroupsLoaded) {
            this.adminLoadAvailablePriceGroups();
        }
    }

    closeModal(reload = false) {
        this.setState({
            priceGroups: [],
            loadingPriceGroups: false,
            priceGroupsLoaded: false,
            priceGroup: '',
            saving: false,
            groupType: 'PRICE_GROUP',
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    adminLoadAvailablePriceGroups() {
        this.setState({ loadingPriceGroups: true });

        this.props.adminLoadAllPriceGroups((res) => {
            this.setState({ priceGroups: res.data.data, priceGroupsLoaded: true, loadingPriceGroups: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ priceGroupsLoaded: true, loadingPriceGroups: false });
                displayErrors(err.response.data);
            }
        });
    }

    updateIPAddresses() {
        this.setState({ saving: true });

        let price_group = this.state.priceGroup.trim();

        if(price_group.length === 0) {
            return message.error('Please select a price group!');
        }

        let data = {
            ip_addresses: this.props.selectedIPAddresses,
        };

        if(this.state.groupType === 'PRICE_GROUP') {
            data['price_group'] = this.state.priceGroup;
        } else if(this.state.groupType === 'DEFAULT_PRICE_GROUP') {
            data['default_price_group'] = this.state.priceGroup;
        }

        this.props.adminBulkUpdateIPAddresses(this.props.ipNode, data,  (res) => {
            this.setState({ saving: false });
            message.success('IP addresses successfully updated!');
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ saving: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        return(
            <Modal title={'Set price group for ' + this.props.selectedIPAddresses.length + ' IP address' + (this.props.selectedIPAddresses.length > 1 ? 'es' : '')}
                   visible={this.props.showModal}
                   onCancel={() => this.closeModal()}
                   footer={<Space>
                       <Button disabled={this.state.saving} onClick={() => this.closeModal()}>Close</Button>
                       <Button disabled={this.state.saving} loading={this.state.saving}  type='primary' onClick={() => this.updateIPAddresses()}>Update</Button>
                   </Space>}>
                <Form layout='vertical'>
                    <Form.Item label='Group type'>
                        <Select value={this.state.groupType} onChange={(value) => this.setState({ groupType: value })}>
                            <Option value='PRICE_GROUP'>Price group</Option>
                            <Option value='DEFAULT_PRICE_GROUP'>Default price group</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label='Price group'>
                        <Select loading={this.state.loadingPriceGroups} disabled={this.state.loadingPriceGroups}
                                showSearch filterOption={(input, option) => {
                                    if(typeof option !== 'undefined') {
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }
                                    return false;
                                }}
                                value={this.state.priceGroup} onChange={(value) => this.setState({ priceGroup: value })}>
                            {this.state.priceGroups.map((group) => <Option key={group.guid} value={group.guid}>{group.name}</Option>)}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { adminLoadAllPriceGroups, adminBulkUpdateIPAddresses })(SetPriceGroupModal);