import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_PER_PAGE} from "../../config";
import {Button, Col, message, Modal, Pagination, Row, Space, Table, Tag, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import DomainsTableFilter from "../../components/domains/DomainsTableFilter";
import {StatusIndicator} from "../../components/shared/Status";
import {Link} from "react-router-dom";
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    FilterOutlined,
    LinkOutlined,
    RetweetOutlined,
    GlobalOutlined
} from "@ant-design/icons";
import {adminBulkResetDomainsDNSZone, adminLoadDomains} from "../../actions/domains";
import qs from "qs";
import DomainStatusChangeModal from "../../components/domains/DomainStatusChangeModal";
import {DateTimeFormat} from "../../components/shared/DateTimeFormat";

class Domains extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        domains: [],
        loadingDomains: true,
        filters: [],
        showFiltersModal: false,
        selectedDomains: [],
        showChangeDomainStatusModal: false
    }

    componentDidMount() {
        this.props.updatePage('Domains');

        let queryString = qs.parse(window.location.search.substring(1));

        let filters = [];

        if('group' in queryString) {
            filters.push({
                name: 'Group',
                key: 'group',
                value: queryString['group'],
                visible: null
            });
        } else if('ip_address' in queryString) {
            filters.push({
                name: 'IP address',
                key: 'ip_address',
                value: queryString['ip_address'],
                visible: null
            });
        }

        if(filters.length === 0) {
            this.adminLoadDomains();
        } else {
            this.setState({ filters }, () => this.adminLoadDomains());
        }
    }

    adminLoadDomains(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingDomains: true });

        this.props.adminLoadDomains(page, per_page, this.state.filters, (res) => {
            window.scrollTo(0, 0);

            this.setState({ domains: res.data.data, loadingDomains: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingDomains: false });
                displayErrors(err.response.data);
            }
        });
    }

    clearFilters() {
        this.setState({ filters: [] }, () => this.adminLoadDomains());
    }

    setFilter(filter) {
        let filters = this.state.filters.filter(f => f.key !== filter.key);
        filters.push(filter);

        this.setState({ filters }, () => this.adminLoadDomains());
    }

    removeFilter(item) {
        let filters = this.state.filters.filter(filter => filter.key !== item.key);
        this.setState({ filters }, () => this.adminLoadDomains());
    }

    showFilters() {
        return this.state.filters.map((filter, i) => {
            let value;

            if (typeof filter.value === 'boolean') {
                if (filter.value) {
                    value = 'Yes';
                } else {
                    value = 'No';
                }
            } else {
                if (filter.visible !== null) {
                    value = filter.visible;
                } else {
                    value = normalizeEnum(filter.value);
                }
            }

            return <Tag key={i} closable onClose={() => this.removeFilter(filter)}>{filter.name}: {value}</Tag>;
        });
    }

    setGroupFilter(record, e) {
        e.target.parentElement.blur();

        let filters = this.state.filters.filter(filter => filter.key !== 'group');

        filters.push({
            name: 'Group',
            key: 'group',
            value: record.group.guid,
            visible: record.group.name
        });

        this.setState({ filters }, () => this.adminLoadDomains());
    }

    adminResetDNS() {
        const {confirm} = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            content: <span>Are you sure you wish reset DNS zones for <strong>{this.state.selectedDomains.length}</strong> domain(s)?</span>,
            onOk: () => {
                this.setState({ loadingDomains: true });

                this.props.adminBulkResetDomainsDNSZone({ domains: this.state.selectedDomains }, () => {
                    this.setState({ loadingDomains: false, selectedDomains: [] });
                    message.success('DNS zones successfully reset!');
                    this.adminLoadDomains();
                }, (err) => {
                    if (typeof err.response !== 'undefined') {
                        this.setState({ loadingDomains: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    render() {
        const columns = [
            { title: 'ID', dataIndex: 'id', width: '30px' },
            { title: 'Domain', dataIndex: 'domain', render: (item, record) => <Link to={'/domains/' + record.guid}>{item}</Link> },
            { title: 'Domain Status', align: 'center', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />},
            { title: 'IP address', dataIndex: 'ip_address', render: (item) => item.ip_address },
            { title: 'IP Status', dataIndex: 'ip_address', render: (item) => <StatusIndicator status={item.status} el='badge' /> },
            { title: 'IP node', dataIndex: 'ip_address', render: (item) => <Link to={'/network/ip-nodes/' + item.ip_node.guid}>{item.ip_node.hostname.replace('.gateway.prioritynap.net', '')}</Link> },
            { title: 'Group', dataIndex: 'group', render: (item, record) => <Button size='small' type='text' onClick={(e) => this.setGroupFilter(record, e)}>{item.name}</Button> },
            { title: 'Owner', dataIndex: 'group', render: (item) => <Link to={'/accounts/companies/' + item.owner.guid}>{item.owner.name}</Link> },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: '', align: 'right', render: (item, record) => {
                return <Space>
                    <a href={'http://' + record.domain} target='_blank' rel='noopener noreferrer'><Button size='small' icon={<LinkOutlined />}>Visit</Button></a>
                    <Link to={'/domains/' + record.guid}><Button size='small' type='round' icon={<EyeOutlined />}>View</Button></Link>
                </Space>;
            }}
        ];

        return(
            <Fragment>
                <p>{this.showFilters()}</p>
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <Space>
                            <Button size='small' type='text' icon={<FilterOutlined />} onClick={() => this.setState({ showFiltersModal: true })}>Filters</Button>
                            <Button size='small' type='text' disabled={this.state.filters.length === 0} onClick={() => this.clearFilters()} icon={<DeleteOutlined />}>Clear Filters</Button>
                            <Button size='small' type='text' disabled={this.state.selectedDomains.length === 0} onClick={() => this.setState({ showChangeDomainStatusModal: true })} icon={<RetweetOutlined />}>Change Status</Button>
                            <Button size='small' type='text' disabled={this.state.selectedDomains.length === 0} onClick={() => this.adminResetDNS()} icon={<GlobalOutlined />}>Reset DNS Zone</Button>
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Tooltip title='Reload' placement='left'><Button icon={<UndoOutlined />} size='small' type='circle' onClick={() => this.adminLoadDomains()} /></Tooltip>
                            <Pagination onChange={(page, pageSize) => this.adminLoadDomains(page, pageSize)} size='small' {...this.state.pagination} />
                        </Space>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    rowKey={(item) => item.guid}
                    dataSource={this.state.domains}
                    loading={this.state.loadingDomains}
                    footer={() => <Row justify='space-between'>
                        <Col>{this.state.selectedDomains.length + ' selected'}</Col>
                        <Col><Pagination onChange={(page, pageSize) => this.adminLoadDomains(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                    </Row>}
                    pagination={false}
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: this.state.selectedDomains,
                        onChange: (domains) => this.setState({ selectedDomains: domains })
                    }}
                />

                <DomainStatusChangeModal
                    showModal={this.state.showChangeDomainStatusModal}
                    selectedDomains={this.state.selectedDomains}
                    reload={() => this.adminLoadDomains()}
                    close={() => this.setState({ showChangeDomainStatusModal: false, selectedDomains: [] })} />

                <DomainsTableFilter
                    showModal={this.state.showFiltersModal}
                    closeModal={() => this.setState({ showFiltersModal: false })}
                    onChange={(filter) => this.setFilter(filter) } />
            </Fragment>
        )
    }
}

export default connect(null, { updatePage, adminLoadDomains, adminBulkResetDomainsDNSZone })(Domains);