import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Col, Form, Input, Row, message, Typography, Select} from "antd";
import {PlayCircleOutlined} from "@ant-design/icons";
import {adminRunDigTool} from "../../actions/tools";
import {displayErrors} from "../../libs/utils";

class DigTool extends React.Component {
    state = {
        domain: '',
        result: '',
        field: 'A',
        nameserver: '8.8.8.8',
        loading: false
    };

    componentDidMount() {
        this.props.updatePage('Dig');
    }

    runPing() {
        let domain = this.state.domain;
        let field = this.state.field;
        let nameserver = this.state.nameserver;

        if(domain.length === 0) {
            return message.error('Please enter a domain!');
        }

        if(field.length === 0) {
            return message.error('Please select DNS record type!');
        }

        if(nameserver.length === 0) {
            return message.error('Please enter nameserver!');
        }

        let data = { domain, field, nameserver };

        this.setState({ loading: true });

        this.props.adminRunDigTool(data, (res) => {
            this.setState({ loading: false, result: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Paragraph } = Typography;
        const { Option } = Select;

        return(
            <Form layout='vertical'>
                <Row gutter={[16, 16]}>
                    <Col span={18}>
                        <Form.Item label='Enter domain'>
                            <Input disabled={this.state.loading} value={this.state.domain}
                                   onChange={(e) => this.setState({ domain: e.target.value })} />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item label='Field'>
                            <Select value={this.state.field} onChange={(value) => this.setState({ field: value })}>
                                <Option value='A'>A</Option>
                                <Option value='CNAME'>CNAME</Option>
                                <Option value='NS'>NS</Option>
                                <Option value='MX'>MX</Option>
                                <Option value='TXT'>TXT</Option>
                                <Option value='SOA'>SOA</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item label='Nameserver'>
                            <Input disabled={this.state.loading} value={this.state.nameserver}
                                   onChange={(e) => this.setState({ nameserver: e.target.value })} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label='&nbsp;'>
                            <Button type='primary' icon={<PlayCircleOutlined />} loading={this.state.loading}
                                    disabled={this.state.loading} onClick={() => this.runPing()}>Run</Button>
                        </Form.Item>
                    </Col>
                </Row>
                <Row hidden={this.state.result === ''}>
                    <Col span={24}>
                        <Paragraph><pre>{this.state.result.trim()}</pre></Paragraph>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default connect(null, { updatePage, adminRunDigTool })(DigTool);