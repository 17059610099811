import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_PER_PAGE} from "../../config";
import {Col, Pagination, Row, Space, Table, Tag, Tooltip} from "antd";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {adminLoadInvoices} from "../../actions/billing";
import {DateFormat} from "../shared/DateFormat";
import {StatusIndicator} from "../shared/Status";
import {Link} from "react-router-dom";
import {DeleteOutlined, EyeOutlined, FilterOutlined, OrderedListOutlined, PlusOutlined} from "@ant-design/icons";
import Button from "antd/es/button";
import InvoicesTableFilter from "./InvoicesTableFilter";
import InvoicesBulkActionsModal from "./InvoicesBulkActionsModal";
import NewInvoiceModal from "./NewInvoiceModal";
import {MoneyField} from "../shared/MoneyField";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";

class InvoicesTable extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        invoices: [],
        loadingInvoices: true,
        filters: [],
        showFiltersModal: false,
        showBulkActionsModal: false,
        selectedInvoices: [],
        showNewInvoiceModal: false
    }

    componentDidMount() {
        this.props.updatePage('Invoices');
        this.adminLoadInvoices();
    }

    adminLoadInvoices(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingInvoices: true });

        this.props.adminLoadInvoices(page, per_page, this.state.filters, (res) => {
            window.scrollTo(0, 0);

            this.setState({ invoices: res.data.data, loadingInvoices: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingInvoices: false });
                displayErrors(err.response.data);
            }
        });
    }

    clearFilters() {
        this.setState({ filters: [] }, () => this.adminLoadInvoices());
    }

    setFilter(filter) {
        let filters = this.state.filters.filter(f => f.key !== filter.key);
        filters.push(filter);

        this.setState({ filters }, () => this.adminLoadInvoices());
    }

    removeFilter(item) {
        let filters = this.state.filters.filter(filter => filter.key !== item.key);
        this.setState({ filters }, () => this.adminLoadInvoices());
    }

    showFilters() {
        return this.state.filters.map((filter, i) => {
            let value;

            if (typeof filter.value === 'boolean') {
                if (filter.value) {
                    value = 'Yes';
                } else {
                    value = 'No';
                }
            } else {
                if (filter.visible !== null) {
                    value = filter.visible;
                } else {
                    value = normalizeEnum(filter.value);
                }
            }

            return <Tag key={i} closable onClose={() => this.removeFilter(filter)}>{filter.name}: {value}</Tag>;
        });
    }

    render() {
        const columns = [
            { title: 'Invoice ID', dataIndex: 'id', render: (item) => <Link to={'/billing/invoices/' + item}>#{item}</Link> },
            { title: 'Owner', dataIndex: 'owner', render: (item) => {
                return <Link to={'/accounts/companies/' + item.guid}>{item.name}</Link>;
            }},
            { title: 'Amount', align: 'center', dataIndex: 'initial_amount', render: (item, record) => <MoneyField amount={record.initial_amount} currency={record.currency} />},
            { title: 'Tax', align: 'center', dataIndex: 'initial_tax', render: (item, record) => <MoneyField amount={record.initial_tax} currency={record.currency} />},
            { title: 'Status', align: 'center', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' /> },
            { title: 'Created', align: 'center', dataIndex: 'date_created', render: (item, record) => <DateFormat value={record.date_created} /> },
            { title: 'Due', align: 'center', dataIndex: 'date_due', render: (item, record) => <DateFormat value={record.date_due} /> },
            { title: '', align: 'right', render: (item, record) => <Button size='small' icon={<EyeOutlined />} onClick={() => this.props.history.push('/billing/invoices/' + record.id)}>View</Button>}
        ];

        return(
            <Fragment>
                {this.showFilters()}
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <Space>
                            <Button size='small' type='text' icon={<PlusOutlined />} onClick={() => this.setState({ showNewInvoiceModal: true })}>New Invoice</Button>
                            <Button size='small' type='text' icon={<FilterOutlined />} onClick={() => this.setState({ showFiltersModal: true })}>Filters</Button>
                            <Button size='small' type='text' disabled={this.state.filters.length === 0} onClick={() => this.clearFilters()} icon={<DeleteOutlined />}>Clear Filters</Button>
                            <Button size='small' type='text' disabled={this.state.selectedInvoices.length === 0} onClick={() => this.setState({ showBulkActionsModal: true })} icon={<OrderedListOutlined />}>Actions</Button>
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Tooltip title='Reload' placement='left'>
                                <Button size='small' type='circle' loading={this.state.loading} disabled={this.state.loading} icon={<UndoOutlined />} onClick={() => this.adminLoadInvoices()} />
                            </Tooltip>
                            <Pagination onChange={(page, pageSize) => this.adminLoadInvoices(page, pageSize)} size='small' {...this.state.pagination} />
                        </Space>
                    </Col>
                </Row>
                <Table
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: this.state.selectedInvoices,
                        onChange: (invoices) => this.setState({ selectedInvoices: invoices })
                    }}
                    columns={columns}
                    rowKey={(item) => item.id}
                    dataSource={this.state.invoices}
                    loading={this.state.loadingInvoices}
                    pagination={false}
                    footer={() => <Row justify='space-between'>
                        <Col>{this.state.selectedInvoices.length + ' selected'}</Col>
                        <Col><Pagination onChange={(page, pageSize) => this.adminLoadInvoices(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                    </Row>}
                />

                <InvoicesTableFilter
                    showModal={this.state.showFiltersModal}
                    closeModal={() => this.setState({ showFiltersModal: false })}
                    onChange={(filter) => this.setFilter(filter) } />

                <InvoicesBulkActionsModal
                    selectedInvoices={this.state.selectedInvoices}
                    reloadInvoices={() => this.adminLoadInvoices()}
                    showModal={this.state.showBulkActionsModal}
                    close={() => this.setState({ showBulkActionsModal: false })}  />

                <NewInvoiceModal
                    history={this.props.history}
                    showModal={this.state.showNewInvoiceModal}
                    close={() => this.setState({ showNewInvoiceModal: false })} />
            </Fragment>
        )
    }
}

export default connect(null, { updatePage, adminLoadInvoices })(InvoicesTable);