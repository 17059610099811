import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Loading} from "../../libs/loading";
import {adminLoadIPAddress} from "../../actions/ip_addresses";
import {displayErrors} from "../../libs/utils";

class IPAddressDetail extends React.Component {
    state = {
        ipAddress: null,
        loading: false,
    };

    componentDidMount() {
        this.adminLoadIPAddress();
    }

    adminLoadIPAddress() {
        this.props.adminLoadIPAddress(this.props.match.params.guid, (res) => {
            this.setState({ loading: false, ipAddress: res.data });
            this.props.updatePage(res.data.ip_address);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        if(this.state.ipAddress === null || this.state.loading) {
            return <div className='text-center'><Loading /></div>;
        }


        return(
            <Fragment>
            </Fragment>
        )
    }
}

export default connect(null, { updatePage, adminLoadIPAddress })(IPAddressDetail);