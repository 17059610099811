import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";

class PaypalTabFile extends React.Component {
    state = {
        dataSource: []
    };

    componentDidMount() {
        this.props.updatePage('Paypal Tab File');
    }

    processFile(e) {
        e.preventDefault();

        const reader = new FileReader();

        reader.onload = (e) => {
            const text = e.target.result;

            let lines = text.split('\n');

            let data = {};
            let total = 0;

            for(let i = 6; i < lines.length; i++) {
                let line = lines[i].split('\t');

                if(line.length >= 8) {
                    let type = line[1].replaceAll('"', '').replaceAll('\'', '');
                    let amount = line[7].replaceAll('"', '');
                    let service = line[3].replaceAll('"', '').replaceAll('\'', '');

                    if(type !== 'Payment Refund' && service !== '' && amount.indexOf('-') === 0) {
                        if(typeof data[service] === 'undefined') {
                            data[service] = {
                                invoices: 0,
                                amount: 0
                            }
                        }

                        amount = amount.replace(',', '.');

                        if(amount.split('.').length > 2) {
                            amount = amount.replace('.', '');
                        }

                        amount = Math.abs(amount);

                        data[service].invoices += 1;
                        data[service].amount += amount;
                        total += amount;
                    }
                }
            }

            let output = '';
            let keys = Object.keys(data);

            for(let i = 0; i < keys.length; i++) {
                output += keys[i] + ';' + data[keys[i]].invoices + ';' + data[keys[i]].amount + '\n';
            }

            document.getElementById('container').innerHTML = '<pre>' + output + '</pre><p>Total: ' + total + '</p>';
        };

        reader.readAsText(e.target.files[0]);
    }

    render() {
        return(
            <Fragment>
                <p><input type="file" onChange={this.processFile} /></p>
                <div id='container' />
            </Fragment>
        );
    }
}

export default connect(null, { updatePage })(PaypalTabFile);