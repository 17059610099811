import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, List, Tabs} from "antd";
import {Link} from "react-router-dom";
import {EyeOutlined} from "@ant-design/icons";

class Accounting extends React.Component {
    componentDidMount() {
        this.props.updatePage('Accounting');
    }

    render() {
        const { TabPane } = Tabs;

        let page = 'reports';

        if(typeof this.props.match.params.page !== 'undefined' && this.state.pages.indexOf(this.props.match.params.page)) {
            page = this.props.match.params.page;
        }

        let reports = [
            { name: 'Monthly grouped', description: 'Displays a table that groups all customers and their revenue.', path: 'monthly-grouped' },
            { name: 'Daily orders', description: 'Displays a table that groups all orders and sums their revenue per day.', path: 'daily-orders'},
            { name: 'Upcoming orders', description: 'Displays a table that groups all orders and sums their revenue per day for upcoming orders.', path: 'upcoming-orders'},
            { name: 'Monthly invoices', description: 'Displays a table that displays all invoices.', path: 'monthly-invoices'},
            { name: 'Monthly refunds', description: 'Displays a table that displays all refunds.', path: 'monthly-refunds'},
            { name: 'Paypal Tab File', description: 'Upload the Paypal tab file to extract information from it.', path: 'paypal-tab-file'},
        ];

        return(
            <Tabs defaultActiveKey={page} onChange={(e) => this.changeTab(e)} destroyInactiveTabPane={true}>
                <TabPane tab='Reports' key='reports'>
                    <List
                        itemLayout="horizontal"
                        dataSource={reports}
                        renderItem={item => (
                            <List.Item actions={[<Link to={'/accounting/report/' + item.path}><Button size='small' icon={<EyeOutlined />}>View</Button></Link>]}>
                                <List.Item.Meta
                                    title={<Link to={'/accounting/report/' + item.path}>{item.name}</Link>}
                                    description={item.description}
                                />
                            </List.Item>
                        )}
                    />
                </TabPane>
            </Tabs>
        );
    }
}

export default connect(null, { updatePage })(Accounting);