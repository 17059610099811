import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, Modal, Select, Space} from "antd";
import {normalizeEnum} from "../../libs/utils";

class FirewallCachedSubnetTableFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFilter: 'SUBNET',
            filterValue: '',
            filterKey: 'subnet',
            filterVisible: null,
        };
    }

    onChange(){
        let visibleValue = null;

        this.props.onChange({
            name: normalizeEnum(this.state.selectedFilter),
            key: this.state.filterKey,
            value: this.state.filterValue.trim(),
            visible: visibleValue
        });
    }

    closeModal(apply = false, close = true) {
        this.setState({
            selectedFilter: 'SUBNET',
            filterValue: '',
            filterKey: 'subnet',
            filterVisible: null,
        });

        if(close) {
            this.props.closeModal();
        }

        if(apply) {
            this.onChange();
        }
    }

    setFilter(name) {
        const filters = {
            IP_ADDRESS: { key: 'subnet', value: 'ACTIVE' },
        };

        this.setState({ selectedFilter: name, filterKey: filters[name].key, filterValue: filters[name].value });
    }

    render() {
        const { Option } = Select;

        let content = '';

        if(this.state.selectedFilter === 'SUBNET') {
            content = <Input value={this.state.filterValue} onChange={(e) => this.setState({ filterValue: e.target.value })} />
        }

        return(
            <Modal
                title='Add Filter'
                destroyOnClose={true}
                onCancel={() => this.closeModal()}
                footer={<Space>
                    <Button onClick={() => this.closeModal()}>Close</Button>
                    <Button onClick={() => this.closeModal(true, false)}>Apply</Button>
                    <Button type='primary' onClick={() => this.closeModal(true, true)}>Apply & Close</Button>
                </Space>}
                open={this.props.showModal}>
                <Form layout='vertical'>
                    <Form.Item label='Select filter:'>
                        <Select value={this.state.selectedFilter} onChange={(value) => this.setFilter(value)}>
                            <Option value='SUBNET'>Subnet</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        {content}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, {  })(FirewallCachedSubnetTableFilter);