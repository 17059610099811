import {httpDelete, httpGet, httpPatch, httpPost} from "../libs/http";

export const adminLoadUsers = (page, per_page, filters, onSuccess = null, onFailure = null) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/admin/users/users/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadCompanies = (page, per_page, filters, onSuccess = null, onFailure = null) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/admin/users/companies/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadAllUsers = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/users/users/?page=1&per_page=9999', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadAllCompanies = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/users/companies/?page=1&per_page=9999', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadCompany = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/users/companies/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadUser = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/users/users/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadUserLogins = (guid, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/users/users/' + guid + '/logins/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadUserCompanies = (guid, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/users/users/' + guid + '/companies/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadCompanyEmails = (owner, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/users/companies/' + owner + '/emails/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadCompanyEmail = (owner, email_id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/users/companies/' + owner + '/emails/' + email_id + '/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadCompanyContacts = (owner, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/users/companies/' + owner + '/contacts/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadCompanyUsers = (owner, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/users/companies/' + owner + '/users/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminUpdateCompany = (owner, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPatch('/admin/users/companies/' + owner + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminCompanySendEmail = (owner, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/users/companies/' + owner + '/emails/', data, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminUpdateUserField = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPatch('/admin/users/users/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminUserSendEmail = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/users/users/' + guid + '/emails/', data, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadUserEmail = (guid, email_id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/users/users/' + guid + '/emails/' + email_id + '/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminResetPassword = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/users/users/' + guid + '/reset-password/', {}, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminResendVerificationEmail = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/users/users/' + guid + '/resend-verification/', {}, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminUpdateCompanySupportPin = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/users/companies/' + guid + '/support-pin/', {}, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminUpdateCompanyCurrency = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/users/companies/' + guid + '/currency/', data, (res) => onSuccess(res), (err) => onFailure(err));
}
export const adminAddSupportUser = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/users/companies/' + guid + '/support/', null,(res) => onSuccess(res), (err) => onFailure(err));
}

export const adminDeleteCompanyUser = (guid, userGuid, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/admin/users/companies/' + guid + '/users/' + userGuid + '/', (res) => onSuccess(res), (err) => onFailure(err));
}