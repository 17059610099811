import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";

class RoutersTable extends React.Component {
    render() {
        return(<p>Routers table</p>)
    }
}

export default connect(null, { updatePage })(RoutersTable);