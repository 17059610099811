import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Col, Form, Row, Select, Table} from "antd";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {adminAnalyticsKeyValueReport} from "../../actions/analytics";
import {
    Area,
    AreaChart,
    CartesianGrid,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip as ChartTooltip,
    XAxis,
    YAxis
} from 'recharts';

class KeyValueReport extends React.Component {
    state = {
        lines: [],
        selectedYear: 0,
        selectedMonth: 0,
        generating: false
    };

    componentDidMount() {
        this.props.updatePage(this.props.title);

        let date = new Date();
        this.setState({ selectedYear: date.getFullYear(), selectedMonth: date.getMonth()+1 });
    }

    generateReport() {
        this.setState({ generating: true });

        this.props.adminAnalyticsKeyValueReport(this.state.selectedMonth, this.state.selectedYear, this.props.report, (res) => {
            this.setState({ generating: false, lines: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ generating: false });
                displayErrors(err.response.data);
            }
        });
    }

    getAverageValue(data) {
        let values = [];

        for(let i = 0; i < data.length; i++) {
            values.push(Math.round(data[i]['value']));
        }

        let sum = values.reduce((a, b) => a + b, 0);

        return (sum / values.length) || 0;
    }

    getMedianValue(data) {
        let values = [];

        for(let i = 0; i < data.length; i++) {
            values.push(Math.round(data[i]['value']));
        }

        const sorted = values.slice().sort((a, b) => a - b);
        const middle = Math.floor(sorted.length / 2);

        if (sorted.length % 2 === 0) {
            return (sorted[middle - 1] + sorted[middle]) / 2;
        }

        return sorted[middle];
    }

    render() {
        const { Option } = Select;

        let years = [];
        for(let i = 2016; i <= new Date().getFullYear(); i++) {
            years.push(i);
        }

        let months = [];
        let monthNames = ["January", "February", "March", "April", "May", "June", "July", "August",
            "September", "October", "November", "December"];
        for(let i = 1; i <= 12; i++) {
            months.push(i);
        }

        const reportColumns = [
            { title: 'Key', dataIndex: 'key', render: (item) => this.props.normalizeKey ? normalizeEnum(item) : item },
            { title: 'Value', dataIndex: 'value' },
            { title: 'Count', dataIndex: 'count' }
        ];

        return(
            <Fragment>
                <Form layout='vertical'>
                    <Row gutter={[16, 16]}>
                        <Col span={3} hidden={!this.props.showMonthPicker}>
                            <Form.Item label='Select month:'>
                                <Select value={this.state.selectedMonth} onChange={(value) => this.setState({ selectedMonth: value })}>
                                    {months.map((month, i) => {
                                        return <Option key={i} value={month}>{monthNames[month-1]}</Option>;
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={2} hidden={!this.props.showYearPicker}>
                            <Form.Item label='Select year:'>
                                <Select value={this.state.selectedYear} onChange={(value) => this.setState({ selectedYear: value })}>
                                    {years.map((year, i) => {
                                        return <Option key={i} value={year}>{year}</Option>;
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label='&nbsp;'>
                                <Button type='primary' disabled={this.state.generating} loading={this.state.generating}
                                        onClick={() => this.generateReport()}>Generate</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <br />
                    {this.props.showGraph ? <div style={{ width: '100%', height: 250 }}>
                        <ResponsiveContainer>
                            <AreaChart data={this.state.lines}>
                                <Area dataKey="value" stroke="#9dd866" fill="#9dd866" />
                                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                <XAxis dataKey="key" />
                                <YAxis />
                                <ChartTooltip formatter={(value, name) => [value, name] } />
                                <ReferenceLine y={this.getMedianValue(this.state.lines)} label={'Median (' + this.getMedianValue(this.state.lines).toFixed(2) + ') Average (' + this.getAverageValue(this.state.lines).toFixed(2) + ')'} stroke="#fc6f03" strokeDasharray="3 3" />
                            </AreaChart>
                        </ResponsiveContainer>
                    </div> : ''}
                    <Row>
                        <Col span={24}>
                            <Table
                                dataSource={this.state.lines}
                                columns={reportColumns} pagination={false} size='small'
                                summary={pageData => {
                                    let totalValue = 0;

                                    pageData.forEach(({ _, value }) => {
                                        totalValue += value;
                                    });

                                    return (
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell className='text-right'>Total:</Table.Summary.Cell>
                                            <Table.Summary.Cell>{totalValue.toFixed(2)}</Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    );
                                }} />
                        </Col>
                    </Row>
                </Form>
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminAnalyticsKeyValueReport })(KeyValueReport);