import {httpGet, httpPost} from "../libs/http";


export const loadAffiliates = (page, per_page, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/affiliate/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadAffiliateDetail = (guid, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/affiliate/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};


export const loadAffiliateClicks = (guid, page, per_page, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/affiliate/' + guid + '/clicks/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadAffiliateSignups = (guid, page, per_page, onSuccess, onFailure) => dispatch => {
    httpGet('/admin/affiliate/' + guid + '/signups/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateAffiliateNotes = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/affiliate/' + guid + '/notes/', data,  (res) => onSuccess(res), (err) => onFailure(err));
};

export const updateAffiliateStatus = (guid, data, onSuccess, onFailure) => dispatch => {
    httpPost('/admin/affiliate/' + guid + '/status/', data,  (res) => onSuccess(res), (err) => onFailure(err));
};
