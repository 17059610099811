import React from 'react';
import {Modal} from "antd";
import {Loading} from "../../libs/loading";

class CreditNotePDFPreviewModal extends React.Component {
    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    render() {
        let content;

        if(!this.props.loading) {
            content = <div dangerouslySetInnerHTML={{__html: this.props.content}} />;
        } else {
            content = <Loading />;
        }

        return <Modal width='900px' visible={this.props.showModal}>
            {content}
        </Modal>
    }
}

export default CreditNotePDFPreviewModal;