import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Col, Popconfirm, Row, Space, Table, message, Pagination, Tooltip} from "antd";
import {adminDeleteConfigurationFile, adminLoadConfigurationFiles} from "../../actions/system";
import {displayErrors} from "../../libs/utils";
import {DEFAULT_PER_PAGE} from "../../config";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {Link} from "react-router-dom";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import NewConfigurationFileModal from "./NewConfigurationFileModal";

class ConfigurationFilesTable extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        files: [],
        loadingFiles: true,
        showNewConfigurationFileModal: false
    };

    componentDidMount() {
        this.props.updatePage('Configuration Files');
        this.adminLoadConfigurationFiles();
    }

    adminLoadConfigurationFiles(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingFiles: true });

        this.props.adminLoadConfigurationFiles(page, per_page, (res) => {
            window.scrollTo(0, 0);

            this.setState({ files: res.data.data, loadingFiles: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingFiles: false });
                displayErrors(err.response.data);
            }
        });
    }

    deleteFile(guid) {
        this.setState({ loading: true });

        this.props.adminDeleteConfigurationFile(guid, () => {
            message.success('File successfully deleted!');
            this.setState({ loading: false });

            this.adminLoadConfigurationFiles();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const columns = [
            { title: 'Name', dataIndex: 'name', render: (item, record) => <Link to={'/network/configuration-files/' + record.guid}>{item}</Link> },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: 'Modified', dataIndex: 'date_modified', render: (item) => <DateTimeFormat value={item} />},
            { title: 'Actions', align: 'right', render: (item, record) => <Space>
                    <Link to={'/network/configuration-files/' + record.guid}><Button size='small' icon={<EditOutlined />}>Edit</Button></Link>
                    <Popconfirm
                        title="Are you sure you wish to delete this file?"
                        onConfirm={() => this.deleteFile(record.guid)}
                        okText='Delete'
                        placement='topRight'
                        okButtonProps={{danger: true}}
                        cancelText=''>
                        <Button size='small' icon={<DeleteOutlined />}>Delete</Button>
                    </Popconfirm>
            </Space>}
        ];

        return(
            <Fragment>
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <Button type='text' size='small' icon={<PlusOutlined />} onClick={() => this.setState({ showNewConfigurationFileModal: true })}>New File</Button>
                    </Col>
                    <Col>
                        <Space>
                            <Tooltip title='Reload' placement='left'><Button type='text' icon={<UndoOutlined />} onClick={() => this.adminLoadConfigurationFiles()} /></Tooltip>
                            <Pagination onChange={(page, pageSize) => this.adminLoadConfigurationFiles(page, pageSize)} size='small' {...this.state.pagination} />
                        </Space>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    rowKey={(item) => item.guid}
                    dataSource={this.state.files}
                    loading={this.state.loadingFiles}
                    pagination={false}
                    footer={() => <Row justify='space-between'>
                        <Col />
                        <Col><Pagination onChange={(page, pageSize) => this.adminLoadConfigurationFiles(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                    </Row>}
                />

                <NewConfigurationFileModal
                    show={this.state.showNewConfigurationFileModal}
                    reload={() => this.adminLoadConfigurationFiles()}
                    close={() => this.setState({ showNewConfigurationFileModal: false })} />
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminLoadConfigurationFiles,
    adminDeleteConfigurationFile })(ConfigurationFilesTable);