import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Col, Form, Input, InputNumber, Row, Select, message, Space} from "antd";
import FirewallRuleCondition from "../../components/firewall/FirewallRuleCondition";
import {createFirewallRule, editFirewallRule, loadFirewallRule, loadPredefinedBots} from "../../actions/firewall";
import {displayErrors, isNumeric} from "../../libs/utils";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import countryList from "react-select-country-list";
import {Loading} from "../../libs/loading";

class FirewallRuleDetail extends React.Component {
    state = {
        name: '',
        rule: {
            type: 'FIREWALL',
            conditions: [
                [ { field: 'http.request.uri', operator: 'EQUALS', value: '' } ]
            ],
            action: {
                type: 'BLOCK',
                default_type: 'ALLOW'
            },
            max_requests: 150,
            time_frame: 'MINUTE'
        },
        priority: 0,
        random: Date.now(),
        loading: false,
        action: 'ADDING',
        ruleLoaded: false
    };

    blockDuration = [
        { label: 'Second', value: 'SECOND' },
        { label: 'Minute', value: 'MINUTE' },
    ]

    countries = countryList().getData();

    predefinedBots = [];

    componentDidMount() {
        if(typeof this.props.match.params.guid !== 'undefined') {
            this.setState({ action: 'EDITING', activeRule: this.props.match.params.guid });


            this.props.loadFirewallRule(this.props.match.params.guid, (res) => {
                this.props.updatePage(res.data.name);
                this.setState({ ruleLoaded: true, name: res.data.name, priority: res.data.priority, rule: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    displayErrors(err.response.data);
                }
            });
        } else {
            this.props.updatePage('Add Firewall Rule');
        }

        this.props.loadPredefinedBots((res) => {
            this.predefinedBots = res.data.map((bot) => {
                return { label: bot.name, value: bot.value };
            });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    updateRuleDetail(field, value) {
        let rule = this.state.rule;
        rule[field] = value;

        this.setState({ rule });
    }

    updateRuleAction(field, value) {
        let rule = this.state.rule;
        let action = rule.action;

        action[field] = value;

        rule.action = action;

        this.setState({ rule });
    }

    updateCondition(condition_block_index, condition_index, data) {
        let rule = this.state.rule;

        rule.conditions = rule.conditions.map((condition_block, _condition_block_index) => {
            if(_condition_block_index === condition_block_index) {
                return condition_block.map((condition, _condition_index) => {
                    if(_condition_index === condition_index) {
                        return data;
                    }

                    return condition;
                });
            }

            return condition_block;
        });

        this.setState({ rule });
    }

    addAndCondition() {
        let rule = this.state.rule;
        rule.conditions.push([ { field: 'http.request.uri', operator: 'EQUALS', value: '' } ]);
        this.setState({ rule });
    }

    addOrCondition(conditionAndBlockIndex, conditionIndex= 0) {
        let rule = this.state.rule;

        if(conditionIndex > 999999) {
            return
        }

        rule.conditions = rule.conditions.map((condition_block, _condition_block_index) => {
            if(_condition_block_index === conditionAndBlockIndex) {
                condition_block.push({ field: 'http.request.uri', operator: 'EQUALS', value: '' });
                return condition_block;
            }

            return condition_block;
        });

        this.setState({ rule });
    }

    removeCondition(conditionAndBlockIndex, conditionIndex) {
        let rule = this.state.rule;

        let conditionsBlock = [];

        for(let i = 0; i < this.state.rule.conditions.length; i++) {
            if(conditionAndBlockIndex === i) {
                let conditionsList = [];

                for(let j = 0; j < this.state.rule.conditions[i].length; j++) {
                    if(conditionIndex !== j) {
                        conditionsList.push(this.state.rule.conditions[i][j]);
                    }
                }

                if(conditionsList.length > 0) {
                    conditionsBlock.push(conditionsList);
                }
            }
            else
            {
                conditionsBlock.push(this.state.rule.conditions[i]);
            }
        }

        rule.conditions = conditionsBlock;

        this.setState({ rule, random: Date.now() });
    }

    updateRule(close = false) {
        let name = this.state.name.trim();
        let priority = this.state.priority;

        if(name === '') {
            return message.error('Please enter a name for your rule!');
        }

        if(!isNumeric(priority)) {
            return message.error('Priority must be a number between 0 and 9999!');
        }

        if(priority < 0 || priority > 9999) {
            return message.error('Priority must be between 0 and 9999!');
        }

        if(this.state.rule.conditions.length === 0) {
            return message.error('Please enter at least 1 condition!');
        }

        if(this.state.rule.type === 'RATE_LIMIT' && this.state.rule.max_requests < 1) {
            return message.error('Maximum number of requests must be a positive number!');
        }

        let rule = this.state.rule;

        if(rule.type === 'FIREWALL') {
            delete rule.max_requests;
            delete rule.time_frame;
        }

        let data = {
            name: name,
            priority: priority,
            rule: this.state.rule
        };

        this.setState({ loading: true });

        if(this.state.action === 'EDITING') {
            this.props.editFirewallRule(this.state.activeRule, data, () => {
                this.setState({ loading: false });
                message.success('Firewall rule successfully updated!');

                if(close) {
                    this.props.history.push('/firewall');
                }
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loading: false });
                    displayErrors(err.response.data);
                }
            });
        }
        else
        {
            this.props.createFirewallRule(data, () => {
                this.setState({ loading: false });
                message.success('Firewall rule successfully created!');
                if(close) {
                    this.props.history.push('/firewall');
                }
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loading: false });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    render() {
        const { Option } = Select;

        if(this.state.action === 'EDITING' && !this.state.ruleLoaded) {
            return <div className='text-center'><Loading /></div>;
        }

        let andBlockCount = this.state.rule.conditions.length;

        let conditions = this.state.rule.conditions.map((conditionAndBlock, conditionAndBlockIndex) => {
            let orBlockCount = conditionAndBlock.length;
            let orBlocks = conditionAndBlock.map((condition, conditionIndex) => {
                return <Fragment key={conditionIndex + this.state.random}>
                    <div className='logic-block'>
                        <Row key={conditionIndex + this.state.random}>
                            <Col span={20}>
                                <FirewallRuleCondition
                                    countries={this.countries}
                                    predefinedBots={this.predefinedBots}
                                    key={conditionIndex}
                                    condition={condition}
                                    onChange={(data) => this.updateCondition(conditionAndBlockIndex, conditionIndex, data)} />
                            </Col>
                            <Col flex="auto" align='right'>
                                <Button.Group>
                                    {(conditionAndBlockIndex + 1) === andBlockCount ? <Button onClick={() => this.addAndCondition()}>AND</Button> : ''}
                                    <Button onClick={() => this.addOrCondition(conditionAndBlockIndex, conditionIndex)}>OR</Button>
                                    <Button disabled={orBlockCount === 1 && andBlockCount === 1} onClick={() => this.removeCondition(conditionAndBlockIndex, conditionIndex)} icon={<DeleteOutlined />} />
                                </Button.Group>
                            </Col>
                        </Row>
                    </div>
                    { (conditionIndex + 1) < orBlockCount ? <div className='logic-block-separator'><span>OR</span></div> : ''}
                </Fragment>
            });

            return <Fragment key={conditionAndBlockIndex}>
                <div className='logic-block'>{orBlocks}</div>
                { (conditionAndBlockIndex + 1) < andBlockCount ? <div className='logic-block-separator'><span>AND</span></div> : '' }
            </Fragment>;
        });

        return(
            <Form layout='vertical'>
                <Form.Item label='Name'>
                    <Input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value})} />
                </Form.Item>
                <Form.Item label='Type'>
                    <Select value={this.state.rule.type} onChange={(value) => this.updateRuleDetail('type', value)}>
                        <Option value='FIREWALL'>Firewall</Option>
                        <Option value='RATE_LIMIT'>Rate limit</Option>
                    </Select>
                </Form.Item>
                <Form.Item label='Priority' extra={<small>Priority sets the order of which firewall rules are executed.</small>}>
                    <InputNumber min={0} maxLength={9999} value={this.state.priority} onChange={(value) => this.setState({ priority: value })} />
                </Form.Item>
                <div className='conditions'>
                    {conditions}
                </div>
                <br />
                <Form.Item label='Action' extra={<small>Action that will be taken when condition matches the request.</small>}>
                    <Select style={{width: '100%'}} value={this.state.rule.action.type} onChange={(value) => this.updateRuleAction('type', value)}>
                        <Option value='BLOCK'>Block</Option>
                        <Option value='ALLOW'>Allow</Option>
                        <Option value='REJECT'>Reject</Option>
                    </Select>
                </Form.Item>
                <Form.Item label='Default action' extra={<small>Action that will be taken if the condition does not match the request.</small>}>
                    <Select style={{width: '100%'}} value={this.state.rule.action.default_type} onChange={(value) => this.updateRuleAction('default_type', value)}>
                        <Option value='BLOCK'>Block</Option>
                        <Option value='ALLOW'>Allow</Option>
                        <Option value='REJECT'>Reject</Option>
                    </Select>
                </Form.Item>
                <Form.Item hidden={this.state.rule.type !== 'RATE_LIMIT'} label='Requests per time frame:'>
                    <Row gutter={[16, 16]}>
                        <Col>
                            <InputNumber value={this.state.rule.max_requests} min={0} onChange={(value) => this.updateRuleDetail('max_requests', value)} />
                        </Col>
                        <Col>
                            <Select options={this.blockDuration}
                                    style={{width: '100px'}}
                                    value={this.state.rule.time_frame}
                                    onChange={(value) => this.updateRuleDetail('time_frame', value)} />
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item>
                    <Space>
                        <Button type='primary' loading={this.state.loading} disabled={this.state.loading}
                                onClick={() => this.updateRule(false)}>{this.state.action === 'EDITING' ? 'Save' : 'Create'}</Button>
                        {this.state.action === 'EDITING' ? <Button loading={this.state.loading} disabled={this.state.loading}
                                                                   onClick={() => this.updateRule(true)}>Save & Close</Button> : ''}
                    </Space>
                </Form.Item>
            </Form>
        );
    }
}

export default connect(null, { updatePage, loadPredefinedBots, createFirewallRule, loadFirewallRule, editFirewallRule })(FirewallRuleDetail);