import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Tabs} from "antd";
import AnnouncementsTable from "../../components/system/AnnouncementsTable";
import IssuesTable from "../../components/system/IssuesTable";

class System extends React.Component {
    state = {
        pages: ['announcements', 'issues'],
        titles: {
            'announcements': 'Announcements',
            'issues': 'Issues'
        }
    };

    changeTab(page) {
        if(this.state.pages.indexOf(page) > -1) {
            this.props.updatePage(this.state.titles[page]);

            if(page === 'ip-nodes') {
                this.props.history.push('/system');
            } else {
                this.props.history.push('/system/' + page);
            }
        }
    }

    render() {
        const { TabPane } = Tabs;

        let page = 'announcements';

        if(typeof this.props.match.params.page !== 'undefined' && this.state.pages.indexOf(this.props.match.params.page)) {
            page = this.props.match.params.page;
        }

        return(
            <Tabs defaultActiveKey={page} onChange={(e) => this.changeTab(e)} destroyInactiveTabPane={true}>
                <TabPane tab='Announcements' key='announcements'>
                    <AnnouncementsTable history={this.props.history} />
                </TabPane>
                <TabPane tab='Issues' key='issues'>
                    <IssuesTable history={this.props.history} />
                </TabPane>
            </Tabs>
        );
    }
}

export default connect(null, { updatePage })(System);