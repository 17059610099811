import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_DOMAINS_PER_PAGE} from "../../config";
import {Col, Pagination, Row, Space, Table, Tag, Tooltip} from "antd";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {DeleteOutlined, EyeOutlined, FilterOutlined} from "@ant-design/icons";
import Button from "antd/es/button";
import {Link} from "react-router-dom";
import {adminLoadEmails} from "../../actions/emails";
import {DateTimeFormat} from "../../components/shared/DateTimeFormat";
import EmailsTableFilter from "../../components/emails/EmailsTableFilter";
import EmailDetailModal from "../../components/emails/EmailDetailModal";

class Emails extends React.Component {
    state = {
        loadingEmails: false,
        emails: [],
        pagination: { current: 1, pageSize: DEFAULT_DOMAINS_PER_PAGE, showSizeChanger: true, total: 0 },
        filters: [],
        showFiltersModal: false,
        selectedEmails: [],
        showEmailModal: false,
        selectedEmail: null,
    };

    componentDidMount() {
        this.props.updatePage('Emails');
        this.loadEmails();
    }

    loadEmails(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingEmails: true });

        this.props.adminLoadEmails(page, per_page, this.state.filters,(response) => {
            window.scrollTo(0, 0);
            this.setState({
                emails: response.data.data,
                pagination: { current: response.data.page, pageSize: response.data.per_page, showSizeChanger: true, total: response.data.total }, loadingEmails: false });
        }, (err) => {
            this.setState({ loadingEmails: false });

            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    clearFilters() {
        this.setState({ filters: [] }, () => this.loadEmails());
    }

    setFilter(filter) {
        let filters = this.state.filters.filter(f => f.key !== filter.key);
        filters.push(filter);

        this.setState({ filters }, () => this.loadEmails());
    }

    removeFilter(item) {
        let filters = this.state.filters.filter(filter => filter.key !== item.key);
        this.setState({ filters }, () => this.loadEmails());
    }

    showFilters() {
        return this.state.filters.map((filter, i) => {
            let value;

            if (typeof filter.value === 'boolean') {
                if (filter.value) {
                    value = 'Yes';
                } else {
                    value = 'No';
                }
            } else {
                if (filter.visible !== null) {
                    value = filter.visible;
                } else {
                    value = normalizeEnum(filter.value);
                }
            }

            return <Tag key={i} closable onClose={() => this.removeFilter(filter)}>{filter.name}: {value}</Tag>;
        });
    }

    getEmail(guid) {
        for(let i = 0; i < this.state.emails.length; i++) {
            if(this.state.emails[i].guid === guid) {
                return this.state.emails[i];
            }
        }

        return null;
    }

    render() {
        const columns = [
            { title: 'ID', dataIndex: 'id'},
            { title: 'Name', dataIndex: 'name'},
            { title: 'Email', dataIndex: 'email'},
            { title: 'Account', render: (item, record) => {
                if(record.user !== null) {
                    return <Link to={'/accounts/users/' + record.user.guid}>{record.user.first_name + ' ' + record.user.last_name}</Link>
                }
                if(record.company !== null) {
                    return <Link to={'/accounts/companies/' + record.company.guid}>{record.company.name}</Link>
                }
            }},
            { title: 'Subject', dataIndex: 'subject'},
            { title: 'Is Sent', align: 'center', dataIndex: 'is_sent', render: (item) => item ? 'Yes' : 'Scheduled'},
            { title: 'Date created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Date sent', dataIndex: 'date_sent', render: (item) => item !== null ? <DateTimeFormat value={item} /> : '-' },
            { title: 'Attachment', dataIndex: 'attachment', render: (item) => item !== null ? <a href={item.url} target='_blank' rel='noreferrer noopener'>{item.file_name}</a> : '-' },
            { title: '', render: (item, record) => <Button size='small' icon={<EyeOutlined />} onClick={() => this.setState({ showEmailModal: true, selectedEmail: record.guid })}>View</Button>}
        ];

        return(
            <Fragment>
                <p>{this.showFilters()}</p>
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <Space>
                            <Button size='small' type='text' icon={<FilterOutlined />} onClick={() => this.setState({ showFiltersModal: true })}>Filters</Button>
                            <Button size='small' type='text' disabled={this.state.filters.length === 0} onClick={() => this.clearFilters()} icon={<DeleteOutlined />}>Clear Filters</Button>
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Tooltip title='Reload' placement='left'><Button size='small' type='circle' icon={<UndoOutlined />} onClick={() => this.loadEmails()} /></Tooltip>
                            <Pagination onChange={(page, pageSize) => this.loadEmails(page, pageSize)} size='small' {...this.state.pagination} />
                        </Space>
                    </Col>
                </Row>

                <Table
                    dataSource={this.state.emails}
                    columns={columns}
                    rowKey={(item) => item.guid}
                    pagination={false}
                    footer={() => <Row justify='space-between'>
                        <Col />
                        <Col><Pagination onChange={(page, pageSize) => this.loadEmails(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                    </Row>}
                    loading={this.state.loadingEmails} />

                <EmailsTableFilter
                    showModal={this.state.showFiltersModal}
                    closeModal={() => this.setState({ showFiltersModal: false })}
                    onChange={(filter) => this.setFilter(filter) } />

                <EmailDetailModal
                    show={this.state.showEmailModal}
                    email={this.getEmail(this.state.selectedEmail)}
                    close={() => this.setState({ showEmailModal: false, selectedEmail: null })} />
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminLoadEmails })(Emails);