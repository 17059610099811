import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_PER_PAGE} from "../../config";
import {Col, Pagination, Row, Space, Table, Tag, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {DeleteOutlined, EyeOutlined, FilterOutlined, PlusOutlined} from "@ant-design/icons";
import {adminLoadCreditNotes} from "../../actions/billing";
import Button from "antd/es/button";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {Link} from "react-router-dom";
import CreditNotesTableFilter from "./CreditNotesTableFilter";
import NewCreditNoteModal from "./NewCreditNoteModal";
import {StatusIndicator} from "../shared/Status";
import {MoneyField} from "../shared/MoneyField";

class CreditNotesTable extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        credit: [],
        loadingCredit: true,
        filters: [],
        showFiltersModal: false,
        showNewCreditNoteModal: false
    }

    componentDidMount() {
        this.props.updatePage('Credit Notes');
        this.adminLoadCreditNotes();
    }

    adminLoadCreditNotes(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingCredit: true });

        this.props.adminLoadCreditNotes(page, per_page, this.state.filters, (res) => {
            window.scrollTo(0, 0);

            this.setState({ credit: res.data.data, loadingCredit: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCredit: false });
                displayErrors(err.response.data);
            }
        });
    }

    clearFilters() {
        this.setState({ filters: [] }, () => this.adminLoadCreditNotes());
    }

    setFilter(filter) {
        let filters = this.state.filters.filter(f => f.key !== filter.key);
        filters.push(filter);

        this.setState({ filters }, () => this.adminLoadCreditNotes());
    }

    removeFilter(item) {
        let filters = this.state.filters.filter(filter => filter.key !== item.key);
        this.setState({ filters }, () => this.adminLoadCreditNotes());
    }

    showFilters() {
        return this.state.filters.map((filter, i) => {
            let value;

            if (typeof filter.value === 'boolean') {
                if (filter.value) {
                    value = 'Yes';
                } else {
                    value = 'No';
                }
            } else {
                if (filter.visible !== null) {
                    value = filter.visible;
                } else {
                    value = normalizeEnum(filter.value);
                }
            }

            return <Tag key={i} closable onClose={() => this.removeFilter(filter)}>{filter.name}: {value}</Tag>;
        });
    }

    render() {
        const columns = [
            { title: 'ID', dataIndex: 'id', render: (item) => <Link to={'/billing/credit-notes/' + item}>#{item}</Link> },
            { title: 'Owner', dataIndex: 'owner', render: (item) => {
                return <Link to={'/accounts/companies/' + item.guid}>{item.name}</Link>;
            }},
            { title: 'Created', dataIndex: 'date_created', render: (item, record) => <DateTimeFormat value={record.date_created} /> },
            { title: 'Status', align: 'center', dataIndex: 'status', render: (item) => <StatusIndicator status={item} /> },
            { title: 'Balance', align: 'center', dataIndex: 'balance', render: (item, record) => <MoneyField amount={item} currency={record.currency} /> },
            { title: '', align: 'right', render: (item, record) => {
                return <Link to={'/billing/credit-notes/' + record.id}><Button size='small' icon={<EyeOutlined />}>View</Button></Link>;
            }}
        ];

        return(
            <Fragment>
                {this.showFilters()}
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <Space>
                            <Button type='text' size='small' icon={<PlusOutlined />} onClick={() => this.setState({ showNewCreditNoteModal: true })}>New Credit Note</Button>
                            <Button type='text' size='small' icon={<FilterOutlined />} onClick={() => this.setState({ showFiltersModal: true })}>Filters</Button>
                            <Button type='text' size='small' disabled={this.state.filters.length === 0} onClick={() => this.clearFilters()} icon={<DeleteOutlined />}>Clear Filters</Button>
                        </Space>
                    </Col>
                    <Col>
                        <Space>
                            <Tooltip title='Reload' placement='left'>
                                <Button size='small' type='circle' loading={this.state.loading} disabled={this.state.loading} icon={<UndoOutlined />} onClick={() => this.adminLoadCreditNotes()} />
                            </Tooltip>
                            <Pagination onChange={(page, pageSize) => this.adminLoadCreditNotes(page, pageSize)} size='small' {...this.state.pagination} />
                        </Space>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    rowKey={(item) => item.id}
                    dataSource={this.state.credit}
                    loading={this.state.loadingCredit}
                    pagination={false}
                    footer={() => <Row justify='space-between'>
                        <Col />
                        <Col><Pagination onChange={(page, pageSize) => this.adminLoadCreditNotes(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                    </Row>}
                />

                <NewCreditNoteModal
                    history={this.props.history}
                    showModal={this.state.showNewCreditNoteModal}
                    close={() => this.setState({ showNewCreditNoteModal: false})} />

                <CreditNotesTableFilter
                    showModal={this.state.showFiltersModal}
                    closeModal={() => this.setState({ showFiltersModal: false })}
                    onChange={(filter) => this.setFilter(filter) } />
            </Fragment>
        )
    }
}

export default connect(null, { updatePage, adminLoadCreditNotes })(CreditNotesTable);