import React from 'react';
import { connect } from 'react-redux';
import {Button, Col, Form, Input, message, Row} from "antd";
import {displayErrors} from "../../libs/utils";
import {Link, Redirect} from "react-router-dom";
import {updatePage} from "../../actions/page";
import {requestPasswordReset, resetPassword, validatePasswordResetToken} from "../../actions/auth";
import qs from "qs";
import {Loading} from "../../libs/loading";

class Recover extends React.Component  {
    state = {
        loading: false,
        validating: false,
        emailAddress: '',
        showPasswordForm: false,
        tokenIsAvailable: false,
        password: '',
        passwordRepeat: '',
        resetting: false,
        hash: ''
    };

    componentDidMount() {
        this.props.updatePage('Reset Password');

        let parsed = qs.parse(this.props.location.search.substring(1));

        if(typeof parsed !== 'undefined') {
            if(typeof parsed.hash !== 'undefined' && parsed.hash.length > 0) {
                this.validateToken(parsed.hash);
            }
        }
    }

    validateToken(token) {
        this.setState({ validating: true });

        this.props.validatePasswordResetToken(token, (res) => {
            this.setState({ validating: false, tokenIsAvailable: true });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ validating: false });
                displayErrors(err.response.data);
            }
        });
    }

    resetPassword() {
        let password = this.state.password.trim();
        let passwordRepeat = this.state.passwordRepeat.trim();

        if(password.length === 0) {
            return message.error('Please enter your password!');
        }

        if(passwordRepeat.length === 0) {
            return message.error('Please repeat your password!');
        }

        if(password !== passwordRepeat) {
            return message.error('Your passwords do not match!');
        }

        let parsed = qs.parse(this.props.location.search.substring(1));

        let data = {
            'hash': parsed.hash,
            'password': password,
            'password_repeat': passwordRepeat
        };

        this.setState({ resetting: true });

        this.props.resetPassword(data, (res) => {
            this.setState({ resetting: false });
            message.success('Password successfully reset!');
            this.props.history.push('/auth/login');
        }, (err) => {
            this.setState({ resetting: false });
            displayErrors(err.response.data);
        });
    }

    sendRecoverRequest() {
        let email_address = this.state.emailAddress;

        let data = { email_address };

        this.setState({ loading: true });

        this.props.requestPasswordReset(data, (res) => {
            this.setState({ emailAddress: '', loading: false });
            message.success('If your email exists in our database, you will get an email very soon with instructions to reset your password!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        })
    }

    render() {
        if(this.props.isAuthenticated) {
            return <Redirect to='/' />;
        }

        let content = '';

        if(!this.state.validating && this.state.tokenIsAvailable)
        {
            content = <Form layout='vertical'>
                <Form.Item>
                    <Input type='password'
                           value={this.state.password}
                           placeholder='Password'
                           onKeyUp={(e) => e.key === 'Enter' ? this.resetPassword() : ''}
                           onChange={(e) => this.setState({ password: e.target.value })} />
                </Form.Item>
                <Form.Item>
                    <Input type='password'
                           value={this.state.passwordRepeat}
                           placeholder='Repeat password'
                           onKeyUp={(e) => e.key === 'Enter' ? this.resetPassword() : ''}
                           onChange={(e) => this.setState({ passwordRepeat: e.target.value })} />
                </Form.Item>
                <Form.Item className='auth-form-footer'>
                    <Row>
                        <Col span={24}>
                            <Button block type='primary'
                                    disabled={this.state.loading}
                                    loading={this.state.loading}
                                    onClick={() => this.resetPassword()}>Reset Password</Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Form>;
        }
        else if(this.state.validating && !this.state.tokenIsAvailable)
        {
            content = <div className='text-center'><Loading /></div>
        }
        else
        {
            content = <Form layout='vertical'>
                <Form.Item>
                    <Input type='email'
                           value={this.state.emailAddress}
                           placeholder='Email address'
                           onKeyUp={(e) => e.key === 'Enter' ? this.sendRecoverRequest() : ''}
                           onChange={(e) => this.setState({ emailAddress: e.target.value })} />
                </Form.Item>
                <Form.Item className='auth-form-footer'>
                    <Button block type='primary'
                            disabled={this.state.loading}
                            loading={this.state.loading}
                            onClick={() => this.sendRecoverRequest()}>Send Request</Button>
                    <div className='auth-mobile-button'>
                        <Link to='/auth/login'>
                            <Button style={{width: '300px', marginTop: '15px'}}>Login</Button>
                        </Link>
                        <Link to='/auth/register'>
                            <Button style={{width: '300px', marginTop: '15px'}}>Create Account</Button>
                        </Link>
                    </div>
                </Form.Item>
            </Form>;
        }

        return(
            <Row className='recover-wrapper'>
                <Col xs={24} sm={12}>
                    <div className='register-form'>
                        <img className='auth-mobile-logo' alt='Priority Prospect' src='/img/logo-top-dark.png' />
                        <h1 className='text-center'>Recover</h1>
                        <div className='text-left'>
                            {content}
                        </div>
                    </div>
                </Col>
                <Col xs={0} sm={12} className='theme-auth-side'>
                    <div className='auth-side-container'>
                        <h1>Already have an account?</h1>
                        <img src='/img/logo-square.png' style={{width: '100%'}} alt='Priority Prospect' />
                        <p style={{marginTop: '25px'}}>
                            <Link to={'/auth/login'}>
                                <Button type='primary'>Log-in Now!</Button>
                            </Link>
                        </p>
                    </div>
                </Col>
            </Row>
        );
    }
}
export default connect(null, { updatePage, requestPasswordReset, validatePasswordResetToken, resetPassword })(Recover);