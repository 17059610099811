import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, Modal, Space, message, Row, Col} from "antd";
import {adminCreatePriceGroup, adminLoadPriceGroup, adminUpdatePriceGroup} from "../../actions/network";
import {displayErrors} from "../../libs/utils";

class PriceGroupModal extends React.Component {
    state = {
        name: '',
        ip_address_price: '',
        ip_address_price_eur: '',
        site_price: '',
        site_price_eur: '',
        loadingPriceGroup: false,
        priceGroupLoaded: false,
        saving: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingPriceGroup && !this.state.priceGroupLoaded) {
            this.adminLoadPriceGroup();
        }
    }

    closeModal(reload = false) {
        this.setState({
            name: '',
            ip_address_price: '',
            ip_address_price_eur: '',
            site_price: '',
            site_price_eur: '',
            loadingPriceGroup: false,
            priceGroupLoaded: false,
            saving: false
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    adminLoadPriceGroup() {
        this.setState({ loadingPriceGroup: true });

        this.props.adminLoadPriceGroup(this.props.activePriceGroup, (res) => {
            this.setState({ loadingPriceGroup: false, priceGroupLoaded: true, ...res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingPriceGroup: false, priceGroupLoaded: true });
                displayErrors(err.response.data);
            }
        });
    }

    savePriceGroup() {
        let name = this.state.name.trim();
        let ip_address_price = this.state.ip_address_price.trim();
        let ip_address_price_eur = this.state.ip_address_price_eur.trim();
        let site_price = this.state.site_price.trim();
        let site_price_eur = this.state.site_price_eur.trim();

        if(name.length === 0) {
            return message.error('Please enter price group name!');
        }

        if(ip_address_price.length === 0) {
            return message.error('Please enter price group IP address price in USD!');
        }

        if(ip_address_price_eur.length === 0) {
            return message.error('Please enter price group IP address price in EUR!');
        }

        if(site_price.length === 0) {
            return message.error('Please enter price group site price in USD!');
        }

        if(site_price_eur.length === 0) {
            return message.error('Please enter price group site price in EUR!');
        }

        let data = { name, ip_address_price, ip_address_price_eur, site_price, site_price_eur };

        this.setState({ saving: true });

        if(this.props.activePriceGroup !== null) {
            this.props.adminUpdatePriceGroup(this.props.activePriceGroup, data, (res) => {
                this.setState({ saving: true });
                message.success('Price group successfully updated!');
                this.closeModal(true);
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ saving: false });
                    displayErrors(err.response.data);
                }
            });
        } else {
            this.props.adminCreatePriceGroup(data, (res) => {
                this.setState({ saving: true });
                message.success('Price group successfully created!');
                this.closeModal(true);
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ saving: false });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    render() {
        return(
            <Modal title='Price Group'
                   visible={this.props.showModal}
                   onCancel={() => this.closeModal()}
                   footer={<Space>
                       <Button disabled={this.state.saving} onClick={() => this.closeModal()}>Close</Button>
                       <Button disabled={this.state.saving} loading={this.state.saving}  type='primary' onClick={() => this.savePriceGroup()}>Save</Button>
                   </Space>}>
                <Form layout='vertical'>
                    <Form.Item label='Name'>
                        <Input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item label='IP price - USD'>
                                <Input value={this.state.ip_address_price} onChange={(e) => this.setState({ ip_address_price: e.target.value })} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='IP price - EUR'>
                                <Input value={this.state.ip_address_price_eur} onChange={(e) => this.setState({ ip_address_price_eur: e.target.value })} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item label='Site price - USD'>
                                <Input value={this.state.site_price} onChange={(e) => this.setState({ site_price: e.target.value })} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Site price - EUR'>
                                <Input value={this.state.site_price_eur} onChange={(e) => this.setState({ site_price_eur: e.target.value })} />
                            </Form.Item>
                        </Col>
                    </Row>


                </Form>
            </Modal>
        )
    }
}

export default connect(null, { adminLoadPriceGroup, adminCreatePriceGroup,
    adminUpdatePriceGroup })(PriceGroupModal);