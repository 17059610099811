import React from 'react';
import { connect } from 'react-redux';
import {Button, Form, message, Modal, Select, Space} from "antd";
import {adminUpdateDomainsIPAddress, loadDomainGroupIPAddresses} from "../../actions/domains";
import {normalizeEnum} from "../../libs/utils";

class IPAddressModifyModal extends React.Component
{
    constructor(props) {
        super(props);

        this.state = {
            showModal: props.showModal,
            domain: props.domain,
            loadingIPAddresses: false,
            ipAddresses: [],
            updatingDomain: false,
            ipAddressesLoaded: false,
            loading: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingIPAddresses && !this.state.ipAddressesLoaded) {
            this.loadIPAddresses();
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    loadIPAddresses() {
        this.setState({ loadingIPAddresses: true });

        this.props.loadDomainGroupIPAddresses(this.props.domainGroup,(response) => {
            this.setState({ ipAddresses: response.data.data, loadingIPAddresses: false, ipAddressesLoaded: true,
                selectedIPAddress: this.props.ipAddress.guid, selectedIPSignature: this.props.ipAddress.signature.guid });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIPAddresses: false, ipAddressesLoaded: true });
                message.error(err.response.error);
            }
        });
    }

    closeModal(reload = false) {
        this.setState({
            loadingIPAddresses: false,
            ipAddresses: [],
            updatingDomain: false,
            ipAddressesLoaded: false,
            selectedIPAddress: '',
            loading: false
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    updateDomainsIPAddress() {
        this.setState({ loading: true });

        let ip_address = this.state.selectedIPAddress;

        if(ip_address.length === 0) {
            return message.error('Please select an IP address!');
        }

        this.props.adminUpdateDomainsIPAddress(this.props.domain, { ip_address }, (res) => {
            this.setState({ loading: false });
            message.success('IP address successfully updated!');
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                message.error(err.response.error);
            }
        });
    }

    render() {
        const { Option } = Select;

        return (
            <Modal
                centered
                title='Modify IP Address'
                visible={this.props.showModal}
                onCancel={() => this.closeModal()}
                footer={<Space>
                    <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Close</Button>
                    <Button disabled={this.state.loading}  loading={this.state.loading} type='primary' onClick={() => this.updateDomainsIPAddress()}>Update</Button>
                </Space>}
                destroyOnClose={true}>

                <Form layout='vertical'>
                    <Form.Item label='Select IP address:'>
                        <Select
                            value={this.state.selectedIPAddress}
                            loading={this.state.loadingIPAddresses}
                            disabled={this.state.loadingIPAddresses}
                            onChange={(value) => this.setState({ selectedIPAddress: value })}>
                            {this.state.ipAddresses.map(ip_address => {
                                return <Option key={ip_address.guid} value={ip_address.guid}
                                               disabled={ip_address.domain_count >= ip_address.domains_allowed}>{ip_address.ip_address} ({normalizeEnum(ip_address.status)}) {ip_address.domain_count + ' / ' + ip_address.domains_allowed}</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Form>

            </Modal>
        )
    }
}

export default connect(null, { loadDomainGroupIPAddresses, adminUpdateDomainsIPAddress })(IPAddressModifyModal);