import React from "react";
import {connect} from "react-redux";
import {Alert} from "antd";
import {adminLoadIssuesNotification} from "../../actions/system";
import {displayErrors} from "../../libs/utils";
import {Link} from "react-router-dom";

class Issues extends React.Component {
    state = {
        issues: []
    };

    componentDidMount() {
        this.loadIssues();
        setInterval(() => this.loadIssues(), 60 * 1000);
    }

    loadIssues() {
        this.props.adminLoadIssuesNotification((res) => {
            this.setState({ issues: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        return(this.state.issues.map((item, i) => {
            return <Alert type='error' key={i} showIcon
                          action={<Link to={'/system/issues/' + item.guid}>Manage</Link>}
                          message={<span dangerouslySetInnerHTML={{__html: item.message }} />}
                          style={{marginBottom: '10px'}} />;
        }));
    }
}

export default connect(null, { adminLoadIssuesNotification })(Issues);