import React from "react";
import { connect } from 'react-redux';
import {updatePage} from "../actions/page";
import AdminDashboard from "./dashboard/AdminDashboard";
import SupportDashboard from "./dashboard/SupportDashboard";

class Dashboard extends React.Component {
    componentDidMount() {
        const { user } = this.props.user;
        this.props.updatePage('Welcome back, ' + user.first_name + '!');
    }

    render() {
        if(this.props.user.user.user_type === 'ADMIN') {
            return(<AdminDashboard />);
        }
        else if(this.props.user.user.user_type === 'STAFF') {
            return(<SupportDashboard />);
        } else {
            return(<p>Hey now, you shouldn't see this message... 🤔</p>)
        }
    }
}

const mapStateToProps = state => ({
    user: state.auth.user
});

export default connect(mapStateToProps, { updatePage })(Dashboard);