import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Button, Col, Modal, Row, Space, Switch, message, Descriptions, Cascader} from "antd";
import {adminLoadReplacementIPAddresses, adminReplaceIPAddress} from "../../actions/ip_addresses";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {UsergroupAddOutlined, UserOutlined} from "@ant-design/icons";

class IPAddressReplacementModal extends React.Component {
    state = {
        disableOldIPAddress: false,
        loadingIPAddresses: false,
        ipAddressesLoaded: false,
        ipAddresses: [],
        newIPAddress: '',
        replacing: false,
        ipAddressCollection: [],
        replaceForAll: false,
        sendReplacementEmail: true,
        rawIPAddressList: {},
        selectedIPAddress: null
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingIPAddresses && !this.state.ipAddressesLoaded && this.props.activeIPAddress !== null && !this.state.replacing) {
            this.setState({ loadingIPAddresses: true });

            let rawIPAddresses = {};

            this.props.adminLoadReplacementIPAddresses(this.props.activeIPAddress.guid, (res) => {
                let data = [];
                let tmpCountries = [];
                let tmpCountriesObj = {};

                for(let i = 0; i < res.data.length; i++) {
                    for(let j = 0; j < res.data[i].ip_addresses.length; j++) {
                        let ip = res.data[i].ip_addresses[j];
                        if(tmpCountries.indexOf(ip.country) === -1) {
                            tmpCountries.push(ip.country);

                            tmpCountriesObj[ip.country] = {
                                label: <div style={{width: '200px'}}>{ip.country}</div>,
                                value: ip.country,
                                children: []
                            };
                        }
                    }
                }

                for(let o = 0; o < tmpCountries.length; o++) {
                    let country = tmpCountries[o];
                    let tmpCities = [];
                    let tmpCitiesObj = {};

                    for(let i = 0; i < res.data.length; i++) {
                        for(let j = 0; j < res.data[i].ip_addresses.length; j++) {
                            let ip = res.data[i].ip_addresses[j];
                            if(ip.country === country && tmpCities.indexOf(ip.city) === -1) {
                                tmpCities.push(ip.city);
                                tmpCitiesObj[ip.city] = {
                                    label: <div style={{width: '200px'}}>{ip.city}</div>,
                                    value: ip.city,
                                    children: []
                                }
                            }
                        }
                    }

                    for(let p = 0; p < tmpCities.length; p++) {
                        let city = tmpCities[p];

                        for(let r = 0; r < res.data.length; r++) {
                            for(let e = 0; e < res.data[r].ip_addresses.length; e++) {
                                let ip = res.data[r].ip_addresses[e];
                                if(ip.country === country && ip.city === city) {
                                    tmpCitiesObj[ip.city]['children'].push({
                                        label: <div style={{width: '200px'}} className={ip.used ? 'used-ip-subnet-bg' : ''}>{ip.ip_address_type === 'DEDICATED' ? <UserOutlined /> : <UsergroupAddOutlined />} {ip.ip_address} <em><small>{ip.provider}</small></em></div>,
                                        value: ip.guid,
                                        ip_address: ip.ip_address,
                                        type: ip.ip_address_type,
                                        used: ip.used
                                    });

                                    rawIPAddresses[ip.guid] = ip.ip_address;
                                }
                            }
                        }

                        tmpCountriesObj[country]['children'].push(tmpCitiesObj[city]);
                    }

                    data.push(tmpCountriesObj[country]);
                }

                this.setState({ loadingIPAddresses: false, ipAddressesLoaded: true, ipAddresses: res.data, rawIPAddresses,
                    ipAddressCollection: data, replaceForAll: this.props.activeIPAddress.ip_address_type === 'SHARED' });

            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingIPAddresses: false, ipAddressesLoaded: true });
                    displayErrors(err.response.data);
                }
            })
        }
    }

    closeModal(reload = false) {
        this.props.close();

        this.setState({
            disableOldIPAddress: false,
            loadingIPAddresses: false,
            ipAddressesLoaded: false,
            ipAddresses: [],
            newIPAddress: '',
            replacing: false,
            ipAddressCollection: [],
            replaceForAll: false,
            sendReplacementEmail: true,
            rawIPAddressList: {},
            selectedIPAddress: null
        }, () => {
            if(reload) {
                this.props.reload();
            }
        });
    }

    replaceIPAddress() {
        let data = {
            'new_ip_address': this.state.selectedIPAddress[2],
            'disable_old_ip_address': this.state.disableOldIPAddress,
            'replace_for_all': this.state.replaceForAll,
            'send_replacement_email': this.state.sendReplacementEmail
        };

        this.setState({ replacing: true });

        this.props.adminReplaceIPAddress(this.props.activeIPAddress.guid, data, (res) => {
            this.setState({ replacing: false });
            message.success('IP address successfully replaced!');
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ replacing: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        if(this.props.activeIPAddress === null) {
            return <Fragment />;
        }

        return(
            <Modal
                title='Replace IP address'
                destroyOnClose={true}
                onCancel={() => this.closeModal()}
                footer={<Space>
                    <Button disabled={this.state.replacing} onClick={() => this.closeModal()}>Close</Button>
                    <Button disabled={this.state.replacing} loading={this.state.replacing} type='primary' onClick={() => this.replaceIPAddress()}>Replace</Button>
                </Space>}
                width='900px'
                visible={this.props.showModal}>
                <Row>
                    <Col span={24}>
                        <Descriptions bordered size='small' layout='horizontal' column={1}>
                            <Descriptions.Item style={{width: '50%'}} label='IP address'>{this.props.activeIPAddress.ip_address}</Descriptions.Item>
                            <Descriptions.Item label='Type'>{normalizeEnum(this.props.activeIPAddress.ip_address_type)}</Descriptions.Item>
                            <Descriptions.Item label='Country'>{this.props.activeIPAddress.country}</Descriptions.Item>
                            <Descriptions.Item label='City'>{this.props.activeIPAddress.city}</Descriptions.Item>
                            <Descriptions.Item label='Provider'>{this.props.activeIPAddress.provider}</Descriptions.Item>
                            <Descriptions.Item label='Domains'>{this.props.activeIPAddress.domain_count + '/' + this.props.activeIPAddress.domains_allowed}</Descriptions.Item>
                            <Descriptions.Item label='Disable old IP address?'>
                                <Switch size='small' checked={this.state.disableOldIPAddress } onChange={(value) => this.setState({ disableOldIPAddress: value })} />
                            </Descriptions.Item>
                            {this.props.activeIPAddress.ip_address_type === 'SHARED' ? <Descriptions.Item label='Replace for all'>
                                <Switch size='small' checked={this.state.replaceForAll } onChange={(value) => this.setState({ replaceForAll: value })} />
                            </Descriptions.Item> : ''}
                            <Descriptions.Item label='Send replacement email'>
                                <Switch size='small' checked={this.state.sendReplacementEmail } onChange={(value) => this.setState({ sendReplacementEmail: value })} />
                            </Descriptions.Item>
                            <Descriptions.Item label='New IP address'>
                                <Cascader
                                    options={this.state.ipAddressCollection}
                                    onChange={(value) => this.setState({ selectedIPAddress: value })}
                                    disabled={this.state.loadingIPAddresses}
                                    style={{width: '100%'}}
                                    expandTrigger="hover"
                                    placeholder="Select IP address"
                                    displayRender={(label, option) => {
                                        if(option.length > 0) {
                                            let ip = option[2];
                                            return ip.label;
                                        }
                                    }}
                                />
                            </Descriptions.Item>
                            <Descriptions.Item label='Selected IP address'>
                                {this.state.selectedIPAddress !== null ? this.state.rawIPAddresses[this.state.selectedIPAddress[2]] : '-'}
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            </Modal>
        );
    }
}

export default connect(null, { adminLoadReplacementIPAddresses, adminReplaceIPAddress })(IPAddressReplacementModal);