import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_PER_PAGE} from "../../config";
import {Table, Row, Col, Tooltip, Button, Pagination, Space} from "antd";
import {displayErrors} from "../../libs/utils";
import {adminLoadLogs} from "../../actions/logs";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {DateTimeFormat} from "../../components/shared/DateTimeFormat";
import {Link} from "react-router-dom";

class Logs extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        logs: [],
        loading: false,
    }

    componentDidMount() {
        this.props.updatePage('Logs');
        this.adminLoadLogs();
    }

    adminLoadLogs(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loading: true });

        this.props.adminLoadLogs(page, per_page, (res) => {
            window.scrollTo(0, 0);

            this.setState({ logs: res.data.data, loading: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    parseAction(row) {
        if(row.action_object === null) {
            return '-';
        }

        if(row.verb === 'updated domain' || row.verb === 'added domain' || row.verb === 'updated SOA record' || row.verb === 'deleted subdomain') {
            return <Link to={'/domains/' + row.action_object.guid}>{row.action_object.domain}</Link>;
        } else if(row.verb === 'processed order' || row.verb === 'created new order') {
            return <Link to={'/billing/orders/' + row.action_object.id}>#{row.action_object.id}</Link>;
        } else if(row.verb === 'logged into software' || row.verb === 'installed software' || row.verb === 'uninstalled software') {
            return row.action_object.software_name;
        } else if(row.verb === 'created IP address group' || row.verb === 'created domain group' || row.verb === 'exported domain data'
            || row.verb === 'exported IP address data' || row.verb === 'deleted domain group' || row.verb === 'deleted IP address group'
            || row.verb === 'updated domain group' || row.verb === 'changed cPanel account password' || row.verb === 'updated hosting account'
            || row.verb === 'changed group settings') {
            return row.action_object.name;
        } else if(row.verb === 'requested SSL certificate' || row.verb === 'installed SSL certificate' || row.verb === 'uninstalled SSL certificate'
            || row.verb === 'deleted SSL certificate') {
            if(row.action_object.lets_encrypt) {
                return 'Let\'s Encrypt';
            } else if(row.action_object.custom) {
                return 'Custom';
            }
        } else if(row.verb === 'created hosting account with a custom host') {
            return <Link to={'/hosting/' + row.action_object.guid}>{row.action_object.name}</Link>;
        } else if(row.verb === 'added a custom host') {
            return row.action_object.hostname;
        } else if(row.verb === 'created email address' || row.verb === 'deleted email account' || row.verb === 'deleted email address') {
            return row.action_object.address;
        } else if(row.verb === 'created IP address downgrade request' || row.verb === 'created IP address upgrade request') {
            return row.action_object.guid;
        } else if(row.verb === 'updated company' || row.verb === 'added VAT id') {
            return <Link to={'/accounts/companies/' + row.action_object.guid}>{row.action_object.name}</Link>;
        } else if(row.verb === 'created new card payment method') {
            return row.action_object.guid + ' (' + row.action_object.payment_method_type + ')';
        }

       return JSON.stringify(row.action_object);
    }

    parseActor(row) {
        let actor = 'System';

        if(row.actor !== null) {
            actor = <Link to={'/accounts/users/' + row.actor.guid}>{row.actor.first_name + ' ' + row.actor.last_name}</Link>;
        }

        return actor;
    }

    parseTarget(row) {
        if(row.target === null) {
            return '-';
        }

        if(row.verb === 'logged into cPanel') {
            return <Link to={'/hosting/' + row.target.guid}>{row.target.name}</Link>;
        } else if(row.verb === 'logged into software' || row.verb === 'requested SSL certificate' || row.verb === 'installed software'
            || row.verb === 'installed SSL certificate' || row.verb === 'uninstalled SSL certificate' || row.verb === 'uninstalled software'
            || row.verb === 'created redirect' || row.verb === 'deleted domain' || row.verb === 'deleted SSL certificate'
            || row.verb === 'deleted redirect' || row.verb === 'updated redirect' || row.verb === 'changed IP address'
            || row.verb === 'created subdomain' || row.verb === 'deleted subdomain' || row.verb === 'created email address'
            || row.verb === 'deleted email address' || row.verb === 'deleted email account') {
            return <Link to={'/domains/' + row.target.guid}>{row.target.domain}</Link>;
        } else if(row.verb === 'submitted payment') {
            return <Link to={'/billing/invoices/'}>#{row.target.id}</Link>
        } else if(row.verb === 'created IP address downgrade request' || row.verb === 'created IP address upgrade request') {
            return row.target.ip_address;
        } else if(row.verb === 'added VAT id') {
            return row.target.vat_id;
        } else if(row.verb === 'created new card payment method') {
            return <Link to={'/accounts/companies/' + row.target.guid}>{row.target.name}</Link>;
        } else if(row.verb === 'changed cPanel account password') {
            return row.target.name;
        }

        return JSON.stringify(row.target);
    }

    render() {
        const columns = [
            { title: 'Timestamp', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'IP Address', dataIndex: 'ip_address' },
            { title: 'Actor', dataIndex: 'actor', render: (item, row) => this.parseActor(row)},
            { title: 'Verb', dataIndex: 'verb'},
            { title: 'Action', dataIndex: 'action', render: (item, row) => this.parseAction(row)},
            { title: 'Target', render: (item, row) => this.parseTarget(row)}
        ];

        return(
            <Fragment>
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col />
                    <Col>
                        <Space>
                            <Tooltip title='Reload' placement='left'>
                                <Button icon={<UndoOutlined />} type='circle' size='small' onClick={() => this.adminLoadLogs()} loading={this.state.loading} disabled={this.state.loading} />
                            </Tooltip>
                            <Pagination onChange={(page, pageSize) => this.adminLoadLogs(page, pageSize)} size='small' {...this.state.pagination} />
                        </Space>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    rowKey={(item) => item.id}
                    dataSource={this.state.logs}
                    loading={this.state.loading}
                    pagination={false}
                    footer={() => <Row justify='space-between'>
                        <Col />
                        <Col><Pagination onChange={(page, pageSize) => this.adminLoadLogs(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                    </Row>}
                />
            </Fragment>
        )
    }
}

export default connect(null, { updatePage, adminLoadLogs })(Logs);