import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Button, Form, Input, Modal, Select, Space} from "antd";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {loadAllCompanies} from "../../actions/users";
import {adminLoadAllCompanysDomainGroups} from "../../actions/domains";
import {adminLoadAllIPNodes} from "../../actions/network";

class DomainsTableFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFilter: 'DOMAIN',
            filterValue: '',
            filterKey: 'domain',
            filterVisible: null,

            loadingCompanies: false,
            companiesLoaded: false,
            companies: [],
            domainGroups: [],
            loadingDomainGroups: false,
            selectedCompany: '',
            loadingIPNodes: false,
            ipNodesLoaded: false,
            ipNodes: []
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingCompanies && !this.state.companiesLoaded) {
            this.setState({ loadingCompanies: true, loadingIPNodes: true })

            this.props.loadAllCompanies((res) => {
                this.setState({ loadingCompanies: false, companiesLoaded: true, companies: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingCompanies: false, companiesLoaded: true });
                    displayErrors(err.response.data);
                }
            });

            this.props.adminLoadAllIPNodes((res) => {
                this.setState({ loadingIPNodes: false, ipNodesLoaded: true, ipNodes: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingIPNodes: false, ipNodesLoaded: true });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    onChange(){
        let visibleValue = null;

        if(this.state.selectedFilter === 'COMPANY') {
            visibleValue = this.getCompanyName(this.state.filterValue);
        } else if(this.state.selectedFilter === 'IP_NODE') {
            visibleValue = this.getIPNodeName(this.state.filterValue);
        }

        this.props.onChange({
            name: normalizeEnum(this.state.selectedFilter),
            key: this.state.filterKey,
            value: this.state.filterValue.trim(),
            visible: visibleValue
        });
    }

    closeModal(apply = false, close = true) {
        this.setState({
            selectedFilter: 'DOMAIN',
            filterValue: '',
            filterKey: 'domain',
            filterVisible: null,
        });

        if(close) {
            this.props.closeModal();
        }

        if(apply) {
            this.onChange();
        }
    }

    getCompanyName(guid) {
        let company = null;

        for(let i = 0; i < this.state.companies.length; i++) {
            if(this.state.companies[i].guid === guid) {
                company = this.state.companies[i];
                break;
            }
        }

        return company !== null ? company.name : '';
    }

    getIPNodeName(guid) {
        let ipNode = null;

        for(let i = 0; i < this.state.ipNodes.length; i++) {
            if(this.state.ipNodes[i].guid === guid) {
                ipNode = this.state.ipNodes[i];
                break;
            }
        }

        return ipNode !== null ? ipNode.hostname : '';
    }

    setFilter(name) {
        const filters = {
            DOMAIN: { key: 'domain', value: '' },
            IS_SUBDOMAIN: { key: 'is_subdomain', value: false },
            STATUS: { key: 'status', value: 'ACTIVE' },
            COMPANY: { key: 'company', value: '' },
            IP_ADDRESS: { key: 'ip_address', value: '' },
            GROUP: { key: 'group', value: '' },
            OWNER_EMAIL: { key: 'owner_email', value: '' },
            IP_NODE: { key: 'ip_node', value: '' },
        };

        this.setState({ selectedFilter: name, filterKey: filters[name].key, filterValue: filters[name].value });
    }

    loadCompanyDomainGroups(owner_id) {
        this.setState({ loadingDomainGroups: true, selectedCompany: owner_id });

        this.props.adminLoadAllCompanysDomainGroups(owner_id, (res) => {
            this.setState({ domainGroups: res.data.data, loadingDomainGroups: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingDomainGroups: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        let content = '';

        if(this.state.selectedFilter === 'DOMAIN') {
            content = <Input value={this.state.filterValue}
                             onChange={(e) => this.setState({filterValue: e.target.value})}/>;
        } else if(this.state.selectedFilter === 'IP_ADDRESS') {
            content = <Input value={this.state.filterValue}
                             onChange={(e) => this.setState({filterValue: e.target.value})}/>;
        } else if(this.state.selectedFilter === 'OWNER_EMAIL') {
            content = <Input value={this.state.filterValue}
                             onChange={(e) => this.setState({filterValue: e.target.value})}/>;
        } else if(this.state.selectedFilter === 'IS_SUBDOMAIN') {
            content = <Select onChange={(value) => this.setState({ filterValue: value })}>
                <Option value={false}>No</Option>
                <Option value={true}>Yes</Option>
            </Select>
        } else if(this.state.selectedFilter === 'STATUS') {
            content = <Select onChange={(value) => this.setState({ filterValue: value })}>
                <Option value='PENDING'>Pending</Option>
                <Option value='ADDING'>Adding</Option>
                <Option value='ACTIVE'>Active</Option>
                <Option value='DELETING'>Deleting</Option>
                <Option value='DELETED'>Deleted</Option>
                <Option value='DISABLED'>Disabled</Option>
                <Option value='SUSPENDED'>Suspended</Option>
            </Select>
        } else if(this.state.selectedFilter === 'COMPANY') {
            content = <Select
                filterOption={(input, option) => {
                    if(typeof option !== 'undefined') {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                }}
                loading={this.state.loadingCompanies}
                disabled={this.state.loadingCompanies}
                value={this.state.filterValue}
                showSearch
                allowClear
                onChange={(value) => this.setState({ filterValue: value })}>
                {this.state.companies.map((company, i) => {
                    return <Option value={company.guid}>{company.name}</Option>
                })}
            </Select>
        } else if(this.state.selectedFilter === 'IP_NODE') {
            content = <Select
                filterOption={(input, option) => {
                    if(typeof option !== 'undefined') {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                }}
                loading={this.state.loadingCompanies}
                disabled={this.state.loadingCompanies}
                value={this.state.filterValue}
                showSearch
                allowClear
                onChange={(value) => this.setState({ filterValue: value })}>
                {this.state.ipNodes.map((ip_node, i) => {
                    return <Option value={ip_node.guid}>{ip_node.hostname}</Option>
                })}
            </Select>
        } else if(this.state.selectedFilter === 'GROUP') {
            content = <Fragment>
                <Form.Item label='Select company:'>
                    <Select
                        loading={this.state.loadingCompanies}
                        disabled={this.state.loadingCompanies}
                        filterOption={(input, option) => {
                            if(typeof option !== 'undefined') {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }
                            return false;
                        }}
                        value={this.state.selectedCompany}
                        showSearch
                        allowClear
                        onChange={(value) => this.loadCompanyDomainGroups(value)}>
                        {this.state.companies.map((company, i) => {
                            return <Option value={company.guid}>{company.name}</Option>
                        })}
                    </Select>
                </Form.Item>
                <Form.Item label='Select domain group:'>
                    <Select
                        loading={this.state.loadingDomainGroups}
                        disabled={this.state.loadingDomainGroups}
                        filterOption={(input, option) => {
                            if(typeof option !== 'undefined') {
                                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                            }
                            return false;
                        }}
                        value={this.state.filterValue}
                        showSearch
                        allowClear
                        onChange={(value) => this.setState({ filterValue: value })}>
                        {this.state.domainGroups.map((group, i) => {
                            return <Option value={group.guid}>{group.name}</Option>
                        })}
                    </Select>
                </Form.Item>
            </Fragment>
        }

        return(
            <Modal
                title='Add Filter'
                destroyOnClose={true}
                onCancel={() => this.closeModal()}
                footer={<Space>
                    <Button onClick={() => this.closeModal()}>Close</Button>
                    <Button onClick={() => this.closeModal(true, false)}>Apply</Button>
                    <Button type='primary' onClick={() => this.closeModal(true, true)}>Apply & Close</Button>
                </Space>}
                visible={this.props.showModal}>
                <Form layout='vertical'>
                    <Form.Item label='Select filter:'>
                        <Select value={this.state.selectedFilter} onChange={(value) => this.setFilter(value)}>
                            <Option value='DOMAIN'>Domain</Option>
                            <Option value='IS_SUBDOMAIN'>Is subdomain?</Option>
                            <Option value='STATUS'>Status</Option>
                            <Option value='COMPANY'>Company</Option>
                            <Option value='IP_ADDRESS'>IP address</Option>
                            <Option value='GROUP'>Domain group</Option>
                            <Option value='OWNER_EMAIL'>Owner email</Option>
                            <Option value='IP_NODE'>IP node</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        {content}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { loadAllCompanies, adminLoadAllCompanysDomainGroups,
    adminLoadAllIPNodes })(DomainsTableFilter);