import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Tabs} from "antd";
import OrdersTable from "../../components/billing/OrdersTable";
import InvoicesTable from "../../components/billing/InvoicesTable";
import CancellationRequestsTable from "../../components/billing/CancellationRequestsTable";
import CreditNotesTable from "../../components/billing/CreditNotesTable";
import TransactionsTable from "../../components/billing/TransactionsTable";

class Billing extends React.Component {
    state = {
        pages: ['orders', 'invoices', 'credit-notes', 'cancellation-requests', 'transactions'],
        titles: {
            'orders': 'Orders',
            'invoices': 'Invoices',
            'credit-notes': 'Credit Notes',
            'cancellation-requests': 'Cancellation Requests',
            'transactions': 'Transactions'
        }
    };

    changeTab(page) {
        if(this.state.pages.indexOf(page) > -1) {
            this.props.updatePage(this.state.titles[page]);

            if(page === 'orders') {
                this.props.history.push('/billing');
            } else {
                this.props.history.push('/billing/' + page);
            }
        }
    }

    render() {
        const { TabPane } = Tabs;

        let page = 'orders';

        if(typeof this.props.match.params.page !== 'undefined' && this.state.pages.indexOf(this.props.match.params.page)) {
            page = this.props.match.params.page;
        }

        return(
            <Tabs defaultActiveKey={page} onChange={(e) => this.changeTab(e)} destroyInactiveTabPane={true}>
                <TabPane tab='Orders' key='orders'>
                    <OrdersTable history={this.props.history} />
                </TabPane>
                <TabPane tab='Invoices' key='invoices'>
                    <InvoicesTable history={this.props.history} />
                </TabPane>
                <TabPane tab='Credit Notes' key='credit-notes'>
                    <CreditNotesTable history={this.props.history} />
                </TabPane>
                <TabPane tab='Transactions' key='transactions'>
                    <TransactionsTable history={this.props.history} />
                </TabPane>
                <TabPane tab='Cancellation Requests' key='cancellation-requests'>
                    <CancellationRequestsTable history={this.props.history} />
                </TabPane>
            </Tabs>
        );
    }
}

export default connect(null, { updatePage })(Billing);