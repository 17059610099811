import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {Button, Descriptions, Modal, Table} from "antd";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {MoneyField} from "../shared/MoneyField";
import {normalizeEnum} from "../../libs/utils";

class TransactionDetailModal extends React.Component {
    closeModal() {
        this.props.close();
    }

    render() {
        return(
            <Modal
                centered
                title='Transaction'
                visible={this.props.showModal}
                destroyOnClose={true}
                onCancel={() => this.closeModal()}
                footer={<Button onClick={() => this.closeModal()}>Close</Button>}>

                <Descriptions column={1} size='small' bordered>
                    <Descriptions.Item label='ID:'>{this.props.activeTransaction.guid}</Descriptions.Item>
                    <Descriptions.Item label='Created:'><DateTimeFormat value={this.props.activeTransaction.date_created} /></Descriptions.Item>
                    <Descriptions.Item label='Amount:'><MoneyField amount={parseFloat(this.props.activeTransaction.amount)} currency={this.props.activeTransaction.currency} /> {this.props.activeTransaction.currency}</Descriptions.Item>
                    <Descriptions.Item label='Method:'>{normalizeEnum(this.props.activeTransaction.payment_method_type)}</Descriptions.Item>
                    <Descriptions.Item label='Transaction ID:'>{this.props.activeTransaction.transaction_id}</Descriptions.Item>
                    <Descriptions.Item label='Hidden trans. ID:'>{this.props.activeTransaction.hidden_transaction_id}</Descriptions.Item>
                </Descriptions>
                <br />
                <Table
                    rowKey={(item) => item.guid}
                    pagination={false}
                    size='small'
                    dataSource={this.props.activeTransaction.associated_invoices}
                    columns={[
                        {title: 'Invoice ID', dataIndex: 'id', render: (item, record) => <Link to={'/billing/invoices/' + item}>#{item}</Link>},
                        {title: 'Status', dataIndex: 'status', render: (item, record) => normalizeEnum(item)}
                    ]}
                />

            </Modal>
        );
    }
}

export default connect(null, { })(TransactionDetailModal);