import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Card, Tabs} from "antd";
import AffiliateOverview from "../../components/affiliate/AffiliateOverview";
import AffiliateReports from "../../components/affiliate/AffiliateReports";
import AffiliateBilling from "../../components/affiliate/AffiliateBilling";

class Affiliate extends React.Component {
    state = {
        accountExists: false,
        activeTab: 'overview',
        pages: ['overview', 'reports', 'billing'],
        titles: {
            overview: 'Overview',
            reports: 'Reports',
            billing: 'Billing'
        }
    }

    componentDidMount() {
        this.props.updatePage('Affiliate');

        let page = 'overview';

        if(typeof this.props.match.params.page !== 'undefined') {
            page = this.props.match.params.page;
        }

        this.setState({ activeTab: page })
    }

    changeTab(page) {
        if(this.state.pages.indexOf(page) > -1) {
            this.setState({ activeTab: page });

            this.props.updatePage(this.state.titles[page]);

            let url = '/affiliate';

            if(page !== 'overview') {
                url = '/affiliate/' + page;
            }

            this.props.history.push(url);
        }
    }

    render() {
        const items = [
            { label: 'Overview', key: 'overview', children: <AffiliateOverview /> },
            { label: 'Reports', key: 'reports', children: <AffiliateReports /> },
            { label: 'Billing', key: 'billing', children: <AffiliateBilling /> },
        ];

        return <Card size='small'>
            <Tabs activeKey={this.state.activeTab} onChange={(tab) => this.changeTab(tab)}
                  destroyInactiveTabPane={true} items={items} />
        </Card>;
    }
}

export default connect(null, { updatePage })(Affiliate);