import React from "react";
import {connect} from "react-redux";
import {Button, Form, message, Modal, Space} from "antd";
import TextArea from "antd/es/input/TextArea";
import {displayErrors} from "../../libs/utils";
import {adminCreateIssueUpdate} from "../../actions/system";

class NewIssueUpdateModal extends React.Component {
    state = {
        message: '',
        loading: false,
    };

    closeModal(reload = false) {
        this.setState({
            message: '',
            loading: false,
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    createUpdate() {
        let messageBody = this.state.message.trim();

        if(messageBody.length === 0) {
            return message.error('Please enter a message!');
        }

        let data = { message: messageBody };

        this.setState({ loading: true });

        this.props.adminCreateIssueUpdate(this.props.issueGuid, data, (res) => {
            this.setState({ loading: false });
            message.success('Issue update successfully created!');
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        return(
            <Modal
                title='Create Update'
                visible={this.props.showModal}
                footer={<Space>
                    <Button onClick={() => this.closeModal()} disabled={this.state.loading}>Close</Button>
                    <Button type='primary' onClick={() => this.createUpdate()} loading={this.state.loading} disabled={this.state.loading}>Create</Button>
                </Space>}
                onCancel={() => this.closeModal()}>
                <Form layout='vertical'>
                    <Form.Item label='Message'>
                        <TextArea rows={3} value={this.state.message} onChange={(e) => this.setState({ message: e.target.value })} />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { adminCreateIssueUpdate })(NewIssueUpdateModal);