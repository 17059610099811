import React from "react";
import {connect} from "react-redux";
import {Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Space, message} from "antd";
import {createFirewallPolicy, loadFirewallRules, loadFirewallRulesets} from "../../actions/firewall";
import {displayErrors, isNumeric} from "../../libs/utils";

class NewFirewallPolicyModal extends React.Component {
    state = {
        name: '',
        priority: 0,
        rulesets: [],
        selectedRulesets: [],
        loadingRulesets: true,
        rules: [],
        selectedRules: [],
        loadingRules: true,
        loadingData: false,
        dataLoaded: false,
        creating: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(!this.state.loadingData && this.props.showModal && !this.state.dataLoaded) {
            this.setState({ loadingData: true });

            this.props.loadFirewallRules(1, 9999, (res) => {
                let rules = res.data.data.map((rule) => {
                    return { label: rule.name, value: rule.guid };
                });

                this.setState({ rules, loadingRules: false });

            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingRules: false });
                    displayErrors(err.response.data);
                }
            });

            this.props.loadFirewallRulesets(1, 9999, (res) => {
                let rulesets = res.data.data.map((ruleset) => {
                    return { label: ruleset.name, value: ruleset.guid };
                });

                this.setState({ rulesets, loadingRulesets: false });

            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingRulesets: false });
                    displayErrors(err.response.data);
                }
            });
        }
    }


    closeModal(reload = false) {
        this.setState({
            name: '',
            priority: 0,
            rulesets: [],
            selectedRulesets: [],
            loadingRulesets: true,
            rules: [],
            selectedRules: [],
            loadingRules: true,
            loadingData: false,
            dataLoaded: false,
            creating: false
        });

        this.props.close();

        if(reload) {
            this.props.reloadPolicies();
        }
    }

    createPolicy() {
        let name = this.state.name.trim();
        let priority = this.state.priority;
        let rulesets = this.state.selectedRulesets;
        let rules = this.state.selectedRules;

        if(name.length === 0) {
            return message.error('Enter your policy\'s name!');
        }

        if(!isNumeric(priority)) {
            return message.error('Priority must be a number between 0 and 9999!');
        }

        if(rulesets.length === 0 && rules.length === 0) {
            return message.error('Select at least one ruleset or rule!');
        }

        let data = {
            name, priority, rulesets, rules
        };

        this.setState({ creating: true });

        this.props.createFirewallPolicy(data, (res) => {
            message.success('Firewall policy successfully created!');
            this.setState({ creating: true });
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ creating: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        return(
            <Modal
                title='Add Firewall Policy'
                visible={this.props.showModal}
                destroyOnClose={true}
                footer={
                    <Space>
                        <Button onClick={() => this.closeModal()}>Close</Button>
                        <Button type='primary' onClick={() => this.createPolicy()}>Create</Button>
                    </Space>
                }
                onCancel={() => this.closeModal()}>

                <Form layout='vertical'>
                    <Row gutter={[16, 16]}>
                        <Col span={19}>
                            <Form.Item label='Name'>
                                <Input value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                            </Form.Item>
                        </Col>
                        <Col span={5}>
                            <Form.Item label='Priority'>
                                <InputNumber value={this.state.priority} onChange={(value) => this.setState({ priority: value })} min={0} max={9999} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider plain>Rules</Divider>
                    <Form.Item label='Rule sets'>
                        <Select
                            options={this.state.rulesets}
                            loading={this.state.loadingRulesets}
                            mode='multiple' showSearch allowClear
                            onChange={(values) => this.setState({ selectedRulesets: values })}
                            filterOption={(input, option) => {
                                if(typeof option !== 'undefined') {
                                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                                return false;
                            }} />
                    </Form.Item>
                    <Form.Item label='Rules'>
                        <Select
                            options={this.state.rules}
                            loading={this.state.loadingRules}
                            mode='multiple' showSearch allowClear
                            onChange={(values) => this.setState({ selectedRules: values })}
                            filterOption={(input, option) => {
                                if(typeof option !== 'undefined') {
                                    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                                return false;
                            }} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { loadFirewallRules, loadFirewallRulesets, createFirewallPolicy })(NewFirewallPolicyModal);