import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Col, Form, Input, Row, message, Typography, InputNumber} from "antd";
import {PlayCircleOutlined} from "@ant-design/icons";
import {adminRunPingTool} from "../../actions/tools";
import {displayErrors} from "../../libs/utils";

class PingTools extends React.Component {
    state = {
        value: '',
        result: '',
        count: 5,
        loading: false
    };

    componentDidMount() {
        this.props.updatePage('Ping');
    }

    runPing() {
        let value = this.state.value;
        let count = this.state.count;

        if(value.length === 0) {
            return message.error('Please enter a domain or an IP address!');
        }

        if(count.length === 0) {
            return message.error('Please enter ping count!');
        }

        let data = { value, count };

        this.setState({ loading: true });

        this.props.adminRunPingTool(data, (res) => {
            this.setState({ loading: false, result: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Paragraph } = Typography;

        return(
            <Form layout='vertical'>
                <Row gutter={[16, 16]}>
                    <Col span={18}>
                        <Form.Item label='Enter IP address or domain'>
                            <Input disabled={this.state.loading} onChange={(e) => this.setState({ value: e.target.value })} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label='Count'>
                            <InputNumber min={1} max={50} value={this.state.count} disabled={this.state.loading} onChange={(value) => this.setState({ count: value })} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label='&nbsp;'>
                            <Button type='primary' icon={<PlayCircleOutlined />} loading={this.state.loading} disabled={this.state.loading} onClick={() => this.runPing()}>Run</Button>
                        </Form.Item>
                    </Col>
                </Row>
                <Row hidden={this.state.result === ''}>
                    <Col span={24}>
                        <Paragraph><pre>{this.state.result.trim()}</pre></Paragraph>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default connect(null, { updatePage, adminRunPingTool })(PingTools);