import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Col, Form, Row, Select, Table} from "antd";
import {displayErrors} from "../../libs/utils";
import {adminBillingReportsUpcomingOrders} from "../../actions/accounting";
import {MoneyField} from "../shared/MoneyField";

class UpcomingOrdersReport extends React.Component {
    state = {
        generating: false,
        orderType: 'IP_ADDRESSES'
    };

    componentDidMount() {
        this.props.updatePage('Upcoming Orders');
    }

    generateReport() {
        this.setState({ generating: true });

        this.props.adminBillingReportsUpcomingOrders(this.state.orderType, (res) => {
            this.setState({ generating: false, lines: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ generating: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const reportColumns = [
            { title: 'Day', dataIndex: 'day' },
            { title: 'Orders', dataIndex: 'order_count' },
            { title: 'Amount', dataIndex: 'amount', render: (item, record) => <MoneyField amount={item} currency='USD' /> },
        ];

        const { Option } = Select;

        return(
            <Fragment>
                <Form layout='vertical'>
                    <Row gutter={[16, 16]}>
                        <Col span={3}>
                            <Form.Item label='Select order type:'>
                                <Select value={this.state.orderType} onChange={(value) => this.setState({ orderType: value })}>
                                    <Option value='IP_ADDRESSES'>IP addresses</Option>
                                    <Option value='SHARED_HOSTING'>Shared hosting</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label='&nbsp;'>
                                <Button type='primary' disabled={this.state.generating} loading={this.state.generating}
                                        onClick={() => this.generateReport()}>Generate</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Table
                    dataSource={this.state.lines}
                    columns={reportColumns}
                    rowKey={(item) => item.day}
                    summary={pageData => {
                        if(pageData.length === 0) {
                            return <Fragment />;
                        }

                        let totalSum = 0;

                        for(let i = 0; i < pageData.length; i++) {
                            totalSum += pageData[i].amount;
                        }

                        return (
                            <Table.Summary.Row>
                                <Table.Summary.Cell colSpan={2} />
                                <Table.Summary.Cell>
                                    <MoneyField amount={totalSum} currency='USD' />
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        );
                    }}
                    pagination={false} size='small' />
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminBillingReportsUpcomingOrders })(UpcomingOrdersReport);