import {httpDelete, httpGet, httpGetDownload, httpPatch, httpPost} from "../libs/http";

export const getErrorMessage = (code, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/system/errors/' + code + '/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadAnnouncements = (page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/system/announcements/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminUpdateAnnouncementField = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPatch('/admin/system/announcements/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadIssues = (page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/system/issues/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateIssue = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/system/issues/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminUpdateIssue = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/system/issues/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadIssue = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/system/issues/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateIssueUpdate = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/system/issues/' + guid + '/updates/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadIssueUpdates = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/system/issues/' + guid + '/updates/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminDeleteIssueUpdate = (issue_id, update_id, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/admin/system/issues/' + issue_id + '/updates/' + update_id + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadIssuesNotification = ( onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/system/issue-notifications/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadConfigurationFiles = (page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/system/config-files/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminNewConfigurationFile = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/system/config-files/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadConfigurationFile = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/system/config-files/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminDeleteConfigurationFile = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/admin/system/config-files/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminUpdateConfigurationFile = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/system/config-files/' + guid + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminDownloadConfigurationFile = (guid, file_name, onSuccess = null, onFailure = null) => dispatch => {
    httpGetDownload('/system/config-files/download/' + guid + '/' + file_name, (res) => onSuccess(res), (err) => onFailure(err));
};