import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, InputNumber, Modal, Space, message} from "antd";
import {displayErrors, isNumeric} from "../../libs/utils";
import {createIPAddressBlocks} from "../../actions/firewall";

class NewIPAddressBlockModal extends React.Component {
    state = {
        ipAddresses: '',
        blockLength: 600,
        creating: false,
    };

    closeModal(reload = false) {
        this.setState({
            ipAddresses: '',
            blockLength: 600,
            creating: false,
        });

        this.props.close();

        if(reload) {
            this.props.reloadBlocks();
        }
    }

    blockIPAddresses() {
        let ip_addresses = this.state.ipAddresses.trim().split('\n');
        let block_length = this.state.blockLength;

        if(ip_addresses.length === 0) {
            return message.error('Enter at least 1 IP address!');
        }

        if(!isNumeric(block_length)) {
            return message.error('Block length must be a number in seconds!');
        }

        let data = { action: 'CREATE', ip_addresses, block_length };

        this.setState({ creating: true });

        this.props.createIPAddressBlocks(data, () => {
            message.success('IP addresses successfully blocked!!');
            this.setState({ creating: false });
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ creating: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { TextArea } = Input;

        return(
            <Modal
                title='Add IP Address Blocks'
                visible={this.props.showModal}
                destroyOnClose={true}
                footer={
                    <Space>
                        <Button onClick={() => this.closeModal()}>Close</Button>
                        <Button type='primary' onClick={() => this.blockIPAddresses()}>Add</Button>
                    </Space>
                }
                onCancel={() => this.closeModal()}>

                <Form layout='vertical'>
                    <Form.Item label='IP addresses'>
                        <TextArea rows={5} onChange={(e) => this.setState({ ipAddresses: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Block length in seconds'>
                        <InputNumber value={this.state.blockLength} onChange={(value) => this.setState({ blockLength: value })} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { createIPAddressBlocks  })(NewIPAddressBlockModal);