import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import MonthlyGroupedReport from "../../components/accounting/MonthlyGroupedReport";
import DailyOrdersGroupedReport from "../../components/accounting/DailyOrdersGroupedReport";
import UpcomingOrdersReport from "../../components/accounting/UpcomingOrdersReport";
import PaypalTabFile from "../../components/accounting/PaypalTabFile";
import MonthlyInvoicesReport from "../../components/accounting/MonthlyInvoicesReport";
import MonthlyRefundsReport from "../../components/accounting/MonthlyRefundsReport";

class AccountingReportView extends React.Component {
    render() {
        let report = this.props.match.params.report;

        if(report === 'monthly-grouped') {
            return <MonthlyGroupedReport />;
        } else if(report === 'daily-orders') {
            return <DailyOrdersGroupedReport />;
        } else if(report === 'upcoming-orders') {
            return <UpcomingOrdersReport />;
        } else if(report === 'paypal-tab-file') {
            return <PaypalTabFile />;
        } else if(report === 'monthly-invoices') {
            return <MonthlyInvoicesReport />
        } else if(report === 'monthly-refunds') {
            return <MonthlyRefundsReport />;
        }
    }
}

export default connect(null, { updatePage })(AccountingReportView);