import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Tabs} from "antd";
import IPAddressTools from "../../components/tools/ip_addresses";
import DigTools from "../../components/tools/dig";
import PingTools from "../../components/tools/ping";
import TracerouteTool from "../../components/tools/traceroute";
import CurlTool from "../../components/tools/curl";
import RouterResolverTool from "../../components/tools/router";
import RequestIdCheckTool from "../../components/tools/request_check";
import DomainStatusChecker from "../../components/tools/domain_status_checker";
import WhoisTool from "../../components/tools/whois";

class Tools extends React.Component {
    state = {
        pages: ['ip-address', 'dig', 'ping', 'traceroute', 'curl', 'router-resolver', 'request-check',
            'domain-status-checker', 'whois'],
    };

    changeTab(page) {
        if(this.state.pages.indexOf(page) > -1) {
            if(page === 'ip-address') {
                this.props.history.push('/tools');
            } else {
                this.props.history.push('/tools/' + page);
            }
        }
    }

    render() {
        const { TabPane } = Tabs;

        let page = 'ip-address';

        if(typeof this.props.match.params.page !== 'undefined' && this.state.pages.indexOf(this.props.match.params.page)) {
            page = this.props.match.params.page;
        }

        return(
            <Tabs defaultActiveKey={page} onChange={(e) => this.changeTab(e)} destroyInactiveTabPane={true}>
                <TabPane tab='Subnet Converter' key='ip-address'>
                    <IPAddressTools />
                </TabPane>
                <TabPane tab='Dig' key='dig'>
                    <DigTools />
                </TabPane>
                <TabPane tab='Ping' key='ping'>
                    <PingTools />
                </TabPane>
                <TabPane tab='Traceroute' key='traceroute'>
                    <TracerouteTool />
                </TabPane>
                <TabPane tab='Curl' key='curl'>
                    <CurlTool />
                </TabPane>
                <TabPane tab='Router Resolver' key='router-resolver'>
                    <RouterResolverTool />
                </TabPane>
                <TabPane tab='Request Check' key='request-check'>
                    <RequestIdCheckTool />
                </TabPane>
                <TabPane tab='Domain Status Checker' key='domain-status-checker'>
                    <DomainStatusChecker />
                </TabPane>
                <TabPane tab='Whois' key='whois'>
                    <WhoisTool />
                </TabPane>
            </Tabs>
        );
    }
}

export default connect(null, { updatePage })(Tools);