import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, Modal, Space, message} from "antd";
import {adminUpdateSharedHostingServerField} from "../../actions/network";
import {displayErrors} from "../../libs/utils";

class SharedHostingServerHashModal extends React.Component {
    state = {
        hash: '',
    };

    closeModal() {
        this.setState({
            hash: ''
        });

        this.props.close();
    }

    saveHash() {
        let hash = this.state.hash.trim();

        if(hash.length === 0) {
            return message.error('Please enter hash!');
        }

        let data = { hash };

        this.setState({ loading: true });

        this.props.adminUpdateSharedHostingServerField(this.props.guid, data, (res) => {
            this.setState({ loading: false });
            message.success('Hash successfully updated!');
            this.closeModal();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        return(
            <Modal
                title='Edit Hash'
                visible={this.props.showModal}
                footer={<Space>
                    <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Close</Button>
                    <Button loading={this.state.loading}  disabled={this.state.loading}  type='primary' onClick={() => this.saveHash()}>Save</Button>
                </Space>}
                onCancel={() => this.closeModal()}>
                <Form layout='vertical'>
                    <Form.Item label='Hash'>
                        <Input value={this.state.hash} onChange={(e) => this.setState({ hash: e.target.value })} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { adminUpdateSharedHostingServerField })(SharedHostingServerHashModal);