import React from 'react';
import {connect} from "react-redux";
import {Button, Form, Modal, Select, Space, message} from "antd";
import {adminBulkUpdateDomains} from "../../actions/domains";
import {displayErrors} from "../../libs/utils";

class ChangeDomainStatusModal extends React.Component {
    state = {
        selectedStatus: '',
        loading: false,
    };

    closeModal(reload = false) {
        this.props.close();

        this.setState({
            selectedStatus: '',
            loading: false,
        });

        if(reload) {
            this.props.reload();
        }
    }

    updateDomains() {
        if(this.props.selectedDomains.length === 0) {
            return message.error('Please select at least 1 domain!');
        }

        if(this.state.selectedStatus === '') {
            return message.error('Please select the status!');
        }

        let data = {
            status: this.state.selectedStatus,
            domains: this.props.selectedDomains
        };

        this.setState({ loading: true });

        this.props.adminBulkUpdateDomains(data, () => {
            this.setState({ loading: false });
            this.closeModal(true);
            message.success('Domains successfully updated!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        return <Modal
            visible={this.props.showModal}
            destroyOnClose={true}
            onCancel={() => this.closeModal()}
            footer={<Space>
                <Button onClick={() => this.closeModal()} disabled={this.state.loading}>Close</Button>
                <Button type='primary' onClick={() => this.updateDomains()} loading={this.state.loading} disabled={this.state.loading}>Update</Button>
            </Space>}
            title='Update Domain Status'>
            <Form layout='vertical'>
                <Form.Item label='Status'>
                    <Select onChange={(value) => this.setState({ selectedStatus: value })}>
                        <Option value='ACTIVE'>Active</Option>
                        <Option value='SUSPENDED'>Suspended</Option>
                        <Option value='DELETED'>Deleted</Option>
                        <Option value='DISABLED'>Disabled</Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    }
}

export default connect(null, { adminBulkUpdateDomains })(ChangeDomainStatusModal);