import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, Modal, Select, Space, Tooltip, message} from "antd";
import {displayErrors} from "../../libs/utils";
import {adminCreateNewCreditTransaction} from "../../actions/billing";
import {RetweetOutlined} from "@ant-design/icons";

class NewCreditTransactionModal extends React.Component {
    state = {
        paymentMethods: [],
        creditTransactions: [],
        creditVouchers: [],
        loadingPaymentMethods: false,

        amount: 0,
        currency: 'USD',
        forceCurrency: false,
        paymentMethodType: 'PAYPAL',
        paymentMethod: '',
        transactionId: '',
        hiddenTransactionId: '',
        creditTransaction: '',
        note: ''
    };

    componentDidMount() {
        this.setState({ currency: this.props.currency });
    }

    closeModal(reload = false) {
        this.setState({
            paymentMethods: [],
            creditTransactions: [],
            creditVouchers: [],
            loadingPaymentMethods: false,

            amount: 0,
            currency: 'USD',
            forceCurrency: false,
            paymentMethodType: 'PAYPAL',
            paymentMethod: '',
            transactionId: '',
            hiddenTransactionId: '',
            creditTransaction: '',
            note: ''
        });

        this.props.close();

        if(reload) {
            this.props.reloadCreditTransactions();
        }
    }

    createCreditTransaction() {
        let amount = parseFloat(this.state.amount);
        let currency = this.state.currency.trim();
        let payment_method_type = this.state.paymentMethodType.trim();
        let transaction_id = this.state.transactionId.trim();
        let hidden_transaction_id = this.state.hiddenTransactionId.trim();
        let note = this.state.note.trim();

        if(amount === 0) {
            return message.error('Please enter the transaction amount!');
        }

        if(currency.length === 0) {
            return message.error('Please enter the transaction currency!');
        }

        if(payment_method_type !== 'CREDIT') {
            if(payment_method_type.length === 0) {
                return message.error('Please enter the transaction payment method type!');
            }

            if(transaction_id.length === 0) {
                return message.error('Please enter the transaction id!');
            }
        }

        let data = { amount, currency, payment_method_type, note };

        if(transaction_id.length > 0) {
            data['transaction_id'] = transaction_id;
        }

        if(hidden_transaction_id.length > 0) {
            data['hidden_transaction_id'] = hidden_transaction_id;
        }

        console.log(currency);

        this.props.adminCreateNewCreditTransaction(this.props.creditNote, data, () => {
            this.setState({ creating: false });
            this.closeModal(true);

            message.success('Transaction successfully created!');

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ creating: false });
                displayErrors(err.response.data);
            }
        });
    }

    async generateTransactionId() {
        const msgBuffer = new TextEncoder().encode(Date.now());
        const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('').toUpperCase().substring(1, 18);

        this.setState({ transactionId: hashHex });
    }

    render() {
        const { Option } = Select;
        const { TextArea } = Input;

        const currencySelector = <Select
            defaultValue={this.state.currency}
            onChange={(value) => this.setState({ currency: value })}>
            <Option value='USD'>USD</Option>
            <Option value='EUR'>EUR</Option>
        </Select>;

        const generateTransactionId = <Tooltip title='Generate'><RetweetOutlined onClick={() => this.generateTransactionId()} /></Tooltip>;

        return(
            <Modal
                visible={this.props.showModal}
                destroyOnClose={true}
                footer={<Space>
                    <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Close</Button>
                    <Button type='primary' loading={this.state.loading} disabled={this.state.loading} onClick={() => this.createCreditTransaction()}>Create</Button>
                </Space>}
                onCancel={() => this.closeModal()}
                title='New Transaction'>
                <Form layout='vertical'>
                    <Form.Item label='Amount:'>
                        <Input addonAfter={currencySelector} value={this.state.amount}
                               onChange={(e) => this.setState({ amount: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Payment method type:'>
                        <Select value={this.state.paymentMethodType} onChange={(value) => this.setState({ paymentMethodType: value })}>
                            <Option value='PAYPAL'>Paypal</Option>
                            <Option value='CARD'>Card</Option>
                            <Option value='BANK_TRANSFER'>Bank transfer</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item hidden={this.state.paymentMethodType === 'CREDIT'} label='Transaction ID:'>
                        <Input value={this.state.transactionId} addonAfter={generateTransactionId}
                               onChange={(e) => this.setState({ transactionId: e.target.value })} />
                    </Form.Item>
                    <Form.Item hidden={this.state.paymentMethodType === 'CREDIT'} label='Hidden transaction ID:'>
                        <Input value={this.state.hiddenTransactionId}
                               onChange={(e) => this.setState({ hiddenTransactionId: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Note:'>
                        <TextArea onChange={(e) => this.setState({ note: e.target.value })} value={this.state.note} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { adminCreateNewCreditTransaction })(NewCreditTransactionModal);