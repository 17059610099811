import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    adminLoadUser,
    adminLoadUserCompanies,
    adminLoadUserLogins, adminResendVerificationEmail,
    adminResetPassword,
    adminUpdateUserField
} from "../../actions/accounts";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {Loading} from "../../libs/loading";
import {Button, Card, Col, Descriptions, Input, message, Modal, Popconfirm, Row, Select, Space, Table} from "antd";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {DEFAULT_PER_PAGE} from "../../config";
import {Link} from "react-router-dom";
import {
    ExclamationCircleOutlined,
    EyeOutlined,
    MailOutlined,
    RetweetOutlined,
    SaveOutlined
} from "@ant-design/icons";
import {adminLoadUserEmails} from "../../actions/users";
import UserNewEmailModal from "./UserNewEmailModal";
import UserEmailViewModal from "./UserEmailViewModal";
import {StatusIndicator} from "../shared/Status";

class UserView extends React.Component {
    state = {
        loadingUser: false,
        user: null,
        logins: [],
        companies: [],
        loginsPagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        companiesPagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        loadingLogins: false,
        loadingCompanies: false,
        userEmail: '',
        updatingEmail: false,
        userStatus: '',
        updatingStatus: false,
        updatingFailedLoginsCount: false,
        failedLoginCount: 0,
        emails: [],
        loadingEmails: false,
        emailsPagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        resettingPassword: false,
        sendingVerificationEmail: false,
    };

    componentDidMount() {
        this.adminLoadUser();
        this.adminLoadLogins();
        this.adminLoadUserCompanies();
        this.adminLoadUserEmails();
    }

    adminLoadUserEmails(page = null, per_page = null) {
        if(page == null) {
            page = this.state.emailsPagination.current;
            per_page = this.state.emailsPagination.pageSize;
        }

        this.setState({ loadingEmails: true });

        this.props.adminLoadUserEmails(this.props.match.params.id, page, per_page, (res) => {
            this.setState({ emails: res.data.data, loadingEmails: false,
                emailsPagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingEmails: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadUser() {
        this.setState({ loadingUser: true });

        this.props.adminLoadUser(this.props.match.params.id, (res) => {
            this.setState({ loadingUser: false, user: res.data, failedLoginCount: res.data.failed_login_count,
                userEmail: res.data.email, userStatus: res.data.status });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingUser: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadLogins(page = null, per_page = null) {
        if(page == null) {
            page = this.state.loginsPagination.current;
            per_page = this.state.loginsPagination.pageSize;
        }

        this.setState({ loadingLogins: true });
        this.props.adminLoadUserLogins(this.props.match.params.id, page, per_page, (res) => {
            this.setState({ loadingLogins: false, logins: res.data.data,
                loginsPagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true } });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingLogins: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadUserCompanies(page = null, per_page = null) {
        if(page == null) {
            page = this.state.companiesPagination.current;
            per_page = this.state.companiesPagination.pageSize;
        }

        this.setState({ loadingCompanies: true });
        this.props.adminLoadUserCompanies(this.props.match.params.id, page, per_page, (res) => {
            this.setState({ loadingCompanies: false, companies: res.data.data,
                companiesPagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true } });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCompanies: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminChangeEmailAddress() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to change the email address?</span>,
            onOk: () => {
                this.setState({ updatingEmail: true });

                this.props.adminUpdateUserField(this.props.match.params.id, { 'email': this.state.userEmail.trim().toLowerCase() }, () => {
                    this.setState({ updatingEmail: false });
                    message.success('User email address successfully updated!');
                    this.adminLoadUser();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ updatingEmail: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    adminChangeFailedLoginCount() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to change the failed login count?</span>,
            onOk: () => {
                this.setState({ updatingFailedLoginCount: true });

                this.props.adminUpdateUserField(this.props.match.params.id, { 'failed_login_count': this.state.failedLoginCount }, () => {
                    this.setState({ updatingFailedLoginCount: false });
                    message.success('User failed login count successfully updated!');
                    this.adminLoadUser();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ updatingFailedLoginCount: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    adminChangeStatus(status) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to change status to <strong>{normalizeEnum(status)}</strong>?</span>,
            onOk: () => {
                this.setState({ updatingEmail: true });

                this.props.adminUpdateUserField(this.props.match.params.id, { 'status': status }, () => {
                    this.setState({ updatingEmail: false });
                    message.success('User status successfully updated!');
                    this.adminLoadUser();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ updatingEmail: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    resetUserPassword() {
        this.setState({ resettingPassword: true });

        this.props.adminResetPassword(this.state.user.guid,  () => {
            this.setState({ resettingPassword: false });
            message.success('Password reset email successfully sent!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ resettingPassword: false });
                displayErrors(err.response.data);
            }
        });
    }

    resendVerificationEmail() {
        this.setState({ sendingVerificationEmail: true });

        this.props.adminResendVerificationEmail(this.state.user.guid,  () => {
            this.setState({ sendingVerificationEmail: false });
            message.success('Verification email successfully sent!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ sendingVerificationEmail: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        if(this.state.user === null || this.state.loadingUser) {
            return <div className='text-center'><Loading /></div>;
        }

        const { user } = this.state;

        const loginColumns = [
            { title: 'Timestamp', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: 'Status', dataIndex: 'status', render: (item) => normalizeEnum(item)},
            { title: 'IP address', dataIndex: 'ip_address'},
            { title: 'Location', render: (item, record) => <span>{record.city}, {record.country}</span>},
        ];

        const companyColumns = [
            { title: 'Name', render: (item, record) => <Link to={'/accounts/companies/' + record.company.guid}>{record.company.name}</Link>},
            { title: 'Status', render: (item, record) => normalizeEnum(record.company.status)},
            { title: 'Created', render: (item, record) => <DateTimeFormat value={record.company.date_created} />},
        ];

        const emailsColumns = [
            { title: 'Subject', dataIndex: 'subject', render: (item) => item },
            { title: 'Sent', dataIndex: 'date_sent', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Is Sent', dataIndex: 'is_sent', align: 'center', render: (item) => item ? 'Yes' : 'No'},
            { title: '', render: (item, record) => <Button onClick={() => this.setState({ activeEmail: record.guid, showEmailModal: true })} size='small' icon={<EyeOutlined />}>View</Button>}
        ];

        const { Option } = Select;

        return(
            <Fragment>
                <Row gutter={[16, 16]}>
                    <Col md={10} xs={24}>
                        <Card size='small' title='Details'>
                            <Descriptions bordered column={1} size='small'>
                                <Descriptions.Item label='ID'>{user.id}</Descriptions.Item>
                                <Descriptions.Item label='First name'>{user.first_name}</Descriptions.Item>
                                <Descriptions.Item label='Last name'>{user.last_name}</Descriptions.Item>
                                <Descriptions.Item label='Type'>{normalizeEnum(user.account_type)}</Descriptions.Item>
                                <Descriptions.Item label='Email'>
                                    <Space>
                                        <Input disabled={this.state.updatingEmail} value={this.state.userEmail} onChange={(e) => this.setState({ userEmail: e.target.value })} />
                                        <Button loading={this.state.updatingEmail} disabled={this.state.updatingEmail} onClick={() => this.adminChangeEmailAddress()} size='small' type='circle' icon={<SaveOutlined />} />
                                    </Space>
                                </Descriptions.Item>
                                <Descriptions.Item label='Status'>
                                    <Select style={{width: '150px'}} value={this.state.userStatus} onChange={(value) => this.adminChangeStatus(value)}>
                                        <Option value='VERIFICATION'>Verification</Option>
                                        <Option value='ACTIVE'>Active</Option>
                                        <Option value='DISABLED'>Disabled</Option>
                                        <Option value='REJECTED'>Rejected</Option>
                                        <Option value='LOGIN_DISABLED'>Login disabled</Option>
                                        <Option value='DELETED'>Deleted</Option>
                                    </Select>
                                </Descriptions.Item>
                                <Descriptions.Item label='Created'><DateTimeFormat value={user.date_created} /></Descriptions.Item>
                                <Descriptions.Item label='Found us'>{user.found_us}</Descriptions.Item>
                                <Descriptions.Item label='Last login'><DateTimeFormat value={user.last_login} /></Descriptions.Item>
                                <Descriptions.Item label='2FA enabled'>
                                    <StatusIndicator status={user.user_2fa_status} el='badge' />
                                </Descriptions.Item>
                                <Descriptions.Item label='Failed logins'>
                                    <Space>
                                        <Input disabled={this.state.updatingFailedLoginsCount} value={this.state.failedLoginCount} onChange={(e) => this.setState({ failedLoginCount: e.target.value })} />
                                        <Button loading={this.state.updatingFailedLoginsCount} disabled={this.state.updatingFailedLoginsCount} onClick={() => this.adminChangeFailedLoginCount()} size='small' type='circle' icon={<SaveOutlined />} />
                                    </Space>
                                </Descriptions.Item>
                                <Descriptions.Item label='Actions'>
                                    <Space>
                                        {this.state.userStatus !== 'VERIFICATION' ? <Popconfirm
                                            title="Are you sure you wish to reset this user's password?"
                                            onConfirm={() => this.resetUserPassword()}
                                            okText='Reset'
                                            okButtonProps={{danger: true}}
                                            cancelText='Cancel'>
                                            <Button size='small' loading={this.state.resettingPassword} icon={<RetweetOutlined />}
                                                    disabled={this.state.resettingPassword}>Reset Password</Button>
                                        </Popconfirm> : ''}

                                        {this.state.userStatus === 'VERIFICATION' ? <Popconfirm
                                            title="Are you sure you wish to resend the verification email?"
                                            onConfirm={() => this.resendVerificationEmail()}
                                            okText='Send'
                                            okButtonProps={{danger: true}}
                                            cancelText='Cancel'>
                                            <Button size='small'
                                                    loading={this.state.sendingVerificationEmail}
                                                    disabled={this.state.sendingVerificationEmail}>Send Verification</Button>
                                        </Popconfirm> : ''}
                                    </Space>
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                        <br />
                        <Card title='Emails' size='small' extra={<Button size='small' onClick={() => this.setState({ showNewEmailModal: true })} icon={<MailOutlined />}>Send Email</Button>}>
                            <Table dataSource={this.state.emails}
                                   rowKey={row => row.guid}
                                   size='small'
                                   loading={this.state.loadingEmails}
                                   columns={emailsColumns}
                                   onChange={(pagination) => this.adminLoadUserEmails(pagination.current, pagination.pageSize)}
                                   pagination={this.state.emailsPagination} />
                        </Card>
                    </Col>
                    <Col md={14} xs={24}>
                        <Card size='small' title='Companies'>
                            <Table dataSource={this.state.companies}
                                   rowKey={row => row.guid}
                                   size='small'
                                   loading={this.state.loadingCompanies}
                                   onChange={(pagination) => this.adminLoadCompanies(pagination.current, pagination.pageSize)}
                                   columns={companyColumns}
                                   pagination={this.state.companiesPagination} />
                        </Card>
                        <br />
                        <Card size='small' title='Logins'>
                            <Table dataSource={this.state.logins}
                                   rowKey={row => row.id}
                                   size='small'
                                   loading={this.state.loadingLogins}
                                   onChange={(pagination) => this.adminLoadLogins(pagination.current, pagination.pageSize)}
                                   columns={loginColumns}
                                   pagination={this.state.loginsPagination} />
                        </Card>
                    </Col>
                </Row>

                <UserNewEmailModal
                    showModal={this.state.showNewEmailModal}
                    company={this.props.match.params.id}
                    reload={() => this.adminLoadUserEmails()}
                    close={() => this.setState({ showNewEmailModal: false })} />

                <UserEmailViewModal guid={this.state.activeEmail}
                                       companyGuid={this.props.match.params.id}
                                       showModal={this.state.showEmailModal}
                                       close={() => this.setState({ showEmailModal: false, activeEmail: null })} />
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminLoadUser, adminLoadUserLogins,
    adminLoadUserCompanies, adminUpdateUserField, adminLoadUserEmails, adminResetPassword, adminResendVerificationEmail })(UserView);