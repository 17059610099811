export const MoneyField = function(props) {
    let amount = props.amount;
    let isNegative = amount < 0;

    if(isNegative) {
        amount = Math.abs(amount);
    }

    amount = parseFloat(amount).toFixed(2);

    if(props.currency === 'EUR') {
        return (isNegative ? '-' : '') + '€' + amount;
    } else if(props.currency === 'USD') {
        return (isNegative ? '-' : '') + '$' + amount;
    }

    return 0;
};