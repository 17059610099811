import React from "react";
import {Button, Form, Modal, Select, Space, message} from "antd";
import {connect} from "react-redux";
import {displayErrors} from "../../libs/utils";
import {loadAllCompanies} from "../../actions/users";
import {adminLoadAllCompanyOrders, adminTransferIPAddresses} from "../../actions/billing";
import {adminLoadAllCompanysIPAddressGroups} from "../../actions/ip_addresses";
import {adminLoadAllCompanysDomainGroups} from "../../actions/domains";
import TextArea from "antd/es/input/TextArea";

class IPAddressChangeOrderModal extends React.Component {
    state = {
        destinationOrder: null,
        loading: false,
        loadingCompanies: false,
        companies: [],
        activeCompany: null,
        loadingOrders: false,
        orders: [],
        activeOrder: null,
        loadingIPGroups: false,
        ipGroups: [],
        selectedIPGroup: null,
        loadingDomainGroups: false,
        domainGroups: [],
        selectedDomainGroup: null,
        orderNotes: '',
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    componentDidMount() {
        this.props.loadAllCompanies((res) => {
            this.setState({ loadingCompanies: false, companies: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCompanies: false });
                displayErrors(err.response.data);
            }
        });
    }

    closeModal(reload = false) {
        this.setState({
            destinationOrder: null,
            loading: false,
            loadingCompanies: false,
            companies: [],
            activeCompany: null,
            loadingOrders: false,
            orders: [],
            activeOrder: null,
            loadingIPGroups: false,
            ipGroups: [],
            selectedIPGroup: null,
            loadingDomainGroups: false,
            domainGroups: [],
            selectedDomainGroup: null,
            orderNotes: '',
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    selectCompany(value) {
        this.setState({ loadingIPGroups: true, loadingDomainGroups: true, activeCompany: value });

        this.props.adminLoadAllCompanysIPAddressGroups(value, (res) => {
            this.setState({ loadingIPGroups: false, ipGroups: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIPGroups: false });
                displayErrors(err.response.data);
            }
        });

        this.props.adminLoadAllCompanysDomainGroups(value, (res) => {
            this.setState({ loadingDomainGroups: false, domainGroups: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingDomainGroups: false });
                displayErrors(err.response.data);
            }
        });
    }

    performAction() {
        this.setState({ loading: true });

        let ip_group = this.state.selectedIPGroup;
        let domain_group = this.state.selectedDomainGroup;
        let source_order = this.props.orderId;
        let order_notes = this.state.orderNotes;

        if(ip_group === null || ip_group.length === 0) {
            return message.error('Please select the IP group!');
        }

        if(domain_group === null || domain_group.length === 0) {
            return message.error('Please select the domain group!');
        }

        if(order_notes === null || order_notes.length === 0) {
            return message.error('Please enter the order note!');
        }

        let data = { ip_group, domain_group, source_order, order_notes, ip_addresses: this.props.selectedIPAddresses };

        this.props.adminTransferIPAddresses(data, () => {
            this.setState({ loading: false });
            message.success('IP addresses successfully transferred!');
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        return(
            <Modal
                destroyOnClose={true}
                title='Change Order'
                visible={this.props.showModal}
                footer={<Space>
                    <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Close</Button>
                    <Button disabled={this.state.loading} loading={this.state.loading}
                            onClick={() => this.performAction()} type='primary'>Submit</Button>
                </Space>}
                onCancel={() => this.closeModal()}>
                <Form layout='vertical'>
                    <Form.Item label='Company'>
                        <Select
                            showSearch allowClear
                            loading={this.state.loadingCompanies}
                            disabled={this.state.loadingCompanies}
                            onChange={(value) => this.selectCompany(value)}
                            filterOption={(input, option) => {
                                if(typeof option !== 'undefined') {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                                return false;
                            }}>
                            {this.state.companies.map((company, i) => <Option key={i} value={company.guid}>{company.name}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label='IP group'>
                        <Select
                            loading={this.state.loadingIPGroups}
                            disabled={this.state.loadingIPGroups || this.state.activeCompany === null}
                            onChange={(value) => this.setState({ selectedIPGroup: value })}>
                            {this.state.ipGroups.map((group, i) => <Option key={i} value={group.guid}>{group.name}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Domain group'>
                        <Select
                            loading={this.state.loadingDomainGroups}
                            disabled={this.state.loadingDomainGroups || this.state.activeCompany === null}
                            onChange={(value) => this.setState({ selectedDomainGroup: value })}>
                            {this.state.domainGroups.map((group, i) => <Option key={i} value={group.guid}>{group.name}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label='Order notes'>
                        <TextArea placeholder='Required! Enter ticket ID or anything identifiable.'
                                  value={this.state.orderNotes}
                                  onChange={(e) => this.setState({ orderNotes: e.target.value })} />
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { loadAllCompanies, adminLoadAllCompanyOrders,
    adminLoadAllCompanysIPAddressGroups, adminTransferIPAddresses,
    adminLoadAllCompanysDomainGroups })(IPAddressChangeOrderModal);