import React from "react";
import {connect} from "react-redux";
import {Button, Modal} from "antd";
import TextArea from "antd/es/input/TextArea";
import {displayErrors} from "../../libs/utils";
import {adminExportIPNodeIPAddresses} from "../../actions/network";

class IPNodeExportIPAddressesModal extends React.Component {
    state = {
        ipAddresses: [],
        loadingIPAddresses: false,
        ipAddressesLoaded: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingIPAddresses && !this.state.ipAddressesLoaded) {
            this.setState({ loadingIPAddresses: true });

            this.props.adminExportIPNodeIPAddresses(this.props.ipNode, (res) => {
                this.setState({ loadingIPAddresses: false, ipAddresses: res.data, ipAddressesLoaded: true });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingIPAddresses: false, ipAddressesLoaded: true });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    closeModal() {
        this.setState({
            ipAddresses: [],
            loadingIPAddresses: false,
            ipAddressesLoaded: false
        });

        this.props.close();
    }

    render() {
        return(
            <Modal visible={this.props.showModal}
                   title='Export'
                   onCancel={() => this.closeModal()}
                   footer={<Button onClick={() => this.closeModal()}>Close</Button>}>
                <TextArea readOnly disabled={this.state.loadingIPAddresses} rows={10} value={this.state.ipAddresses.join('\n')} />
            </Modal>
        )
    }
}

export default connect(null, { adminExportIPNodeIPAddresses })(IPNodeExportIPAddressesModal);