import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {DEFAULT_PER_PAGE} from "../../config";
import {Button, Menu, message, Modal, Space, Table, Tooltip} from "antd";
import {EditOutlined, ExclamationCircleOutlined, PlusOutlined} from "@ant-design/icons";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {adminLoadAnnouncements, adminUpdateAnnouncementField} from "../../actions/system";
import DeleteOutlined from "@ant-design/icons/lib/icons/DeleteOutlined";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import AnnouncementModal from "./AnnouncementModal";

class AnnouncementsTable extends React.Component {
    state = {
        announcements: [],
        loadingAnnouncements: false,
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        showAnnouncementModal: false,
        activeAnnouncement: null
    };

    componentDidMount() {
        this.props.updatePage('Announcements');
        this.adminLoadAnnouncements();
    }

    adminLoadAnnouncements(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingAnnouncements: true });

        this.props.adminLoadAnnouncements(page, per_page, (res) => {
            this.setState({ announcements: res.data.data, loadingAnnouncements: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingAnnouncements: false });
                displayErrors(err.response.data);
            }
        });
    }

    toggleAnnouncementStatus(guid, value) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set the status to <strong>{normalizeEnum(value)}</strong> for this announcement?</span>,
            onOk: () => {
                this.props.adminUpdateAnnouncementField(guid, { 'status': value }, (res) => {
                    message.success('Announcement successfully updated!');
                    this.adminLoadAnnouncements();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    render() {
        const columns = [
            { title: 'Message', width: '50%', dataIndex: 'message' },
            { title: 'Title', dataIndex: 'title', render: (item, record) => item ? item : '-' },
            { title: 'Status', dataIndex: 'status', render: (item, record) => normalizeEnum(item)},
            { title: 'Type', dataIndex: 'announcement_type', render: (item, record) => normalizeEnum(item)},
            { title: 'Expires', dataIndex: 'date_expires', render: (item, record) => item ? <DateTimeFormat value={item} /> : '-' },
            { title: '', align: 'right', render: (item, record) => {
                return <Space>
                    <Button size='small' onClick={() => this.toggleAnnouncementStatus(record.guid, record.status === 'ACTIVE' ? 'EXPIRED' : 'ACTIVE')} icon={<DeleteOutlined />}>{record.status === 'ACTIVE' ? 'Disable' : 'Activate'}</Button>
                    <Button size='small' icon={<EditOutlined />} onClick={() => this.setState({ showAnnouncementModal: true, activeAnnouncement: record})}>Modify</Button>
                </Space>;
            }}
        ];

        return(
            <Fragment>
                <Menu mode="horizontal" selectedKeys={[]}>
                    <Menu.Item key='new_announcement' icon={<PlusOutlined />} onClick={() => this.setState({ showAnnouncementModal: true })}>
                        New Announcement
                    </Menu.Item>
                    <Menu.Item key='reload_table' style={{float: 'right'}} onClick={() => this.adminLoadAnnouncements()}>
                        <Tooltip title='Reload' placement='left'><UndoOutlined /></Tooltip>
                    </Menu.Item>
                </Menu>
                <Table
                    columns={columns}
                    rowKey={(item) => item.guid}
                    dataSource={this.state.announcements}
                    loading={this.state.loadingAnnouncements}
                    pagination={this.state.pagination}
                    onChange={(pagination) => this.adminLoadAnnouncements(pagination)}
                />

                <AnnouncementModal
                    showModal={this.state.showAnnouncementModal}
                    activeAnnouncement={this.state.activeAnnouncement}
                    reload={() => this.adminLoadAnnouncements()}
                    close={() => this.setState({ showAnnouncementModal: false, activeAnnouncement: null })} />
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminLoadAnnouncements, adminUpdateAnnouncementField })(AnnouncementsTable);