import React from 'react';
import ReactDOM from 'react-dom'
import {BrowserRouter, Switch} from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';

import 'antd/dist/antd.css';
import './style/app.css';
import './style/theme.css';

import {loadUser} from "./actions/auth";
import PrivateRoute from "./components/shared/PrivateRoute";
import DefaultRoute from "./components/shared/DefaultRoute";

import Logout from "./pages/auth/logout";
import Auth from "./components/auth";
import App from "./components/app";

class PBNDashboardV4 extends React.Component {
    componentDidMount() {
        store.dispatch(loadUser());
    }

    render() {
        return(
            <Provider store={store}>
                <BrowserRouter>
                    <Switch>
                        <DefaultRoute path='/auth/*' component={Auth} />
                        <PrivateRoute path='/logout' component={Logout} exact />
                        <PrivateRoute path='/*' component={App} />
                    </Switch>
                </BrowserRouter>
            </Provider>
        );
    }
}

ReactDOM.render(
    <PBNDashboardV4 />,
    document.getElementById('app')
);