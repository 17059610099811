import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    Button,
    Card,
    Col,
    Descriptions,
    Form,
    Input,
    message,
    Modal,
    Popconfirm,
    Row,
    Select,
    Space, Switch
} from "antd";
import {
    adminChangeCpanelPassword,
    adminDeleteCpanelAccount,
    adminGetSharedHostingPassword,
    adminLoadHostingAccount,
    adminLoginTocPanel, adminResendNewHostingAccountEmail,
    adminSuspendHostingAccount,
    adminUnuspendHostingAccount,
    adminUpdateHostingAccountField,
} from "../../actions/hosting";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {Loading} from "../../libs/loading";
import {DateTimeFormat} from "../../components/shared/DateTimeFormat";
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
    EyeOutlined,
    LockOutlined,
    MailOutlined,
    UnlockOutlined
} from "@ant-design/icons";
import LoginOutlined from "@ant-design/icons/lib/icons/LoginOutlined";
import HostingAccountDomainsTable from "../../components/hosting/HostingAccountDomainsTable";
import Paragraph from "antd/es/typography/Paragraph";
import {adminLoadSharedHostingServers} from "../../actions/network";
import {Link} from "react-router-dom";

class HostingDetails extends React.Component {
    state = {
        loadingHostingAccount: true,
        data: null,
        hostingPassword: '',
        accountPassword: '',
        loadingPassword: false,
        showPasswordModal: false,
        passwordInputType: 'password',
        showCpanelPasswordChangeModal: false,
        newRepeatCpanelPassword: '',
        newCpanelPassword: '',
        currentCpanelPassword: '',
        changingCpanelPassword: false,
        activeStatus: '',
        updatingStatus: false,
        isLegacyAccount: false,
        updatingLegacyStatus: false,
        sharedHostingServers: [],
        loadingSharedHostingServers: false,
        selectedSharedHostingServer: '',
        suspending: false,
    };

    componentDidMount() {
        this.props.updatePage('Hosting Account');
        this.loadHostingAccount()
    }

    loadHostingAccount() {
        this.setState({ loadingHostingAccount: true });

        this.props.adminLoadHostingAccount(this.props.match.params.guid, (res) => {
            this.props.updatePage(res.data.name);

            this.setState({ loadingHostingAccount: false, data: res.data, activeStatus: res.data.status, isLegacyAccount: res.data.is_legacy });

            if(res.data.account_type === 'SHARED_HOSTING') {
                this.setState({ selectedSharedHostingServer: res.data.hosting_server.guid });
                this.loadSharedHostingServers();
            }
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingHostingAccount: false });
            }
        });
    }

    loadSharedHostingServers() {
        this.setState({ loadingSharedHostingServers: true });

        this.props.adminLoadSharedHostingServers(1, 9999, [], (res) => {
            this.setState({ loadingSharedHostingServers: false, sharedHostingServers: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingSharedHostingServers: false });
                displayErrors(err.response.data);
            }
        });
    }

    getSharedHostingPassword() {
        let password = this.state.accountPassword.trim();

        if(password.length === 0) {
            return message.error('Please enter your account password!');
        }

        let data = { password };

        this.setState({ loadingPassword: true });

        this.props.adminGetSharedHostingPassword(this.props.match.params.guid, data, (res) => {
            this.setState({ loadingPassword: false, hostingPassword: res.data.password, showPasswordModal: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingPassword: false });
            }
        });
    }

    loginToCpanel() {
        message.info('Creating cPanel login URL...');

        this.props.adminLoginTocPanel(this.props.match.params.guid, (res) => {
            window.open(res.data.redirect_url);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    changeCpanelPassword() {
        let current_password = this.state.currentCpanelPassword;
        let new_password = this.state.newCpanelPassword;
        let new_password_repeat = this.state.newRepeatCpanelPassword;

        if(new_password !== new_password_repeat) {
            return message.error('Your new passwords do not match!');
        }

        let data = { current_password, new_password, new_password_repeat };

        this.setState({ changingCpanelPassword: true });

        this.props.adminChangeCpanelPassword(this.props.match.params.guid, data, (res) => {
            this.setState({ changingCpanelPassword: false, showCpanelPasswordChangeModal: false,
                currentCpanelPassword: '', newCpanelPassword: '', newRepeatCpanelPassword: '' });
            message.success('cPanel password successfully updated!');

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ changingCpanelPassword: false });
            }
        });
    }

    adminUpdateHostingAccountStatus(status) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set the status to <strong>{normalizeEnum(status)}</strong>?</span>,
            onOk: () => {
                this.setState({ updatingStatus: true });

                this.props.adminUpdateHostingAccountField(this.props.match.params.guid, { 'status': status }, (res) => {
                    this.setState({ updatingStatus: false });
                    message.success('Hosting account status successfully updated!');
                    this.loadHostingAccount();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ updatingStatus: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    adminUpdateHostingAccountLegacyStatus(status) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set the status to <strong>{status ? 'Yes' : 'No' }</strong>?</span>,
            onOk: () => {
                this.setState({ updatingLegacyStatus: true });

                this.props.adminUpdateHostingAccountField(this.props.match.params.guid, { 'is_legacy': status }, (res) => {
                    this.setState({ updatingLegacyStatus: false });
                    message.success('Hosting account legacy status successfully updated!');
                    this.loadHostingAccount();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ updatingLegacyStatus: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    adminDeleteCpanelAccount() {
        this.setState({ deleting: true });

        this.props.adminDeleteCpanelAccount(this.props.match.params.guid, (res) => {
            this.setState({ deleting: false });
            message.success('cPanel account successfully deleted!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ deleting: false });
                displayErrors(err.response.data);
            }
        });
    }

    setSharedHostingServer(server) {
        let hostname = '';

        for(let i = 0; i < this.state.sharedHostingServers.length; i++) {
            if(this.state.sharedHostingServers[i].guid === server) {
                hostname = this.state.sharedHostingServers[i].hostname;
                break;
            }
        }

        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set the server host to <strong>{hostname}</strong>?</span>,
            onOk: () => {
                this.setState({ updatingHostingAccountHost: true });

                this.props.adminUpdateHostingAccountField(this.props.match.params.guid, { 'shared_hosting_server': server }, (res) => {
                    this.setState({ updatingHostingAccountHost: false });
                    message.success('Hosting account server successfully updated!');
                    this.loadHostingAccount();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ updatingHostingAccountHost: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    toggleDomainDeletion(value) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to {value ? 'enable' : 'disable'} domain deleting?</span>,
            onOk: () => {
                this.setState({ updatingHostingAccountHost: true });

                this.props.adminUpdateHostingAccountField(this.props.match.params.guid, { 'allow_domain_deletion': value }, (res) => {
                    this.setState({ updatingHostingAccountHost: false });
                    message.success('Domain deletion successfully updated!');
                    this.loadHostingAccount();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ updatingHostingAccountHost: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    suspendAccount() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to suspend account?</span>,
            onOk: () => {
                this.setState({ suspending: true });

                this.props.adminSuspendHostingAccount(this.props.match.params.guid, (res) => {
                    this.setState({ suspending: false });
                    message.success('Hosting account successfully suspended!');
                    this.loadHostingAccount();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ suspending: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    unsuspendAccount() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to unsuspend account?</span>,
            onOk: () => {
                this.setState({ suspending: true });

                this.props.adminUnuspendHostingAccount(this.props.match.params.guid, (res) => {
                    this.setState({ suspending: false });
                    message.success('Hosting account successfully suspended!');
                    this.loadHostingAccount();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ suspending: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    resendNewHostingAccountEmail() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to resend the new hosting account email?</span>,
            onOk: () => {
                this.setState({ resending: true });

                this.props.adminResendNewHostingAccountEmail(this.props.match.params.guid, (res) => {
                    this.setState({ resending: false });
                    message.success('New hosting account email successfully sent!');
                    this.loadHostingAccount();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ resending: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    render() {
        if(this.state.loadingHostingAccount) {
            return <div className='text-center'><Loading /></div>;
        }

        const { data } = this.state;
        const { Option } = Select;

        let sharedHostingPlanDetails = '';

        if(data.account_type === 'SHARED_HOSTING') {
            sharedHostingPlanDetails = <Card size='small' title='Hosting Plan' className='card-bottom-margin'>
                <Descriptions column={1} bordered size='small'>
                    <Descriptions.Item label={<span>Plan</span>}>{data.plan.name}</Descriptions.Item>
                    <Descriptions.Item label={<span>Storage</span>}>{data.plan.storage}</Descriptions.Item>
                    <Descriptions.Item label={<span>Bandwidth</span>}>{data.plan.bandwidth}</Descriptions.Item>
                    <Descriptions.Item label={<span>Inodes</span>}>{data.plan.inodes}</Descriptions.Item>
                    <Descriptions.Item label={<span>RAM</span>}>{data.plan.memory}</Descriptions.Item>
                    <Descriptions.Item label={<span>CPU</span>}>{data.plan.cpu}</Descriptions.Item>
                    <Descriptions.Item label={<span>Entry processes</span>}>{data.plan.entry_processes}</Descriptions.Item>
                    <Descriptions.Item label={<span>Active processes</span>}>{data.plan.active_processes}</Descriptions.Item>
                    <Descriptions.Item label={<span>Email accounts</span>}>{data.plan.email_accounts}</Descriptions.Item>
                    <Descriptions.Item label={<span>Databases</span>}>{data.plan.databases}</Descriptions.Item>
                    <Descriptions.Item label={<span>Subdomains</span>}>{data.plan.subdomains}</Descriptions.Item>
                    <Descriptions.Item label={<span>Parked domains</span>}>{data.plan.parked_domains}</Descriptions.Item>
                    <Descriptions.Item label={<span>Addon domains</span>}>{data.plan.addon_domains}</Descriptions.Item>
                </Descriptions>
            </Card>;
        }

        let serverDetails = '';

        if(data.account_type === 'CUSTOM_HOST')
        {
            serverDetails = <Card size='small' title='Server Details' className='card-bottom-margin'>
                <Descriptions size='small' column={1} bordered>
                    <Descriptions.Item label='Hostname' style={{width:'50%'}}>{data.custom_host_server.hostname}</Descriptions.Item>
                    <Descriptions.Item label='IP address'>{data.custom_host_server.ip_address}</Descriptions.Item>
                </Descriptions>
            </Card>;
        }
        else
        {
            serverDetails = <Card size='small' title='Service Details' className='card-bottom-margin'>
                <Descriptions size='small' column={1} bordered>
                    <Descriptions.Item label='Hostname' style={{width:'50%'}}>
                        <Select style={{width: '100%'}} value={this.state.selectedSharedHostingServer} onChange={(value) => this.setSharedHostingServer(value)}>
                            {this.state.sharedHostingServers.map((server, i) => {
                                return <Option key={i} value={server.guid}>{server.hostname} ({normalizeEnum(server.status)})</Option>;
                            })}
                        </Select>
                    </Descriptions.Item>
                    <Descriptions.Item label='IP address'>{data.hosting_server.ip_address}</Descriptions.Item>
                    <Descriptions.Item label='Username'><Paragraph copyable style={{margin:0}}>{data.username}</Paragraph></Descriptions.Item>
                    <Descriptions.Item label='Password'>
                        {this.state.hostingPassword === '' ? <Button onClick={() => this.setState({ showPasswordModal: true })} size='small' icon={<EyeOutlined />}>View cPanel Password</Button> : <Paragraph copyable style={{margin:0}}>{this.state.hostingPassword}</Paragraph>}
                    </Descriptions.Item>
                    <Descriptions.Item label='Actions'>
                        <Space>
                            <Button size='small' disabled={this.state.changingCpanelPassword}
                                    icon={<LoginOutlined />} onClick={() => this.loginToCpanel()}>Login to cPanel</Button>
                            <Button size='small' disabled={this.state.changingCpanelPassword}
                                    loading={this.state.changingCpanelPassword} icon={<LockOutlined />}
                                    onClick={() => this.setState({ showCpanelPasswordChangeModal: true })}>Change cPanel Password</Button>

                            <Popconfirm
                                title="Are you sure you want to delete this account?"
                                onConfirm={() => this.adminDeleteCpanelAccount()}
                                okText='Delete'
                                placement='topRight'
                                okButtonProps={{danger: true}}
                                cancelText='Cancel'>
                                <Button size='small' icon={<DeleteOutlined />}>Delete</Button>
                            </Popconfirm>
                        </Space>
                    </Descriptions.Item>
                </Descriptions>

                <Modal
                    onCancel={() => this.setState({ showCpanelPasswordChangeModal: false })}
                    title='Change Password'
                    footer={<Space>
                        <Button disabled={this.state.changingCpanelPassword}
                                onClick={() => this.setState({ showCpanelPasswordChangeModal: false })}>Close</Button>
                        <Button disabled={this.state.changingCpanelPassword}
                                onKeyDown={(e) => e.key === 'Enter' ? this.changeCpanelPassword() : null}
                                loading={this.state.changingCpanelPassword} type='primary'
                                onClick={() => this.changeCpanelPassword()}>Update</Button>
                    </Space>}
                    visible={this.state.showCpanelPasswordChangeModal}>
                    <Form layout='vertical'>
                        <Form.Item label='Current password:'>
                            <Input type='password' value={this.state.currentCpanelPassword}
                                   onChange={(e) => this.setState({ currentCpanelPassword: e.target.value })} />
                        </Form.Item>
                        <Form.Item label='New password:'>
                            <Input type='password' value={this.state.newCpanelPassword}
                                   onChange={(e) => this.setState({ newCpanelPassword: e.target.value })} />
                        </Form.Item>
                        <Form.Item label='Repeat password:'>
                            <Input type='password' value={this.state.newRepeatCpanelPassword}
                                   onChange={(e) => this.setState({ newRepeatCpanelPassword: e.target.value })} />
                        </Form.Item>
                    </Form>
                </Modal>
            </Card>;
        }

        return(
            <Fragment>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12}>
                        <Card size='small' title='Service Information' className='card-bottom-margin'>
                            <Descriptions size='small' column={1} bordered>
                                <Descriptions.Item label='ID' style={{width:'50%'}}>{data.id}</Descriptions.Item>
                                <Descriptions.Item label='Name'>{data.name}</Descriptions.Item>
                                <Descriptions.Item label='Type'>{normalizeEnum(data.account_type)}</Descriptions.Item>
                                <Descriptions.Item label='Created'><DateTimeFormat value={data.date_created} /></Descriptions.Item>
                                <Descriptions.Item label='Owner'><Link to={'/accounts/companies/' + data.owner.guid}>{data.owner.name}</Link></Descriptions.Item>
                                <Descriptions.Item label='Status'>
                                    <Select style={{width:'200px'}} value={this.state.activeStatus} disabled={this.state.updatingStatus}
                                            loading={this.state.updatingStatus}
                                            onChange={(value) => this.adminUpdateHostingAccountStatus(value)}>
                                        <Option value='ACTIVE'>Active</Option>
                                        <Option value='SUSPENDED'>Suspended</Option>
                                        <Option value='PENDING'>Pending</Option>
                                        <Option value='DELETED'>Deleted</Option>
                                    </Select>
                                </Descriptions.Item>
                                <Descriptions.Item label='Is legacy?'>
                                    <Select style={{width:'200px'}} value={this.state.isLegacyAccount} disabled={this.state.updatingLegacyStatus}
                                            loading={this.state.updatingLegacyStatus}
                                            onChange={(value) => this.adminUpdateHostingAccountLegacyStatus(value)}>
                                        <Option value={true}>Yes</Option>
                                        <Option value={false}>No</Option>
                                    </Select>
                                </Descriptions.Item>
                                <Descriptions.Item label='Allow domain deletion?'>
                                    <Switch size='small' defaultChecked={data.allow_domain_deletion} onChange={(value) => this.toggleDomainDeletion(value)} />
                                </Descriptions.Item>
                                <Descriptions.Item label='Actions'>
                                    {data.status === 'SUSPENDED' ? <Button size='small' loading={this.state.suspending} disabled={this.state.suspending} icon={<UnlockOutlined />} onClick={() => this.unsuspendAccount()}>Unsuspend</Button> : ''}
                                    {data.status !== 'SUSPENDED' ? <Button size='small' loading={this.state.suspending} disabled={this.state.suspending} icon={<LockOutlined />} onClick={() => this.suspendAccount()}>Suspend</Button> : ''}
                                    <Button size='small' icon={<MailOutlined />} loading={this.state.resending} disabled={this.state.resending} onClick={() => this.resendNewHostingAccountEmail()}>Resend Welcome Email</Button>
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                        {serverDetails}

                    </Col>
                    <Col xs={24} sm={12}>
                        {sharedHostingPlanDetails}
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <HostingAccountDomainsTable guid={this.props.match.params.guid} />
                    </Col>
                </Row>

                <Modal
                    visible={this.state.showPasswordModal}
                    footer={<Space>
                        <Button onClick={() => this.setState({ showPasswordModal: false, accountPassword: '' })}>Close</Button>
                        <Button onClick={() => this.getSharedHostingPassword()} type='primary'>Continue</Button>
                    </Space>}
                    onOk={() => this.getSharedHostingPassword()}
                    onCancel={() => this.setState({ showPasswordModal: false, accountPassword: '' })}>
                    <p>Enter your account password:</p>

                    <Input type='password'
                           onKeyDown={(e) => e.key === 'Enter' ? this.getSharedHostingPassword() : null}
                           onChange={(e) => this.setState({ accountPassword: e.target.value})}
                           value={this.state.accountPassword} />
                </Modal>
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminLoadHostingAccount,
    adminGetSharedHostingPassword, adminLoginTocPanel, adminChangeCpanelPassword, adminUpdateHostingAccountField,
    adminDeleteCpanelAccount, adminLoadSharedHostingServers, adminSuspendHostingAccount, adminUnuspendHostingAccount,
    adminResendNewHostingAccountEmail })(HostingDetails);