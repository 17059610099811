
import Moment from "react-moment";
import React, {Fragment} from "react";

export const TimeFormat = function(props) {
    let format = 'HH:mm:ss';

    if(props.value === null) {
        return <Fragment>N/A</Fragment>;
    }

    return <Moment date={props.value} format={format} />;
};