import {PAGE_UPDATED, PAGE_LOADING} from "../actions/types";

const initialState = {
    isLoading: false,
    title: '',
    subtitle: ''
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch(action.type) {
        case PAGE_LOADING:
            return {
                ...state,
                isLoading: true
            };
        case PAGE_UPDATED:
            return {
                ...state,
                isLoading: false,
                title: action.payload.title,
                subtitle: action.payload.subtitle
            };
        default:
            return state;
    }
}