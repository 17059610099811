import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, Modal, Space, message} from "antd";
import TextArea from "antd/es/input/TextArea";
import {adminCompanySendEmail} from "../../actions/accounts";
import {displayErrors} from "../../libs/utils";

class CompanyNewEmailModal extends React.Component {
    state = {
        subject: '',
        body: '',
        sending: false
    };

    closeModal(reload = false) {
        this.setState({
            subject: '',
            body: '',
            sending: false
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    sendEmail() {
        this.setState({ sending: true });

        let subject = this.state.subject.trim();
        let body = this.state.body.trim();

        if(subject.length === 0) {
            return message.error('Please enter subject!');
        }

        if(body.length === 0) {
            return message.error('Please enter message!');
        }

        let data = { subject, body };

        this.props.adminCompanySendEmail(this.props.company, data, (res) => {
            message.success('Email successfully scheduled to be sent!');
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ sending: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        return(
            <Modal visible={this.props.showModal}
                   onCancel={() => this.closeModal()}
                   footer={<Space>
                       <Button onClick={() => this.closeModal()} disabled={this.state.sending}>Close</Button>
                       <Button type='primary' loading={this.state.sending} disabled={this.state.sending} onClick={() => this.sendEmail()}>Send</Button>
                   </Space>}
                   title='Send Email'>
                <Form layout='vertical'>
                    <Form.Item label='Subject'>
                        <Input value={this.state.subject} onChange={(e) => this.setState({ subject: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Body'>
                        <TextArea rows={5} onChange={(e) => this.setState({ body: e.target.value })} />
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}


export default connect(null, { adminCompanySendEmail })(CompanyNewEmailModal);