import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {DEFAULT_FLAG_SIZE, DEFAULT_PER_PAGE} from "../../config";
import {Button, Col, Pagination, Row, Space, Table, Tag, Tooltip} from "antd";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {
    DeleteOutlined, EyeOutlined,
    FilterOutlined, PlusOutlined
} from "@ant-design/icons";
import IPNodesTableFilter from "./IPNodesTableFilter";
import {adminLoadIPNodes} from "../../actions/network";
import Flag from "react-world-flags";
import {Link} from "react-router-dom";
import {StatusIndicator} from "../shared/Status";
import NewIPNodeModal from "./NewIPNodeModal";

class IPNodesTable extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        ipNodes: [],
        loadingIPNodes: true,
        filters: [],
        showFiltersModal: false,
        showBulkActionsModal: false,
        showNewIPNodeModal: false,
    }

    componentDidMount() {
        this.props.updatePage('IP Nodes');
        this.adminLoadIPNodes();
    }

    adminLoadIPNodes(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingIPNodes: true });

        this.props.adminLoadIPNodes(page, per_page, this.state.filters, (res) => {
            window.scrollTo(0, 0);

            this.setState({ ipNodes: res.data.data, loadingIPNodes: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIPNodes: false });
                displayErrors(err.response.data);
            }
        });
    }

    clearFilters() {
        this.setState({ filters: [] }, () => this.adminLoadIPNodes());
    }

    setFilter(filter) {
        let filters = this.state.filters.filter(f => f.key !== filter.key);
        filters.push(filter);

        this.setState({ filters }, () => this.adminLoadIPNodes());
    }

    removeFilter(item) {
        let filters = this.state.filters.filter(filter => filter.key !== item.key);
        this.setState({ filters }, () => this.adminLoadIPNodes());
    }

    showFilters() {
        return this.state.filters.map((filter, i) => {
            let value;

            if (typeof filter.value === 'boolean') {
                if (filter.value) {
                    value = 'Yes';
                } else {
                    value = 'No';
                }
            } else {
                if (filter.visible !== null) {
                    value = filter.visible;
                } else {
                    value = normalizeEnum(filter.value);
                }
            }

            return <Tag key={i} closable onClose={() => this.removeFilter(filter)}>{filter.name}: {value}</Tag>;
        });
    }

    render() {
        const columns = [
            { title: 'Hostname', dataIndex: 'hostname', render: (item, record) => <Link to={'/network/ip-nodes/' + record.guid}>{item.replace('.gateway.prioritynap.net', '')}</Link> },
            { title: 'IP address', dataIndex: 'ip_address', render: (item, record) => <Link to={'/network/ip-nodes/' + record.guid}>{item}</Link> },
            { title: 'IP count', dataIndex: 'ip_address_count', align: 'center'},
            { title: 'Used', align: 'center', width: '150px', dataIndex: 'used_ip_address_count', render: (item, record) => {
                    if(record.ip_address_count === 0) {
                        return '0 (0%)';
                    }
                    return item + ' (' + Math.floor(((item * 100) / record.ip_address_count)) + '%)';
            }},
            { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />},
            { title: 'Location', dataIndex: 'location', render: (item) => <span><Flag code={item.country.toLowerCase()} height={DEFAULT_FLAG_SIZE} /> {item.city + ', ' + item.country}</span>},
            { title: 'Provider', dataIndex: 'provider', render: (item) => <a href={item.website} target='_blank' rel='noopener noreferrer'>{item.name}</a>},
            { title: '', align: 'left', render: (item, record) => <Link to={'/network/ip-nodes/' + record.guid}><Button size='small' icon={<EyeOutlined />}>View</Button></Link>}
        ];

        return(
            <Fragment>
                {this.showFilters()}

                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <Button type='text' size='small' icon={<PlusOutlined />} onClick={() => this.setState({ showNewIPNodeModal: true })}>New IP Node</Button>
                        <Button type='text' size='small' icon={<FilterOutlined />} onClick={() => this.setState({ showFiltersModal: true })}>Filters</Button>
                        <Button type='text' size='small' disabled={this.state.filters.length === 0} onClick={() => this.clearFilters()} icon={<DeleteOutlined />}>Clear Filters</Button>
                    </Col>
                    <Col>
                        <Space>
                            <Tooltip title='Reload' placement='left'><Button icon={<UndoOutlined />} size='small' type='circle' onClick={() => this.adminLoadIPNodes()} /></Tooltip>
                            <Pagination onChange={(page, pageSize) => this.adminLoadIPNodes(page, pageSize)} size='small' {...this.state.pagination} />
                        </Space>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    rowKey={(item) => item.guid}
                    dataSource={this.state.ipNodes}
                    loading={this.state.loadingIPNodes}
                    pagination={false}
                    footer={() => <Row justify='space-between'>
                        <Col />
                        <Col><Pagination onChange={(page, pageSize) => this.adminLoadIPNodes(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                    </Row>}
                />

                <NewIPNodeModal
                    showModal={this.state.showNewIPNodeModal}
                    reload={() => this.adminLoadIPNodes()}
                    close={() => this.setState({ showNewIPNodeModal: false })} />

                <IPNodesTableFilter
                    showModal={this.state.showFiltersModal}
                    closeModal={() => this.setState({ showFiltersModal: false })}
                    onChange={(filter) => this.setFilter(filter) } />

            </Fragment>
        )
    }
}

export default connect(null, { updatePage, adminLoadIPNodes })(IPNodesTable);