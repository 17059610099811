import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {adminSubnetToIPAddress} from "../../actions/tools";
import {Button, Card, Col, Form, Input, Row, message, Select, Space} from "antd";
import {DeleteOutlined, PlayCircleOutlined} from "@ant-design/icons";
import {displayErrors} from "../../libs/utils";

class IPAddressTools extends React.Component {
    state = {
        subnetsToIPAddressesResultBusy: false,
        subnetsToIPAddressesData: [],
        subnetsToIPAddressesResult: [],
        subnetsToIPAddressAction: 'LIST_ALL',
    };

    componentDidMount() {
        this.props.updatePage('IP Address Tools');
    }

    subnetsToIPAddresses() {
        if(this.state.subnetsToIPAddressesData.length === 0) {
            return message.error('Please enter at least 1 subnet!');
        }

        this.setState({ subnetsToIPAddressesResultBusy: true });

        let data = {
            subnets: this.state.subnetsToIPAddressesData,
            action: this.state.subnetsToIPAddressAction
        };

        this.props.adminSubnetToIPAddress(data, (res) => {
            this.setState({ subnetsToIPAddressesResult: res.data, subnetsToIPAddressesResultBusy: false })
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ subnetsToIPAddressesResultBusy: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { TextArea } = Input;
        const { Option } = Select;

        return(
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Card bordered size='small' title='Subnet to IP addresses' extra={<Space>
                        <Button size='small' icon={<DeleteOutlined />}
                                onClick={() => this.setState({ subnetsToIPAddressesData: [], subnetsToIPAddressesResult: [] })}>Clear</Button>
                        <Button size='small' icon={<PlayCircleOutlined />}
                                loading={this.state.subnetsToIPAddressesResultBusy}
                                disabled={this.state.subnetsToIPAddressesResultBusy} type='primary' onClick={() => this.subnetsToIPAddresses()}>Run</Button>
                    </Space>}>
                        <Form layout='vertical'>
                            <Form.Item label='Action'>
                                <Select value={this.state.subnetsToIPAddressAction} onChange={(value) => this.setState({ subnetsToIPAddressAction: value })}>
                                    <Option value='LIST_ALL'>List all</Option>
                                    <Option value='LIST_USABLE'>List usable</Option>
                                    <Option value='REPLACE_ALL'>Replace all</Option>
                                    <Option value='REPLACE_USABLE'>Replace usable</Option>
                                </Select>
                            </Form.Item>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item label='Subnets' help={this.state.subnetsToIPAddressAction === 'REPLACE_ALL' || this.state.subnetsToIPAddressAction === 'REPLACE_USABLE' ? <span>Use semicolon (;) as a separator for subnets!</span> : ''}>
                                        <TextArea rows={10} value={this.state.subnetsToIPAddressesData.join('\n')}
                                                  onChange={(e) => this.setState({ subnetsToIPAddressesData: e.target.value.split('\n')}) } />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label='Result'>
                                        <TextArea rows={10} value={this.state.subnetsToIPAddressesResult.join('\n')}
                                                  disabled={this.state.subnetsToIPAddressesResultBusy} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
                <Col span={12} />
            </Row>
        );
    }
}

export default connect(null, { updatePage, adminSubnetToIPAddress })(IPAddressTools);