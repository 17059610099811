import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Menu, message, Modal, Table, Tooltip} from "antd";
import {adminLoadCacheData, adminRebuildCache} from "../../actions/cache";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {DateTimeFormat} from "../../components/shared/DateTimeFormat";
import {ExclamationCircleOutlined, RetweetOutlined} from "@ant-design/icons";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";

class SystemCache extends React.Component {
    state = {
        loading: false,
        data: []
    };

    componentDidMount() {
        this.props.updatePage('Cache');
        this.loadCacheData();
    }

    loadCacheData() {
        this.setState({ loading: true })

        this.props.adminLoadCacheData((res) => {
            this.setState({ loading: false, data: res.data });

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false })
            }
        });
    }

    rebuildCache(cache) {
        this.setState({ loading: true })

        this.props.adminRebuildCache({ cache }, (res) => {
            this.setState({ loading: false });
            message.success('Cache successfully scheduled to be rebuilt!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false })
            }
        });
    }

    confirmRebuild(cache) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you wish to rebuild ' + normalizeEnum(cache) + ' cache?',
            onOk: () => {
                this.rebuildCache(cache);
            }
        })
    }

    render() {
        let columns = [
            { title: 'Title', dataIndex: 'title' },
            { title: 'Description', dataIndex: 'description' },
            { title: 'Last Update', dataIndex: 'date_updated', render: (item, record) => <DateTimeFormat value={item} /> },
            { title: 'Actions', align: 'right', render: (item, record) => {
                return <Button size='small' icon={<RetweetOutlined />} onClick={() => this.confirmRebuild(record.key)}>Rebuild</Button>
            }},
        ];

        return(
            <Fragment>
                <Menu mode="horizontal" selectedKeys={[]}>
                    <Menu.Item key='reload_table' style={{float: 'right'}} onClick={() => this.loadCacheData()}>
                        <Tooltip title='Reload table' placement='left'><UndoOutlined /></Tooltip>
                    </Menu.Item>
                </Menu>
                <Table columns={columns} dataSource={this.state.data} loading={this.state.loading} pagination={false} />
            </Fragment>
        );
    }
}

export default connect(null,  { updatePage, adminLoadCacheData, adminRebuildCache } )(SystemCache);