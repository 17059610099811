import React, {Fragment} from "react";
import {connect} from "react-redux";
import {Switch} from "react-router-dom";
import DefaultRoute from "./shared/DefaultRoute";
import Login from "../pages/auth/login";
import {Helmet} from "react-helmet";
import Recover from "../pages/auth/recover";

class Auth extends React.Component {
    render() {
        return(
            <Fragment>
                <Helmet>
                    <title>{this.props.page.title}</title>
                </Helmet>

                <Switch>
                    <DefaultRoute path='/auth/reset' component={Recover} />
                    <DefaultRoute path='/auth/login' component={Login} exact />
                </Switch>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    page: state.page
});

export default connect(mapStateToProps, { })(Auth);