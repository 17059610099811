import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {displayErrors} from "../../libs/utils";
import {DEFAULT_PER_PAGE} from "../../config";
import {Button, Menu,Table, Tooltip} from "antd";
import {EyeOutlined, PlusOutlined} from "@ant-design/icons";
import {adminLoadIssues} from "../../actions/system";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {Link} from "react-router-dom";
import NewIssueModal from "./NewIssueModal";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {StatusIndicator} from "../shared/Status";

class IssuesTable extends React.Component {
    state = {
        announcements: [],
        loadingIssues: false,
        showNewIssueModal: false,
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
    };

    componentDidMount() {
        this.props.updatePage('Issues');
        this.adminLoadIssues();
    }

    adminLoadIssues(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loadingIssues: true });

        this.props.adminLoadIssues(page, per_page, (res) => {
            this.setState({ issues: res.data.data, loadingIssues: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingIssues: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const columns = [
            { title: 'Message', width: '50%', dataIndex: 'message' },
            { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />},
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: '', align: 'right', render: (item, record) => <Link to={'/system/issues/' + record.guid}><Button size='small' icon={<EyeOutlined />}>View</Button></Link>}
        ];

        return(
            <Fragment>
                <Menu mode="horizontal" selectedKeys={[]}>
                    <Menu.Item key='new_issue' icon={<PlusOutlined />} onClick={() => this.setState({ showNewIssueModal: true })}>
                        New Issue
                    </Menu.Item>
                    <Menu.Item key='reload_table' style={{float: 'right'}} onClick={() => this.adminLoadIssues()}>
                        <Tooltip title='Reload' placement='left'><UndoOutlined /></Tooltip>
                    </Menu.Item>
                </Menu>
                <Table
                    columns={columns}
                    rowKey={(item) => item.guid}
                    dataSource={this.state.issues}
                    loading={this.state.loadingIssues}
                    pagination={this.state.pagination}
                    onChange={(pagination) => this.adminLoadIssues(pagination)}
                />

                <NewIssueModal
                    showModal={this.state.showNewIssueModal}
                    reload={() => this.adminLoadIssues()}
                    close={() => this.setState({ showNewIssueModal: false })} />
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminLoadIssues })(IssuesTable);