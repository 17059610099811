import {httpDelete, httpGet, httpPatch, httpPost} from "../libs/http";

export const adminLoadIPAddresses = (page, per_page, filters = [], onSuccess = null, onFailure = null) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/admin/ip_addresses/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadIPAddress = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadAllCompanysIPAddressGroups = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/groups/?page=1&per_page=9999&status=ACTIVE&company=' + guid, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadCompanyIPGroups = (owner, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/groups/?page=' + page + '&per_page=' + per_page + '&company=' + owner, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadReplacementIPAddresses = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/' + guid + '/replacements/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminReplaceIPAddress = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/ip_addresses/' + guid + '/replacements/',  data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminBulkUpdateAllocatedIPAddresses = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/ip_addresses/bulk/',  data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadAllLocations = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/locations/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadIPAddressReservations = (page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/reservations/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateIPAddressReservation = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/ip_addresses/reservations/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadIPAddressReservation = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/reservations/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminUpdateIPAddressesFromReservation = (guid, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPatch('/admin/ip_addresses/reservations/' + guid + '/ip-addresses/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadIPAddressReservationAvailableIPAddresses = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/ip_addresses/reservations/' + guid + '/available-ip-addresses/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminDeleteIPAddressReservationList = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/admin/ip_addresses/reservations/' + guid + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateOrderFromIPAddressReservationList = (guid, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/ip_addresses/reservations/' + guid + '/create-order/', null, (res) => onSuccess(res), (err) => onFailure(err));
};