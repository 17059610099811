import React from "react";
import {connect} from "react-redux";
import {Button, Modal, Space, message, Table} from "antd";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {
    adminLoadIPAddressReservationAvailableIPAddresses,
    adminUpdateIPAddressesFromReservation
} from "../../actions/ip_addresses";
import {Link} from "react-router-dom";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {MoneyField} from "../shared/MoneyField";

class AddIPAddressToReservationModal extends React.Component {
    state = {
        saving: false,
        loadingIPAddresses: false,
        ipAddressesLoaded: false,
        ipAddresses: [],
        selectedIPAddresses: []
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingIPAddresses && !this.state.ipAddressesLoaded && !this.state.saving) {

            this.setState({ loadingIPAddresses: true });

            this.props.adminLoadIPAddressReservationAvailableIPAddresses(this.props.guid, (res) => {
                this.setState({ loadingIPAddresses: false, ipAddressesLoaded: true, ipAddresses: res.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingIPAddresses: false, ipAddressesLoaded: true });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    closeModal(reload = false) {
        this.setState({
            saving: false,
            loadingIPAddresses: false,
            ipAddressesLoaded: false,
            ipAddresses: [],
            selectedIPAddresses: []
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    addIPAddresses() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you wish to reserve these IP addresses?',
            onOk: () => {
                this.setState({ loading: true });

                let data = {
                    action: 'ADD',
                    ip_addresses: this.state.selectedIPAddresses
                };

                this.props.adminUpdateIPAddressesFromReservation(this.props.guid, data, () => {
                    message.success('IP addresses successfully added!');
                    this.closeModal(true);
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ loading: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        })
    }

    render() {
        const locationFilterIds = [];
        const locationFilters = [];

        for(let i = 0; i < this.state.ipAddresses.length; i++) {
            let location = this.state.ipAddresses[i].ip_node_location;

            if(!locationFilterIds.includes(location)) {
                locationFilters.push({'text': location, 'value': location});
                locationFilterIds.push(location);
            }
        }

        const ipAddressTypeFilters = [
            { text: 'Dedicated', value: 'DEDICATED' },
            { text: 'Shared', value: 'SHARED' },
        ]

        const columns = [
            { title: 'IP Address', dataIndex: 'ip_address' },
            { title: 'IP node', render: (item, record) => <Link to={'/network/ip-nodes/' + record.ip_node_guid}>{record.ip_node_hostname.replace('.gateway.prioritynap.net', '')}</Link>},
            { title: 'Location', dataIndex: 'ip_node_location', filters: locationFilters, onFilter: (value, record) => record.ip_node_location.includes(value), filterMode: 'tree', filterSearch: true},
            { title: 'Type', dataIndex: 'ip_address_type', filters: ipAddressTypeFilters, onFilter: (value, record) => record.ip_address_type.indexOf(value) === 0, render: (item) => normalizeEnum(item)},
            { title: 'Price', dataIndex: 'price', render: (item) => <MoneyField amount={item} currency='USD' />}
        ];

        return(
            <Modal title='Reservation List'
                   open={this.props.showModal}
                   onCancel={() => this.closeModal()}
                   width={900}
                   footer={<Space>
                       <Button disabled={this.state.saving} onClick={() => this.closeModal()}>Close</Button>
                       <Button disabled={this.state.saving} loading={this.state.saving}  type='primary' onClick={() => this.addIPAddresses()}>Add IP Address{this.state.selectedIPAddresses.length > 0 ? 'es': ''}</Button>
                   </Space>}>

                <Table
                    dataSource={this.state.ipAddresses}
                    loading={this.state.loadingIPAddresses}
                    rowKey={(item) => item.ip_address_guid}
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: this.state.selectedIPAddresses,
                        onChange: (ipAddresses) => this.setState({ selectedIPAddresses: ipAddresses })
                    }}
                    columns={columns} size='small' />
            </Modal>
        )
    }
}

export default connect(null, { adminLoadIPAddressReservationAvailableIPAddresses, adminUpdateIPAddressesFromReservation })(AddIPAddressToReservationModal);