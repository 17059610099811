import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    adminCheckHealth, adminCreateDomainsInCpanel,
    adminDomainCheckMetrics,
    adminDomainCheckWhois,
    adminInstallSSLCertificate,
    adminLoadDomain,
    adminLoadDomainLogs,
    adminLoadDomainTasks,
    adminLoadEmailAddresses,
    adminLoadSoftwareInstallations, adminLoadSSLCertificates,
    adminLoadSubdomains,
    adminLoadUrlRedirects,
    adminLoginToSoftware,
    adminMarkDomainTaskFailed,
    adminResetDomainTask,
    adminResetSSLCertificates, adminRestartDomainTaskItem, adminSendTestEmail,
    adminUninstallSSLCertificate,
    adminUpdateDomain, adminUpdateSSLCertificateField,
    adminUpdateSSLCertificateStatus,
    createDNSZone,
    loginTocPanel,
    resetDNSZone,
    uninstallSoftware
} from "../../actions/domains";
import {Loading} from "../../libs/loading";
import {
    Alert,
    Card,
    Col,
    Descriptions,
    Progress,
    Row,
    Space,
    Table,
    Tabs,
    Tooltip,
    Popconfirm,
    message,
    Select, Modal, Collapse, Switch, DatePicker, Divider
} from "antd";
import {DateTimeFormat} from "../../components/shared/DateTimeFormat";
import {StatusIndicator} from "../../components/shared/Status";
import {Link} from "react-router-dom";
import Button from "antd/es/button";
import {
    CloseOutlined, DeleteOutlined, ExclamationCircleOutlined,
    EyeOutlined, LinkOutlined, LoginOutlined, PlusOutlined, RetweetOutlined,
    SendOutlined
} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {DEFAULT_PER_PAGE} from "../../config";
import HostingAccountChangeModal from "../../components/domains/HostingAccountChangeModal";
import {DateFormat} from "../../components/shared/DateFormat";
import IPAddressModifyModal from "../../components/domains/IPAddressModifyModal";
import DNSZoneModal from "../../components/domains/DNSZoneModal";
import moment from "moment";
import {MoneyField} from "../../components/shared/MoneyField";

class DomainDetail extends React.Component {
    state = {
        domain: null,
        loading: false,
        loadingDomainLogs: false,
        domainLogsPagination: {current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true},
        domainLogs: [],
        loadingDomainTasks: false,
        domainTasksPagination: {current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true},
        domainTasks: [],
        loadingURLRedirects: false,
        urlRedirectsPagination: {current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true},
        urlRedirects: [],
        loadingSubdomains: false,
        subdomainsPagination: {current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true},
        subdomains: [],
        loadingEmailAccounts: false,
        emailAccountsPagination: {current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true},
        emailAccounts: [],
        loadingSoftwareInstallations: false,
        softwareInstallationsPagination: {current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true},
        softwareInstallations: [],
        loadingSSLCertificates: false,
        sslCertificatesPagination: {current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true},
        sslCertificates: [],
        showHostingAccountChangeModal: false,
        showIPAddressModifyModal: false,
        activeStatus: '',
        showDNSModal: false,
        activePanel: 'ip_address',
        resettingSSLCertificates: false,
        sslCertificateRenewed: '',
        sslCertificateRenewedValue: '',
        checkingMetrics: false,
        checkingWhois: false,
        updatingSoftware: false,
        checkingHealth: false,
    };

    componentDidMount() {
        this.loadComponents();
    }

    loadComponents() {
        this.loadDomain();
        this.loadDomainLogs();
        this.loadDomainTasks();
        this.adminLoadURLRedirects();
        this.adminLoadSubdomains();
        this.loadSoftwareInstallations();
        this.loadSSLCertificates();
        this.adminLoadEmailAddresses();
    }

    loadDomain() {
        this.setState({ loading: true });

        this.props.adminLoadDomain(this.props.match.params.guid, (res) => {
            let newState = {
                loading: false,
                domain: res.data,
                activeStatus: res.data.status,
            };

            if(res.data.ssl_certificate !== null) {
                newState['sslCertificateRenewedValue'] = moment(res.data.ssl_certificate.date_renewed, 'YYYY-MM-DD');
                newState['sslCertificateRenewed'] = res.data.ssl_certificate.date_renewed;
            }

            this.setState(newState);
            this.props.updatePage(res.data.domain);
        }, (err) => {
            console.log(err);
            if (typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    loadDomainLogs(page = null, per_page = null) {
        if (page === null) {
            page = this.state.domainLogsPagination.current;
            per_page = this.state.domainLogsPagination.pageSize;
        }

        this.setState({loadingDomainLogs: true});

        this.props.adminLoadDomainLogs(this.props.match.params.guid, page, per_page, (response) => {
            this.setState({
                loadingDomainLogs: false, domainLogs: response.data.data,
                domainLogsPagination: { current: response.data.page, pageSize: response.data.per_page, total: response.data.total, showSizeChanger: true}
            });

        }, (err) => {
            if (typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingDomainLogs: false })
            }
        });
    }

    loadSoftwareInstallations(page = null, per_page = null) {
        if (page === null) {
            page = this.state.softwareInstallationsPagination.current;
            per_page = this.state.softwareInstallationsPagination.pageSize;
        }

        this.setState({loadingSoftwareInstallations: true});

        this.props.adminLoadSoftwareInstallations(this.props.match.params.guid, page, per_page, (response) => {
            this.setState({
                loadingSoftwareInstallations: false, softwareInstallations: response.data.data,
                softwareInstallationsPagination: {
                    current: response.data.page,
                    pageSize: response.data.per_page,
                    total: response.data.total,
                    showSizeChanger: true
                }
            });

        }, (err) => {
            if (typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({loadingSoftwareInstallations: false})
            }
        });
    }

    loadSSLCertificates(page = null, per_page = null) {
        if (page === null) {
            page = this.state.sslCertificatesPagination.current;
            per_page = this.state.sslCertificatesPagination.pageSize;
        }

        this.setState({loadingSSLCertificates: true});

        this.props.adminLoadSSLCertificates(this.props.match.params.guid, page, per_page, (response) => {
            this.setState({
                loadingSSLCertificates: false, sslCertificates: response.data.data,
                sslCertificatesPagination: {
                    current: response.data.page,
                    pageSize: response.data.per_page,
                    total: response.data.total,
                    showSizeChanger: true
                }
            });

        }, (err) => {
            if (typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loadingSSLCertificates: false })
            }
        });
    }

    loadDomainTasks(page = null, per_page = null) {
        if (page === null) {
            page = this.state.domainTasksPagination.current;
            per_page = this.state.domainTasksPagination.pageSize;
        }

        this.setState({ loadingDomainTasks: true });

        this.props.adminLoadDomainTasks(this.props.match.params.guid, page, per_page, (response) => {
            this.setState({
                loadingDomainTasks: false, domainTasks: response.data.data,
                domainLogsPagination: {
                    current: response.data.page,
                    pageSize: response.data.per_page,
                    total: response.data.total,
                    showSizeChanger: true
                }
            });

        }, (err) => {
            if (typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({loadingDomainTasks: false})
            }
        });
    }

    adminLoadURLRedirects(page = null, per_page = null) {
        if (page === null) {
            page = this.state.urlRedirectsPagination.current;
            per_page = this.state.urlRedirectsPagination.pageSize;
        }

        this.setState({loadingURLRedirects: true});

        this.props.adminLoadUrlRedirects(this.props.match.params.guid, page, per_page, (response) => {
            this.setState({
                loadingURLRedirects: false, urlRedirects: response.data.data,
                urlRedirectsPagination: {
                    current: response.data.page,
                    pageSize: response.data.per_page,
                    total: response.data.total,
                    showSizeChanger: true
                }
            });

        }, (err) => {
            if (typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({loadingURLRedirects: false})
            }
        });
    }

    adminLoadSubdomains(page = null, per_page = null) {
        if (page === null) {
            page = this.state.subdomainsPagination.current;
            per_page = this.state.subdomainsPagination.pageSize;
        }

        this.setState({loadingSubdomains: true});

        this.props.adminLoadSubdomains(this.props.match.params.guid, page, per_page, (response) => {
            this.setState({
                loadingSubdomains: false, subdomains: response.data.data,
                subdomainsPagination: {
                    current: response.data.page,
                    pageSize: response.data.per_page,
                    total: response.data.total,
                    showSizeChanger: true
                }
            });

        }, (err) => {
            if (typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({loadingSubdomains: false})
            }
        });
    }

    adminLoadEmailAddresses(page = null, per_page = null) {
        if (page === null) {
            page = this.state.emailAccountsPagination.current;
            per_page = this.state.emailAccountsPagination.pageSize;
        }

        this.setState({ loadingEmailAddresses: true });

        this.props.adminLoadEmailAddresses(this.props.match.params.guid, page, per_page, (response) => {
            this.setState({
                loadingEmailAddresses: false, emailAccounts: response.data.data,
                emailAccountsPagination: {
                    current: response.data.page,
                    pageSize: response.data.per_page,
                    total: response.data.total,
                    showSizeChanger: true
                }
            });

        }, (err) => {
            if (typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({loadingEmailAddresses: false})
            }
        });
    }

    parseLog(row, field) {
        let data = null;
        let data_type = null;

        if(field === 'target') {
            data = row.target;
            data_type = row.target_type;
        } else if(field === 'actor') {
            data = row.actor;
            data_type = row.actor_type;
        } else if(field === 'action_object') {
            data = row.action_object;
            data_type = row.action_object_type;
        }

        if(data_type === 'user') {
            return data.first_name + ' ' + data.last_name;
        } else if(data_type === 'domain') {
            return data.domain;
        } else if(data_type === 'ssl_certificate') {
            return data.id;
        } else if(data_type === 'software_installation') {
            return data.software_name + ' (' + data.id + ')';
        } else if(data_type === 'email_address') {
            return data.address + ' (' + data.id + ')';
        }
    }

    progressBarStatus(record) {
        if (record.status === 'FAILURE') {
            return 'exception';
        }

        if (record.status === 'ACTIVE') {
            return 'active';
        }
    }

    adminResetDomainTask(guid) {
        this.setState({loadingDomainTasks: true});

        this.props.adminResetDomainTask(this.props.match.params.guid, guid, () => {
            message.success('Domain task successfully reset!');
            this.loadDomainTasks();
        }, (err) => {
            if (typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({loadingDomainTasks: false})
            }
        });
    }

    adminMarkDomainTaskFailed(guid) {
        this.setState({loadingDomainTasks: true});

        this.props.adminMarkDomainTaskFailed(this.props.match.params.guid, guid, () => {
            message.success('Domain task successfully marked as failed!');
            this.loadDomainTasks();
        }, (err) => {
            if (typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({loadingDomainTasks: false})
            }
        });
    }

    adminUninstallSSLCertificate() {
        this.props.adminUninstallSSLCertificate(this.props.match.params.guid, () => {
            message.success('SSL certificate successfully scheduled to be uninstalled!');
            this.loadDomain();
            this.loadSSLCertificates();
        }, (err) => {
            if (typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    adminInstallSSLCertificate() {
        this.props.adminInstallSSLCertificate(this.props.match.params.guid, () => {
            message.success('SSL certificate successfully scheduled to be installed!');
            this.loadDomain();
            this.loadSSLCertificates();
        }, (err) => {
            if (typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    cPanelLogin() {
        message.info('Creating cPanel login URL...');

        this.props.loginTocPanel(this.props.match.params.guid, (res) => {
            window.open(res.data.redirect_url);
        }, (err) => {
            if (typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    adminUpdateDomainStatus(status) {
        const {confirm} = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            content: <span>Are you sure you wish to set the status to <strong>{normalizeEnum(status)}</strong>?</span>,
            onOk: () => {
                this.props.adminUpdateDomain(this.props.match.params.guid, {'status': status}, () => {
                    message.success('Domain status successfully updated!');
                    this.loadDomain();
                }, (err) => {
                    if (typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    createDNSZone() {
        const {confirm} = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            content: <span>Are you sure you wish create the DNS zone?</span>,
            onOk: () => {
                this.props.createDNSZone(this.props.match.params.guid, () => {
                    message.success('The DNS zone was successfully created!');
                    this.loadDomain();
                }, (err) => {
                    if (typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    rebuildDNSZone() {
        const {confirm} = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            content: <span>Are you sure you wish rebuild the DNS zone?</span>,
            onOk: () => {
                this.props.resetDNSZone(this.props.match.params.guid, () => {
                    message.success('The DNS zone was successfully rebuilt!');
                    this.loadDomain();
                }, (err) => {
                    if (typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    setDomainCache(value) {
        const {confirm} = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            content:
                <span>Are you sure you wish to <strong>{value ? 'enable' : 'disable'}</strong> domain cache?</span>,
            onOk: () => {
                this.props.adminUpdateDomain(this.props.match.params.guid, {'cache_enabled': value}, () => {
                    message.success('Domain cache successfully updated!');
                    this.loadDomain();
                }, (err) => {
                    if (typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    updateDomainRedirect(field, value) {
        const {confirm} = Modal;

        let messages = {
            'redirect_to_www': 'redirect to "www"',
            'redirect_to_nonwww': 'redirect to "non-www"',
            'redirect_to_http': 'redirect HTTPS to HTTP',
            'redirect_to_https': 'redirect HTTP to HTTP',
        }

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            content:
                <span>Are you sure you wish to <strong>{value ? 'enable' : 'disable'}</strong> {messages[field]}?</span>,
            onOk: () => {
                this.props.adminUpdateDomain(this.props.match.params.guid, {[field]: value}, () => {
                    message.success('Domain redirect successfully updated!');
                    this.loadDomain();
                }, (err) => {
                    if (typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    resetAllSSLCertificates() {
        const {confirm} = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            content: <span>Are you sure you wish reset all SSL certificates?</span>,
            onOk: () => {
                this.setState({resettingSSLCertificates: true});
                this.props.adminResetSSLCertificates(this.props.match.params.guid, () => {
                    this.setState({resettingSSLCertificates: false});
                    message.success('Domain SSL certificates reset!');
                    this.loadDomain();
                    this.loadSSLCertificates();
                }, (err) => {
                    if (typeof err.response !== 'undefined') {
                        this.setState({resettingSSLCertificates: false});
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    changeSSLCertificateStatus(status) {
        const {confirm} = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            content: <span>Are you sure you wish to set SSL certificate's status to {normalizeEnum(status)}?</span>,
            onOk: () => {
                this.props.adminUpdateSSLCertificateStatus(this.props.match.params.guid, {'status': status}, () => {
                    this.setState({activePanel: 'ssl_certificate'});
                    message.success('Domain SSL certificate status successfully set!');
                    this.loadDomain();
                    this.loadSSLCertificates();
                }, (err) => {
                    if (typeof err.response !== 'undefined') {
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    loginToSoftware(guid) {
        message.info('Creating software login URL...');

        this.props.adminLoginToSoftware(guid, (res) => {
            window.open(res.data.redirect_url);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    updateSSLCertificateDateRenewed(guid, value, valueStr) {
        const {confirm} = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            content: <span>Are you sure you wish to update SSL certificate's date renewed to <strong>{valueStr}</strong>?</span>,
            onOk: () => {
                let data = {
                    'date_renewed': valueStr
                };

                this.props.adminUpdateSSLCertificateField(guid, data, () => {
                    this.setState({ sslCertificateRenewed: valueStr, sslCertificateRenewedValue: value });
                    message.success('SSL certificate\'s date renewed successfully updated!');
                }, (err) => {
                    displayErrors(err.response.data);
                });
            }
        });
    }

    checkWhois(guid) {
        const {confirm} = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            content: <span>Are you sure you wish to check whois for this domain?</span>,
            onOk: () => {
                this.setState({ checkingWhois: true });

                this.props.adminDomainCheckWhois(guid, () => {
                    this.setState({ checkingWhois: false });
                    message.success('Whois check successfully completed!');
                    this.loadDomain();
                }, (err) => {
                    this.setState({ checkingWhois: false });
                    displayErrors(err.response.data);
                });
            }
        });
    }

    checkMetrics(guid) {
        const {confirm} = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            content: <span>Are you sure you wish to check metrics for this domain?</span>,
            onOk: () => {
                this.setState({ checkingMetrics: true });

                this.props.adminDomainCheckMetrics(guid, () => {
                    this.setState({ checkingMetrics: false });
                    message.success('Metrics check successfully completed!');
                    this.loadDomain();
                }, (err) => {
                    this.setState({ checkingMetrics: false });
                    displayErrors(err.response.data);
                });
            }
        });
    }

    sendTestEmail(guid) {
        const {confirm} = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            content: <span>Are you sure you wish to send a test email to this email address?</span>,
            onOk: () => {
                this.setState({ loadingEmailAccounts: true });
                this.props.adminSendTestEmail(guid, () => {
                    this.setState({ loadingEmailAccounts: false });
                    message.success('Test email successfully sent!');
                    this.loadDomain();
                }, (err) => {
                    this.setState({ loadingEmailAccounts: false });
                    displayErrors(err.response.data);
                });
            }
        });
    }

    uninstallSoftware(guid) {
        this.setState({ updatingSoftware: true });

        this.props.uninstallSoftware(guid, () => {
            this.setState({ updatingSoftware: false });

            message.success('Software update successfully scheduled!');

            this.loadDomain();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ updatingSoftware: false });
                displayErrors(err.response.data);
            }
        });
    }

    getOrderList(orders) {
        let orderList = [];

        for(let i = 0; i < orders.length; i++) {
            orderList.push(<Link key={i} to={'/billing/orders/' + orders[i]}>#{orders[i]}</Link>);
        }

        if(orders.length === 0) {
            return <span>Missing!</span>;
        }

        return orderList.reduce((prev, curr) => [ prev, ', ', curr ])
    }

    restartDomainTaskItem(guid, taskId, itemId) {
        this.props.adminRestartDomainTaskItem(guid, { task_id: taskId, item_id: itemId }, () => {
            message.success('Task item restart successfully scheduled!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ updatingSoftware: false });
                displayErrors(err.response.data);
            }
        });
    }

    checkHealth(guid) {
        const {confirm} = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined/>,
            content: <span>Are you sure you wish to run a health check for this domain?</span>,
            onOk: () => {
                this.props.adminCheckHealth(guid, () => {
                    message.success('Health check successfully completed!');
                    this.loadDomain();

                    this.setState({ checkingHealth: false });
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ checkingHealth: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    createDomainsInCpanel() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to add this domain to cPanel?</span>,
            onOk: () => {
                this.setState({ loading: true });

                let data = {
                    domains: [this.state.domain.guid]
                };

                this.props.adminCreateDomainsInCpanel(data, () => {
                    message.success('Domain adding tasks successfully created!');
                    this.closeModal(true);
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ loading: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        })
    }

    render() {
        if (this.state.domain === null) {
            return <div className='text-center'><Loading/></div>;
        }

        const {TabPane} = Tabs;

        const {domain} = this.state;

        let resolved_ip_address;

        if (domain.health !== null && domain.health.resolved_ip_address) {
            if (domain.health.resolved_ip_address !== domain.ip_address.ip_address) {
                resolved_ip_address = <Text type='danger'>{domain.health.resolved_ip_address}</Text>;
            } else {
                resolved_ip_address = domain.health.resolved_ip_address;
            }
        } else {
            resolved_ip_address = <Text type='danger'>Did not resolve!</Text>;
        }

        let www_resolved_ip_address;
        if (domain.health !== null && domain.health.resolved_www_ip_address) {
            if (domain.health.resolved_www_ip_address !== domain.ip_address.ip_address) {
                www_resolved_ip_address = <Text type='danger'>{domain.health.resolved_www_ip_address}</Text>;
            } else {
                www_resolved_ip_address = domain.health.resolved_www_ip_address;
            }
        } else {
            www_resolved_ip_address = <Text type='danger'>Did not resolve!</Text>;
        }

        let hosting_ip_address = domain.hosting_account.ip_address;
        if (domain.hosting_account.account_type === 'CUSTOM_HOST') {
            hosting_ip_address = domain.hosting_account.custom_host_server.ip_address;
        }

        let hosting_hostname = domain.hosting_account.hostname;
        if (domain.hosting_account.account_type === 'CUSTOM_HOST') {
            hosting_hostname = domain.hosting_account.custom_host_server.hostname;
        }

        const domainLogColumns = [
            {title: 'Timestamp', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item}/>},
            {title: 'IP Address', dataIndex: 'ip_address'},
            {title: 'Actor', dataIndex: 'target', render: (item, row) => this.parseLog(row, 'actor')},
            {title: 'Verb', dataIndex: 'verb', render: (item) =>  item.charAt(0).toUpperCase() + item.slice(1)},
            {title: 'Target', dataIndex: 'target', render: (item, row) => this.parseLog(row, 'target')},
            {title: 'Object', dataIndex: 'action_object', render: (item, row) => this.parseLog(row, 'action_object')},
        ];

        const domainTasksColumns = [
            {title: 'ID', dataIndex: 'id'},
            {title: 'Created', render: (item, record) => <DateTimeFormat value={record.date_created}/>},
            {
                title: 'Completed',
                render: (item, record) => record.date_completed !== null ?
                    <DateTimeFormat value={record.date_completed}/> : '-'
            },
            {title: 'Status', render: (item, record) => normalizeEnum(record.status)},
            {
                title: 'Progress',
                width: '40%',
                render: (item, record) => <Progress percent={record.progress_percentage}
                                                    status={this.progressBarStatus(record)}/>
            },
            {
                title: '', align: 'right', render: (item, record) => {
                    return <Space>
                        <Popconfirm
                            title="Are you sure you want to reset this task?"
                            onConfirm={() => this.adminResetDomainTask(record.guid)}
                            okText='Reset'
                            okButtonProps={{danger: true}}
                            cancelText=''>
                            <Button size='small' type='round' icon={<RetweetOutlined/>}>Reset</Button>
                        </Popconfirm>
                        {record.status !== 'FAILED' ? <Popconfirm
                            title="Are you sure you want to mark this task as failed?"
                            onConfirm={() => this.adminMarkDomainTaskFailed(record.guid)}
                            okText='Continue'
                            okButtonProps={{danger: true}}
                            cancelText=''>
                            <Button size='small' icon={<CloseOutlined/>}>Mark Failed</Button>
                        </Popconfirm> : ''}
                    </Space>
                }
            }
        ];

        const urlRedirectsColumns = [
            {
                title: 'URLs',
                dataIndex: 'domain',
                render: (item, row) => row.urls.map((url, i) => <Text key={i} code>{url}</Text>)
            },
            {title: 'Destination', dataIndex: 'destination', render: (item, row) => row.destination},
            {
                title: 'Status Code',
                dataIndex: 'response_code',
                align: 'center',
                render: (item, row) => <Tooltip title={row.response_code}>{row.response_code}</Tooltip>
            },
            {
                title: 'Status',
                dataIndex: 'status',
                align: 'center',
                render: (item) => <StatusIndicator el='badge' status={item}/>
            },
        ];
        const subdomainsColumns = [
            {
                title: 'Domain',
                dataIndex: 'domain',
                key: 'domain',
                render: (item, row) => <a href={'/domains/' + row.guid}>{row.domain}</a>
            },
            {title: 'Added', key: 'records', render: (item, row) => <DateTimeFormat value={row.date_created}/>},
            {
                title: 'Status',
                key: 'Status',
                dataIndex: 'status',
                render: (item) => <StatusIndicator el='badge' status={item}/>
            },
        ];

        const emailAddressesColumns = [
            { title: 'Email', dataIndex: 'address', key: 'address', render: (item, row) => row.address},
            { title: 'Type', dataIndex: 'email_type', key: 'email_type', render: (item, row) => normalizeEnum(row.email_type)},
            { title: 'Forward To', dataIndex: 'forward_address', key: 'forward_address', render: (item, row) => row.forward_address},
            { title: 'Created', key: 'records', render: (item, row) => <DateTimeFormat value={row.date_created}/>},
            { title: 'Status', key: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator el='badge' status={item}/>},
            { title: 'Actions', render: (item, row) => {
                return <Space>
                    <Button onClick={() => this.sendTestEmail(row.guid)} size='small' icon={<SendOutlined />}>Test Email</Button>
                </Space>
            }}
        ];

        const softwareInstallationsColumns = [
            { title: 'ID', dataIndex: 'id' },
            { title: 'Software', dataIndex: 'software_name' },
            { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />},
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: 'Deleted', dataIndex: 'date_deleted', render: (item) => <DateTimeFormat value={item} />},
            { title: 'Install ID', dataIndex: 'install_id' },
            { title: 'User', dataIndex: 'username' },
        ];

        const sslCertificateColumns = [
            { title: 'ID', dataIndex: 'id'},
            { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />},
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: 'Deleted', dataIndex: 'date_deleted', render: (item) => <DateTimeFormat value={item} />},
            { title: 'Renewed', dataIndex: 'date_renewed', render: (item) => <DateFormat value={item} />},
        ];

        const ipAddressHistoryColumns = [
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} />},
            { title: 'IP address', dataIndex: 'ip_address', render: (item) => item.ip_address },
            { title: 'IP node', dataIndex: 'ip_address', render: (item) => <Link to={'/network/ip_node/' + item.ip_node.guid}>{item.ip_node.hostname.replace('.gateway.prioritynap.net', '')}</Link>},
            { title: 'Price', dataIndex: 'price', render: (item) => <MoneyField amount={item} currency='USD' />},
            { title: 'Price group', dataIndex: 'price_group', render: (item) => item.name }
        ];

        const {Option} = Select;
        const {Panel} = Collapse;

        return (
            <Fragment>
                <Row>
                    <Col span={24} className='text-right'>
                        <Button loading={this.state.loading} disabled={this.state.loading}
                                onClick={() => this.loadComponents()} size='small' icon={<RetweetOutlined/>}>Reload</Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={11} xs={24}>
                        <Card bordered={false} title='Details' size='small'>
                            <Descriptions bordered size='small' column={1}>
                                <Descriptions.Item label='ID'>{domain.id}</Descriptions.Item>
                                <Descriptions.Item style={{width: '40%'}} label='Domain'>
                                    <a href={domain.ssl_certificate !== null ? 'https://' + domain.domain : 'http://' + domain.domain}
                                       target='_blank' rel='noopener noreferrer'>{domain.domain}</a>
                                </Descriptions.Item>
                                <Descriptions.Item label='Created'>
                                    <DateTimeFormat value={domain.date_created}/>
                                </Descriptions.Item>
                                {domain.status === 'DELETED' ? <Descriptions.Item label='Deleted'><DateTimeFormat value={domain.date_deleted} /></Descriptions.Item> : ''}
                                <Descriptions.Item label='Status'>
                                    <Select style={{width: '200px'}} value={this.state.activeStatus}
                                            disabled={this.state.updatingOrderStatus}
                                            loading={this.state.updatingOrderStatus}
                                            onChange={(value) => this.adminUpdateDomainStatus(value)}>
                                        <Option value='ACTIVE'>Active</Option>
                                        <Option value='DISABLED'>Disabled</Option>
                                        <Option value='SUSPENDED'>Suspended</Option>
                                        <Option value='PENDING'>Pending</Option>
                                        <Option value='DELETED'>Deleted</Option>
                                    </Select>
                                </Descriptions.Item>
                                <Descriptions.Item label='Group'><Link
                                    to={'/domains/?group=' + domain.group.guid}>{domain.group.name}</Link></Descriptions.Item>
                                <Descriptions.Item label='Company'>
                                    <Link to={'/accounts/companies/' + domain.group.owner.guid}>{domain.group.owner.name}</Link>
                                </Descriptions.Item>
                                <Descriptions.Item label='User'>
                                    <Link to={'/accounts/users/' + domain.group.owner.owner.guid}>
                                        {domain.group.owner.owner.first_name + ' ' + domain.group.owner.owner.last_name}
                                    </Link>
                                </Descriptions.Item>
                                {!domain.is_subdomain ? <Descriptions.Item label='DNS zone created'>
                                    <Space>
                                        <StatusIndicator status={domain.is_dns_zone_created ? 'YES' : 'NO'} el='badge'/>
                                        {domain.is_dns_zone_created ? <Button size='small' onClick={() => this.setState({showDNSModal: true})} icon={<EyeOutlined/>}>DNS zone</Button> : ''}
                                    </Space>

                                </Descriptions.Item> : ''}
                                <Descriptions.Item label='Cache enabled'>
                                    <Switch size='small' checked={domain.cache_enabled} onChange={(value) => this.setDomainCache(value)}/>
                                </Descriptions.Item>
                                <Descriptions.Item label='Actions'>
                                    <Space>
                                        {domain.is_dns_zone_created ? <Button size='small' onClick={() => this.rebuildDNSZone()}
                                                                              icon={<RetweetOutlined/>}>Rebuild DNS zone</Button> : <Button size='small' onClick={() => this.createDNSZone()}
                                                                                                                                            icon={<PlusOutlined/>}>Create DNS zone</Button>}
                                        <Button onClick={() => this.createDomainsInCpanel()} size='small' icon={<PlusOutlined />}>Create in cPanel</Button>
                                    </Space>
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                        {!domain.is_subdomain ? <Card bordered={false} title='Google Safe Browsing' size='small'>
                                {domain.google_safe_browsing !== null ? <Descriptions bordered size='small' column={1}>
                                    <Descriptions.Item label='Checked'><DateTimeFormat value={domain.google_safe_browsing.date_last_check}/></Descriptions.Item>
                                    <Descriptions.Item label='Status'>{domain.google_safe_browsing.status !== null ? <Text type="danger">{domain.google_safe_browsing.status.join(', ')}</Text> : <Text type="success">OK</Text>}</Descriptions.Item>
                                    {domain.google_safe_browsing.status !== null ? <Descriptions.Item label='Expires' style={{width: '40%'}}><DateTimeFormat value={domain.google_safe_browsing.date_status_Expires}/></Descriptions.Item> : ''}
                                </Descriptions> : <Alert message='Not checked, yet!' type='info' /> }
                        </Card> : ''}
                        {!domain.is_subdomain ? <Card bordered={false} title='Whois' size='small'>
                                {domain.whois !== null ? <Descriptions bordered size='small' column={1}>
                                    <Descriptions.Item label='Expires' style={{width: '40%'}}><DateFormat value={domain.whois.whois_expires}/></Descriptions.Item>
                                    <Descriptions.Item label='Checked'><DateFormat value={domain.whois.date_whois_checked}/></Descriptions.Item>
                                    <Descriptions.Item label='Failed?'>{domain.whois.whois_check_failed ? 'Yes' : 'No'}</Descriptions.Item>
                                    <Descriptions.Item label='Actions'>
                                        <Button icon={<RetweetOutlined />} loading={this.state.checkingWhois}
                                                disabled={this.state.checkingWhois}
                                                onClick={() => this.checkWhois(domain.guid)} size='small'>Check whois</Button>
                                    </Descriptions.Item>
                                </Descriptions> : <Alert message='Not checked, yet!' type='info' /> }
                        </Card> : ''}
                        {!domain.is_subdomain ? <Card bordered={false} title='Metrics' size='small'>
                                {domain.metrics !== null ? <Descriptions bordered size='small' column={1}>
                                    <Descriptions.Item label='DA' style={{width: '40%'}}>{domain.metrics.da}</Descriptions.Item>
                                    <Descriptions.Item label='TF' style={{width: '40%'}}>{domain.metrics.tf}</Descriptions.Item>
                                    <Descriptions.Item label='Checked'><DateFormat value={domain.metrics.date_metrics_checked}/></Descriptions.Item>
                                    <Descriptions.Item label='Actions'>
                                        <Button icon={<RetweetOutlined />} loading={this.state.checkingMetrics}
                                                disabled={this.state.checkingMetrics}
                                                onClick={() => this.checkMetrics(domain.guid)} size='small'>Check metrics</Button>
                                    </Descriptions.Item>
                                </Descriptions> : <Alert message='Not checked, yet!' type='info' /> }
                        </Card> : ''}
                        <Card bordered={false} title='Hosting' size='small'>
                            <Descriptions bordered size='small' column={1}>
                                <Descriptions.Item label='Name' style={{width: '40%'}}>
                                    <Space>
                                        {domain.hosting_account.name}
                                        <Button size='small'
                                                onClick={() => this.setState({showHostingAccountChangeModal: true})}
                                                icon={<RetweetOutlined/>}>Change</Button>
                                    </Space>
                                </Descriptions.Item>
                                <Descriptions.Item label='Type'>{normalizeEnum(domain.hosting_account.account_type)}</Descriptions.Item>
                                <Descriptions.Item label='Hostname'>{hosting_hostname}</Descriptions.Item>
                                <Descriptions.Item label='IP address'>{hosting_ip_address}</Descriptions.Item>
                                <Descriptions.Item label='Status'><StatusIndicator el='badge'
                                                                                   status={domain.hosting_account.status}/></Descriptions.Item>
                                {domain.hosting_account.account_type === 'SHARED_HOSTING' ?
                                    <Descriptions.Item label='Username'>
                                        {domain.hosting_account.username}
                                    </Descriptions.Item> : ''}
                                {domain.hosting_account.account_type === 'SHARED_HOSTING' ?
                                    <Descriptions.Item label='Actions'>
                                        <Space>
                                            <Button size='small' type='round'
                                                    disabled={domain.hosting_account.status !== 'ACTIVE'}
                                                    icon={<LoginOutlined/>}
                                                    onClick={() => this.cPanelLogin()}>Login to cPanel</Button>
                                            <Link to={'/hosting/' + domain.hosting_account.guid}>
                                                <Button size='small' type='round'
                                                        disabled={domain.hosting_account.status !== 'ACTIVE'} icon={<EyeOutlined/>}>View</Button>
                                            </Link>
                                        </Space>
                                    </Descriptions.Item> : ''}
                            </Descriptions>
                        </Card>
                    </Col>
                    <Col md={13} xs={24}>
                        <Card bordered={false} title='Items' size='small'>
                            <Collapse defaultActiveKey={[this.state.activePanel]}
                                      onChange={(panel) => this.setState({activePanel: panel})} accordion ghost>
                                <Panel header='IP address' key='ip_address'>
                                    <Descriptions bordered size='small' column={1}>
                                        <Descriptions.Item label='ID'>{domain.ip_address.id}</Descriptions.Item>
                                        <Descriptions.Item style={{width: '40%'}} label='IP address'>
                                            <Space>
                                                <Link
                                                    to={'/ip-addresses/' + domain.ip_address.guid}>{domain.ip_address.ip_address}</Link>
                                                <Button size='small' icon={<RetweetOutlined/>}
                                                        onClick={() => this.setState({showIPAddressModifyModal: true})}>Change</Button>
                                            </Space>
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            label='Type'>{normalizeEnum(domain.ip_address.ip_address_type)}</Descriptions.Item>
                                        <Descriptions.Item label='Status'><StatusIndicator
                                            status={domain.ip_address.status} el='badge'/></Descriptions.Item>
                                        <Descriptions.Item label='HTTP version'>
                                            {domain.ip_address.http_version === 1 ? 'HTTP/1.1' : 'HTTP/2'}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                            label='Location'>{domain.ip_address.city + ', ' + domain.ip_address.country}</Descriptions.Item>
                                        <Descriptions.Item label='Group'><Link
                                            to={'/ip-addresses/?group=' + domain.ip_address.group.guid}>{domain.ip_address.group.name}</Link></Descriptions.Item>
                                        <Descriptions.Item label='IP node'><Link
                                            to={'/network/ip-nodes/' + domain.ip_address.ip_node.guid}>{domain.ip_address.ip_node.hostname.replace('.gateway.prioritynap.net', '')}</Link></Descriptions.Item>
                                        <Descriptions.Item label='Order'>{this.getOrderList(domain.ip_address.orders)}</Descriptions.Item>
                                    </Descriptions>
                                </Panel>
                                <Panel header='IP address history' key='ip_address_history'>
                                    <Table columns={ipAddressHistoryColumns} dataSource={domain.ip_address_history} pagination={false} size='small' />
                                </Panel>
                                <Panel header='SSL certificate' key='ssl_certificate'>
                                    {domain.ssl_certificate === null ? <Space>
                                            <Popconfirm
                                                title="Are you sure you want to install SSL certificate?"
                                                onConfirm={() => this.adminInstallSSLCertificate()}
                                                okText='Continue'>
                                                <Button size='small' icon={<PlusOutlined/>}>Install SSL certificate</Button>
                                            </Popconfirm>
                                        </Space> :
                                        <Descriptions bordered size='small' column={1}>
                                            <Descriptions.Item style={{width: '40%'}} label='Type'>
                                                {domain.ssl_certificate.certificate_type === 'LETS_ENCRYPT' ? 'Let\'s Encrypt' : 'Custom'}
                                            </Descriptions.Item>
                                            <Descriptions.Item label='Created'>
                                                <DateTimeFormat value={domain.ssl_certificate.date_created}/>
                                            </Descriptions.Item>
                                            <Descriptions.Item label='Renewed'>
                                                <DatePicker value={this.state.sslCertificateRenewedValue} format='YYYY-MM-DD'
                                                            allowClear={false}
                                                            onChange={(value, valueStr) => this.updateSSLCertificateDateRenewed(domain.ssl_certificate.guid, value, valueStr)} />
                                            </Descriptions.Item>
                                            <Descriptions.Item label='Status'>
                                                <Select style={{width: '200px'}}
                                                        value={domain.ssl_certificate.status}
                                                        onChange={(value) => this.changeSSLCertificateStatus(value)}>
                                                    <Option value='ACTIVE'>Active</Option>
                                                    <Option value='PENDING'>Pending</Option>
                                                    <Option value='UNINSTALLING'>Uninstalling</Option>
                                                    <Option value='DELETED'>Deleted</Option>
                                                </Select>
                                            </Descriptions.Item>
                                            <Descriptions.Item label='Actions'>
                                                <Space>
                                                    <Popconfirm
                                                        title="Are you sure you want to uninstall?"
                                                        onConfirm={() => this.adminUninstallSSLCertificate()}
                                                        okText='Continue'
                                                        okButtonProps={{danger: true}}
                                                        cancelText=''>
                                                        <Button size='small'
                                                                icon={<DeleteOutlined/>}>Uninstall</Button>
                                                    </Popconfirm>
                                                    <Button size='small'
                                                            loading={this.state.resettingSSLCertificates}
                                                            disabled={this.state.resettingSSLCertificates}
                                                            onClick={() => this.resetAllSSLCertificates()}
                                                            icon={<RetweetOutlined/>}>Reset certificates</Button>
                                                </Space>
                                            </Descriptions.Item>
                                        </Descriptions>}
                                    {this.state.sslCertificates.length > 0 ? <Fragment>
                                        <Divider />
                                        <Table
                                            onChange={(pagination) => this.loadSSLCertificates(pagination.current, pagination.pageSize)}
                                            dataSource={this.state.sslCertificates}
                                            columns={sslCertificateColumns}
                                            rowKey={(item) => item.guid}
                                            size='small'
                                            pagination={this.state.sslCertificatesPagination}
                                            loading={this.state.loadingSSLCertificates}/>
                                    </Fragment> : ''}

                                </Panel>
                                <Panel header='Software' key='software'>
                                    {domain.software === null ?
                                        <Alert showIcon type='error' message='Not installed!'/> :
                                        <Descriptions bordered size='small' column={1}>
                                            <Descriptions.Item style={{width: '40%'}} label='Software'>
                                                {domain.software.software_name}
                                            </Descriptions.Item>
                                            <Descriptions.Item label='Installed'>
                                                <DateTimeFormat value={domain.software.date_created}/>
                                            </Descriptions.Item>
                                            <Descriptions.Item label='Status'>
                                                <StatusIndicator el='badge' status={domain.software.status}/>
                                            </Descriptions.Item>
                                            <Descriptions.Item label='Actions'>
                                                <Space>
                                                    <Button size='small' type='round' disabled={domain.software.status !== 'ACTIVE'} icon={<LoginOutlined />} onClick={() => this.loginToSoftware(domain.guid)}>Login</Button>
                                                    <Popconfirm
                                                        title="Are you sure you want to uninstall?"
                                                        onConfirm={() => this.uninstallSoftware(domain.software.guid)}
                                                        okText='Continue'
                                                        okButtonProps={{danger: true}}
                                                        cancelText=''>
                                                        <Button size='small' icon={<DeleteOutlined/>} loading={this.state.updatingSoftware} disabled={this.state.updatingSoftware}>Uninstall</Button>
                                                    </Popconfirm>
                                                </Space>

                                            </Descriptions.Item>
                                        </Descriptions>}
                                    {this.state.softwareInstallations.length > 0 ? <Fragment>
                                        <Divider />
                                        <Table
                                            onChange={(pagination) => this.loadSoftwareInstallations(pagination.current, pagination.pageSize)}
                                            dataSource={this.state.softwareInstallations}
                                            columns={softwareInstallationsColumns}
                                            rowKey={(item) => item.guid}
                                            size='small'
                                            pagination={this.state.softwareInstallationsPagination}
                                            loading={this.state.loadingSoftwareInstallations}/>
                                    </Fragment> : ''}


                                </Panel>
                                {!domain.is_subdomain ? <Panel header='Health' key='health'>
                                    {domain.health === null ? <Alert showIcon type='warning' message='Not checked!'/> :
                                        <Descriptions bordered size='small' column={1}>
                                            <Descriptions.Item style={{width: '40%'}} label='Last check'>
                                                <DateTimeFormat value={domain.health.date_health_checked}/>
                                            </Descriptions.Item>
                                            <Descriptions.Item
                                                label='Resolved IP address'>{resolved_ip_address}</Descriptions.Item>
                                            <Descriptions.Item
                                                label='Resolved IP address (www)'>{www_resolved_ip_address}</Descriptions.Item>
                                            <Descriptions.Item label='Expired'>{domain.health.is_expired ?
                                                <Text type='danger'>Yes</Text> : 'No'}</Descriptions.Item>
                                            <Descriptions.Item
                                                label='Cloudflare'>{domain.health.is_cloudflare ? 'Yes' : 'No'}</Descriptions.Item>
                                            <Descriptions.Item label='NXDomain'>{domain.health.is_nxdomain ?
                                                <Text type='danger'>Yes</Text> : 'No'}</Descriptions.Item>
                                            <Descriptions.Item label='IntoDNS'>
                                                <a href={'https://intodns.com/' + domain.domain} target='_blank'
                                                   rel='noopener noreferrer'>
                                                    <Button size='small' icon={<LinkOutlined/>}>Visit</Button>
                                                </a>
                                            </Descriptions.Item>
                                            <Descriptions.Item label='LeafDNS'>
                                                <a href={'http://leafdns.com/?domain=' + domain.domain + '&name=' + domain.domain}
                                                   target='_blank' rel='noopener noreferrer'>
                                                    <Button size='small' icon={<LinkOutlined/>}>Visit</Button>
                                                </a>
                                            </Descriptions.Item>
                                            <Descriptions.Item label='DNSSEC Analyzer'>
                                                <a href={'https://dnssec-analyzer.verisignlabs.com/' + domain.domain}
                                                   target='_blank' rel='noopener noreferrer'>
                                                    <Button size='small' icon={<LinkOutlined/>}>Visit</Button>
                                                </a>
                                            </Descriptions.Item>
                                            <Descriptions.Item label='Actions'>
                                                <Button size='small' icon={<RetweetOutlined />} loading={this.state.checkingHealth} onClick={() => this.checkHealth(domain.guid)}>Check Health</Button>
                                            </Descriptions.Item>
                                        </Descriptions>}
                                </Panel> : ''}
                                <Panel header='Domain redirects' key='domain_redirects'>
                                    <Descriptions bordered size='small' column={1}>
                                        <Descriptions.Item label='Redirect to "www"'>
                                            <Switch size='small' checked={domain.redirect_to_www} onChange={(value) => this.updateDomainRedirect('redirect_to_www', value)}/>
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Redirect to "non-www"'>
                                            <Switch size='small' checked={domain.redirect_to_nonwww} onChange={(value) => this.updateDomainRedirect('redirect_to_nonwww', value)}/>
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Redirect HTTPS to HTTP'>
                                            <Switch size='small' checked={domain.redirect_to_http} onChange={(value) => this.updateDomainRedirect('redirect_to_http', value)}/>
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Redirect HTTP to HTTPS'>
                                            <Switch size='small' checked={domain.redirect_to_https} onChange={(value) => this.updateDomainRedirect('redirect_to_https', value)}/>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Panel>
                                <Panel header='URL redirects' key='url_redirects'>
                                    <Table
                                        onChange={(pagination) => this.adminLoadURLRedirects(pagination.current, pagination.pageSize)}
                                        dataSource={this.state.urlRedirects}
                                        columns={urlRedirectsColumns}
                                        rowKey={(item) => item.guid}
                                        size='small'
                                        pagination={this.state.urlRedirectsPagination}
                                        loading={this.state.loadingURLRedirects}/>
                                </Panel>
                                {!domain.is_subdomain ? <Panel header='Subdomains' key='subdomains'>
                                    <Table
                                        onChange={(pagination) => this.adminLoadSubdomains(pagination.current, pagination.pageSize)}
                                        dataSource={this.state.subdomains}
                                        columns={subdomainsColumns}
                                        rowKey={(item) => item.guid}
                                        size='small'
                                        pagination={this.state.subdomainsPagination}
                                        loading={this.state.loadingSubdomains}/>
                                </Panel> : ''}
                                {!domain.is_subdomain ? <Panel header='Emails' key='emails'>
                                    <Table
                                        onChange={(pagination) => this.adminLoadEmailAddresses(pagination.current, pagination.pageSize)}
                                        dataSource={this.state.emailAccounts}
                                        columns={emailAddressesColumns}
                                        rowKey={(item) => item.guid}
                                        size='small'
                                        pagination={this.state.emailAccountsPagination}
                                        loading={this.state.loadingEmailAccounts}/>
                                </Panel> : ''}
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Tabs destroyInactiveTabPane={true}>
                            <TabPane tab='Logs' key='logs'>
                                <Table
                                    onChange={(pagination) => this.loadDomainLogs(pagination.current, pagination.pageSize)}
                                    dataSource={this.state.domainLogs}
                                    columns={domainLogColumns}
                                    rowKey={(item) => item.guid}
                                    size='small'
                                    pagination={this.state.domainLogsPagination}
                                    loading={this.state.loadingDomainLogs}/>
                            </TabPane>
                            <TabPane tab='Tasks' key='tasks'>
                                <Table
                                    onChange={(pagination) => this.loadDomainTasks(pagination.current, pagination.pageSize)}
                                    dataSource={this.state.domainTasks}
                                    columns={domainTasksColumns}
                                    rowKey={(item) => item.id}
                                    size='small'
                                    pagination={this.state.domainTasksPagination}
                                    loading={this.state.loadingDomainTasks}
                                    expandable={{
                                        expandedRowRender: record => {
                                            const domainTaskColumns = [
                                                { title: 'ID', dataIndex: 'id' },
                                                { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
                                                { title: 'Completed', dataIndex: 'date_completed', render: (item) => <DateTimeFormat value={item} /> },
                                                { title: 'Action', dataIndex: 'action', render: (item) => normalizeEnum(item) },
                                                { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' /> },
                                                { title: 'Details', dataIndex: 'details' },
                                                { title: '', align: 'right', render: (item) => <Space>
                                                        <Button size='small' onClick={() => this.restartDomainTaskItem(domain.guid, record.id, item.id)} icon={<RetweetOutlined />}>Restart</Button>
                                                </Space> },
                                            ];

                                            return <div style={{padding: '10px'}}>
                                                <Table size='small' columns={domainTaskColumns}
                                                       rowKey={(item) => item.id}
                                                       dataSource={record.items} pagination={false} />
                                            </div>
                                        },
                                        rowExpandable: record => record.items.length > 0,
                                    }}
                                />
                            </TabPane>
                        </Tabs>
                    </Col>
                </Row>

                <DNSZoneModal
                    showModal={this.state.showDNSModal}
                    close={() => this.setState({showDNSModal: false})}
                    activeDomain={this.props.match.params.guid}/>

                <IPAddressModifyModal
                    domain={this.props.match.params.guid}
                    showModal={this.state.showIPAddressModifyModal}
                    close={() => this.setState({showIPAddressModifyModal: false})}
                    ipAddress={domain.ip_address}
                    domainGroup={domain.group.guid}
                    reload={() => this.loadDomain()}
                />

                <HostingAccountChangeModal
                    owner={domain.group.owner.guid}
                    domain={this.props.match.params.guid}
                    showModal={this.state.showHostingAccountChangeModal}
                    reload={() => this.loadDomain()}
                    close={() => this.setState({showHostingAccountChangeModal: false})}/>
            </Fragment>
        )
    }
}

export default connect(null, {
    updatePage, adminLoadDomain, adminLoadDomainLogs,
    adminLoadDomainTasks, adminResetDomainTask, adminMarkDomainTaskFailed, adminUninstallSSLCertificate,
    adminInstallSSLCertificate, loginTocPanel, adminUpdateDomain, resetDNSZone, createDNSZone,
    adminLoadUrlRedirects, adminLoadSubdomains, adminLoadEmailAddresses, adminResetSSLCertificates,
    adminUpdateSSLCertificateStatus, adminLoginToSoftware, adminLoadSoftwareInstallations, adminLoadSSLCertificates,
    adminUpdateSSLCertificateField, adminDomainCheckWhois, adminDomainCheckMetrics, adminSendTestEmail,
    uninstallSoftware, adminRestartDomainTaskItem, adminCheckHealth, adminCreateDomainsInCpanel
})(DomainDetail);