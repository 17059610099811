import {httpDelete, httpGet, httpGetDownload, httpPatch, httpPost} from "../libs/http";

export const adminLoadOrders = (page, per_page, filters = [], onSuccess = null, onFailure = null) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/admin/billing/orders/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};


export const adminLoadAllCompanyOrders = (company_id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/orders/?page=1&per_page=9999&company=' + company_id, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadInvoices = (page, per_page, filters = [], onSuccess = null, onFailure = null) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/admin/billing/invoices/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadAllInvoices = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/invoices/?page=1&per_page=9999&status=UNPAID', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadCancellationRequests = (page, per_page, filters = [], onSuccess = null, onFailure = null) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/admin/billing/cancellations/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminDownloadInvoice = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGetDownload('/admin/billing/invoices/' + id + '/pdf/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminDownloadCreditNote = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGetDownload('/admin/billing/credit/' + id + '/pdf/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminPreviewCreditNote = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/credit/' + id + '/pdf/preview/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadInvoice = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/invoices/' + id + '/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadInvoiceItems = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/invoices/' + id + '/items/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadInvoiceTransactions = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/invoices/' + id + '/transactions/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminDeleteInvoiceTransaction = (invoice_id, transaction_id, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/admin/billing/invoices/' + invoice_id + '/transactions/' + transaction_id + '/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadInvoiceRefunds = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/invoices/' + id + '/refunds/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminDeleteInvoiceRefund = (invoice_id, transaction_id, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/admin/billing/invoices/' + invoice_id + '/refunds/' + transaction_id + '/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadOrder = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/orders/' + id + '/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadAvailableIPAddressesForOrder = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/orders/' + id + '/available-ip-addresses/', (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminUpdateOrderIPAddresses = (id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPatch('/admin/billing/orders/' + id + '/ip-addresses/', data, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminAddAvailableIPAddressesForOrder = (id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/orders/' + id + '/available-ip-addresses/', data, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadOrderInvoices = (id, page, per_page, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/orders/' + id + '/invoices/?page=' + page + '&per_page=' + per_page, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminUpdateOrderField = (id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPatch('/admin/billing/orders/' + id + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminLoadCreditNotes = (page, per_page, filters = [], onSuccess = null, onFailure = null) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/admin/billing/credit/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadActiveCreditNotes = (company, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/credit/all/?company=' + company + '&status=ACTIVE', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminSubmitCancellationRequest = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/cancellations/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCancelCancellationRequest = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/admin/billing/cancellations/' + id + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminRemoveCancellationItem = (id, item_id, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/admin/billing/cancellations/' + id + '/item/' + item_id + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadCancellationRequest = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/cancellations/' + id + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminProcessCancellationRequest = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/cancellations/' + id + '/process/', {}, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateCancellationRequestCreditNote = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/cancellations/' + id + '/credit_note/', {}, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadCreditNote = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/credit/' + id + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadTransactions = (page, per_page, filters = [], onSuccess = null, onFailure = null) => dispatch => {
    let filter_list = [];

    for(let i = 0; i < filters.length; i++) {
        filter_list.push(filters[i].key + '=' + filters[i].value);
    }

    let filters_param = '';

    if(filter_list.length > 0) {
        filters_param = '&' + filter_list.join('&')
    }

    httpGet('/admin/billing/transactions/?page=' + page + '&per_page=' + per_page + filters_param, (res) => onSuccess(res), (err) => onFailure(err));
};

export const submitBulkOrderActions = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/orders/bulk/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const submitBulkInvoicesActions = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/invoices/bulk/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadAllPaymentMethods = (company_id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/payment_methods/?page=1&per_page=9999&owner=' + company_id, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadAllCoupons = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/coupons/?page=1&per_page=9999', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateNewTransaction = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/transactions/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateNewCreditTransaction = (credit_note_id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/credit/' + credit_note_id + '/transactions/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadCreditTransactions = (credit_note_id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/credit/' + credit_note_id + '/transactions/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateNewRefund = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/refunds/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateInvoiceItem = (id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/invoices/' + id + '/item/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminUpdateInvoiceItem = (invoice_id, item_id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/invoices/' + invoice_id + '/item/' + item_id + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const admingLoadInvoiceItem = (invoice_id, item_id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/invoices/' + invoice_id + '/item/' + item_id + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminDeleteInvoiceItem = (invoice_id, item_id, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/admin/billing/invoices/' + invoice_id + '/item/' + item_id + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateInvoice = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/invoices/', data, (res) => onSuccess(res), (err) => onFailure(err));
};


export const adminUpdateInvoiceField = (invoice_id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPatch('/admin/billing/invoices/' + invoice_id + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminUpdateCreditNoteField = (credit_note, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPatch('/admin/billing/credit/' + credit_note + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const loadSharedHostingPlans = (onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/billing/shared-hosting/plans/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadCreditNoteItem = (credit_note_id, item_id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/credit/' + credit_note_id + '/items/' + item_id + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateCreditNote = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/credit/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateCreditNoteItem = (id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/credit/' + id + '/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminUpdateCreditNoteItem = (credit_note_id, item_id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/credit/' + credit_note_id + '/items/' + item_id + '/' , data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminDeleteCreditNoteItem = (credit_note_id, item_id, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/admin/billing/credit/' + credit_note_id + '/items/' + item_id + '/' , (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminDeleteCreditNoteTransaction = (credit_note_id, item_id, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/admin/billing/credit/' + credit_note_id + '/transactions/' + item_id + '/' , (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminCreateSharedHostingAccount = (order_id, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/orders/' + order_id + '/create/', {}, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminLoadOwnerTransactions = (owner_id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/transactions/' + owner_id + '/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminExtendOrder = (order_id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/orders/' + order_id + '/extend/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminTransferIPAddresses = (data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/orders/transfer-ip-addresses/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminRegenerateInvoice = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/invoices/' + id + '/regenerate/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminDeleteOrderCoupon = (id, onSuccess = null, onFailure = null) => dispatch => {
    httpDelete('/admin/billing/orders/' + id + '/coupon/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminAddOrderCoupon = (id, data, onSuccess = null, onFailure = null) => dispatch => {
    httpPost('/admin/billing/orders/' + id + '/coupon/', data,(res) => onSuccess(res), (err) => onFailure(err));
};
