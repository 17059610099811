import {AUTH_ERROR, LOGIN_SUCCESS, LOGOUT_FAILURE, LOGOUT_IN_PROGRESS, LOGOUT_SUCCESS, USER_LOADED, USER_LOADING} from "./types";
import {httpGet, httpPost} from "../libs/http";
import {displayErrors} from "../libs/utils";

export const loadUser = () => (dispatch) => {
    dispatch({ type: USER_LOADING });

    httpGet('/account/whoami/admin/', (res) => {
        dispatch({ type: USER_LOADED, payload: res.data})
    }, (err) => {
        if(typeof err.response !== 'undefined') {
            dispatch({ type: AUTH_ERROR });
        }
    });
};

export const login = (data, onSuccess, onFailure) => (dispatch) => {
    httpPost('/auth/login/admin/', data, (res) => {
        dispatch({ type: LOGIN_SUCCESS, payload: res.data });
        onSuccess(res);
    }, (err) => {
        onFailure(err);
    });
};

export const logout = (onSuccess) => (dispatch) => {
    dispatch({ type: LOGOUT_IN_PROGRESS });

    httpPost('/auth/logout/', {}, (res) => {
        dispatch({ type: LOGOUT_SUCCESS });
        onSuccess();
    }, (err) => {
        if(typeof err.response !== 'undefined') {
            displayErrors(err.response.data);
            dispatch({ type: LOGOUT_FAILURE });
        }
    });
};

export const requestPasswordReset = (data, onSuccess, onFailure) => (dispatch) => {
    httpPost('/auth/recover/admin/', data, (res) => onSuccess(res), (err) => onFailure(err));
};

export const validatePasswordResetToken = (token, onSuccess, onFailure) => (dispatch) => {
    httpGet('/auth/recover/admin/' + token + '/validate/', (res) => onSuccess(res), (err) => onFailure(err));
};

export const resetPassword = (data, onSuccess, onFailure) => (dispatch) => {
    httpPost('/auth/recover/admin/reset/', data, (res) => onSuccess(res), (err) => onFailure(err));
};
