import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Col, Form, Input, Row, message, Descriptions} from "antd";
import {PlayCircleOutlined} from "@ant-design/icons";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {Link} from "react-router-dom";
import {adminCheckRequestId} from "../../actions/tools";

class RequestIdCheckTool extends React.Component {
    state = {
        value: '',
        result: null,
        loading: false
    };

    componentDidMount() {
        this.props.updatePage('Request ID Check');
    }

    checkRequestId() {
        this.setState({ loading: true });

        let request_id = this.state.value.trim();

        if(request_id.length === 0) {
            return message.error('Please enter request id!');
        }

        let data = { request_id };

        this.props.adminCheckRequestId(data, (res) => {
            this.setState({ loading: false, result: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false })
            }
        });
    }

    processValue(key, value) {
        if(key === 'domain') {
            if(value.domain_exists) {
                return <strong><Link target='_blank' to={'/domains/' + value.guid}>{value.domain}</Link></strong>;
            }

            return value.error;
        }

        if(key === 'target_domain_group') {
            if(value.domain_group_exists) {
                return <strong><Link target='_blank' to={'/domains/?group=' + value.guid}>{value.domain}</Link></strong>;
            }

            return value.error;
        }

        if(key === 'target_web_server') {
            if(value.web_server_exists) {
                return <strong><Link target='_blank' to={'/network/shared-hosting-servers/' + value.guid}>{value.name}</Link></strong>;
            }

            return value.error;
        }

        if(key === 'ip_node') {
            if(value.ip_node_exists) {
                return <strong><Link target='_blank' to={'/network/ip-nodes/' + value.guid}>{value.name}</Link></strong>;
            }

            return value.error;
        }

        return value;
    }

    render() {
        return(
            <Fragment>
                <Form layout='vertical'>
                    <Row gutter={[16, 16]}>
                        <Col span={22}>
                            <Form.Item label='Enter request ID'>
                                <Input disabled={this.state.loading} onChange={(e) => this.setState({ value: e.target.value })} />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item label='&nbsp;'>
                                <Button icon={<PlayCircleOutlined />} type='primary' loading={this.state.loading}
                                        disabled={this.state.loading} onClick={() => this.checkRequestId()}>Check</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row hidden={this.state.result === null}>
                        <Col span={24}>
                            <Descriptions bordered size='small' layout='horizontal' column={1}>
                                {this.state.result !== null ? this.state.result.map((item, i) => <Descriptions.Item label={normalizeEnum(item.key)} key={i}>
                                    {this.processValue(item.key, item.value)}
                                </Descriptions.Item>) : ''}
                            </Descriptions>
                        </Col>
                    </Row>
                </Form>
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminCheckRequestId })(RequestIdCheckTool);