import React from "react";
import {connect} from "react-redux";
import {Button, Form, Modal, Select, Space, message} from "antd";
import {adminLoadAllHostingAccounts} from "../../actions/hosting";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {adminUpdateDomain} from "../../actions/domains";

class HostingAccountChangeModal extends React.Component {
    state = {
        loadingHostingAccounts: false,
        hostingAccountsLoaded: false,
        hostingAccounts: [],
        selectedHostingAccount: '',
        loading: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingHostingAccounts && !this.state.hostingAccountsLoaded) {
            this.setState({ loadingHostingAccounts: true });

            this.props.adminLoadAllHostingAccounts(this.props.owner, (res) => {
                this.setState({ loadingHostingAccounts: false, hostingAccountsLoaded: true, hostingAccounts: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    displayErrors(err.response.data);
                    this.setState({ loadingHostingAccounts: false, hostingAccountsLoaded: true });
                }
            })
        }
    }

    closeModal(reload = false) {
        this.setState({
            loadingHostingAccounts: false,
            hostingAccountsLoaded: false,
            hostingAccounts: []
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    adminUpdateHostingAccount() {
        let hosting_account = this.state.selectedHostingAccount.trim();
        let domain = this.props.domain.trim();

        if(hosting_account.length === 0) {
            return message.error('Please select a hosting account!');
        }

        if(domain.length === 0) {
            return message.error('Please ensure you have domain selected!');
        }

        let data = { hosting_account };

        this.setState({ loading: true });

        this.props.adminUpdateDomain(domain, data, (res) => {
            this.setState({ loading: false });
            message.success('Hosting account successfully updated!');
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ loading: false });
            }
        });
    }

    render() {
        const { Option } = Select;

        return(
            <Modal title='Change Hosting Account'
                   footer={<Space>
                       <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Close</Button>
                       <Button type='primary' loading={this.state.loading} disabled={this.state.loading} onClick={() => this.adminUpdateHostingAccount()}>Change</Button>
                   </Space>}
                   visible={this.props.showModal}
                   onCancel={() => this.closeModal()}>
                <Form layout='vertical'>
                    <Form.Item label='Select hosting account:'>
                        <Select onChange={(value) => this.setState({ selectedHostingAccount: value })}
                                showSearch
                                loading={this.state.loadingHostingAccounts}
                                disabled={this.state.loadingHostingAccounts}
                                filterOption={(input, option) => {
                                    if(typeof option !== 'undefined') {
                                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                    }
                                    return false;
                                }}
                                value={this.state.selectedHostingAccount}>
                            {this.state.hostingAccounts.map((account) => {
                                return <Option value={account.guid}>{account.name} ({normalizeEnum(account.account_type)})</Option>
                            })}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { adminLoadAllHostingAccounts, adminUpdateDomain })(HostingAccountChangeModal);