import React from "react";
import {connect} from "react-redux";
import {Button, Col, DatePicker, Form, Modal, Row, Select, Space, message} from "antd";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {loadAllCompanies} from "../../actions/users";
import {adminCreateInvoice, adminLoadAllCompanyOrders} from "../../actions/billing";

class NewInvoiceModal extends React.Component {
    state = {
        loadingCompanies: false,
        companiesLoaded: false,
        companies: [],
        selectedCompany: '',
        billingCycle: 'MONTHLY',
        periodStart: '',
        periodStartValue: '',
        periodEnd: '',
        periodEndValue: '',
        dateDue: '',
        dateDueValue: '',
        orders: [],
        loadingOrders: false,
        selectedOrders: []
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingCompanies && !this.state.companiesLoaded) {
            this.setState({ loadingCompanies: true })

            this.props.loadAllCompanies((res) => {
                this.setState({ loadingCompanies: false, companiesLoaded: true, companies: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingCompanies: false, companiesLoaded: true });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    closeModal() {
        this.setState({
            loadingCompanies: false,
            companiesLoaded: false,
            companies: [],
            selectedCompany: '',
            billingCycle: 'MONTHLY',
            periodStart: '',
            periodStartValue: '',
            periodEnd: '',
            periodEndValue: '',
            dateDue: '',
            dateDueValue: ''
        });

        this.props.close();
    }

    selectCompany(guid) {
        this.setState({ loadingOrders: true, selectedCompany: guid });

        this.props.adminLoadAllCompanyOrders(guid, (res) => {
            this.setState({ loadingOrders: false, orders: res.data.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingOrders: false });
                displayErrors(err.response.data);
            }
        });
    }

    createInvoice() {
        let company = this.state.selectedCompany.trim();
        let billing_cycle = this.state.billingCycle.trim();
        let period_start = this.state.periodStart.trim();
        let period_end = this.state.periodEnd.trim();
        let date_due = this.state.dateDue.trim();
        let order_list = this.state.selectedOrders;

        if(company.length === 0) {
            return message.error('Please select the company!');
        }

        if(billing_cycle.length === 0) {
            return message.error('Please select the billing cycle!');
        }

        if(period_start.length === 0) {
            return message.error('Please select the period start date!');
        }

        if(period_end.length === 0) {
            return message.error('Please select the period end date!');
        }

        if(date_due.length === 0) {
            return message.error('Please select the due date!');
        }

        let data = { company, billing_cycle, period_start, period_end, date_due };

        if(order_list.length > 0) {
            data['order_list'] = order_list;
        }

        this.setState({ loading: true });

        this.props.adminCreateInvoice(data, (res) => {
            this.props.history.push('/billing/invoices/' + res.data.invoice_id);
            message.success('Invoice successfully created!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { RangePicker } = DatePicker;
        const { Option } = Select;

        return(
            <Modal
                title={'New Invoice'}
                destroyOnClose={true}
                visible={this.props.showModal}
                footer={<Space>
                    <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Close</Button>
                    <Button disabled={this.state.loading}  loading={this.state.loading}  type='primary'
                            onClick={() => this.createInvoice()}>Create</Button>
                </Space>}
                onCancel={() => this.closeModal()}>
                <Form layout='vertical'>
                    <Form.Item label='Select company:'>
                        <Select
                            filterOption={(input, option) => {
                                if(typeof option !== 'undefined') {
                                    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                }
                                return false;
                            }}
                            loading={this.state.loadingCompanies}
                            disabled={this.state.loadingCompanies}
                            value={this.state.filterValue}
                            showSearch
                            allowClear
                            onChange={(value) => this.selectCompany(value)}>
                            {this.state.companies.map((company, i) => {
                                return <Option key={i} value={company.guid}>{company.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col span={17}>
                            <Form.Item label='Period:'>
                                <RangePicker format="YYYY-MM-DD"
                                             value={[this.state.periodStartValue, this.state.periodEndValue]}
                                             onChange={(_, value) => this.setState({
                                                 periodStart: value[0],
                                                 periodEnd: value[1],
                                                 periodStartValue: _[0],
                                                 periodEndValue: _[1]
                                             })} />
                            </Form.Item>
                        </Col>
                        <Col span={7}>
                            <Form.Item label='Due date:'>
                                <DatePicker onChange={(_, value) => this.setState({ dateDue: value, dateDueValue: _})} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item label='Billing cycle:'>
                                <Select value={this.state.billingCycle} onChange={(value) => this.setState({ billingCycle: value}) }>
                                    <Option value='MONTHLY'>Monthly</Option>
                                    <Option value='QUARTERLY'>Quarterly</Option>
                                    <Option value='SEMIANNUALLY'>Semiannually</Option>
                                    <Option value='ANNUALLY'>Annually</Option>
                                    <Option value='ONE_TIME'>One-time</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Orders:' extra={<small><em>* optional</em></small>}>
                                <Select
                                    filterOption={(input, option) => {
                                        if(typeof option !== 'undefined') {
                                            let value = option.children[0] + option.children[1];
                                            return value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                                        }
                                        return false;
                                    }}
                                    loading={this.state.loadingOrders}
                                    disabled={this.state.loadingOrders}
                                    value={this.state.selectedOrders}
                                    showSearch allowClear mode='multiple'
                                    onChange={(value) => this.setState({ selectedOrders: value })}>
                                    {this.state.orders.map((order, i) => {
                                        return <Option key={i} value={order.id}>#{order.id} - {normalizeEnum(order.status)} ({normalizeEnum(order.order_type)})</Option>
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { loadAllCompanies, adminCreateInvoice, adminLoadAllCompanyOrders })(NewInvoiceModal);