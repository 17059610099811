import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    adminDeleteInvoiceItem,
    adminDeleteInvoiceRefund,
    adminDeleteInvoiceTransaction,
    adminDownloadInvoice,
    adminLoadInvoice,
    adminLoadInvoiceItems,
    adminLoadInvoiceRefunds,
    adminLoadInvoiceTransactions,
    adminRegenerateInvoice,
    adminUpdateInvoiceField, submitBulkOrderActions
} from "../../actions/billing";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {
    Button,
    Col,
    Layout,
    Menu,
    Popconfirm,
    Row,
    Space,
    Table,
    Tooltip,
    message,
    Select,
    Descriptions, Modal, Input, Card, DatePicker
} from "antd";
import {Loading} from "../../libs/loading";
import {DateFormat} from "../shared/DateFormat";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined,
    FilePdfOutlined,
    PlusOutlined, RetweetOutlined, SaveOutlined
} from "@ant-design/icons";
import {MoneyField} from "../shared/MoneyField";
import InvoiceItemModal from "./InvoiceItemModal";
import NewTransactionModal from "./NewTransactionModal";
import NewRefundModal from "./NewRefundModal";
import {Link} from "react-router-dom";
import {StatusIndicator} from "../shared/Status";
import moment from "moment";

class InvoiceView extends React.Component {
    state = {
        invoice: null,
        loadingInvoice: true,
        showInvoiceItemModal: false,
        activeInvoiceItem: null,
        showAddCouponModal: false,
        updatingInvoiceItems: false,
        showNewTransactionModal: false,
        loadingTransactions: false,
        showNewRefundModal: false,
        invoiceStatus: '',
        invoiceNotes: '',
        updatingNotes: false,
        regeneratingInvoice: false,
        newDueDate: null,
        creatingCancellationRequests: false
    };

    componentDidMount() {
        this.props.updatePage('Invoice #' + this.props.match.params.id);
        this.adminLoadInvoice();
    }

    adminLoadInvoiceItems() {
        this.setState({ updatingInvoiceItems: true });

        this.props.adminLoadInvoiceItems(this.props.match.params.id, (res) => {
            this.setState({ updatingInvoiceItems: false });
            let invoice = {...this.state.invoice, ...res.data};

            this.setState({ invoice });

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ updatingInvoiceItems: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadInvoice() {
        this.setState({ loadingInvoice: true });

        this.props.adminLoadInvoice(this.props.match.params.id, (res) => {
            this.setState({ loadingInvoice: false, invoice: res.data, newDueDate: res.data.date_due, invoiceStatus: res.data.status, invoiceNotes: res.data.custom_notes });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingInvoice: true });
                displayErrors(err.response.data);
            }
        });
    }

    adminDeleteInvoiceItem(id) {
        this.setState({ updatingInvoiceItems: true });

        this.props.adminDeleteInvoiceItem(this.props.match.params.id, id, () => {
            message.success('Invoice item successfully deleted!');
            this.adminLoadInvoiceItems();
            this.setState({ updatingInvoiceItems: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
                this.setState({ updatingInvoiceItems: false });
            }
        });
    }

    adminLoadInvoiceTransactions() {
        this.setState({ loadingTransactions: true });

        this.props.adminLoadInvoiceTransactions(this.props.match.params.id, (res) => {
            let invoice = {...this.state.invoice, transactions: res.data };
            this.setState({ invoice: invoice, loadingTransactions: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingTransactions: false });
                displayErrors(err.response.data);
            }
        });

        this.adminLoadInvoiceItems();
    }

    adminLoadInvoiceRefunds() {
        this.setState({ loadingRefunds: true });

        this.props.adminLoadInvoiceRefunds(this.props.match.params.id, (res) => {
            let invoice = {...this.state.invoice, refunds: res.data };
            this.setState({ invoice: invoice, loadingRefunds: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingRefunds: false });
                displayErrors(err.response.data);
            }
        });

        this.adminLoadInvoiceItems();
    }

    adminDeleteTransaction(transactionId) {
        this.setState({ loadingTransactions: true });

        this.props.adminDeleteInvoiceTransaction(this.props.match.params.id, transactionId, () => {
            this.setState({ loadingTransactions: false });
            message.success('Transaction successfully deleted!');
            this.adminLoadInvoiceTransactions();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingTransactions: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminDeleteRefund(refundId) {
        this.setState({ loadingRefunds: true });

        this.props.adminDeleteInvoiceRefund(this.props.match.params.id, refundId, () => {
            this.setState({ loadingRefunds: false });
            message.success('Refund successfully deleted!');
            this.adminLoadInvoiceRefunds();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingRefunds: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminUpdateInvoiceStatus(status) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set the status to <strong>{normalizeEnum(status)}</strong>?</span>,
            onOk: () => {
                this.setState({ updatingOrderStatus: true });

                this.props.adminUpdateInvoiceField(this.props.match.params.id, { 'status': status }, () => {
                    this.setState({ updatingOrderStatus: false });
                    message.success('Order status successfully updated!');
                    this.adminLoadInvoice();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ updatingOrderStatus: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    adminUpdateInvoiceNotes() {
        let notes = this.state.invoiceNotes;

        this.setState({ updatingNotes: true });

        this.props.adminUpdateInvoiceField(this.props.match.params.id, { 'custom_notes': notes }, () => {
            this.setState({ updatingNotes: false });
            message.success('Custom notes successfully updated!');
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ updatingNotes: false });
                displayErrors(err.response.data);
            }
        });
    }

    regenerateInvoice() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you wish to regenerate this invoice?',
            onOk: () => {
                this.setState({ regeneratingInvoice: true });

                this.props.adminRegenerateInvoice(this.props.match.params.id, () => {
                    this.setState({ regeneratingInvoice: false });
                    message.success('Invoice successfully regenerated!');
                    this.adminLoadInvoice();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ regeneratingInvoice: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    changeDueDate(_, value) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set the due date to <strong>{value}</strong>?</span>,
            onOk: () => {
                this.props.adminUpdateInvoiceField(this.props.match.params.id, { 'due_date': value }, () => {
                    this.setState({ updatingOrderStatus: false });
                    message.success('Due date successfully updated!');
                    this.adminLoadInvoice();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ updatingOrderStatus: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    createCancellationRequests() {
        const { confirm } = Modal;

        let orders = this.state.invoice.order_list.map((order) => order.id);

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to create cancellations for all order</span>,
            onOk: () => {
                let data = {
                    orders: orders,
                    action: 'CANCEL',
                    value: 'IMMEDIATE'
                };

                this.props.submitBulkOrderActions(data, () => {
                    this.setState({ creatingCancellationRequests: false });
                    message.success('Cancellation requests successfully created!');
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ creatingCancellationRequests: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    render() {
        if(this.state.loadingInvoice) {
            return <div className='text-center'><Loading /></div>;
        }

        const { invoice } = this.state;

        const itemTableColumns = [
            { title: 'Description', dataIndex: 'description', render: (item, record) => {
                let period = '';
                if(record.period_start !== null) {
                    return <span>{item.split('\n').map((item, i) => <span key={i}>{item}<br /></span>)}<p>(<DateFormat value={record.period_start} /> - <DateFormat value={record.period_end} />)</p></span>;
                }

                return <Fragment>{item} <p>{period}</p></Fragment>;
            }},
            { title: 'Qty', dataIndex: 'quantity', align: 'center' },
            { title: 'Rate', dataIndex: 'rate', align: 'center', render: (item) => <MoneyField amount={item} currency={invoice.currency} /> },
            { title: 'Sub Total', dataIndex: 'sub_total', align: 'center', render: (item) => <MoneyField amount={item} currency={invoice.currency} /> },
            { title: '', align: 'center', width: '50px', render: (item, record) => {
                return <Space>
                    <Tooltip title='Edit item'>
                        <Button size='small' type='circle' icon={<EditOutlined />} onClick={() => this.setState({ showInvoiceItemModal: true, activeInvoiceItem: record.id })} />
                    </Tooltip>
                    <Popconfirm
                        title="Are you sure you want to delete this item?"
                        onConfirm={() => this.adminDeleteInvoiceItem(record.id)}
                        okText='Delete'
                        placement='topRight'
                        okButtonProps={{danger: true}}
                        cancelText='Cancel'>
                        <Tooltip title='Delete item'>
                            <Button size='small' type='circle' icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </Space>;
            }},
        ];

        const { Content } = Layout;

        const refundTableColumns = [
            { title: 'ID', dataIndex: 'id' },
            { title: 'Amount', render: (item, record) => <MoneyField amount={parseFloat(record.transaction.amount)} currency={record.transaction.currency} /> },
            { title: 'Date', render: (item, record) => <DateTimeFormat value={record.date_created} /> },
            { title: 'Method', render: (item, record) => normalizeEnum(record.transaction.payment_method_type) },
            { title: 'Status', render: (item, record) => normalizeEnum(record.status) },
            { title: 'Transaction ID', render: (item, record) => record.transaction.transaction_id },
            { title: '', align: 'center', width: '50px', render: (item, record) => {
                return <Popconfirm
                    title="Are you sure you want to delete this refund?"
                    onConfirm={() => this.adminDeleteRefund(record.id)}
                    okText='Delete'
                    placement='topRight'
                    okButtonProps={{danger: true}}
                    cancelText='Cancel'>
                    <Tooltip title='Delete item'>
                        <Button size='small' type='circle' icon={<DeleteOutlined />} />
                    </Tooltip>
                </Popconfirm>;
            }},
        ];

        const transactionsTableColumns = [
            { title: 'ID', dataIndex: 'id' },
            { title: 'Amount', render: (item, record) => <MoneyField amount={parseFloat(record.amount)} currency={record.currency} /> },
            { title: 'Date', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Method', render: (item, record) => normalizeEnum(record.payment_method_type) },
            { title: 'Transaction ID', render: (item, record) => record.transaction_id },
            { title: '', align: 'center', width: '50px', render: (item, record) => {
                return <Popconfirm
                    title="Are you sure you want to delete this transaction?"
                    onConfirm={() => this.adminDeleteTransaction(record.id)}
                    okText='Delete'
                    placement='topRight'
                    okButtonProps={{danger: true}}
                    cancelText='Cancel'>
                    <Tooltip title='Delete item'>
                        <Button size='small' type='circle' icon={<DeleteOutlined />} />
                    </Tooltip>
                </Popconfirm>;
            }},
        ];

        const orderListColumns = [
            { title: 'ID', dataIndex: 'id', render: (item) => <Link to={'/billing/orders/' + item}>#{item}</Link>},
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Status', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' />},
        ];

        const { Option } = Select;
        const { TextArea } = Input;

        return(
            <Content>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Descriptions bordered column={1} size='small'>
                            <Descriptions.Item label='ID' style={{width: '50%'}}>{invoice.id}</Descriptions.Item>
                            <Descriptions.Item label='Owner'><Link to={'/accounts/companies/' + invoice.company_guid}>{invoice.company_name}</Link></Descriptions.Item>
                            <Descriptions.Item label='Status'>
                                <Select style={{width: '100%'}} value={this.state.invoiceStatus} onChange={(value) => this.adminUpdateInvoiceStatus(value)}>
                                    <Option value='UNPAID'>Unpaid</Option>
                                    <Option value='PAID'>Paid</Option>
                                    <Option value='PARTIALLY_PAID'>Partially paid</Option>
                                    <Option value='CANCELLED'>Cancelled</Option>
                                    <Option value='DELETED'>Deleted</Option>
                                </Select>
                            </Descriptions.Item>
                            <Descriptions.Item label='Due date'>
                                <DatePicker defaultValue={moment(invoice.date_due, 'YYYY-MM-DD')} format='YYYY-MM-DD' onChange={(e, _) => this.changeDueDate(e, _)} />
                            </Descriptions.Item>
                            <Descriptions.Item label='Actions'>
                                <Space>
                                    <Button size='small' icon={<FilePdfOutlined />} onClick={() => this.props.adminDownloadInvoice(this.props.match.params.id)}>Download PDF</Button>
                                    <Button size='small' disabled={this.state.regeneratingInvoice || parseFloat(invoice.payment_made) > 0} loading={this.state.regeneratingInvoice} icon={<RetweetOutlined />} onClick={() => this.regenerateInvoice()}>Regenerate Invoice</Button>
                                    <Button size='small' disabled={invoice.status !== 'UNPAID'} loading={this.state.creatingCancellationRequests} icon={<RetweetOutlined />} onClick={() => this.createCancellationRequests()}>Create Cancellation Requests</Button>
                                </Space>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row className='invoice-content'>
                    <Col span={24}>
                        <Row gutter={[16, 48]}>
                            <Col sm={12} xs={24}>
                                <img src='/img/logo-top-dark.png' alt='Priority Prospect' className='invoice-logo' />
                            </Col>
                        </Row>
                        <Row gutter={[16, 48]}>
                            <Col span={12} xs={24} sm={12} style={{verticalAlign:'middle'}}>
                                <b>Priority Prospect OÜ</b><br />
                                Vandu tee 6-2, Hulja<br />
                                Lääne Virumaa, Estonia<br />
                                45203<br />
                                Registration No. 14059959<br />
                                VAT No. EE101884183
                            </Col>
                            <Col xs={24} sm={12} className='invoice-name-block' style={{verticalAlign:'middle'}}>
                                <h2>Invoice<br /><small>#{invoice.id}</small></h2>
                            </Col>
                        </Row>
                        <br />
                        <Row gutter={[16, 16]} style={{marginBottom: '15px'}}>
                            <Col xs={24} sm={18}>
                                Invoice to:<br />
                                <b>{invoice.company_name}</b><br />
                                ATTN: {invoice.first_name} {invoice.last_name}<br />
                                {invoice.address1}
                                {invoice.address2 !== null && invoice.address2 !== '' ? <span>{invoice.address2}<br /></span> : ''}
                                <br />{invoice.region}, {invoice.country}<br />
                                {invoice.postal_code}
                                {invoice.vat !== null ? <span><br />{invoice.vat.vat_id}</span> : ''}
                            </Col>
                            <Col xs={24} sm={6} className='text-right' style={{verticalAlign: 'middle'}}>
                                Invoice date: <DateFormat value={invoice.date_created} /><br />
                                Terms: NET{invoice.payment_terms}<br />
                                Due date: <DateFormat value={invoice.date_due} />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} gutter={[16, 48]}>
                                <Menu mode="horizontal" selectedKeys={[]}>
                                    <Menu.Item key='new_invoice_item' icon={<PlusOutlined />} onClick={() => this.setState({ showInvoiceItemModal: true })}>
                                        New Invoice Item
                                    </Menu.Item>
                                </Menu>
                                <Table size='middle'
                                       columns={itemTableColumns}
                                       loading={this.state.updatingInvoiceItems}
                                       dataSource={invoice.items}
                                       pagination={false}
                                       rowKey={(item) => item.description}
                                       summary={() => {
                                           let columns = [];

                                           columns.push(<Table.Summary.Row key='sub_total'>
                                               <Table.Summary.Cell colSpan={2} className='no-border-cell' />
                                               <Table.Summary.Cell className='text-center no-border-cell'>Sub Total</Table.Summary.Cell>
                                               <Table.Summary.Cell className='text-center no-border-cell'><MoneyField amount={invoice.sub_total} currency={invoice.currency} /></Table.Summary.Cell>
                                           </Table.Summary.Row>);

                                           columns.push(<Table.Summary.Row key='coupon'>
                                               <Table.Summary.Cell colSpan={2} className='no-border-cell' />
                                               <Table.Summary.Cell className='text-center no-border-cell'>{invoice.coupon_code !== null ? invoice.coupon_code.name : <Button size='small' icon={<PlusOutlined />} onClick={() => this.setState({ showAddCouponModal: true })}>Add Coupon</Button>}</Table.Summary.Cell>
                                               <Table.Summary.Cell className='text-center no-border-cell'>-<MoneyField amount={invoice.discount_amount} currency={invoice.currency} /></Table.Summary.Cell>
                                           </Table.Summary.Row>);

                                           columns.push(<Table.Summary.Row key='tax'>
                                               <Table.Summary.Cell colSpan={2} className='no-border-cell' />
                                               <Table.Summary.Cell className='text-center no-border-cell'>Tax ({invoice.tax_rate}%)</Table.Summary.Cell>
                                               <Table.Summary.Cell className='text-center no-border-cell'><MoneyField amount={invoice.initial_tax} currency={invoice.currency} /></Table.Summary.Cell>
                                           </Table.Summary.Row>);

                                           columns.push(<Table.Summary.Row key='total'>
                                               <Table.Summary.Cell colSpan={2} className='no-border-cell' />
                                               <Table.Summary.Cell className='text-center no-border-cell'><b>Total</b></Table.Summary.Cell>
                                               <Table.Summary.Cell className='text-center no-border-cell'><b><MoneyField amount={invoice.total_amount} currency={invoice.currency} /></b></Table.Summary.Cell>
                                           </Table.Summary.Row>);

                                           if(invoice.payment_made > 0) {
                                               columns.push(<Table.Summary.Row key='payment_made'>
                                                   <Table.Summary.Cell colSpan={2} className='no-border-cell' />
                                                   <Table.Summary.Cell className='text-center no-border-cell'>Payment Made</Table.Summary.Cell>
                                                   <Table.Summary.Cell className='text-center no-border-cell'>-<MoneyField amount={invoice.payment_made} currency={invoice.currency} /></Table.Summary.Cell>
                                               </Table.Summary.Row>);
                                           }

                                           columns.push(<Table.Summary.Row key='balance_due'>
                                               <Table.Summary.Cell colSpan={2} className='no-border-cell' />
                                               <Table.Summary.Cell className='text-center gray-cell no-border-cell'><b>Balance Due</b></Table.Summary.Cell>
                                               <Table.Summary.Cell className='text-center gray-cell no-border-cell'><b><MoneyField amount={invoice.balance_due} currency={invoice.currency} /></b></Table.Summary.Cell>
                                           </Table.Summary.Row>);

                                           return(columns.map((item) => {
                                               return item;
                                           }))
                                       }}/>
                            </Col>
                        </Row>

                        <Row span={24} gutter={[16, 48]} style={{marginBottom: '20px'}}>
                            <Col span={24}>
                                <h3>Transactions</h3>
                                <Menu mode="horizontal" selectedKeys={[]}>
                                    <Menu.Item key='new_transaction' icon={<PlusOutlined />}
                                               onClick={() => this.setState({ showNewTransactionModal: true })}>
                                        New Transaction
                                    </Menu.Item>
                                </Menu>
                                <Table size='small'
                                       rowKey={(item) => item.guid}
                                       pagination={false}
                                       columns={transactionsTableColumns}
                                       loading={this.state.loadingTransactions}
                                       dataSource={invoice.transactions} />
                            </Col>
                        </Row>
                        <Row span={24} gutter={[16, 48]}>
                            <Col span={24}>
                                <h3>Refunds</h3>
                                <Menu mode="horizontal" selectedKeys={[]}>
                                    <Menu.Item key='new_refund' icon={<PlusOutlined />}
                                               onClick={() => this.setState({ showNewRefundModal: true })}>
                                        New Refund
                                    </Menu.Item>
                                </Menu>
                                <Table size='small'
                                       rowKey={(item) => item.guid}
                                       pagination={false}
                                       columns={refundTableColumns}
                                       loading={this.state.loadingRefunds}
                                       dataSource={invoice.refunds} />
                            </Col>
                        </Row>
                        <br />
                        <Row span={24} gutter={[16, 48]}>
                            <Col span={24}>
                                <h3>Associated Orders</h3>
                                <Table size='small'
                                       rowKey={(item) => item.id}
                                       pagination={false}
                                       columns={orderListColumns}
                                       dataSource={invoice.order_list} />
                            </Col>
                        </Row>
                        <br />
                        <Row span={24} gutter={[16, 48]}>
                            <Col span={24}>
                                <Card size='small' title='Custom notes' extra={
                                    <Button onClick={() => this.adminUpdateInvoiceNotes()} loading={this.state.updatingNotes}
                                            disabled={this.state.updatingNotes} size='small' type='round' icon={<SaveOutlined />}>Save</Button>
                                }>
                                    <TextArea value={this.state.invoiceNotes}
                                              onChange={(e) => this.setState({ invoiceNotes: e.target.value})} rows={5} />
                                </Card>
                            </Col>
                        </Row>

                    </Col>
                </Row>
                <InvoiceItemModal
                    invoice={this.props.match.params.id}
                    currency={this.state.invoice.currency}
                    invoiceItem={this.state.activeInvoiceItem}
                    close={() => this.setState({ showInvoiceItemModal: false, activeInvoiceItem: null })}
                    reload={() => this.adminLoadInvoiceItems()}
                    showModal={this.state.showInvoiceItemModal} />

                <NewTransactionModal
                    amount={this.state.invoice.balance_due}
                    invoice={this.props.match.params.id}
                    company={this.state.invoice.company_guid}
                    currency={this.state.invoice.currency}
                    reloadTransactions={() => this.adminLoadInvoiceTransactions()}
                    showModal={this.state.showNewTransactionModal}
                    close={() => this.setState({ showNewTransactionModal: false })} />

                <NewRefundModal
                    company={this.state.invoice.company_guid}
                    invoice={this.props.match.params.id}
                    currency={this.state.invoice.currency}
                    reloadRefunds={() => this.adminLoadInvoiceRefunds()}
                    showModal={this.state.showNewRefundModal}
                    close={() => this.setState({ showNewRefundModal: false })} />
            </Content>);
    }
}

export default connect(null, { updatePage, adminLoadInvoice, adminDownloadInvoice,
    adminDeleteInvoiceItem, adminLoadInvoiceItems, adminLoadInvoiceTransactions, adminDeleteInvoiceTransaction,
    adminLoadInvoiceRefunds, adminDeleteInvoiceRefund, adminUpdateInvoiceField, adminRegenerateInvoice,
    submitBulkOrderActions})(InvoiceView);