import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, List, Tabs} from "antd";
import {Link} from "react-router-dom";
import {EyeOutlined} from "@ant-design/icons";

class Analytics extends React.Component {
    componentDidMount() {
        this.props.updatePage('Analytics');
    }

    render() {
        const { TabPane } = Tabs;

        let page = 'reports';

        if(typeof this.props.match.params.page !== 'undefined' && this.state.pages.indexOf(this.props.match.params.page)) {
            page = this.props.match.params.page;
        }

        let reports = {
            'users': [
                { name: 'Daily user registrations', description: 'Displays user signups grouped by the day.', path: 'daily-registrations' },
                { name: 'Users by country', description: 'Displays users grouped by the country.', path: 'users-by-country' },
                { name: 'Active orders by user', description: 'Displays not cancelled order counts grouped by user.', path: 'users-by-orders' },
                { name: 'Users by revenue', description: 'Displays revenue grouped by the user.', path: 'users-by-revenue' },
                { name: 'Countries by revenue', description: 'Displays revenue grouped by the country.', path: 'countries-by-revenue' },
                { name: 'Users by found value', description: 'Displays users grouped by the value of found.', path: 'users-by-found' },
            ],
            'billing': [
                { name: 'Daily orders', description: 'Displays user orders grouped by the day.', path: 'daily-orders' },
                { name: 'Daily revenue', description: 'Displays revenue grouped by the day.', path: 'daily-revenue' },
                { name: 'Weekly revenue', description: 'Displays revenue grouped by the week.', path: 'weekly-revenue' },
                { name: 'Monthly revenue', description: 'Displays revenue grouped by the month.', path: 'monthly-revenue' },
                { name: 'Orders by coupons', description: 'Displays coupons and order counts grouped by the coupon.', path: 'orders-by-coupons' },
                { name: 'IP allocation by coupons', description: 'Displays coupons and IP counts grouped by the coupon.', path: 'ip-allocation-by-coupons' },
                { name: 'Orders by type', description: 'Displays orders grouped by the order type.', path: 'orders-by-type' },
                { name: 'Orders by status', description: 'Displays orders grouped by the status.', path: 'orders-by-status' },
                { name: 'Invoices by status', description: 'Displays invoices grouped by the status.', path: 'invoices-by-status' },
                { name: 'Revenue by coupons', description: 'Displays revenue grouped by coupons.', path: 'revenue-by-coupons' },
            ],
            'ip-addresses': [
                { name: 'Daily IP allocation', description: 'Displays IP allocation grouped by the day.', path: 'daily-ip-allocation' },
                { name: 'Daily IP cancellations', description: 'Displays IP cancellations grouped by the day.', path: 'daily-ip-cancellations' },
                { name: 'Active IP addresses by user', description: 'Displays not cancelled IP address counts grouped by user.', path: 'ip-addresses-by-orders' },
            ]
        };

        return(
            <Tabs defaultActiveKey={page}>
                <TabPane tab='Billing' key='billing'>
                    <List
                        itemLayout="horizontal"
                        dataSource={reports['billing']}
                        renderItem={item => (
                            <List.Item actions={[<Link to={'/analytics/report/' + item.path}><Button size='small' icon={<EyeOutlined />}>View</Button></Link>]}>
                                <List.Item.Meta
                                    title={<Link to={'/analytics/report/' + item.path}>{item.name}</Link>}
                                    description={item.description}
                                />
                            </List.Item>
                        )}
                    />
                </TabPane>
                <TabPane tab='IP addresses' key='ip-addresses'>
                    <List
                        itemLayout="horizontal"
                        dataSource={reports['ip-addresses']}
                        renderItem={item => (
                            <List.Item actions={[<Link to={'/analytics/report/' + item.path}><Button size='small' icon={<EyeOutlined />}>View</Button></Link>]}>
                                <List.Item.Meta
                                    title={<Link to={'/analytics/report/' + item.path}>{item.name}</Link>}
                                    description={item.description}
                                />
                            </List.Item>
                        )}
                    />
                </TabPane>
                <TabPane tab='Users' key='users'>
                    <List
                        itemLayout="horizontal"
                        dataSource={reports['users']}
                        renderItem={item => (
                            <List.Item actions={[<Link to={'/analytics/report/' + item.path}><Button size='small' icon={<EyeOutlined />}>View</Button></Link>]}>
                                <List.Item.Meta
                                    title={<Link to={'/analytics/report/' + item.path}>{item.name}</Link>}
                                    description={item.description}
                                />
                            </List.Item>
                        )}
                    />
                </TabPane>
            </Tabs>
        );
    }
}

export default connect(null, { updatePage })(Analytics);