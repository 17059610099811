import React from "react";
import {connect} from "react-redux";
import {Button, Modal, Space, message, Table, Typography} from "antd";
import {displayErrors} from "../../libs/utils";
import {adminAddAvailableIPAddressesForOrder, adminLoadAvailableIPAddressesForOrder} from "../../actions/billing";
import {InputNumber} from "antd/es";

class AddIPAddressesToOrderModal extends React.Component {
    state = {
        loadingIPAddresses: false,
        ipAddressesLoaded: false,
        ipAddresses: [],
        selectedIPAddresses: [],
        selectedIPAddressesData: [],
        countries: [],
        cities: [],
        providers: [],
        addingIPAddresses: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingIPAddresses && !this.state.ipAddressesLoaded) {
            this.setState({ loadingIPAddresses: true })

            this.props.adminLoadAvailableIPAddressesForOrder(this.props.order, (res) => {
                let countries = [];
                let cities = [];
                let providers = [];

                for(let i = 0; i < res.data.length; i++) {
                    if(countries.indexOf(res.data[i].country) === -1) {
                        countries.push(res.data[i].country);
                    }

                    if(cities.indexOf(res.data[i].city) === -1) {
                        cities.push(res.data[i].city);
                    }

                    if(providers.indexOf(res.data[i].provider) === -1) {
                        providers.push(res.data[i].provider);
                    }
                }

                this.setState({ loadingIPAddresses: false, ipAddressesLoaded: true, ipAddresses: res.data, countries, cities, providers });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingIPAddresses: false, ipAddressesLoaded: true });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    closeModal(reload = false) {
        this.setState({
            loadingIPAddresses: false,
            ipAddressesLoaded: false,
            ipAddresses: [],
            selectedIPAddresses: [],
            selectedIPAddressesData: [],
            countries: [],
            cities: [],
            providers: [],
            addingIPAddresses: false,
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    selectIPAddresses() {
        if(this.state.selectedIPAddresses.length === 0) {
            return message.error('Please select at least 1 IP address!');
        }

        for(let i = 0; i < this.state.selectedIPAddressesData.length; i++) {
            if(this.state.selectedIPAddressesData[i].site_count === 0) {
                return message.error('Make sure all IP addresses have site count set up!');
            }
        }

        this.setState({ addingIPAddresses: true });

        this.props.adminAddAvailableIPAddressesForOrder(this.props.order, this.state.selectedIPAddressesData, (res) => {
            message.success('IP addresses successfully added!');
            this.closeModal(true);
            this.setState({ addingIPAddresses: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ addingIPAddresses: false });
                displayErrors(err.response.data);
            }
        });
    }

    getSiteCount(guid) {
        for(let i = 0; i < this.state.selectedIPAddressesData.length; i++) {
            if(this.state.selectedIPAddressesData[i].guid === guid) {
                return this.state.selectedIPAddressesData[i].site_count;
            }
        }

        return 0;
    }

    setSiteCount(guid, value) {
        let ipAddresses = this.state.selectedIPAddressesData.map((ip_address, i) => {
            if(ip_address.guid === guid) {
                return {...ip_address, site_count: value};
            }

            return ip_address;
        });

        this.setState({ selectedIPAddressesData: ipAddresses });
    }

    addSelectedIPAddress(ipAddresses) {
        let selectedIPAddressesData = [];
        let existingIPAddresses = {};

        for(let i = 0; i < this.state.selectedIPAddressesData.length; i++) {
            existingIPAddresses[this.state.selectedIPAddressesData[i].guid] = this.state.selectedIPAddressesData[i].site_count;
        }

        let keys = Object.keys(existingIPAddresses);

        for(let i = 0; i < ipAddresses.length; i++) {
            if(keys.indexOf(ipAddresses[i]) !== -1) {
                selectedIPAddressesData.push({
                    guid: ipAddresses[i],
                    site_count: existingIPAddresses[ipAddresses[i]]
                });
            } else {
                selectedIPAddressesData.push({
                    guid: ipAddresses[i],
                    site_count: 0
                });
            }
        }

        this.setState({ selectedIPAddresses: ipAddresses, selectedIPAddressesData });
    }

    render() {
        const { Text } = Typography;

        const columns = [
            { title: 'IP Address', dataIndex: 'ip_address' },
            { title: 'Used', dataIndex: 'used', render: (item, record) => item ? <Text type="danger">Yes</Text> : <Text type="success">No</Text> },
            { title: 'City', dataIndex: 'city', filters: this.state.cities.map((city, i) => { return {text: <span>{city}</span>, value: city} }), filterSearch: true, onFilter: (value, record) => record.city === value},
            { title: 'Country', dataIndex: 'country', filters: this.state.countries.map((country, i) => { return {text: <span>{country}</span>, value: country} }), filterSearch: true, onFilter: (value, record) => record.country === value},
            { title: 'Provider', dataIndex: 'provider', filters: this.state.providers.map((provider, i) => { return {text: <span>{provider}</span>, value: provider} }), filterSearch: true, onFilter: (value, record) => record.provider === value},
            { title: 'Sites', render: (item, record) => <InputNumber disabled={this.state.selectedIPAddresses.indexOf(record.guid) === -1} onChange={(value) => this.setSiteCount(record.guid, value)} min={0} max={10} value={this.getSiteCount(record.guid)} />},
        ];

        return(
            <Modal
                title='Add IP Addresses'
                destroyOnClose={true}
                visible={this.props.showModal}
                footer={<Space>
                    <Button disabled={this.state.addingIPAddresses} onClick={() => this.closeModal()}>Close</Button>
                    <Button disabled={this.state.addingIPAddresses}  loading={this.state.addingIPAddresses}  type='primary'
                            onClick={() => this.selectIPAddresses()}>Add IP Addresses</Button>
                </Space>}
                width={800}
                centered={true}
                onCancel={() => this.closeModal()}>
                <Table
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: this.state.selectedIPAddresses,
                        onChange: (ipAddresses) => this.addSelectedIPAddress(ipAddresses)
                    }}
                    columns={columns}
                    rowKey={(item) => item.guid}
                    loading={this.state.loadingIPAddresses}
                    dataSource={this.state.ipAddresses} size='small' />
            </Modal>
        );
    }
}

export default connect(null, { adminLoadAvailableIPAddressesForOrder,
    adminAddAvailableIPAddressesForOrder })(AddIPAddressesToOrderModal);