import React from "react";
import {connect} from "react-redux";
import {adminUpdateOrderField, loadSharedHostingPlans} from "../../actions/billing";
import {Button, Form, Modal, Select, Space, message, Descriptions} from "antd";
import {displayErrors} from "../../libs/utils";
import {MoneyField} from "../shared/MoneyField";

class HostingPlanChangeModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loadingHostingPlans: false,
            hostingPlansLoaded: false,
            selectedHostingPlan: '',
            activePackage: null,
            hostingPlans: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && !this.state.loadingHostingPlans && !this.state.hostingPlansLoaded) {
            this.setState({ loadingHostingPlans: true, selectedHostingPlan: this.props.selectedHostingPlan });

            this.props.loadSharedHostingPlans((res) => {
                let activePackage = null;

                for(let i = 0; i < res.data.data.length; i++) {
                    if(res.data.data[i].guid === this.props.selectedHostingPlan) {
                        activePackage = res.data.data[i];
                        break;
                    }
                }

                this.setState({ loadingHostingPlans: false, hostingPlansLoaded: true, hostingPlans: res.data.data,
                    activePackage: activePackage });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingHostingPlans: false, hostingPlansLoaded: true });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    closeModal(reload = false) {
        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    changeHostingPlan() {
        this.setState({ loading: true });

        let new_shared_hosting_plan = this.state.selectedHostingPlan;

        if(new_shared_hosting_plan.length === 0) {
            return message.error('Please select new shared hosting plan!');
        }

        let data = { new_shared_hosting_plan };

        this.props.adminUpdateOrderField(this.props.activeOrder, data, (res) => {
            this.setState({ loading: false });
            message.success('Hosting plan successfully updated!');
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    selectNewPlan(id) {
        for(let i = 0; i < this.state.hostingPlans.length; i++) {
            if(this.state.hostingPlans[i].guid === id) {
                this.setState({ activePackage: this.state.hostingPlans[i], selectedHostingPlan: id });
                break;
            }
        }
    }

    render() {
        const { Option } = Select;

        return(
            <Modal visible={this.props.showModal}
                   title='Change Hosting Plan'
                   footer={<Space>
                       <Button onClick={() => this.closeModal()}>Close</Button>
                       <Button type='primary' loading={this.state.loading} disabled={this.state.loading} onClick={() => this.changeHostingPlan()}>Change</Button>
                   </Space>}
                   onCancel={() => this.closeModal()}>
                <Form layout='vertical'>
                    <Form.Item label='Choose hosting plan:'>
                        <Select loading={this.state.loadingHostingPlans}
                                value={this.state.selectedHostingPlan}
                                disabled={this.state.loadingHostingPlans}
                                onChange={(value) => this.selectNewPlan(value)}>
                            {this.state.hostingPlans.map((plan, i) => {
                                console.log(plan);
                                return <Option key={plan.guid} value={plan.guid}>{plan.name}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item hidden={this.state.activePackage === null}>
                        {this.state.activePackage !== null ? <Descriptions column={1} bordered size='small'>
                            <Descriptions.Item label={<span>Package</span>}>{this.state.activePackage.name}</Descriptions.Item>
                            <Descriptions.Item label={<span>Monthly price</span>}>
                                <MoneyField amount={this.state.activePackage.price_eur } currency='EUR' /><br />
                                <MoneyField amount={this.state.activePackage.price_usd } currency='USD' />
                            </Descriptions.Item>
                            <Descriptions.Item label={<span>Storage</span>}>{this.state.activePackage.storage}</Descriptions.Item>
                            <Descriptions.Item label={<span>Bandwidth</span>}>{this.state.activePackage.bandwidth}</Descriptions.Item>
                            <Descriptions.Item label={<span>Inodes</span>}>{this.state.activePackage.inodes}</Descriptions.Item>
                            <Descriptions.Item label={<span>RAM</span>}>{this.state.activePackage.memory}</Descriptions.Item>
                            <Descriptions.Item label={<span>CPU</span>}>{this.state.activePackage.cpu}</Descriptions.Item>
                            <Descriptions.Item label={<span>Entry processes</span>}>{this.state.activePackage.entry_processes}</Descriptions.Item>
                            <Descriptions.Item label={<span>Active processes</span>}>{this.state.activePackage.active_processes}</Descriptions.Item>
                            <Descriptions.Item label={<span>Email accounts</span>}>{this.state.activePackage.email_accounts}</Descriptions.Item>
                            <Descriptions.Item label={<span>Databases</span>}>{this.state.activePackage.databases}</Descriptions.Item>
                            <Descriptions.Item label={<span>Subdomains</span>}>{this.state.activePackage.subdomains}</Descriptions.Item>
                            <Descriptions.Item label={<span>Parked domains</span>}>{this.state.activePackage.parked_domains}</Descriptions.Item>
                            <Descriptions.Item label={<span>Addon domains</span>}>{this.state.activePackage.addon_domains}</Descriptions.Item>
                        </Descriptions> : ''}
                    </Form.Item>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { loadSharedHostingPlans, adminUpdateOrderField })(HostingPlanChangeModal);