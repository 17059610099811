import React, {Fragment} from "react";
import { connect } from 'react-redux';
import {sidebarMenu} from "../menu";
import {Link, Switch} from "react-router-dom";
import {CloseCircleOutlined, MenuOutlined} from "@ant-design/icons";
import PrivateRoute from "./shared/PrivateRoute";
import {Loading} from "../libs/loading";
import {Helmet} from "react-helmet";
import {getRootPath} from "../libs/utils";
import {message, PageHeader} from "antd";
import qs from "qs";
import {getErrorMessage} from "../actions/system";
import Issues from "./shared/Issues";

class App extends React.Component {
    state = {
        sidebarMenuOpen: false,
        activeMenuItem: '/',
        activeSubmenuItem: null,
        submenuOpen: false
    };

    componentDidMount() {
        this.updatePage(this.props.location.pathname);

        this.props.history.listen((location, action) => {
            this.updatePage(location.pathname);
        });

        if(this.props.location.search !== '')
        {
            let parsed = qs.parse(this.props.location.search.substring(1));

            if(typeof parsed.error !== 'undefined') {
                this.props.getErrorMessage(parsed.error, (res) => {
                    message.error(res.data.description);
                }, (err) => {});
            }
        }
    }

    updatePage(location) {
        if(typeof location !== 'undefined') {
            const rootPath = getRootPath(location);
            this.setState({ activeMenuItem : rootPath, activeSubmenuItem: (rootPath === location ? rootPath : location) });
        }
    }

    render() {
        if(this.props.user === null) {
            return <Loading />;
        }

        const { user } = this.props.user;
        const menuItems = [];
        let routes = [];

        Object.keys(sidebarMenu).forEach((path, i) => {
            const item = sidebarMenu[path];

            let is_staff = this.props.user.user.user_type === 'STAFF';
            let is_admin = this.props.user.user.user_type === 'ADMIN';

            if((item.show_staff && is_staff) || is_admin) {
                if(!item.subpage) {
                    menuItems.push(<li key={i} className={this.state.activeMenuItem === path ? 'menu-item active' : 'menu-item'}>
                        <Link to={path} onClick={() => this.setState({ activeMenuItem: path, activeSubmenuItem: path })}>{item.icon ? item.icon : null} {item.name}</Link>
                    </li>);
                }

                if(item.page) {
                    routes.push(<PrivateRoute key={path} path={path} component={item.page} exact={item.exact} />);
                }
            }

            return null;
        });

        return(
            <Fragment>
                <div className='wrapper'>
                    <MenuOutlined className='menu-icon' style={{color:'#fff'}}
                                  onClick={() => this.setState({ sidebarMenuOpen: !this.state.sidebarMenuOpen })} />

                    <header className='header'>
                        <div className='logo'>
                            <img src='/img/logo-top.png' alt='Priority Prospect' />
                        </div>
                        <div className='header-actions' style={{paddingLeft: '30px'}}>
                            <span style={{color:'#fff'}}>{user.first_name + ' ' + user.last_name}</span>
                        </div>
                    </header>
                    <aside className={this.state.sidebarMenuOpen ? 'sidebar active' : 'sidebar'}>
                        <div className='logo'>
                            <img src='/img/logo-top-dark.png' alt='Priority Prospect' />
                        </div>

                        <CloseCircleOutlined className='menu-icon-close' onClick={() => this.setState({ sidebarMenuOpen: !this.state.sidebarMenuOpen })} />
                        <nav>
                            <ul className='menu'>
                                {menuItems}
                            </ul>
                        </nav>
                    </aside>

                    <main className='main' onClick={() => this.state.sidebarMenuOpen ? this.setState( { sidebarMenuOpen: false }) : null}>
                        <div className='main-header'>
                            <PageHeader className='main-header-text' title={this.props.page.title} subTitle={this.props.page.subtitle} />
                            <Helmet>
                                <title>{this.props.page.title}</title>
                            </Helmet>
                        </div>
                        <div className='main-content'>
                            <Issues />
                            <div className='body-container'>
                                <Switch>
                                    {routes}
                                </Switch>
                            </div>
                        </div>
                    </main>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
    page: state.page
});

export default connect(mapStateToProps, { getErrorMessage })(App);