import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {Button, Col, Form, Input, Row, message, Typography, Select} from "antd";
import {PlayCircleOutlined} from "@ant-design/icons";
import {adminRunCurlTool} from "../../actions/tools";
import {displayErrors} from "../../libs/utils";

class CurlTool extends React.Component {
    state = {
        value: '',
        output_type: 'HEADERS',
        result: '',
        loading: false
    };

    componentDidMount() {
        this.props.updatePage('Curl');
    }

    runCurl() {
        let value = this.state.value;

        if(value.length === 0) {
            return message.error('Please enter an URL or an IP address!');
        }

        let output_type = this.state.output_type;

        if(value.length === 0) {
            return message.error('Please select the output type!');
        }

        let data = { value, output_type };

        this.setState({ loading: true });

        this.props.adminRunCurlTool(data, (res) => {
            this.setState({ loading: false, result: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Paragraph } = Typography;
        const { Option } = Select;

        return(
            <Form layout='vertical'>
                <Row gutter={[16, 16]}>
                    <Col span={18}>
                        <Form.Item label='Enter IP address or URL'>
                            <Input disabled={this.state.loading} onChange={(e) => this.setState({ value: e.target.value })} />
                        </Form.Item>
                    </Col>
                    <Col span={3}>
                        <Form.Item label='Output type'>
                            <Select value={this.state.output_type} disabled={this.state.loading} onChange={(value) => this.setState({ output_type: value })}>
                                <Option value='HEADERS'>Headers</Option>
                                <Option value='FULL_BODY'>Full body</Option>
                                <Option value='HEADERS_AND_BODY'>Headers + body</Option>
                                <Option value='VERBOSE'>Verbose</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label='&nbsp;'>
                            <Button type='primary' icon={<PlayCircleOutlined />} loading={this.state.loading} disabled={this.state.loading} onClick={() => this.runCurl()}>Run</Button>
                        </Form.Item>
                    </Col>
                </Row>
                <Row hidden={this.state.result === ''}>
                    <Col span={24}>
                        <Paragraph><pre>{this.state.result.trim()}</pre></Paragraph>
                    </Col>
                </Row>
            </Form>
        );
    }
}

export default connect(null, { updatePage, adminRunCurlTool })(CurlTool);