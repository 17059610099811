import React from "react";
import {connect} from "react-redux";
import {Button, Form, Modal, Space, message, Select} from "antd";
import {displayErrors} from "../../libs/utils";
import {adminBulkUpdateDomains} from "../../actions/domains";

class DomainStatusChangeModal extends React.Component {
    state = {
        selectedStatus: ''
    };

    closeModal(reload = false) {
        this.setState({
            selectedStatus: ''
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    updateDomains() {
        this.setState({ saving: true });

        let status = this.state.selectedStatus.trim();

        if(status.length === 0) {
            return message.error('Please select the status!');
        }

        let data = {
            domains: this.props.selectedDomains,
            status: this.state.selectedStatus
        };

        this.props.adminBulkUpdateDomains(data,  (res) => {
            this.setState({ saving: false });
            message.success('Domains successfully updated!');
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ saving: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        return(
            <Modal title='Update Status'
                   visible={this.props.showModal}
                   onCancel={() => this.closeModal()}
                   footer={<Space>
                       <Button disabled={this.state.saving} onClick={() => this.closeModal()}>Close</Button>
                       <Button disabled={this.state.saving} loading={this.state.saving}  type='primary' onClick={() => this.updateDomains()}>Update</Button>
                   </Space>}>
                <Form layout='vertical'>
                    <Form.Item label='New status'>
                        <Select value={this.state.selectedStatus} onChange={(value) => this.setState({ selectedStatus: value })}>
                            <Option value='ACTIVE'>Active</Option>
                            <Option value='DISABLED'>Disabled</Option>
                            <Option value='SUSPENDED'>Suspended</Option>
                            <Option value='DELETED'>Deleted</Option>
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { adminBulkUpdateDomains })(DomainStatusChangeModal);