import React from "react";
import {connect} from "react-redux";
import {Button, Col, Form, Input, Modal, Row, Select, Space, Switch, message} from "antd";
import {adminCreateSharedHostingServer} from "../../actions/network";
import {displayErrors} from "../../libs/utils";

class SharedHostingServerModal extends React.Component {
    state = {
        hostname: '',
        ipAddress: '',
        hash: '',
        cost: '',
        costCurrency: 'USD',
        isAvailable: true
    };

    closeModal(reload = false) {
        this.setState({

        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    saveServer() {
        let hostname = this.state.hostname.trim();
        let ip_address = this.state.ipAddress.trim();
        let hash = this.state.hash.trim();
        let cost = this.state.cost.trim();
        let cost_currency = this.state.costCurrency.trim();
        let is_available = this.state.isAvailable;

        if(hostname.length === 0) {
            return message.error('Please enter hostname!');
        }

        if(ip_address.length === 0) {
            return message.error('Please enter IP address!');
        }

        if(hash.length === 0) {
            return message.error('Please enter hash!');
        }

        if(cost.length === 0) {
            return message.error('Please enter cost!');
        }

        if(cost_currency.length === 0) {
            return message.error('Please enter cost currency!');
        }

        let data = { hostname, ip_address, hash, cost, cost_currency };
        data['is_available'] = is_available;

        this.setState({ loading: true });

        this.props.adminCreateSharedHostingServer(data, (res) => {
            this.setState({ loading: false });
            message.success('Shared hosting server successfully created!');
            this.closeModal(true);
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        const { Option } = Select;

        return(
            <Modal
                title='Create Shared Hosting Server'
                visible={this.props.showModal}
                footer={<Space>
                    <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Close</Button>
                    <Button loading={this.state.loading}  disabled={this.state.loading}  type='primary' onClick={() => this.saveServer()}>Create</Button>
                </Space>}
                onCancel={() => this.closeModal()}>
                <Form layout='vertical'>
                    <Form.Item label='Hostname'>
                        <Input value={this.state.hostname} onChange={(e) => this.setState({ hostname: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='IP address'>
                        <Input value={this.state.ipAddress} onChange={(e) => this.setState({ ipAddress: e.target.value })} />
                    </Form.Item>
                    <Form.Item label='Hash'>
                        <Input value={this.state.hash} onChange={(e) => this.setState({ hash: e.target.value })} />
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item label='Cost'>
                                <Input value={this.state.cost} onChange={(e) => this.setState({ cost: e.target.value })}
                                       addonAfter={<Select value={this.state.costCurrency} onChange={(value) => this.setState({ costCurrency: value })}>
                                           <Option value="USD">USD</Option>
                                           <Option value="EUR">EUR</Option>
                                       </Select>} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Is available?'>
                                <Switch checked={this.state.isAvailable} onChange={(value) => this.setState({ isAvailable: value })} />
                            </Form.Item>
                        </Col>
                    </Row>


                </Form>
            </Modal>
        );
    }
}

export default connect(null, { adminCreateSharedHostingServer })(SharedHostingServerModal);