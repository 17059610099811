import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    adminDeleteCreditNoteItem, adminDeleteCreditNoteTransaction,
    adminDownloadCreditNote,
    adminLoadCreditNote,
    adminLoadCreditTransactions, adminPreviewCreditNote, adminUpdateCreditNoteField, adminUpdateCreditNoteItem
} from "../../actions/billing";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {
    Button,
    Col,
    Layout,
    Menu,
    Popconfirm,
    Row,
    message,
    Table,
    Tooltip,
    Descriptions,
    Select,
    Modal,
    Space
} from "antd";
import {Loading} from "../../libs/loading";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {
    DeleteOutlined,
    ExclamationCircleOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    FilePdfOutlined,
    PlusOutlined
} from "@ant-design/icons";
import {DateFormat} from "../shared/DateFormat";
import CreditNoteItemModal from "./CreditNoteItemModal";
import NewCreditTransactionModal from "./NewCreditTransactionModal";
import {MoneyField} from "../shared/MoneyField";
import {Link} from "react-router-dom";
import CreditNotePDFPreviewModal from "./CreditNotePDFPreviewModal";

class CreditNoteView extends React.Component {
    state = {
        credit: null,
        loadingCreditNote: true,
        showCreditNoteItemModal: false,
        activeCreditNoteItem: null,
        showNewCreditTransactionModal: false,
        creditTransactions: [],
        loadingCreditTransactions: false,
        showCreditNotePDFPreview: false,
        loadingCreditNotePDFPreview: false,
        creditNotePDFPreview: ''
    };

    componentDidMount() {
        this.props.updatePage('Credit Note #' + this.props.match.params.id);
        this.adminLoadCreditNote();
    }

    adminLoadCreditNote() {
        this.props.adminLoadCreditNote(this.props.match.params.id, (res) => {
            this.setState({ loadingCreditNote: false, credit: res.data, creditTransactions: res.data.credit_transactions });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCreditNote: false, invoiceItemLoaded: true });
                displayErrors(err.response.data);
            }
        });
    }

    adminDeleteCreditNoteItem(id) {
        this.setState({ loading: true });

        this.props.adminDeleteCreditNoteItem(this.props.match.params.id, id, () => {
            message.success('Credit note item successfully deleted!');
            this.adminLoadCreditNote();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminUpdateCreditNoteItem(item_id, field, value) {
        this.setState({ loading: true });

        this.props.adminUpdateCreditNoteItem(this.props.match.params.id, item_id, { [field]: value }, () => {
            message.success('Credit note item successfully updated!');
            this.adminLoadCreditNote();
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadCreditTransactions() {
        this.setState({ loadingCreditTransactions: true });

        this.props.adminLoadCreditTransactions(this.props.match.params.id, (res) => {
            this.setState({ creditTransactions: res.data, loadingCreditTransactions: false });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCreditTransactions: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminUpdateCreditNoteStatus(status) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set the status to <strong>{normalizeEnum(status)}</strong>?</span>,
            onOk: () => {
                this.setState({ updatingCreditNoteStatus: true });

                this.props.adminUpdateCreditNoteField(this.props.match.params.id, { 'status': status }, () => {
                    this.setState({ updatingCreditNoteStatus: false });
                    message.success('Credit note status successfully updated!');
                    this.adminLoadCreditNote();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ updatingCreditNoteStatus: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    previewCreditNotePDF() {
        this.setState({ showCreditNotePDFPreview: true, loadingCreditNotePDFPreview: true, creditNotePDFPreview: '' });

        this.props.adminPreviewCreditNote(this.props.match.params.id, (res) => {
            this.setState({ loadingCreditNotePDFPreview: false, creditNotePDFPreview: res.data });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingCreditNotePDFPreview: false, creditNotePDFPreview: '' });
                displayErrors(err.response.data);
            }
        });
    }

    render() {
        if(this.state.loadingCreditNote) {
            return <Loading />;
        }

        const { credit } = this.state;

        const itemTableColumns = [
            { title: 'ID', dataIndex: 'id', align: 'center' },
            { title: 'Description', width: '60%', dataIndex: 'description', render: (item, record) => <span>{item} {record.is_hidden ? <em><small>(hidden)</small></em> : ''}</span>},
            { title: 'Amount', align: 'center', render: (item, record) => <MoneyField amount={parseFloat(record.is_hidden ? record.amount : -record.amount)} currency={record.currency} /> },
            { title: 'Tax', align: 'center', render: (item, record) => <MoneyField amount={record.tax} currency={record.currency} /> },
            { title: 'Tax Rate', align: 'center', dataIndex: 'tax_rate', render: (item) => <span>{item}%</span> },
            { title: 'Total', align: 'center', render: (item, record) => <MoneyField amount={parseFloat(record.amount) + parseFloat(record.tax)} currency={record.currency} /> },
            { title: '', align: 'center', width: '50px', render: (item, record) => {
                return <Space>
                    <Popconfirm
                        title="Are you sure you want to delete this item?"
                        onConfirm={() => this.adminDeleteCreditNoteItem(record.id)}
                        okText='Delete'
                        placement='topRight'
                        okButtonProps={{danger: true}}
                        cancelText='Cancel'>
                        <Tooltip title='Delete item'>
                            <Button size='small' type='circle' icon={<DeleteOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                    <Popconfirm
                        title="Are you sure you want to toggle visibility of this item?"
                        onConfirm={() => this.adminUpdateCreditNoteItem(record.id, 'is_hidden', !item.is_hidden)}
                        okText={item.is_hidden ? 'Show': 'Hide'}
                        placement='topRight'
                        okButtonProps={{danger: true}}
                        cancelText='Cancel'>
                        <Tooltip title='Hide item'>
                            <Button size='small' type='circle' icon={item.is_hidden ? <EyeOutlined /> : <EyeInvisibleOutlined />} />
                        </Tooltip>
                    </Popconfirm>
                </Space>;
            }},
        ];

        const creditTransactionsTableColumns = [
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Action', dataIndex: 'action', render: (item) => normalizeEnum(item) },
            { title: 'Amount', dataIndex: 'amount', render: (item, record) => <MoneyField amount={item} currency={record.currency} /> },
            { title: 'Currency', dataIndex: 'currency' },
        ];

        const transactionsTableColumns = [
            { title: 'Amount', dataIndex: 'amount', render: (item, record) => <MoneyField amount={record.amount} currency={record.currency} /> },
            { title: 'Currency', dataIndex: 'currency' },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Payment method', dataIndex: 'payment_method', render: (item) => normalizeEnum(item) },
            { title: 'Transaction ID', dataIndex: 'transaction_id' },
        ];

        const { Content } = Layout;
        const { Option } = Select;

        return(
            <Content>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Descriptions bordered column={1} size='small'>
                            <Descriptions.Item label='Owner'><Link to={'/accounts/companies/' + credit.company_guid}>{credit.company_name}</Link></Descriptions.Item>
                            <Descriptions.Item label='Status'>
                                <Select style={{width: '100%'}} value={this.state.credit.status} onChange={(value) => this.adminUpdateCreditNoteStatus(value)}>
                                    <Option value='ACTIVE'>Active</Option>
                                    <Option value='CANCELLED'>Cancelled</Option>
                                </Select>
                            </Descriptions.Item>
                            <Descriptions.Item label='PDF'>
                                <Button size='small' icon={<FilePdfOutlined />} onClick={() => this.props.adminDownloadCreditNote(this.props.match.params.id)}>Download PDF</Button>
                                <Button size='small' icon={<FilePdfOutlined />} onClick={() => this.previewCreditNotePDF()}>Preview PDF</Button>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
                <Row className='credit-note-content'>
                    <Col span={24}>
                        <Row gutter={[16, 48]}>
                            <Col span={24}>
                                <img src='/img/logo-top-dark.png' alt='Priority Prospect' className='invoice-logo' />
                            </Col>
                        </Row>
                        <Row gutter={[16, 48]}>
                            <Col span={12} xs={24} sm={12} style={{verticalAlign:'middle'}}>
                                <b>Priority Prospect OÜ</b><br />
                                Vandu tee 6-2, Hulja<br />
                                Lääne Virumaa, Estonia<br />
                                45203<br />
                                Registration No. 14059959<br />
                                VAT No. EE101884183
                            </Col>
                            <Col xs={24} sm={12} className='credit-note-name-block' style={{verticalAlign:'middle'}}>
                                <h2>CREDIT NOTE<br /><small>#{credit.id}</small></h2>
                            </Col>
                        </Row>
                        <br />
                        <Row gutter={[16, 48]} align="middle">
                            <Col span={12}>
                                Bill to:<br />
                                <b>{credit.company_name}</b><br />
                                {credit.first_name} {credit.last_name}<br />
                                {credit.address1}<br />
                                {credit.address2 !== null && credit.address2 !== '' ? <span>{credit.address2}<br /></span> : ''}
                                {credit.region}, {credit.country}<br />
                                {credit.postal_code}
                            </Col>
                            <Col span={12} className='text-right'>
                                Credit date: <DateFormat value={credit.date_created} />
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col span={24} gutter={[16, 48]}>
                                <Menu mode="horizontal" selectedKeys={[]}>
                                    <Menu.Item key='new_invoice_item' icon={<PlusOutlined />} onClick={() => this.setState({ showCreditNoteItemModal: true })}>
                                        New Credit Item
                                    </Menu.Item>
                                </Menu>

                                <Table size='middle' rowKey={item => item.id} columns={itemTableColumns} dataSource={credit.items} pagination={false} summary={() => {
                                    return <>
                                        <Table.Summary.Row key='initial_amount'>
                                            <Table.Summary.Cell colSpan={3} className='no-border-cell' />
                                            <Table.Summary.Cell colSpan={2} className='text-center no-border-cell'>Initial Balance</Table.Summary.Cell>
                                            <Table.Summary.Cell className='text-center no-border-cell'><MoneyField amount={credit.initial_balance} currency={credit.currency} /></Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row key='tax'>
                                            <Table.Summary.Cell colSpan={4} className='no-border-cell' />
                                            <Table.Summary.Cell className='text-center no-border-cell'>Tax</Table.Summary.Cell>
                                            <Table.Summary.Cell className='text-center no-border-cell'><MoneyField amount={credit.initial_tax} currency={credit.currency} /></Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row key='balance_total'>
                                            <Table.Summary.Cell colSpan={4} className='no-border-cell' />
                                            <Table.Summary.Cell className='text-center no-border-cell'>Total</Table.Summary.Cell>
                                            <Table.Summary.Cell className='text-center no-border-cell'><MoneyField amount={parseFloat(credit.initial_balance) + parseFloat(credit.initial_tax)} currency={credit.currency} /></Table.Summary.Cell>
                                        </Table.Summary.Row>
                                        <Table.Summary.Row key='balance_due'>
                                            <Table.Summary.Cell colSpan={3} className='no-border-cell' />
                                            <Table.Summary.Cell colSpan={2} className='text-center gray-cell no-border-cell'><b>Balance Available</b></Table.Summary.Cell>
                                            <Table.Summary.Cell className='text-center gray-cell no-border-cell'><b><MoneyField amount={credit.balance} currency={credit.currency} /></b></Table.Summary.Cell>
                                        </Table.Summary.Row>
                                    </>;
                                }}/>
                            </Col>
                        </Row>
                        <Row span={24} gutter={[16, 48]}>
                            <Col span={24}>
                                <h3>Associated Transaction</h3>
                                <Table size='small' pagination={false}
                                       columns={transactionsTableColumns}
                                       rowKey={item => item.id}
                                       dataSource={credit.transaction !== null ? [credit.transaction] : []} />
                            </Col>
                        </Row>
                        <br />
                        <Row span={24} gutter={[16, 48]}>
                            <Col span={24}>
                                <h3>Credit Transactions</h3>
                                <Menu mode="horizontal" selectedKeys={[]}>
                                    <Menu.Item
                                        key='new_transaction' icon={<PlusOutlined />}
                                        onClick={() => this.setState({ showNewCreditTransactionModal: true })}>
                                        New Transaction
                                    </Menu.Item>
                                </Menu>
                                <Table size='small' pagination={false}
                                       columns={creditTransactionsTableColumns}
                                       rowKey={item => item.guid}
                                       loading={this.state.loadingCreditTransactions}
                                       dataSource={this.state.creditTransactions} />
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <NewCreditTransactionModal
                    creditNote={this.props.match.params.id}
                    currency={credit.currency}
                    reloadCreditTransactions={() => this.adminLoadCreditNote()}
                    showModal={this.state.showNewCreditTransactionModal}
                    close={() => this.setState({ showNewCreditTransactionModal: false })} />

                <CreditNoteItemModal
                    showModal={this.state.showCreditNoteItemModal}
                    creditNote={this.props.match.params.id}
                    currency={credit.currency}
                    reload={() => this.adminLoadCreditNote()}
                    close={() => this.setState({ showCreditNoteItemModal: false, activeCreditNoteItem: null })} />

                <CreditNotePDFPreviewModal
                    showModal={this.state.showCreditNotePDFPreview}
                    loading={this.state.loadingCreditNotePDFPreview}
                    content={this.state.creditNotePDFPreview} />
            </Content>);
    }
}

export default connect(null, { updatePage, adminLoadCreditNote, adminDeleteCreditNoteItem,
    adminDownloadCreditNote, adminLoadCreditTransactions, adminUpdateCreditNoteField,
    adminDeleteCreditNoteTransaction, adminUpdateCreditNoteItem, adminPreviewCreditNote })(CreditNoteView);