import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {
    adminCreateSharedHostingAccount, adminDeleteOrderCoupon,
    adminLoadOrder,
    adminLoadOrderInvoices,
    adminUpdateOrderField, adminUpdateOrderIPAddresses
} from "../../actions/billing";
import {Loading} from "../../libs/loading";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {
    Card,
    Col,
    Descriptions,
    Modal,
    Row,
    Select,
    message,
    Table,
    DatePicker,
    Button,
    Tag,
    Alert,
    Space, Tooltip
} from "antd";
import {DateFormat} from "../shared/DateFormat";
import {Link} from "react-router-dom";
import {DateTimeFormat} from "../shared/DateTimeFormat";
import {StatusIndicator} from "../shared/Status";
import {
    DeleteOutlined,
    EditOutlined,
    ExclamationCircleOutlined, EyeOutlined, LoginOutlined, PlusOutlined,
    RetweetOutlined, ShoppingCartOutlined, SwapOutlined
} from "@ant-design/icons";
import moment from 'moment';
import HostingPlanChangeModal from "./HostingPlanChangeModal";
import SharedHostingAccountChangeModal from "./SharedHostingAccountChangeModal";
import OrderIPAddressStatusChangeModal from "./OrderIPAddressStatusChangeModal";
import {DEFAULT_PER_PAGE} from "../../config";
import {MoneyField} from "../shared/MoneyField";
import OrderExtendModal from "./OrderExtendModal";
import IPAddressChangeOrderModal from "./IPAddressChangeOrderModal";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import AddIPAddressesToOrderModal from "./AddIPAddressesToOrderModal";
import AllocatedIPPriceGroupChangeModal from "./AllocatedIPPriceGroupChangeModal";
import OrderAddCouponModal from "./OrderAddCouponModal";

class OrderView extends React.Component {
    state = {
        order: null,
        loadingOrder: true,
        updatingOrderStatus: false,
        activeStatus: '',
        updatingOrderBillingCycle: false,
        activeBillingCycle: '',
        updatingOrderNextInvoiceDate: false,
        showHostingPlanChangeModal: false,
        showHostingAccountChangeModal: false,
        showIPAddressStatusChangeModal: false,
        selectedIPAddresses: [],
        invoicesPagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        loadingInvoices: false,
        invoices: [],
        creatingHostingAccount: false,
        showOrderExtendModal: false,
        showOrderChangingModal: false,
        showAddIPAddressModal: false,
        deletingIPAddresses: false,
        showSetPriceGroupModal: false,
        removingCoupon: false,
    };

    componentDidMount() {
        this.props.updatePage('Order #' + this.props.match.params.id);

        this.adminLoadOrder();
        this.adminLoadOrderInvoices();
    }

    adminLoadOrder() {
        this.props.adminLoadOrder(this.props.match.params.id, (res) => {
            this.setState({ loadingOrder: false, order: res.data, activeStatus: res.data.status,
                activeBillingCycle: res.data.billing_cycle });
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                displayErrors(err.response.data);
            }
        });
    }

    adminLoadOrderInvoices(page = null, per_page = null) {
        if(page == null) {
            page = this.state.invoicesPagination.current;
            per_page = this.state.invoicesPagination.pageSize;
        }

        this.setState({ loadingInvoices: true });

        this.props.adminLoadOrderInvoices(this.props.match.params.id, page, per_page, (res) => {
            this.setState({ invoices: res.data.data, loadingInvoices: false,
                invoicesPagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loadingInvoices: false });
                displayErrors(err.response.data);
            }
        });
    }

    adminUpdateOrderStatus(status) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set the status to <strong>{normalizeEnum(status)}</strong>?</span>,
            onOk: () => {
                this.setState({ updatingOrderStatus: true });

                this.props.adminUpdateOrderField(this.props.match.params.id, { 'status': status }, () => {
                    this.setState({ updatingOrderStatus: false });
                    message.success('Order status successfully updated!');
                    this.adminLoadOrder();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ updatingOrderStatus: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    adminUpdateOrderBillingCycle(billingCycle) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set the billing cycle to <strong>{normalizeEnum(billingCycle)}</strong>?</span>,
            onOk: () => {
                this.setState({ updatingOrderBillingCycle: true });

                this.props.adminUpdateOrderField(this.props.match.params.id, { 'billing_cycle': billingCycle }, () => {
                    this.setState({ updatingOrderBillingCycle: false });
                    message.success('Order\'s billing cycle successfully updated!');
                    this.adminLoadOrder();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ updatingOrderBillingCycle: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    adminUpdateOrderNextInvoiceDate(date) {
        const { confirm } = Modal;

        date = moment(date).format('YYYY-MM-DD');

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to set the next invoice date to <strong>{date}</strong>?</span>,
            onOk: () => {
                this.setState({ updatingOrderNextInvoiceDate: true });

                this.props.adminUpdateOrderField(this.props.match.params.id, { 'date_next_invoice': date }, () => {
                    this.setState({ updatingOrderNextInvoiceDate: false });
                    message.success('Order\'s next invoice date successfully updated!');
                    this.adminLoadOrder();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ updatingOrderNextInvoiceDate: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    createHostingAccount(orderId) {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: <span>Are you sure you wish to create this hosting account?</span>,
            onOk: () => {
                this.setState({ creatingHostingAccount: true });

                this.props.adminCreateSharedHostingAccount(orderId, () => {

                    this.setState({ creatingHostingAccount: false });
                    message.success('Hosting account successfully created!');
                    this.adminLoadOrder();

                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ creatingHostingAccount: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    deleteSelectedIPAddresses() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you wish to delete selected IP addresses?',
            onOk: () => {
                this.setState({ deletingIPAddresses: true });

                let data = {
                    action: 'DELETE',
                    ip_addresses: this.state.selectedIPAddresses
                };

                this.props.adminUpdateOrderIPAddresses(this.props.match.params.id, data, () => {
                    this.setState({ deletingIPAddresses: false });
                    message.success('IP addresses successfully deleted!');
                    this.adminLoadOrder();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ deletingIPAddresses: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    removeCoupon() {
        const { confirm } = Modal;

        confirm({
            title: 'Are you sure?',
            centered: true,
            okText: 'Yes',
            okType: 'danger',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you wish to remove the coupon?',
            onOk: () => {
                this.setState({ removingCoupon: true });

                this.props.adminDeleteOrderCoupon(this.props.match.params.id, () => {
                    this.setState({ removingCoupon: false });
                    message.success('Coupon successfully removed!');
                    this.adminLoadOrder();
                }, (err) => {
                    if(typeof err.response !== 'undefined') {
                        this.setState({ removingCoupon: false });
                        displayErrors(err.response.data);
                    }
                });
            }
        });
    }

    render() {
        if(this.state.loadingOrder) {
            return <div className='text-center'><Loading /></div>;
        }

        const { order } = this.state;

        let orderItems = null;

        const invoiceColumns = [
            { title: 'ID', dataIndex: 'id', render: (item) => <Link to={'/billing/invoices/' + item}>#{item}</Link>},
            { title: 'Amount', align: 'center', dataIndex: 'initial_amount', render: (item, record) => <MoneyField amount={record.initial_amount} currency={record.currency} />},
            { title: 'Tax', align: 'center', dataIndex: 'initial_tax', render: (item, record) => <MoneyField amount={record.initial_tax} currency={record.currency} />},
            { title: 'Status', align: 'center', dataIndex: 'status', render: (item) => <StatusIndicator status={item} el='badge' /> },
            { title: 'Created', align: 'center', dataIndex: 'date_created', render: (item, record) => <DateFormat value={record.date_created} /> },
            { title: 'Due', align: 'center', dataIndex: 'date_due', render: (item, record) => <DateFormat value={record.date_due} /> },
        ];

        if(order.order_type === 'IP_ADDRESS_HOSTING') {
            const columns = [
                { title: 'ID', dataIndex: 'id' },
                { title: 'IP address', dataIndex: 'ip_address' },
                { title: 'Status', dataIndex: 'status', render: (item, record) => <StatusIndicator el='badge' status={record.status} />},
                { title: 'Type', dataIndex: 'ip_address_type', render: (item, record) => normalizeEnum(record.ip_address_type) },
                { title: 'Location', render: (item) => <span>{item.ip_node.location.city}, {item.ip_node.location.country}</span>},
                { title: 'IP node', render: (item) => <Link to={'/network/ip-nodes/' + item.ip_node.guid}>{item.ip_node.hostname.replace('.gateway.prioritynap.net', '')}</Link>},
                { title: 'IP Used Count', dataIndex: 'ip_address_used_count', align: 'center'},
                { title: 'Domains Allowed', dataIndex: 'domains_allowed', align: 'center'},
                { title: 'Price Group', dataIndex: 'price_group', render: (item) => item ? item.name : '-' },
                { title: 'Price', align: 'center', render: (item, record) => order.currency === 'USD' ? <MoneyField amount={record.usd_price} currency='USD' /> : <MoneyField amount={record.eur_price} currency='EUR' /> },
                { title: 'Date Cancelled', dataIndex: 'date_cancelled', render: (item, record) => record.status === 'CANCELLED' ? <DateTimeFormat value={record.date_cancelled} /> : '-' },
                { title: 'Replacement', dataIndex: 'replacement_ip_address', render: (item) => item !== null ? <span>{item.ip_address} <small><em>({item.id})</em></small></span> : '-'},
                { title: 'Date Replaced', dataIndex: 'date_replaced', render: (item) => item !== null ? <DateTimeFormat value={item} /> : '-'}
            ];

            let totalIPAddressCountRequirement = 0;

            for(let i = 0; i < order.locations.length; i++) {
                totalIPAddressCountRequirement += order.locations[i].ip_address_count;
            }

            let allocatedIPAddressesCount = 0;

            for(let i = 0; i < order.ip_addresses.length; i++) {
                if(order.ip_addresses[i].status !== 'REPLACED') {
                    allocatedIPAddressesCount++;
                }
            }

            orderItems = <Fragment>
                <Row justify='space-between' style={{marginBottom: '10px'}}>
                    <Col>
                        <Space>
                            <Button type='text' icon={<PlusOutlined />} onClick={() => this.setState({ showAddIPAddressModal: true })}>Add IP Addresses</Button>
                            <Button type='text' disabled={this.state.selectedIPAddresses.length === 0} icon={<RetweetOutlined />} onClick={() => this.setState({ showIPAddressStatusChangeModal: true })}>Change Status</Button>
                            <Button type='text' disabled={this.state.selectedIPAddresses.length === 0} icon={<LoginOutlined />} onClick={() => this.setState({ showOrderChangingModal: true })}>Change Order</Button>
                            <Button type='text' disabled={this.state.selectedIPAddresses.length === 0 || this.state.deletingIPAddresses} loading={this.state.deletingIPAddresses} icon={<DeleteOutlined />} onClick={() => this.deleteSelectedIPAddresses()}>Delete IP Addresses</Button>
                            <Button type='text' disabled={this.state.selectedIPAddresses.length === 0} icon={<ShoppingCartOutlined />} onClick={() => this.setState({ showSetPriceGroupModal: true })}>Edit Price Group</Button>
                        </Space>
                    </Col>
                    <Col>
                        <Tooltip title='Reload' placement='left'><Button onClick={() => this.adminLoadOrder()} type='link' icon={<UndoOutlined />} /></Tooltip>
                    </Col>
                </Row>

                {totalIPAddressCountRequirement !== allocatedIPAddressesCount && order.status !== 'PENDING' ? <Alert type='error' showIcon message='This order was not fully fulfilled!' /> : ''}

                <Table dataSource={order.ip_addresses}
                       columns={columns}
                       pagination={false}
                       size='small'
                       footer={this.state.selectedIPAddresses.length > 0 ? () => <span>{this.state.selectedIPAddresses.length} IP address{this.state.selectedIPAddresses.length > 1 ? 'es' : ''} selected out of {order.ip_addresses.length}</span> : () => <span>{order.ip_addresses.length} IP addresses</span> }
                       rowSelection={{
                           type: 'checkbox',
                           selectedRowKeys: this.state.selectedIPAddresses,
                           onChange: (ipAddresses) => this.setState({ selectedIPAddresses: ipAddresses }),
                           getCheckboxProps: (record) => ({
                               disabled: record.status === 'REPLACED'
                           }),
                       }}
                       rowKey={item => item.guid} />

                <AddIPAddressesToOrderModal
                    order={this.props.match.params.id}
                    showModal={this.state.showAddIPAddressModal}
                    close={() => this.setState({ showAddIPAddressModal: false })}
                    reload={() => this.adminLoadOrder()} />

                <OrderIPAddressStatusChangeModal
                    showModal={this.state.showIPAddressStatusChangeModal}
                    selectedIPAddresses={this.state.selectedIPAddresses}
                    reload={() => this.adminLoadOrder()}
                    close={() => this.setState({ showIPAddressStatusChangeModal: false, selectedIPAddresses: [] })} />

                <IPAddressChangeOrderModal
                    showModal={this.state.showOrderChangingModal}
                    orderId={order.id}
                    reload={() => this.adminLoadOrder()}
                    selectedIPAddresses={this.state.selectedIPAddresses}
                    close={() => this.setState({ showOrderChangingModal: false, selectedIPAddresses: [] })} />

                <AllocatedIPPriceGroupChangeModal
                    showModal={this.state.showSetPriceGroupModal}
                    reload={() => this.adminLoadOrder()}
                    selectedIPAddresses={this.state.selectedIPAddresses}
                    close={() => this.setState({ showSetPriceGroupModal: false, selectedIPAddresses: [] })} />
            </Fragment>;
        }

        const { Option } = Select;

        return(
            <Fragment>
                <Row gutter={[16, 16]}>
                    <Col sm={24} md={12}>
                        <Card size="small" title="Information">
                            <Descriptions bordered size='small' layout='horizontal' column={1}>
                                <Descriptions.Item style={{width: '20%'}} label='ID'>{order.id}</Descriptions.Item>
                                <Descriptions.Item label='Created'><DateTimeFormat value={order.date_created} /></Descriptions.Item>
                                <Descriptions.Item label='Processed'><DateTimeFormat value={order.date_processed} /></Descriptions.Item>
                                <Descriptions.Item label='Type'>{normalizeEnum(order.order_type)}</Descriptions.Item>
                                <Descriptions.Item label='Currency'>{order.currency}</Descriptions.Item>
                                <Descriptions.Item label='Company'><Link to={'/accounts/companies/' + order.owner.guid}>{order.owner.name}</Link></Descriptions.Item>
                                <Descriptions.Item label='User'><Link to={'/accounts/users/' + order.owner.owner.guid}>{order.owner.owner.first_name + ' ' + order.owner.owner.last_name}</Link></Descriptions.Item>
                                <Descriptions.Item label='Next invoice'>
                                    <Space>
                                        <DatePicker style={{width: '200px'}} allowClear={false} onChange={(value) => this.adminUpdateOrderNextInvoiceDate(value)}
                                                    defaultValue={moment(order.date_next_invoice, 'YYYY/MM/DD')} format='YYYY/MM/DD' />
                                        <small>{order.until_next_invoice.years} years, {order.until_next_invoice.months} months, {order.until_next_invoice.days} days</small>
                                    </Space>
                                </Descriptions.Item>
                                <Descriptions.Item label='Billing cycle'>
                                    <Select style={{width:'200px'}} value={this.state.activeBillingCycle} disabled={this.state.updatingOrderBillingCycle}
                                            loading={this.state.updatingOrderStatus}
                                            onChange={(value) => this.adminUpdateOrderBillingCycle(value)}>
                                        <Option value='MONTHLY'>Monthly</Option>
                                        <Option value='QUARTERLY'>Quarterly</Option>
                                        <Option value='SEMIANNUALLY'>Semiannually</Option>
                                        <Option value='ANNUALLY'>Annually</Option>
                                        <Option value='ONE_TIME'>One time</Option>
                                    </Select>
                                </Descriptions.Item>
                                <Descriptions.Item label='Status'>
                                    <Select style={{width:'200px'}} value={this.state.activeStatus} disabled={this.state.updatingOrderStatus}
                                            loading={this.state.updatingOrderStatus}
                                            onChange={(value) => this.adminUpdateOrderStatus(value)}>
                                        <Option value='ACTIVE'>Active</Option>
                                        <Option value='SUSPENDED'>Suspended</Option>
                                        <Option value='PENDING'>Pending</Option>
                                        <Option value='CANCELLED'>Cancelled</Option>
                                        <Option value='DELIVERED'>Delivered</Option>
                                    </Select>
                                </Descriptions.Item>
                                <Descriptions.Item key='monthly_cost' label='Monthly cost'><MoneyField amount={order.monthly_cost} currency={order.currency} /></Descriptions.Item>
                                {order.notes !== null ? <Descriptions.Item key='notes' label='Notes'>{order.notes}</Descriptions.Item> : ''}
                                <Descriptions.Item label='Tags'>{order.tags.length > 0 ? order.tags.map((tag) => <Tag>{tag}</Tag>) : '-'}</Descriptions.Item>
                                <Descriptions.Item label='Coupon'>
                                    {order.coupon !== null ? <span>{order.coupon.name} ({order.coupon.value}) <Button size='small' disabled={this.state.removingCoupon} loading={this.state.removingCoupon} type='circle' onClick={() => this.removeCoupon()} icon={<DeleteOutlined />} /></span> : <Button size='small' icon={<PlusOutlined />} onClick={() => this.setState({ showAddCouponModal: true })}>Add Coupon</Button>}
                                </Descriptions.Item>
                                <Descriptions.Item label='Reservation list'>
                                    {typeof order.reservation_list !== 'undefined' && order.reservation_list !== null ? <Link to={'/network/reservations/' + order.reservation_list.guid}>{order.reservation_list.name}</Link> : '-'}
                                </Descriptions.Item>
                                <Descriptions.Item label='Actions'>
                                    <Button size='small' icon={<SwapOutlined />} onClick={() => this.setState({ showOrderExtendModal: true })}>Extend</Button>
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>
                    </Col>
                    <Col sm={24} md={12}>
                        {order.invoice !== null ? <Card size='small' title='Invoice'>
                            <Descriptions bordered size='small' layout='horizontal' column={1}>
                                <Descriptions.Item label='ID'><Link to={'/billing/invoices/' + order.invoice.id}>#{order.invoice.id}</Link></Descriptions.Item>
                                <Descriptions.Item label='Status'><StatusIndicator el='badge' status={order.invoice.status} /></Descriptions.Item>
                            </Descriptions>
                        </Card> : ''}
                        <br />
                        {order.order_type === 'SHARED_HOSTING' && order.hosting_account !== null ? <Fragment>
                            <Card size='small' title='Hosting account' extra={<Button size='small' onClick={() => this.setState({ showHostingAccountChangeModal: true })} icon={<RetweetOutlined />}>Modify</Button>}>
                                <Descriptions bordered size='small' layout='horizontal' column={1}>
                                    <Descriptions.Item label='Plan'>{order.hosting_account.plan.name} <EditOutlined onClick={() => this.setState({ showHostingPlanChangeModal: true })} /></Descriptions.Item>
                                    <Descriptions.Item label='Name'>{order.hosting_account.name}</Descriptions.Item>
                                    <Descriptions.Item label='Username'>{order.hosting_account.username}</Descriptions.Item>
                                    <Descriptions.Item label='Actions'>
                                        <Link to={'/hosting/' + order.hosting_account.guid}><Button size='small' icon={<EyeOutlined />}>View</Button></Link>
                                    </Descriptions.Item>
                                </Descriptions>
                            </Card>
                            <br />
                            <Card size='small' title='Hosting server'>
                                <Descriptions bordered size='small' layout='horizontal' column={1}>
                                <Descriptions.Item label='Hostname'>{order.hosting_account.hosting_server.hostname}</Descriptions.Item>
                                <Descriptions.Item label='IP address'>{order.hosting_account.hosting_server.ip_address}</Descriptions.Item>
                                </Descriptions>
                            </Card>
                        </Fragment>: ''}
                        {order.order_type === 'SHARED_HOSTING' && order.hosting_account === null ? <Fragment>
                            <Card size='small' title='Hosting account'>
                                <Alert showIcon
                                       message={<span>Hosting account not created!</span>}
                                       action={<Button size='small' loading={this.state.creatingHostingAccount} disabled={this.state.creatingHostingAccount} onClick={() => this.createHostingAccount(order.id)} icon={<PlusOutlined />}>Create</Button>}
                                       type='warning' />
                            </Card>
                        </Fragment> : ''}
                        {order.order_type === 'IP_ADDRESS_HOSTING' ? <Card size='small' title='IP Address Information'>
                            <Descriptions bordered size='small' layout='horizontal' column={1}>
                                <Descriptions.Item label='IP address type'>{normalizeEnum(order.ip_address_type)}</Descriptions.Item>
                                <Descriptions.Item label='IP address filter'>{normalizeEnum(order.ip_address_filter)}</Descriptions.Item>
                                <Descriptions.Item label='Excluded IP groups'>{order.excluded_groups.map((group, i) => <div key={i}><Link to={'/ip-addresses/?group=' + group.guid}>{group.name}</Link></div>)}</Descriptions.Item>
                                <Descriptions.Item label='Locations'>{<Table rowKey={item => item.location.guid + item.price_group.guid} dataSource={order.locations} size='small' pagination={false} columns={[{ title: 'Location', dataIndex: 'location', render: (item) => item.full_name}, { title: 'IP count', align: 'center', dataIndex: 'ip_address_count'}, { title: 'Site count', align: 'center', dataIndex: 'site_count'}, { title: 'Price group', dataIndex: 'price_group', render: (item) => <span>{item.name} (<MoneyField amount={parseFloat(item.ip_address_price)} currency='USD' />)</span>}]} />}</Descriptions.Item>
                            </Descriptions>
                        </Card>: ''}
                    </Col>
                </Row>
                <Row hidden={order.order_type !== 'IP_ADDRESS_HOSTING'}>
                    <Col span={24} style={{marginTop: '10px'}}>
                        <Card size='small' title='Items'>
                            {orderItems}
                        </Card>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={24}>
                        <Card size='small' title='Invoices'>
                            <Table dataSource={this.state.invoices}
                                   columns={invoiceColumns}
                                   size='small'
                                   loading={this.state.loadingInvoices}
                                   pagination={this.state.invoicesPagination}
                                   onChange={(pagination) => this.adminLoadOrderInvoices(pagination.current, pagination.pageSize)}
                                   rowKey={item => item.id} />
                        </Card>
                    </Col>
                </Row>
                {order.order_type === 'SHARED_HOSTING' && order.hosting_account !== null ? <HostingPlanChangeModal
                    showModal={this.state.showHostingPlanChangeModal}
                    reload={() => this.adminLoadOrder()}
                    activeOrder={this.props.match.params.id}
                    selectedHostingPlan={order.hosting_account.plan.guid}
                    close={() => this.setState({ showHostingPlanChangeModal: false })} /> : ''}

                {order.order_type === 'SHARED_HOSTING' && order.hosting_account !== null ? <SharedHostingAccountChangeModal
                    showModal={this.state.showHostingAccountChangeModal}
                    owner={this.state.order.owner.guid}
                    order={this.props.match.params.id}
                    reload={() => this.adminLoadOrder()}
                    close={() => this.setState({ showHostingAccountChangeModal: false })} /> : ''}

                <OrderAddCouponModal
                    orderId={this.props.match.params.id}
                    showModal={this.state.showAddCouponModal}
                    reload={() => this.adminLoadOrder()}
                    close={() => this.setState({ showAddCouponModal: false })} />

                <OrderExtendModal
                    orderId={this.props.match.params.id}
                    renewalDate={order.date_next_invoice}
                    showModal={this.state.showOrderExtendModal}
                    reload={() => this.adminLoadOrder()}
                    close={() => this.setState({ showOrderExtendModal: false })} />
            </Fragment>
        );
    }
}

export default connect(null, { updatePage, adminLoadOrder, adminUpdateOrderField,
    adminLoadOrderInvoices, adminCreateSharedHostingAccount, adminUpdateOrderIPAddresses, adminDeleteOrderCoupon })(OrderView);