import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import {logout} from "../../actions/auth";
import {Redirect} from "react-router-dom";

class Logout extends React.Component
{
    componentDidMount() {
        this.props.logout((res) => {
            return <Redirect to={'/auth/login'} />;
        });
    }

    render() {
        return(<Fragment/>);
    }
}

export default connect(null, { logout })(Logout);