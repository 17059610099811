import {httpGet} from "../libs/http";

export const adminBillingReportsMonthlyGrouped = (month, year, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/reports/accounting/monthly-grouped/?month=' + month + '&year=' + year, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminBillingReportsDailyOrders = (orderType, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/reports/accounting/daily-orders/?order_type=' + orderType, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminBillingReportsUpcomingOrders = (orderType, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/reports/accounting/upcoming-orders/?order_type=' + orderType, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminBillingMonthlyGroupedInvoices = (company_id, company_name, currency, vat_id, month, year, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/reports/accounting/monthly-grouped/details/?company_id=' + company_id + '&company_name=' + company_name + '&currency=' + currency +
        '&vat_id=' + vat_id + '&month=' + month + '&year=' + year, (res) => onSuccess(res), (err) => onFailure(err));
}

export const adminMonthlyInvoicesReport = (month, year, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/reports/accounting/monthly-invoices/?month=' + month + '&year=' + year, (res) => onSuccess(res), (err) => onFailure(err));
};

export const adminMonthlyRefundsReport = (month, year, onSuccess = null, onFailure = null) => dispatch => {
    httpGet('/admin/billing/reports/accounting/monthly-refunds/?month=' + month + '&year=' + year, (res) => onSuccess(res), (err) => onFailure(err));
};