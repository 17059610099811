import React, {Fragment} from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";
import {adminLoadMessages} from "../../actions/messages";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {Col, Pagination, Row, Space, Table, Tag, Tooltip} from "antd";
import {DEFAULT_PER_PAGE} from "../../config";
import Button from "antd/es/button";
import {DeleteOutlined, EyeOutlined, FilterOutlined} from "@ant-design/icons";
import UndoOutlined from "@ant-design/icons/lib/icons/UndoOutlined";
import {StatusIndicator} from "../../components/shared/Status";
import {DateTimeFormat} from "../../components/shared/DateTimeFormat";
import MessagesFilterModal from "../../components/messages/MessagesFilterModal";
import MessageDetailModal from "../../components/messages/MessageDetailModal";


class Messages extends React.Component {
    state = {
        pagination: { current: 1, pageSize: DEFAULT_PER_PAGE, total: 0, showSizeChanger: true },
        loading: false,
        filters: [],
        showFiltersModal: false,
        messages: [],
        showMessageDetailModal: false,
        activeMessage: null,
    };

    componentDidMount() {
        this.props.updatePage('Messages');
        this.loadMessages();
    }

    loadMessages(page = null, per_page = null) {
        if(page == null) {
            page = this.state.pagination.current;
            per_page = this.state.pagination.pageSize;
        }

        this.setState({ loading: true });

        this.props.adminLoadMessages(page, per_page, this.state.filters, (res) => {
            this.setState({ messages: res.data.data, loading: false,
                pagination: { current: res.data.page, pageSize: res.data.per_page, total: res.data.total, showSizeChanger: true }});
        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    clearFilters() {
        this.setState({ filters: [] }, () => this.loadMessages());
    }

    setFilter(filter) {
        let filters = this.state.filters.filter(f => f.key !== filter.key);
        filters.push(filter);

        this.setState({ filters }, () => this.loadMessages());
    }

    removeFilter(item) {
        let filters = this.state.filters.filter(filter => filter.key !== item.key);
        this.setState({ filters }, () => this.loadMessages());
    }

    showFilters() {
        return this.state.filters.map((filter, i) => {
            let value;

            if (typeof filter.value === 'boolean') {
                if (filter.value) {
                    value = 'Yes';
                } else {
                    value = 'No';
                }
            } else {
                if (filter.visible !== null) {
                    value = filter.visible;
                } else {
                    value = normalizeEnum(filter.value);
                }
            }

            return <Tag key={i} closable onClose={() => this.removeFilter(filter)}>{filter.name}: {value}</Tag>;
        });
    }

    viewMessage(message) {
        this.setState({ showMessageDetailModal: true, activeMessage: message });
    }

    render() {
        const columns = [
            { title: 'ID', dataIndex: 'id' },
            { title: 'Type', dataIndex: 'message_type', render: (item) => <StatusIndicator status={item} el='badge' /> },
            { title: 'Created', dataIndex: 'date_created', render: (item) => <DateTimeFormat value={item} /> },
            { title: 'Messages', dataIndex: 'excerpt' },
            { title: '', align: 'right', dataIndex: 'id', render: (_, record) => <Button size='small' icon={<EyeOutlined />} onClick={() => this.viewMessage(record)}>View</Button>}
        ];

        return <Fragment>
            {this.showFilters()}
            <Row justify='space-between' style={{marginBottom: '10px'}}>
                <Col>
                    <Space>
                        <Button size='small' type='text' icon={<FilterOutlined />} onClick={() => this.setState({ showFiltersModal: true })}>Filters</Button>
                        <Button size='small' type='text' disabled={this.state.filters.length === 0} onClick={() => this.clearFilters()} icon={<DeleteOutlined />}>Clear Filters</Button>
                    </Space>
                </Col>
                <Col>
                    <Space>
                        <Tooltip title='Reload' placement='left'>
                            <Button size='small' type='circle' loading={this.state.loading} disabled={this.state.loading} icon={<UndoOutlined />} onClick={() => this.loadMessages()} />
                        </Tooltip>
                        <Pagination onChange={(page, pageSize) => this.loadMessages(page, pageSize)} size='small' {...this.state.pagination} />
                    </Space>
                </Col>
            </Row>

            <Table
                columns={columns}
                rowKey={(item) => item.id}
                dataSource={this.state.messages}
                loading={this.state.loading}
                footer={() => <Row justify='space-between'>
                    <Col />
                    <Col><Pagination onChange={(page, pageSize) => this.loadMessages(page, pageSize)} size='small' {...this.state.pagination} /></Col>
                </Row>}
                pagination={false} />

            <MessageDetailModal
                message={this.state.activeMessage}
                showModal={this.state.showMessageDetailModal}
                closeModal={() => this.setState({ showMessageDetailModal: false, activeMessage: null })} />

            <MessagesFilterModal
                showModal={this.state.showFiltersModal}
                closeModal={() => this.setState({ showFiltersModal: false })}
                onChange={(filter) => this.setFilter(filter) } />
        </Fragment>;
    }
}

export default connect(null, { updatePage, adminLoadMessages })(Messages);