import React from "react";
import {connect} from "react-redux";
import {Button, Form, Input, Modal, Select, Space} from "antd";
import {displayErrors, normalizeEnum} from "../../libs/utils";
import {adminLoadAllLocations} from "../../actions/ip_addresses";
import {adminLoadAllIPNodes, adminLoadAllProviders} from "../../actions/network";

class IPAddressesTableFilter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedFilter: 'IP_ADDRESS',
            filterValue: '',
            filterKey: 'ip_address',
            filterVisible: null,

            loadingIPNodes: false,
            ipNodes: [],
            loadingProviders: false,
            providers: [],
            loadingLocations: false,
            locations: []
        };
    }

    onChange(){
        let visibleValue = null;

        if(this.state.selectedFilter === 'PROVIDER') {
            visibleValue = this.getProviderName(this.state.filterValue);
        } else if(this.state.selectedFilter === 'LOCATION') {
            visibleValue = this.getLocationName(this.state.filterValue);
        }

        this.props.onChange({
            name: normalizeEnum(this.state.selectedFilter),
            key: this.state.filterKey,
            value: this.state.filterValue.trim(),
            visible: visibleValue
        });
    }

    closeModal(apply = false, close = true) {
        this.setState({
            selectedFilter: 'IP_ADDRESS',
            filterValue: '',
            filterKey: 'ip_address',
            filterVisible: null,

            loadingIPNodes: false,
            ipNodes: [],
            loadingProviders: false,
            providers: [],
            loadingLocations: false,
            locations: []
        });

        if(close) {
            this.props.closeModal();
        }

        if(apply) {
            this.onChange();
        }
    }

    getProviderName(guid) {
        let provider = null;

        for(let i = 0; i < this.state.providers.length; i++) {
            if(this.state.providers[i].guid === guid) {
                provider = this.state.providers[i];
                break;
            }
        }

        return provider !== null ? provider.name : '';
    }

    getLocationName(guid) {
        let location = null;

        for(let i = 0; i < this.state.locations.length; i++) {
            if(this.state.locations[i].guid === guid) {
                location = this.state.locations[i];
                break;
            }
        }

        return location !== null ? location.full_name : '';
    }

    setFilter(name) {
        const filters = {
            STATUS: { key: 'status', value: 'ACTIVE' },
            IP_ADDRESS: { key: 'ip_address', value: '' },
            IP_TYPE: { key: 'ip_address_type', value: '' },
            IP_NODE: { key: 'ip_node', value: '' },
            PROVIDER: { key: 'provider', value: '' },
            LOCATION: { key: 'location', value: '' },
        };

        this.setState({ selectedFilter: name, filterKey: filters[name].key, filterValue: filters[name].value });

        if(name === 'IP_NODE') {
            this.setState({ loadingIPNodes: true });

            this.props.adminLoadAllIPNodes((res) => {
                this.setState({ loadingIPNodes: false, ipNodes: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingIPNodes: false });
                    displayErrors(err.response.data);
                }
            });
        } else if(name === 'PROVIDER') {
            this.setState({ loadingProviders: true });

            this.props.adminLoadAllProviders((res) => {
                this.setState({ loadingProviders: false, providers: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingProviders: false });
                    displayErrors(err.response.data);
                }
            });
        } else if(name === 'LOCATION') {
            this.setState({ loadingLocations: true });

            this.props.adminLoadAllLocations((res) => {
                this.setState({ loadingLocations: false, locations: res.data.data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingLocations: false });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    render() {
        const { Option } = Select;

        let content = '';

        if(this.state.selectedFilter === 'IP_ADDRESS') {
            content = <Input value={this.state.filterValue}
                             onChange={(e) => this.setState({filterValue: e.target.value})}/>;
        } else if(this.state.selectedFilter === 'STATUS') {
            content = <Select onChange={(value) => this.setState({ filterValue: value })}>
                <Option value='ACTIVE'>Active</Option>
                <Option value='DISABLED'>Disabled</Option>
                <Option value='DELETED'>Deleted</Option>
            </Select>
        } else if(this.state.selectedFilter === 'IP_TYPE') {
            content = <Select onChange={(value) => this.setState({ filterValue: value })}>
                <Option value='DEDICATED'>Dedicated</Option>
                <Option value='SHARED'>Shared</Option>
            </Select>
        } else if(this.state.selectedFilter === 'IP_NODE') {
            content = <Select
                filterOption={(input, option) => {
                    if(typeof option !== 'undefined') {
                        return option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0 || option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                }}
                loading={this.state.loadingIPNodes}
                disabled={this.state.loadingIPNodes}
                value={this.state.filterValue}
                showSearch
                allowClear
                onChange={(value) => this.setState({ filterValue: value })}>
                {this.state.ipNodes.map((ipNode, i) => {
                    return <Option key={i} value={ipNode.guid}>{ipNode.hostname.replace('.gateway.prioritynap.net', '')} ({ipNode.provider.name})</Option>
                })}
            </Select>
        } else if(this.state.selectedFilter === 'PROVIDER') {
            content = <Select
                filterOption={(input, option) => {
                    if(typeof option !== 'undefined') {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                }}
                loading={this.state.loadingProviders}
                disabled={this.state.loadingProviders}
                value={this.state.filterValue}
                showSearch
                allowClear
                onChange={(value) => this.setState({ filterValue: value })}>
                {this.state.providers.map((provider, i) => {
                    return <Option key={i} value={provider.guid}>{provider.name}</Option>
                })}
            </Select>
        } else if(this.state.selectedFilter === 'LOCATION') {
            content = <Select
                filterOption={(input, option) => {
                    if(typeof option !== 'undefined') {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                    }
                    return false;
                }}
                showSearch
                allowClear
                loading={this.state.loadingLocations}
                disabled={this.state.loadingLocations}
                value={this.state.filterValue}
                onChange={(value) => this.setState({ filterValue: value })}>
                {this.state.locations.map((location, i) => {
                    return <Option key={i} value={location.guid}>{location.full_name}</Option>
                })}
            </Select>
        }

        return(
            <Modal
                title='Add Filter'
                destroyOnClose={true}
                onCancel={() => this.closeModal()}
                footer={<Space>
                    <Button onClick={() => this.closeModal()}>Close</Button>
                    <Button onClick={() => this.closeModal(true, false)}>Apply</Button>
                    <Button type='primary' onClick={() => this.closeModal(true, true)}>Apply & Close</Button>
                </Space>}
                visible={this.props.showModal}>
                <Form layout='vertical'>
                    <Form.Item label='Select filter:'>
                        <Select value={this.state.selectedFilter} onChange={(value) => this.setFilter(value)}>
                            <Option value='STATUS'>Status</Option>
                            <Option value='IP_ADDRESS'>IP address</Option>
                            <Option value='IP_TYPE'>IP address type</Option>
                            <Option value='IP_NODE'>IP node</Option>
                            <Option value='PROVIDER'>Provider</Option>
                            <Option value='LOCATION'>Location</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        {content}
                    </Form.Item>
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { adminLoadAllIPNodes, adminLoadAllProviders, adminLoadAllLocations })(IPAddressesTableFilter);