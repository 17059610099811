import {Route} from "react-router-dom";
import React from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom'

const DefaultRoute = ({component: Component, ...rest}) => {

    return(<Route {...rest} render={(props) => <Component {...props} {...rest} />} />);
};

export default withRouter(connect()(DefaultRoute));