import React from "react";
import {Button, Form, Modal, Select, Space, message, DatePicker} from "antd";
import {connect} from "react-redux";
import {displayErrors} from "../../libs/utils";
import moment from 'moment';
import {adminExtendOrder, adminLoadAllInvoices} from "../../actions/billing";

class OrderExtendModal extends React.Component {
    state = {
        selectedAction: '',
        selectedValue: null,
        loading: false,
        invoiceList: [],
        loadingInvoices: true,
        renewalDate: this.props.renewalDate,
        newRenewalDate: '',
        destinationInvoice: 'NEW_INVOICE',
        destinationInvoiceId: null,
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps !== prevState) {
            return nextProps;
        }

        return null;
    }

    closeModal(reload = false) {
        this.setState({
            newRenewalDate: '',
            destinationInvoice: 'NEW_INVOICE',
            destinationInvoiceId: null,
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    performAction() {
        let data = {
            invoice_action: this.state.destinationInvoice,
            invoice_id: this.state.destinationInvoiceId,
            new_renewal_date: this.state.newRenewalDate
        };

        this.setState({ loading: true });

        this.props.adminExtendOrder(this.props.orderId, data, (res) => {
            this.closeModal(true);

            this.setState({ loading: false });

            message.success('Changes successfully submitted!');

        }, (err) => {
            if(typeof err.response !== 'undefined') {
                this.setState({ loading: false });
                displayErrors(err.response.data);
            }
        });
    }

    updateRenewalDate(_, value) {
        this.setState({ renewalDate: moment(_, ).format('YYYY-MM-DD'), newRenewalDate: value });
    }

    updateDestinationInvoice(value) {
        if(value === 'EXISTING_INVOICE') {
            this.setState({ loadingInvoices: true });

            this.props.adminLoadAllInvoices((res) => {
                this.setState({ invoiceList: res.data.data, loadingInvoices: false, destinationInvoice: value });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingInvoices: false, invoiceList: [], destinationInvoice: value });
                    displayErrors(err.response.data);
                }
            });
        } else {
            this.setState({ destinationInvoice: value, destinationInvoiceId: null });
        }
    }

    render() {
        const { Option } = Select;

        let existingInvoiceContent = '';

        if(this.state.destinationInvoice === 'EXISTING_INVOICE') {
            existingInvoiceContent = <Form.Item label='Select invoice'>
                <Select loading={this.state.loadingInvoices} disabled={this.state.loadingInvoices} onChange={(value) => this.setState({ destinationInvoiceId: value })}>
                    {this.state.invoiceList.map((item, i) => {
                        return <Option key={i} value={item.id}>#{item.id}</Option>
                    })}
                </Select>
            </Form.Item>;
        }

        return(
            <Modal
                destroyOnClose={true}
                title='Extend Order'
                visible={this.props.showModal}
                footer={<Space>
                    <Button disabled={this.state.loading} onClick={() => this.closeModal()}>Close</Button>
                    <Button disabled={this.state.loading} loading={this.state.loading}
                            onClick={() => this.performAction()} type='primary'>Submit</Button>
                </Space>}
                onCancel={() => this.closeModal()}>
                <Form layout='vertical'>
                    <Form.Item label='New renewal date'>
                        <DatePicker defaultValue={moment(this.state.renewalDate, 'YYYY-MM-DD')} format='YYYY-MM-DD' onChange={(e, _) => this.updateRenewalDate(e, _)} />
                    </Form.Item>
                    <Form.Item label='Destination invoice'>
                        <Select value={this.state.destinationInvoice} onChange={(value) => this.updateDestinationInvoice(value)}>
                            <Option value='NEW_INVOICE'>New invoice</Option>
                            <Option value='EXISTING_INVOICE'>Existing invoice</Option>
                        </Select>
                    </Form.Item>
                    {existingInvoiceContent}
                </Form>
            </Modal>
        )
    }
}

export default connect(null, { adminLoadAllInvoices, adminExtendOrder })(OrderExtendModal);