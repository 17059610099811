import React from "react";
import {connect} from "react-redux";
import {updatePage} from "../../actions/page";

class AffiliateBilling extends React.Component {
    componentDidMount() {
        this.props.updatePage('Billing');
    }

    render() {
        return <p>Affiliate billing</p>;
    }
}

export default connect(null, { updatePage })(AffiliateBilling);