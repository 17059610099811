import React from "react";
import {connect} from "react-redux";
import {Button, Col, DatePicker, Form, InputNumber, Modal, Row, Select, Space, message} from "antd";
import { Input } from 'antd';
import {adminCreateInvoiceItem, admingLoadInvoiceItem, adminUpdateInvoiceItem} from "../../actions/billing";
import {displayErrors} from "../../libs/utils";
import moment from 'moment';

class InvoiceItemModal extends React.Component {
    state = {
        description: '',
        quantity: 1,
        rate: 0.0,
        rateCurrency: 'USD',
        subtotal: 0.0,
        subtotalCurrency: 'USD',
        taxable: true,
        periodStart: '',
        periodEnd: '',
        periodStartValue: '',
        periodEndValue: '',
        loading: false,
        loadingInvoiceItem: false,
        invoiceItemLoaded: false
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showModal && this.props.invoiceItem !== null && !this.state.loadingInvoiceItem && !this.state.invoiceItemLoaded) {
            this.setState({ loadingInvoiceItem: true });

            this.props.admingLoadInvoiceItem(this.props.invoice, this.props.invoiceItem, (res) => {
                let data = {
                    description: res.data.description,
                    quantity: res.data.quantity,
                    rate: res.data.rate,
                    taxable: res.data.taxable,
                    periodStart: res.data.period_start,
                    periodStartValue: moment(res.data.period_start, 'YYYY-MM-DD'),
                    periodEnd: res.data.period_end,
                    periodEndValue: moment(res.data.period_end, 'YYYY-MM-DD'),
                };

                this.setState({ loadingInvoiceItem: false, invoiceItemLoaded: true, ...data });
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loadingInvoiceItem: false, invoiceItemLoaded: true });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    closeModal(reload = false) {
        this.setState({
            description: '',
            quantity: 1,
            rate: 0.0,
            rateCurrency: 'USD',
            subtotal: 0.0,
            subtotalCurrency: 'USD',
            taxable: true,
            periodStart: '',
            periodEnd: '',
            periodStartValue: '',
            periodEndValue: '',
            loading: false,
            loadingInvoiceItem: false,
            invoiceItemLoaded: false
        });

        this.props.close();

        if(reload) {
            this.props.reload();
        }
    }

    saveItem() {
        let description = this.state.description.trim();
        let quantity = this.state.quantity;
        let rate = parseFloat(this.state.rate);
        let subtotal = this.state.subtotal;
        let taxable = this.state.taxable;
        let period_start = this.state.periodStart.trim();
        let period_end = this.state.periodEnd.trim();

        if(description.length === 0) {
            return message.error('Please enter invoice item description!');
        }

        if(parseFloat(quantity) === 0) {
            return message.error('Please enter quantity!');
        }

        if(parseFloat(rate) === 0) {
            return message.error('Please enter correct rate!');
        }

        let data = { description, quantity, rate, taxable };

        if(parseFloat(subtotal) > 0) {
            data['sub_total'] = parseFloat(subtotal);
        }

        if(period_start !== '' && period_end !== '') {
            data['period_start'] = period_start;
            data['period_end'] = period_end;
        }

        this.setState({ loading: true });

        if(this.props.invoiceItem === null) {
            this.props.adminCreateInvoiceItem(this.props.invoice, data, (res) => {
                message.success('Invoice item successfully created!');
                this.setState({ loading: false });
                this.closeModal(true);
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loading: false });
                    displayErrors(err.response.data);
                }
            });
        } else {
            this.props.adminUpdateInvoiceItem(this.props.invoice, this.props.invoiceItem, data, (res) => {
                message.success('Invoice item successfully updated!');
                this.setState({ loading: false });
                this.closeModal(true);
            }, (err) => {
                if(typeof err.response !== 'undefined') {
                    this.setState({ loading: false });
                    displayErrors(err.response.data);
                }
            });
        }
    }

    render() {
        const { TextArea } = Input;
        const { RangePicker } = DatePicker;
        const { Option } = Select;

        return(
            <Modal
                title={this.props.invoiceItem !== null ? 'Edit Invoice Item' : 'Add Invoice Item'}
                visible={this.props.showModal}
                destroyOnClose={true}
                footer={<Space>
                    <Button disabled={this.state.loading || this.state.loadingInvoiceItem} onClick={() => this.closeModal()}>Close</Button>
                    <Button disabled={this.state.loading || this.state.loadingInvoiceItem}  loading={this.state.loading}  type='primary' onClick={() => this.saveItem()}>{this.props.invoiceItem !== null ? 'Update' : 'Create'}</Button>
                </Space>}
                onCancel={() => this.closeModal()}>
                <Form layout='vertical'>
                    <Form.Item label='Description:'>
                        <TextArea rows={2} value={this.state.description}
                                  onChange={(e) => this.setState({ description: e.target.value })} />
                    </Form.Item>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <Form.Item label='Quantity:'>
                                <InputNumber value={this.state.quantity}
                                             onChange={(value) => this.setState({ quantity: parseInt(value) })} />
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item label='Rate:'>
                                <Input value={this.state.rate}
                                       addonAfter={this.props.currency}
                                       onChange={(e) => this.setState({ rate: e.target.value})} />
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item label='Sub-total:' extra={<small><em>* optional</em></small>}>
                                <Input value={this.state.subtotal}
                                       addonAfter={this.props.currency}
                                       onChange={(e) => this.setState({ subtotal: e.target.value })} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item label='Period:' extra={<small><em>* optional</em></small>}>
                                <RangePicker format="YYYY-MM-DD"
                                             value={[this.state.periodStartValue, this.state.periodEndValue]}
                                             onChange={(_, value) => this.setState({
                                                 periodStart: value[0],
                                                 periodEnd: value[1],
                                                 periodStartValue: _[0],
                                                 periodEndValue: _[1]
                                             })} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label='Taxable:'>
                                <Select value={this.state.taxable} onChange={(value) => this.setState({ taxable: value })}>
                                    <Option value={true}>Yes</Option>
                                    <Option value={false}>No</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        );
    }
}

export default connect(null, { adminCreateInvoiceItem, adminUpdateInvoiceItem, admingLoadInvoiceItem })(InvoiceItemModal);